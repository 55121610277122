import React from "react";
import {SimpleCalendar} from "assecobs-faktor-web-common/index";
import {injectIntl} from 'react-intl';

class DateInputField extends React.PureComponent {

    componentDidMount() {
        if (this.props.initialDate) {
            this.props.input.onChange(this.props.initialDate);
        }
    }

    onChange = (date) => {
        if (date) {
            this.props.input.onChange(date);
        }
        else
            this.props.input.onChange(null);
    };

    render() {
        const {input, invalid, initialDate, disabled} = this.props;

        return (
            <SimpleCalendar dateHandler={this.onChange}
                            customInitialDate={initialDate || input.value}
                            invalid={invalid}
                            disabled={disabled}
            />
        );
    }

    static get defaultProps() {
        return {
            widthLevel: 1
        }
    }
}

export default injectIntl(DateInputField);
