import React from "react";
import classnames from "classnames";
import _ from "lodash";

export default class TextInputField extends React.PureComponent {

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.focus, this.props.focus) && this.props.focus) {
            this._input.focus();
        }
    }

    render() {
        const {input, invalid, maxLength, disabled, style} = this.props;

        return (
            <input ref={c => this._input = c}
                   {...input}
                   {...maxLength && {maxLength: maxLength}}
                   className={classnames({
                       "k-invalid": invalid
                   }, style)}
                   disabled={disabled}
            />
        )

    }

    static get defaultProps() {
        return {
            widthLevel: 2
        }
    }
}
