import * as actionTypes from "./actionTypes";
import {fromJS, List} from "immutable";

const initState = {
    loading: false,
    list: List(),
    loadingStats: false,
    unreadCount: undefined
};

export default function (state = initState, action) {

    switch (action.type) {
        case actionTypes.LOAD:
            return {
                ...state,
                loading: true
            };
            break;

        case actionTypes.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                list: fromJS(action.alerts)
            };
            break;

        case actionTypes.LOAD_STATS:
            return {
                ...state,
                loadingStats: true
            };
            break;

        case actionTypes.LOAD_STATS_SUCCESS:
            return {
                ...state,
                loadingStats: false,
                unreadCount: action.stats.unread
            };
            break;
        case actionTypes.MARK_ONE_AS_READ:
            const index = state.list.findIndex(item => item.get("id") === action.alertId)

            return {
                ...state,
                list: state.list.setIn([index, "read"], true),
            };
            break;
    }

    return state;
}
