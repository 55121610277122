import React from 'react';
import PropTypes from 'prop-types';
import {I18nMessage} from "assecobs-faktor-web-common/index";
import PostActionButton from "./PostActionButton";

const noop = () => {
};

const defaultButtonsDefinition = [{
    ButtonText: <I18nMessage id="common.closeWindow"/>,
    ButtonAction: "CLOSE"
}];

const PostActionModalButtonPanel = ({buttonsDefinition = [], closeModal = noop}) => {

    const buttonsDef = buttonsDefinition.length > 0 ? buttonsDefinition : defaultButtonsDefinition;

    return (
        <React.Fragment>
            {buttonsDef.map(button => (
                <PostActionButton closeModal={closeModal} buttonInfo={button} key={button.ButtonText}/>
            ))}
        </React.Fragment>
    );
};

PostActionModalButtonPanel.propTypes = {
    buttonsDefinition: PropTypes.array,
    closeModal: PropTypes.func
};

export default PostActionModalButtonPanel;