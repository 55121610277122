import React from "react";
import MediaQuery from "react-responsive";
import DropdownMenu from "../dropdownMenu/DropdownMenu";

const MobileToolbar = ({toolbar}) => {
    if (toolbar.length > 1) {
        return (
            <DropdownMenu className="abs-page-header__toolbar__dropdown" buttonClassName="btn-type-a">
                {toolbar.map((element, index) => (
                    <li role="presentation" key={index}>
                        <span className="menuitem">
                            {element}
                        </span>
                    </li>
                ))}
            </DropdownMenu>
        )
    }

    return toolbar;
};

export default ({toolbar}) => {

    if (!toolbar) {
        return null;
    }

    return (
        <div className="abs-page-header__toolbar">
            <MediaQuery maxWidth={767}>
                <MobileToolbar toolbar={toolbar} />
            </MediaQuery>

            <MediaQuery minWidth={768}>
                {toolbar}
            </MediaQuery>
        </div>
    )
};