import React from "react";
import CollapsibleDropdownMenu from "./CollapsibleDropdownMenu";

export default class CollapsibleMenu extends React.PureComponent {

    render() {
        const {items} = this.props;

        if (!items) {
            return null;
        }

        const menuItems = items.map((item, index) => {
            switch (item.type) {
                case "DROPDOWN_MENU":
                    return (
                        <CollapsibleDropdownMenu key={index}
                                                 icon={item.icon}
                                                 label={item.label}
                                                 styleClass={item.styleClass}
                                                 content={item.content}
                                                 openOnRight={index > 2}
                        />
                    );

                default:
                    return null;
            }
        });

        return menuItems;
    }
}
