import storageManager from '../storageManager/storageManager';

const storage = Symbol();

class LocalStorage {

    constructor() {
        this[storage] = {};
    }

    initialize() {
        const localStorageItem = storageManager.getLocalStorageItem();

        if (localStorageItem != null && this[storage].hasOwnProperty('config'))  {
            this[storage] = storageManager.getLocalStorageItem().config;
        }
    }

    getItem(key) {
        if (this[storage].hasOwnProperty(key)) {
            return this[storage][key];
        }

        return void 0;
    }
}

export default new LocalStorage();