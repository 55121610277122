import React from "react";
import Money from "./Money";
import Percent from "./Percent";
import _ from "lodash";
import PropTypes from "prop-types";
import * as AppProperties from "assecobs-faktor-web-profile/AppProperties";

export default class Commission extends React.Component {


    render() {
        const {amount, currency, percent, type} = this.props;

        if (type === "PERCENT_PLUS_AMOUNT") {
            return (
                <span>
                    {percent > 0 && <Percent value={percent}
                                             minFractionDigits={AppProperties.MINIMUM_FRACTION_DIGITS}
                                             maxFractionDigits={AppProperties.MAXIMUM_FRACTION_DIGITS}/>}
                    {(percent > 0) && (amount > 0) && !_.isNil(amount) && !_.isNil(percent) && " + "}
                    {amount > 0 && <Money amount={amount} currency={currency}/>}
                </span>
            )
        }

        return (
            <span>
                {amount > 0 && <Money amount={amount} currency={currency}/>}
                {(percent > 0) && (amount > 0) && !_.isNil(amount) && !_.isNil(percent) && " + "}
                {percent > 0 && <Percent value={percent}
                                         minFractionDigits={AppProperties.MINIMUM_FRACTION_DIGITS}
                                         maxFractionDigits={AppProperties.MAXIMUM_FRACTION_DIGITS}/>}
            </span>
        )
    }

    static get propTypes() {
        return {
            amount: PropTypes.number,
            currency: PropTypes.string,
            percent: PropTypes.number,
            type: PropTypes.oneOf(["AMOUNT_PLUS_PERCENT", "PERCENT_PLUS_AMOUNT"])
        }
    }
}
