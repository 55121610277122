import React from "react";
import {SingleSelectFilterComponent} from "assecobs-react-data-table/index";
import {fwUrlUtils, RestService} from "assecobs-faktor-web-common/index";
import BaseAgreementSingleOptionTemplate from "../filters/baseAgreement/BaseAgreementSingleOptionTemplate";
import BaseAgreementSingleSelectedTemplate from "../filters/baseAgreement/BaseAgreementSingleSelectedTemplate";
import baseAgreementFilterParams from "../filters/baseAgreement/baseAgreementFilterParams";
import _ from "lodash";
import {injectIntl} from 'react-intl';
import classnames from "classnames";

const URL = fwUrlUtils.getApiUrl("/webapi/filter/baseAgreements/all");

class BaseAgreementSelectForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: null,
            loading: false
        }
    }

    getBaseAgreements = async () => {

        await this.setState({
            loading: true
        });

        const baseAgreements = await RestService.postJSON(URL);

        await this.setState({
            options: baseAgreements,
            loading: false
        });

        if (_.isEqual(baseAgreements.length, 1)) {
            this.props.input.onChange(baseAgreements[0].baseAgreementAnnex0Id);
            this.props.onFieldValueChange(baseAgreements[0].actualAgreementNo);

        }

    };

    componentDidMount = async () => {
        await this.getBaseAgreements();
    };

    onChange = (agreement) => {
        if (!_.isNil(agreement)) {
            this.props.input.onChange(agreement.baseAgreementAnnex0Id);
            this.props.onFieldValueChange(agreement);
        }
    };

    getLoading = () => {
        if (_.isNil(this.props.loading)) {
            return this.state.loading;
        }
        return this.props.loading || this.state.loading;
    }

    render() {
        const {input, customClass, intl} = this.props;
        const loading = this.getLoading();

        return <SingleSelectFilterComponent
            ref={(c) => this._selectComponent = c}
            {...baseAgreementFilterParams}
            searchPlaceholder={intl.formatMessage({id: "fw.filter.baseAgreement.searchPlaceholder"})}
            customClass={classnames("k-dropdown", customClass)}
            initialValue={(input && !_.isNil(input.value) && !_.isEmpty(input.value)) ? input.value : undefined}
            title={this.props.intl.formatMessage({id: "fw.select.placeholder"})}
            options={this.state.options}
            loading={loading}
            clearable={false}
            onChange={this.onChange}
            optionTemplate={BaseAgreementSingleOptionTemplate}
            selectedValueTemplate={BaseAgreementSingleSelectedTemplate}
        />;
    }
}

export default injectIntl(BaseAgreementSelectForm);
