import React from "react";
import Button from "../../button/Button";
import I18nMessage from "../../i18n/I18nMessage";
import ClientSelectOption from "./ClientSelectOption";
import UserProfileOption from "./UserProfileOption";
import fwUrlUtils from "../../utils/fwUrlUtils";
import {Permission} from "@assecobs/react-common-components";

class ConfigurationMenuItem extends React.Component {

    showSettings = () => {
        window.location.href = fwUrlUtils.getApiUrl("/settings");
    };

    render() {
        return (
            <div className="fw-menu-configuration">
                <UserProfileOption/>
                <ClientSelectOption/>
                <Permission has="CLIENT_IS_FACTORER">
                    <Button styleType="D"
                            icon="abs_settings"
                            text={<I18nMessage id="fw.settings"/>}
                            onClick={this.showSettings}
                            className="fw-menu-configuration__btn"
                    />
                </Permission>
            </div>
        )
    }
}

export default ConfigurationMenuItem;
