import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index";

export default class LoadMore extends React.Component {

    onLoadMore = () => {
        this.props.onLoadMore(this.props.loadedPagesCount + 1);
    };

    render() {
        const {totalCount, loadedRecordsCount, width} = this.props;

        if (totalCount > 0 && loadedRecordsCount < totalCount) {
            return (
                <div className="load-more" onClick={this.onLoadMore} style={{width}}>
                    <a><I18nMessage id="common.loadMore"/>...</a>
                </div>
            )
        }

        return null;
    }
}
