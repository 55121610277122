import React from "react";
import MediaQuery from "react-responsive";
import classnames from "classnames";
import _ from "lodash";

import {Loadable, Icon} from "@assecobs/react-common-components/index";
import {I18nMessage} from "assecobs-faktor-web-common";

import ExpandableListHeader from "./ExpandableListHeader";
import ExpandableListDataRow from "./ExpandableListDataRow";
import NoData from "../common/NoData";
import LoadMore from "../common/LoadMore";
import BarComponent from "../bar/BarComponent";
import BarSortingComponent from "../bar/BarSortingComponent";

export default class ExpandableListComponent extends React.Component {

    constructor() {
        super();
        this.state = {
            columnNameKey: null,
            sortingDirection: null,
            sortableColumns: []
        };
    }

    componentDidMount() {
        if (!_.isNil(this.props.columns)) {

            const sortableArray = [];

            _.forEach(this.props.columns, (column) => {
                if (column.sortable) {
                    sortableArray.push(column);
                }
            });

            _.forEach(sortableArray, (data) => {
                data.upArrowActive = false;
                data.downArrowActive = false;
            });

            this.setState({sortableColumns: [...sortableArray]});

        }
    }

    onSort = (sorting, columnNameKey) => {
        this.props.onSort(sorting);
        this.setState({columnNameKey, sortingDirection: sorting[Object.keys(sorting)[0]]})
    };

    onLoadMore = (page) => {
        this.props.onLoadMore(page);
    };

    onAllSelectionChange = () => {
        this.props.onAllSelectionChange();
    };

    onOneSelectionChange = (id) => {
        this.props.onOneSelectionChange(id);
    };

    render() {
        const {
            id, data, columns, multiselect, loading, totalCount, loadedPages, itemIdProperty, selected, isAllSelected, selectable, sorting,
            expandable, menu, SlaveComponent, onExpand, loadingSlaves, detailsLink
        } = this.props;

        const {columnNameKey, sortingDirection, sortableColumns} = this.state;

        const barInfo = (!_.isNil(columnNameKey) && !_.isNil(sortingDirection) ? <div className="abs-sorting-bar-info">
            <Icon name={_.isEqual(sortingDirection, "ASC") ? "abs_arrow_upward" : "abs_arrow_downward"}/>
            <I18nMessage id={columnNameKey}/>
        </div> : null);

        return [
            sortableColumns.length > 0 && <MediaQuery maxWidth={767} key="bar-component">
                <BarComponent titleKey="common.sort" barInfo={barInfo}>
                    <BarSortingComponent columns={sortableColumns} onSort={this.onSort}/>
                </BarComponent>
            </MediaQuery>,
            <Loadable key="table-component" loading={loading} transparent>
                <div className="abs-flex-table">
                    <div id={"dataTable_" + id}
                         className={classnames(
                             "abs-list-table",
                             "style-a",
                             "abs-md-list",
                             {
                                 "abs-list-table-expandable": expandable
                             },
                             this.props.className)}>

                        <ExpandableListHeader columns={columns}
                                              multiselect={multiselect}
                                              selectAllIds={this.selectAllIds}
                                              selectable={selectable}
                                              isAllSelected={isAllSelected}
                                              onAllSelectionChange={this.onAllSelectionChange}
                                              sorting={sorting}
                                              expandable={expandable}
                                              onSort={this.onSort}
                        />

                        <div className="abs-list-table-tbody">
                            {data.map((row) => {
                                    const id = itemIdProperty ? row.getIn(itemIdProperty.split(".")) : undefined;
                                    return (
                                        <ExpandableListDataRow key={id}
                                                               id={id}
                                                               data={row}
                                                               columns={columns}
                                                               multiselect={multiselect}
                                                               disabled={!selectable.has(id)}
                                                               selected={selected.has(id)}
                                                               onOneSelectionChange={this.onOneSelectionChange}
                                                               expandable={expandable}
                                                               menu={menu}
                                                               SlaveComponent={SlaveComponent}
                                                               onExpand={onExpand}
                                                               loadingSlaves={loadingSlaves}
                                                               detailsLink={detailsLink}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </div>

                    <NoData visible={!loading && data.size === 0}/>

                    <LoadMore totalCount={totalCount}
                              loadedRecordsCount={data.size}
                              loadedPagesCount={loadedPages}
                              onLoadMore={this.onLoadMore}
                    />
                </div>
            </Loadable>
        ]
    }
}
