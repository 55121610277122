import React from "react";
import IconWithText from "../dataPresentation/IconWithText";

export default class InvoiceVATCorrectiveStatus extends React.Component {

    render() {
        const {id, text} = this.props;

        let iconClass = "";
        switch (id) {
            case "TO_CONFIRM":
                iconClass = "abs_help_outline info-icon";
                break;
            case "CONFIRMED":
                iconClass = "abs_check success-icon";
                break;
        }

        return <IconWithText icon={iconClass} text={text} mobile className="invoice-corrective-status-icon"/>
    }

}
