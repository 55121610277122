import React from "react";
import logoutHandler from "../../logout/logoutHandler";
import {Icon} from "@assecobs/react-common-components/index";
import {injectIntl} from 'react-intl';

class LogoutMenuItem extends React.PureComponent {

    logout = () => {
        logoutHandler.logout();
    };

    render() {
        const title = this.props.intl.formatMessage({id: this.props.title});

        return (
            <a onClick={() => this.logout()} title={title}>
                <Icon name={this.props.icon} className="navbar-nav-icon"/>
            </a>
        )
    }
}

export default injectIntl(LogoutMenuItem);
