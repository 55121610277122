import React from "react";
import {Money, I18nMessage} from "assecobs-faktor-web-common/index";

export default class OutstandingText extends React.Component {

    render() {
        const {outstanding, limit, freeLimit, currency} = this.props;

        return (

            <div className="limits">
                <div className="limits-labels">
                            <span className="limit-label">{<I18nMessage
                                id="fw.baseAgreement.limit">Limit</I18nMessage>}</span>
                    <span className="limit-label">{<I18nMessage
                        id="fw.baseAgreement.outstanding">Zaangażowanie</I18nMessage>}</span>
                    <span className="limit-label">{<I18nMessage id="fw.baseAgreement.freeLimit">Dostępne
                        środki</I18nMessage>}</span>
                </div>

                <div className="limits-values">
                    <Money amount={limit} currency={currency}/>
                    <Money amount={outstanding} currency={currency}/>
                    <Money amount={freeLimit} currency={currency}/>
                </div>
            </div>
        )
    }

}
