import React from "react";
import FilterComponent from "./FilterComponent";
import FilterField from "../FilterField";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import _ from "lodash";
import PropTypes from "prop-types";
import rangeValueFormatter from "./utils/rangeValueFormatter";
import FromI18n from "./utils/FromI18n";
import ToI18n from "./utils/ToI18n";
import OKI18n from "./utils/OKI18n";
import CancelI18n from "./utils/CancelI18n";
import numeral from "numeral";
import "numeral/locales/pl";
import {Config} from "assecobs-faktor-web-common/index";
import classnames from "classnames";

const initialState = {
    from: null,
    to: null
};

class NumberRange extends FilterComponent {

    constructor(props, context) {
        super(props, context);
        numeral.locale('pl');

        this.state = initialState;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(this.props.open, nextProps.open) || !_.isEqual(this.state.value, nextState.value);
    }

    componentDidUpdate() {
        if (this.props.open) {
            this.focus();
        }
    }

    focus = () => {
        this.textInput.focus();
    };

    componentWillReceiveProps(nextProps) {
        if (!_.isEmpty(nextProps.input.value)) {
            this.setState({...initialState, ...nextProps.input.value});
        } else {
            this.setState(initialState);
        }
    }

    onFromChange = (e) => {
        this.setState({
            from: e.target.value
        });
    };

    onToChange = (e) => {
        this.setState({
            to: e.target.value
        });
    };

    submit = () => {
        let from = this.state.from;
        let to = this.state.to;

        this.props.onSubmit({
            from: from,
            to: to
        });
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.submit();
        } else if (e.key === "Escape" || e.key === "Esc") {
            this.props.onCancel();
        }
    };

    cancel = () => {
        this.props.onCancel();
    };

    render() {
        const {currency, open} = this.props;

        const mask = createNumberMask({
            prefix: '',
            suffix: '',
            includeThousandsSeparator: false,
            allowDecimal: true,
            decimalSymbol: ",",
            allowNegative: true
        });

        if (!open) {
            return null;
        }

        return (
            <div>
                <div>
                    <div className="abs-filter-form">
                        <div className="abs-filter-range-from">
                            <span className="abs-filter-range-label"><FromI18n i18n={this.props.fromI18n}
                                                                               component={this.props.i18nComponent}/></span>
                            <div
                                className={classnames("abs-filter-number", {"abs-filter-number-behind": !_.isNil(currency)})}>
                                <MaskedInput mask={mask}
                                             value={amountFormatter(this.state.from)}
                                             onChange={this.onFromChange}
                                             onKeyDown={this.onKeyDown}
                                             ref={(input) => {
                                                 if (input) {
                                                     this.textInput = input.inputElement;
                                                 }
                                             }}
                                             inputMode="number"
                                             className="abs-filter-text"
                                             showMask={true}
                                             guide={false}
                                             {...(currency && {style: {textAlign: "right"}})}/>
                                {currency && <span className="abs-filter-number-placeholder">{currency}</span>}
                            </div>
                        </div>

                        <div className="abs-filter-range-to">
                            <span className="abs-filter-range-label"><ToI18n i18n={this.props.toI18n}
                                                                             component={this.props.i18nComponent}/></span>
                            <div
                                className={classnames("abs-filter-number", {"abs-filter-number-behind": !_.isNil(currency)})}>
                                <MaskedInput mask={mask}
                                             value={amountFormatter(this.state.to)}
                                             onChange={this.onToChange}
                                             onKeyDown={this.onKeyDown}
                                             inputMode="number"
                                             className="abs-filter-text"
                                             showMask={true}
                                             guide={false}
                                             {...(currency && {style: {textAlign: "right"}})}/>
                                {currency && <span className="abs-filter-number-placeholder">{currency}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="abs-filter-actions">
                    <button onClick={() => this.submit(Config.apiDateFormat())}
                            className="k-button k-state-default btn btn-type-b btn-xs"><OKI18n
                        i18n={this.props.okI18n} component={this.props.i18nComponent}/></button>
                    <button onClick={this.cancel} className="k-button k-state-default btn btn-type-a btn-xs"><CancelI18n
                        i18n={this.props.cancelI18n}
                        component={this.props.i18nComponent}/>
                    </button>

                    {/*TODO: Do usunięcia po naprawie styli*/}
                    <button className="btn" style={{display: "none"}}/>
                </div>
            </div>
        )
    }

}

NumberRange.contextTypes = {
    intl: PropTypes.any
};

const amountFormatter = (amount) => (amount ? parseFloat(amount).toFixed(2).toString().replace('.', ',') : null);

const valueFormatter = (value, currency, fromI18n, toI18n, component) => {
    const from = value.from ? (amountFormatter(value.from) + (currency ? (" " + currency) : "")) : null;
    const to = value.to ? (amountFormatter(value.to) + (currency ? (" " + currency) : "")) : null;

    return rangeValueFormatter(from, to, fromI18n, toI18n, component);
};

const NumberRangeFilter = (props, context) => (
    <FilterField {...props}
                 normalize={value => {
                     if (!value || _.isEmpty(value)) {
                         return null;
                     }

                     return {
                         ...value.from && {from: numeral(value.from).value()},
                         ...value.to && {to: numeral(value.to).value()}
                     };
                 }}
                 format={value => {
                     return value ? {
                         ...value.from && {from: value.from},
                         ...value.to && {to: value.to}
                     } : null
                 }
                 }
                 Content={NumberRange}
                 selectedValueFormatter={(value) => valueFormatter(value, props.currency, props.fromI18n, props.toI18n, props.i18nComponent)}/>
);

NumberRangeFilter.contextTypes = {
    intl: PropTypes.any
};

export default NumberRangeFilter;
