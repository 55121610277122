import React from "react";
import TaxNumberAndCode from "../../dataPresentation/TaxNumberAndCode";

export default ({option}) => {

    return (
        <div className="option-template-view option-template-view--multiline">
            <div className="option-main-value">{option.shortName}</div>
            <TaxNumberAndCode taxNumberTypeDesc={option.taxNumberTypeDesc}
                                        taxNumber={option.taxNumber}
                                        code={option.code}
            />
        </div>
    );
}
