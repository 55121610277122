import {ReactElement} from 'react'
// @ts-ignore
import {I18nMessage} from 'assecobs-faktor-web-common/index'
import {Nullable} from '@assecobs-js-utils/common-types/src/types/Nullable'
import {isEmpty} from 'ramda'

interface Props {
  files?: Array<string>
  messageId: string
}

const AttachmentErrorList = ({files = [], messageId}: Props): Nullable<ReactElement> => {

  if (isEmpty(files)) {
    return null
  }

  return (
    <>
      <b><I18nMessage id={messageId} />:</b>
      <ul className="fw-dropzone-toast fw-dropzone-toast-ul">
        {files.map((item, key) => (
          <li key={key}><span className="fw-dropzone-toast-li">{item}</span></li>
        ))}
      </ul>
    </>
  )
}

export default AttachmentErrorList