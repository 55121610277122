import React from "react";
import {RestService, I18nMessage} from "assecobs-faktor-web-common/index";
import {injectIntl} from "react-intl";
import _ from "lodash";
import Select from "react-select";
import classnames from "classnames";
import ReactDOM from 'react-dom';

class CreatableSelectInputField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: props.options || null,
            loading: false,
            initialValue: null
        }
    }

    componentDidMount = async() => {
        if (this.props.url) {
            await this.setState({
                loading: true
            });

            const options = (await RestService.getJSON(this.props.url)).list;

            await this.setState({
                options: options,
                loading: false
            });

            const selectInput = ReactDOM.findDOMNode(this.refs.selectCreatable).getElementsByClassName('Select-input');
            selectInput[0].children[0].setAttribute('maxlength', this.props.maxLength);
        }
    };

    componentDidUpdate = async(prevProps) => {
        if (this.props.clear && !_.isEqual(this.props.clear, prevProps.clear)) {
            const tempOptions = this.state.options;

            await this.setState({
                options: null
            });

            this.setState({
                options: tempOptions
            });
        }
    };

    onChange = (option) => {
        if (option) {
            this.props.input.onChange(option[this.props.optionValueProp]);
        }
        else {
            this.props.input.onChange(null);
            this.setState({
                initialValue: null
            });
        }
    };

    render() {
        const {input, optionValueProp, optionNameProp, maxLength, invalid} = this.props;

        return (
            <div>
                <Select.Creatable
                    ref="selectCreatable"
                    multi={false}
                    options={this.state.options}
                    valueKey={optionValueProp}
                    labelKey={optionNameProp}
                    onChange={this.onChange}
                    value={input.value}
                    searchPromptText={null}
                    noResultsText={null}
                    placeholder={null}
                    promptTextCreator={(value) => value}
                    {...maxLength && {maxLength: maxLength}}
                    className={classnames({
                        "k-invalid": invalid
                    })}
                />
                {maxLength && <div className="light-txt remaining-signs-creatable-select-input"><I18nMessage id="fw.remainingChars" values={{
                    remaining: maxLength - input.value.length,
                    max: maxLength
                }}/></div>}
            </div>
        );
    }

    static get defaultProps() {
        return {
            widthLevel: 2
        }
    }
}

export default injectIntl(CreatableSelectInputField);
