import React from "react";
import {MultiSelectFilter, AbstractSelectFilter} from "assecobs-react-data-table/index";
import {RestService, fwUrlUtils} from "assecobs-faktor-web-common/index";
import {List} from "immutable";
import ClientOptionTemplate from "./ClientOptionTemplate";
import {injectIntl} from "react-intl";

const URL = fwUrlUtils.getApiUrl("/webapi/filter/clients/all");

class ClientFilterComponent extends AbstractSelectFilter {

    constructor(props) {
        super(props);

        this.state = {
            options: new List(),
            loading: false
        }
    }

    componentDidMount = async () => {
        await this.setState({
            loading: true
        });

        const clients = await RestService.postJSON(URL);

        await this.setState({
            options: clients,
            loading: false
        })
    };

    render() {

        const {intl} = this.props;

        const searchPlaceholder = intl.formatMessage({id: "fw.transfer.payer.searchPlaceholder"});

        let title = intl.formatMessage({id: "fw.customer"});
        if (!_.isNil(this.props.title)) {
            title = this.props.title;
        }

        return (
            <MultiSelectFilter
                {...this.props}
                title={title}
                optionValueProp="clientId"
                filterProp={["clientShortName", "clientTaxNumber"]}
                searchPlaceholder={searchPlaceholder}
                id={this.props.id}
                options={this.state.options}
                loading={this.state.loading}
                optionTemplate={ClientOptionTemplate}
            />
        )

    }
}

export default injectIntl(ClientFilterComponent);
