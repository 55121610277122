import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import {I18nMessage,} from "assecobs-faktor-web-common/index";
import {ErrorMessage} from "assecobs-faktor-web-common/forms";
import {Collapse} from "react-bootstrap";

export default class FormFieldsComponent extends React.PureComponent {

    render() {
        const {id, required, labelKey, mainField, className, InputComponent, size, names, collapseActive, open, ...rest} = this.props;

        let invalid;
        let errorName = null;

        if (!_.isNil(mainField)) {
            invalid = required && this.props[mainField].meta && this.props[mainField].meta.touched && this.props[mainField].meta.error;
        } else {
            names.forEach(name => {
                invalid = required && this.props[name].meta && this.props[name].meta.touched && this.props[name].meta.error;
                errorName = name;
            })
        }

        const label = labelKey && (
            <label className={classnames("fw-form-field__label", {
                "fw-form-field__label--required": (collapseActive && required) ? open : required
            })}>
                {collapseActive ? <Collapse in={open}>
                    <div>
                        <I18nMessage id={labelKey}/>
                    </div>
                </Collapse> : <I18nMessage id={labelKey}/>}
            </label>
        );

        const component = <div
            className={classnames("fw-form-field__control", {"fw-form-field--invalid": invalid}, `fw-form-field__control--size-${size}`)}>
            {collapseActive ? <Collapse in={open}>
                    <div><InputComponent id={id} meta={this.props[mainField || errorName].meta}
                                         invalid={invalid} {...rest} /></div>
                </Collapse> :
                <InputComponent id={id} meta={this.props[mainField || errorName].meta} invalid={invalid} {...rest} />}
            {invalid && <ErrorMessage message={this.props[mainField || errorName].meta.error}/>}
        </div>;

        return (
            <div className={classnames("fw-form-field", {"fw-form-field--no-label": !labelKey}, className)}>
                {label}

                {component}
            </div>
        )

    }

    static get propTypes() {
        return {
            size: PropTypes.oneOf(["S", "M", "L"])
        }
    }

    static get defaultProps() {
        return {
            size: "M"
        }
    }
}
