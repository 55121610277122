import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type SettingSentToastType = {
    header: string,
    templateInvoiceName: string
}

interface ToastInfoForSettingSentTemplateType extends TemplateType {
    templateProps: SettingSentToastType;
}

const ToastInfoForSettingSentTemplate = ({
                                             templateProps,
                                             removeToast,
                                             id,
                                             timeOut = 5000
                                         }: ToastInfoForSettingSentTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id={templateProps.header}/>
    const messageBody = <><strong><I18nMessage id="fw.setting.number"/>:</strong> {templateProps.templateInvoiceName}</>
    
    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{...templateProps, header: messageHeader, body: messageBody}}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastInfoForSettingSentTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForSettingSentTemplate