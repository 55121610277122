import {RestService, fwUrlUtils, storageManager} from "assecobs-faktor-web-common";
import * as actionTypes from "./actionTypes";

const URL = fwUrlUtils.getApiUrl("/webapi/attachmentAdding/getExtensions");
const URL_SEND_ATTACHMENT = fwUrlUtils.getApiUrl("/webapi/attachmentAdding/send");
const URL_SEND_ATTACHMENTS = fwUrlUtils.getApiUrl("/webapi/attachmentAdding/send/all");

function request() {
    return {
        type: actionTypes.REQUEST_FILE_EXTENSIONS,
        isFetching: true
    }
}

function receive(fileExtensions) {
    return {
        type: actionTypes.RECEIVE_FILE_EXTENSIONS,
        isFetching: false,
        fileExtensions
    }
}

export function fetchFileExtensions() {

    return async (dispatch) => {

        dispatch(request());

        let fileExtensions;

        if (storageManager.isNeedUpdateItem(storageManager.getLocalStorageItem(), "fileExtensions")) {
            fileExtensions = (await RestService.getJSON(URL)).list;
            storageManager.updateLocalStorageItem("fileExtensions", fileExtensions)
        } else {
            fileExtensions = storageManager.getLocalStorageItem().fileExtensions;
        }


        dispatch(receive(fileExtensions));
    }

}

export function sendAttachment(formData) {

    return async () => {

        return await RestService.postFile(URL_SEND_ATTACHMENT, formData);
    }

}

export function sendAttachments(formData) {

    return async () => {

        return await RestService.postFile(URL_SEND_ATTACHMENTS, formData);
    }

}
