import React from "react";
import {FilterFieldsContainer, MultiSelectFilter} from "assecobs-react-data-table/index";
import {fwUrlUtils, RestService} from "assecobs-faktor-web-common/index";
import {List} from "immutable";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";

const URL = fwUrlUtils.getApiUrl("/webapi/invoices/invoiceIncomingConfirmationStatusList");
const MIN_LENGTH = 4;

class ConfirmationStatusFilter extends FilterFieldsContainer {

    constructor(props) {
        super(props);

        this.applyOnChange = this.props.applyOnChange === true;

        this.state = {
            options: new List(),
            loading: false,
            selectedConfirmation: []
        }
    }

    componentDidMount = async () => {
        if (this.isConfirmationStatus(this.props.location)) {
            const tabConfirmationStatusString = this.props.location.query.confirmationStatus.toString()
                .substring(2, this.props.location.query.confirmationStatus.length - 2).split("\",\"");
            const tabConfirmationStatusInt = [];

            for (const el of tabConfirmationStatusString) {
                tabConfirmationStatusInt.push(parseInt(el));
            }

            this.setState({selectedConfirmation: tabConfirmationStatusInt});
        }

        await this.setState({
            loading: true
        });

        const options = await RestService.getJSON(URL);

        await this.setState({
            options: options,
            loading: false
        })
    };

    isConfirmationStatus = (value) => {
        return value && value.query.confirmationStatus && value.query.confirmationStatus.toString().length > MIN_LENGTH
    }

    render() {
        return (
            <MultiSelectFilter
                optionValueProp="invoiceIncomingConfirmationStatus"
                optionNameProp="invoiceIncomingConfirmationStatusDesc"
                filterProp="invoiceIncomingConfirmationStatusDesc"
                {...this.props}
                id={this.props.id}
                title={this.props.intl.formatMessage({id: "fw.invoice.confirmationStatus"})}
                options={this.state.options}
                loading={this.state.loading}
                initialValue={this.state.selectedConfirmation ? this.state.selectedConfirmation : undefined}
                applyOnChange={this.applyOnChange}
            />
        )

    }
}

export default injectIntl(withRouter(ConfirmationStatusFilter));
