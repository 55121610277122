import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index";
import classnames from "classnames";

const ENTER = 'Enter';

export default class TextareaInputField extends React.PureComponent {

    noEnter = async (e) => {
        if (this.props.noEnter && e.key === ENTER)
            e.preventDefault();
    }

    render() {
        const {input, invalid, maxLength, style} = this.props;

        return (
            <div>
                <textarea {...input}
                          {...maxLength && {maxLength: maxLength}}
                          className={classnames({
                              "k-invalid": invalid
                          }, style)}
                          onKeyDown={this.noEnter}
                />
                <div className={classnames("light-txt remaining-signs")}><I18nMessage id="fw.remainingChars" values={{
                    remaining: maxLength - input.value.length,
                    max: maxLength
                }}/></div>
            </div>
        )
    }
}