import React from "react";
import PropTypes from 'prop-types';
import DropdownButton from "../../dropdownMenu/DropdownButton";
import ButtonMenuItem from "./ButtonMenuItem";
import LinkMenuItem from "./LinkMenuItem";
import classnames from "classnames";
import MediaQuery from "react-responsive";

export default class DropdownMenuContent extends React.PureComponent {

    render() {
        const {items, className} = this.props;

        if (!items) {
            return null;
        }

        return (
            <div className={className}>
                {items.map((subitem, subindex) => {
                        switch (subitem.type) {
                            case "LINK":
                                return (
                                    <LinkMenuItem key={subindex}
                                                  label={subitem.label}
                                                  url={subitem.url}
                                                  styleClass={subitem.styleClass}
                                    />
                                );
                            case "BUTTON":
                                return (
                                    <ButtonMenuItem key={subindex}
                                                    label={subitem.label}
                                                    additionalLabel={subitem.additionalLabel}
                                                    url={subitem.url}
                                                    style={subitem.style}
                                                    styleClass={subitem.styleClass}
                                    />
                                );

                            case "DROPDOWN_BUTTON":
                                return (
                                    <MediaQuery minWidth={768} key={subindex}>
                                        {(matches) => {
                                            return <DropdownButton
                                                key={subindex}
                                                label={subitem.label}
                                                options={subitem.options}
                                                optionNameProp="text"
                                                optionLinkProp="url"
                                                className={classnames(subitem.styleClass)}
                                                buttonClassName={classnames({"btn-type-menu-mobile dropdown": !matches})}
                                            />
                                        }}
                                    </MediaQuery>
                                );

                            default:
                                return null;
                        }
                    }
                )}
            </div>
        )
    }

    static get propTypes() {
        return {
            items: PropTypes.array,
            className: PropTypes.string
        }
    }
}
