import React from "react";
import {RestService, DropdownMenu, I18nMessage} from "assecobs-faktor-web-common/index";
import _ from "lodash";
import {storageManager} from "../index";

export default class DropdownButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    componentDidMount = async () => {

        if (this.props.optionsUrl) {

            let data;

            if (this.props.storageName) {
                data = await storageManager.checkClientSessionStorageItem(this.props.storageName, this.props.optionsUrl)
            } else {
                data = await RestService.getJSON(this.props.optionsUrl);
            }

            this.setState({
                options: data
            });

        } else if (this.props.options) {
            this.setState({
                options: this.props.options
            });
        }


    };

    render() {

        const {label, appLink, optionNameProp, optionIdProp, optionLinkProp, buttonClassName, right} = this.props;

        if (_.isEmpty(this.state.options)) {
            return null;
        }

        return (
            <div className="dropdown abs-dropdown">
                <DropdownMenu id={1}
                              rendered={true}
                              buttonClassName={buttonClassName ? buttonClassName : "btn btn-type-b"}
                              text={<I18nMessage id={label}/>}
                              right={right}
                >
                    {this.state.options && this.state.options.map((option, index) => {

                        return <DropdownMenu.Item
                            key={index}
                            rendered={true}
                            href={optionLinkProp ? option[optionLinkProp] : (optionIdProp ? appLink + option[optionIdProp] : appLink)}
                            label={optionNameProp ? option[optionNameProp] : option}
                        />;

                    })}
                </DropdownMenu>
            </div>
        );
    }
}
