// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common";
// @ts-ignore
import {isVisible} from "assecobs-faktor-web-common/instanceConfigMetaData/instanceConfigMetaDataHelpers";
import FileRequestPromptBody from "./FileRequestPromptBody";
import FileRequestPromptBodyNoMail from "./FileRequestPromptBodyNoMail";
import React from "react";
import PropTypes from "prop-types";

interface FileRequestPromptBodyFullProps {
    handleSubmit: () => void,
    getFile: () => void,
    emailOnChange: (value: string) => void,
    sendFile: () => void,
    loading: boolean,
    errorMessage: string
}

const FileRequestPromptBodyFull = ({
                                       handleSubmit,
                                       getFile,
                                       emailOnChange,
                                       sendFile,
                                       loading,
                                       errorMessage
                                   }: FileRequestPromptBodyFullProps): React.ReactElement => {

    const isVisibleSendFile = isVisible({module: null, window: null, component: 'send.mail', element: null});

    if (isVisibleSendFile) {
        return <FileRequestPromptBody handleSubmit={handleSubmit} emailOnChange={emailOnChange}
                                      getFile={getFile} sendFile={sendFile}
                                      loading={loading}
                                      errorMessage={errorMessage}/>
    }

    return <FileRequestPromptBodyNoMail getFile={getFile}/>
}

FileRequestPromptBodyFull.propTypes = {
    handleSubmit: PropTypes.func,
    getFile: PropTypes.func,
    emailOnChange: PropTypes.func,
    sendFile: PropTypes.func,
    loading: PropTypes.bool,
    errorMessage: PropTypes.string
}

export default FileRequestPromptBodyFull