import React from "react";
import {Money, I18nMessage} from "assecobs-faktor-web-common/index";
import classnames from "classnames";

export default class OutstandingTextMobile extends React.Component {

    render() {
        const {outstanding, limit, freeLimit, currency, column} = this.props;

        return [
            <div key="limit" className={classnames({"col": column})}>
                    <span className="mobile-column-label">{<I18nMessage
                        id="fw.baseAgreement.limit">Limit</I18nMessage>}: </span>
                <Money amount={limit} currency={currency}/>
            </div>,
            <div key="outstanding" className={classnames({"col": column})}>
                    <span className="mobile-column-label">{<I18nMessage
                        id="fw.baseAgreement.outstanding">Zaangażowanie</I18nMessage>}: </span>
                <Money amount={outstanding} currency={currency}/>
            </div>,
            <div key="freeLimit" className={classnames({"col": column})}>
                    <span className="mobile-column-label">{<I18nMessage id="fw.baseAgreement.freeLimit">Dostępne
                        środki</I18nMessage>}: </span>
                <Money amount={freeLimit} currency={currency}/>
            </div>
        ]
    }

}
