import React from "react";
import ResponsiveListHeader from "./ResponsiveListHeader";
import ResponsiveListDataRow from "./ResponsiveListDataRow";
import CollapsibleSortingHeader from "./CollapsibleSortingHeader";
import {
    HeaderCell
} from "../index";
import OrderingDirection from "./types/OrderingDirection";

export default class ResponsiveList extends React.Component {


    constructor(props) {

        super(props);
        this.state = {checkboxesActive: null, forceCheckMainCheckbox: null};
        this.elementsIds = [];
        this._checkedRowsSize = 0;
    }

    componentWillReceiveProps(prevProps, nextProps) {

        if ((prevProps.rows !== nextProps.rows || nextProps.isFetching) && prevProps.config.multiselect) {
            this.setState({checkboxesActive: false, forceCheckMainCheckbox: false});
            this.elementsIds = [];
            this.props.getSelectedIds(this.elementsIds);
        }
    }

    selectAllIds = () => {

        this.setState({checkboxesActive: true, forceCheckMainCheckbox: null});

        this.elementsIds = [];
        this.props.rows.map((row) => {
            if (row.props["selectable"])
                this.elementsIds.push(row.key);
        });
        this.props.getSelectedIds(this.elementsIds);
    };


    deselectAllIds = () => {

        this.setState({checkboxesActive: false, forceCheckMainCheckbox: null});

        this.elementsIds = [];
        this.props.getSelectedIds(this.elementsIds);
    };

    selectId = (id) => {

        this.elementsIds.push(id);
        if (this.elementsIds.length === this._checkedRowsSize) {

            this.setState({checkboxesActive: true, forceCheckMainCheckbox: true});
        }
        this.props.getSelectedIds(this.elementsIds);
    };

    deselectId = (id) => {


        if (this.state.checkboxesActive) {
            this.setState({checkboxesActive: null, forceCheckMainCheckbox: false});
        }

        let index = this.elementsIds.indexOf(id);
        if (this.elementsIds.length > 1) {
            this.elementsIds.splice(index, 1);
        }
        else {
            this.elementsIds = [];
        }
        this.props.getSelectedIds(this.elementsIds);
    };

    toggleOrdering = (key) => {
        this.props.toggleOrdering(key);
    };

    fetchData = () => {
        this.props.fetchData();
    };

    render() {
        const {config, rows, totalCount, ordering} = this.props;
        let checkboxesActive = this.state.checkboxesActive;
        this._checkedRowsSize = 0;

        return <div className="container-visible">
            <CollapsibleSortingHeader columns={config.columns} ordering={ordering}
                                      onToggleOrdering={this.toggleOrdering}/>

            <div
                className={"abs-list-table " + (!config.multiselect ? "not-selectable " : "") + (this.props.className || "")}>
                <ResponsiveListHeader selectable={config.multiselect} selectAllIds={this.selectAllIds}
                                      deselectAllIds={this.deselectAllIds}
                                      forceCheckMainCheckbox={this.state.forceCheckMainCheckbox}>
                    {config.columns.map((column, index) =>
                        <HeaderCell key={index} label={column.label} orderingKey={column.sorting && column.sorting.key}
                                    orderingDirection={column.sorting && ordering ? ordering.get(column.sorting.key) : undefined}
                                    onToggleOrdering={(key) => this.toggleOrdering(key)}/>
                    )}
                </ResponsiveListHeader>

                {rows.map((row) => {
                        if(row.props["selectable"])
                        this._checkedRowsSize++;
                        return <ResponsiveListDataRow key={row.key} id={row.key} selectable={config.multiselect}
                                                      checkboxActive={checkboxesActive && row.props["selectable"]}
                                                      checkboxDisabled={!row.props["selectable"]}
                                                      selectId={(id) => this.selectId(id)}
                                                      deselectId={(id) => this.deselectId(id)}>
                            {row.props.children}
                        </ResponsiveListDataRow>
                    }
                )}
            </div>

            {totalCount > 0 && rows.size < totalCount && <button onClick={this.fetchData}>Pobierz więcej</button>}
        </div>;
    }
}
