import React from "react";
import classnames from "classnames";

export default class IconWithText extends React.Component {

    render() {
        const {icon, text, mobile, className} = this.props;

        return (
            <span className={classnames("icon-with-text", {"icon-with-text--mobile": mobile}, className)}>
                <span className={"abs-icon " + icon}/>
                <span className="icon-text">{text}</span>
            </span>
        )
    }
}
