import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {Icon} from "@assecobs/react-common-components";
import {Collapse} from "react-bootstrap";
import {I18nMessage} from "assecobs-faktor-web-common";

const ItemTemplate = ({itemProps, item, isSelected}) => {

    const [showDescription, setShow] = React.useState(false)

    const handleOnClick = (props) => {
        if (item.hasAgreements) {
            itemProps.onClick(props);
        } else {
            setShow(!showDescription)
        }
    }

    const getPropsAndHelpers = () => ({
        ...itemProps, onClick: handleOnClick
    })

    return (
        <li className="client-select-dropdown__item" {...getPropsAndHelpers()}>
            <span className="client-select-option">
                    <span className="client-select-option__name">{item.label}</span>
                {!item.hasAgreements &&
                <span className={classnames("client-select-option__no-agreements", "client-select-option__no-agreements--expanded")}>
                        <I18nMessage id="fw.menu.clientSelect.noAgreements"/>
                        <Icon name="abs_warning2"/>
                    </span>}
                </span>
            <Collapse in={showDescription}>
                <div className="client-select-option__no-agreements-desc"><I18nMessage id="fw.menu.clientSelect.noAgreements.description"/></div>
            </Collapse>
        </li>
    )
}

ItemTemplate.propTypes = {
    getItemProps: PropTypes.func.isRequired,
    itemClass: PropTypes.string.isRequired,
    itemContentWidth: PropTypes.number.isRequired
};

export default ItemTemplate;