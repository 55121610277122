import React from "react";
import PropTypes from "prop-types";
import I18nMessage from "../i18n/I18nMessage";

export default class BooleanValue extends React.Component {

    render() {
        const {value, label, negative, noIcon} = this.props;
        const txt = label || (value ? <I18nMessage id="common.yes">Tak</I18nMessage> : <I18nMessage id="common.no">Nie</I18nMessage>);

        return (
            <span className="abs-boolean">
                {value && !negative && !noIcon && <i className="abs-icon abs_check abs-boolean-icon abs-boolean-icon-positive" />}
                {!value && !negative && !noIcon && <i className="abs-icon abs_error abs-boolean-icon abs-boolean-icon-negative" />}
                {value && negative && !noIcon && <i className="abs-icon abs_warning2 abs-boolean-icon abs-boolean-icon-warning" />}
                <span className={"abs-boolean-label"}>{txt}</span>
            </span>
        )
    }

    static get propTypes() {
        return {
            value: PropTypes.bool,
            label: PropTypes.string,
            negative: PropTypes.bool
        }
    }
}
