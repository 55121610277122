import React from "react";
import {connect} from "react-redux";
import I18nMessage from "../i18n/I18nMessage";
import {injectIntl} from 'react-intl';
import * as actions from "./searchActions";
import InvoicesList from "./InvoicesList";
import MainSearchBlock from "./MainSearchBlock";
import fwUrlUtils from "../utils/fwUrlUtils";

class QuickSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {searchOpen: false, resultOpen: false};
    }

    setAdvancedSearch = () => {

        if (this.props.invoiceNumber) {
            window.location.href = fwUrlUtils.getContextUrl("/invoices/search?invoiceNumber=") + this.props.invoiceNumber;
        }
        else {
            window.location.href = fwUrlUtils.getContextUrl("/invoices/search");
        }

    };

    getItemList = (invoiceNumber) => {
        if (invoiceNumber.length > 0) {
            this.props.dispatch(actions.searchInvoices(invoiceNumber, true));
        }
    };

    fetchMoreInvoices = async () => {

        this.props.dispatch(actions.searchInvoices(this.props.invoiceNumber, false, this.props.pageIndex));
    };

    cancelFetch = () => {

        this.props.dispatch(actions.cancelFetch());
    };

    render() {

        const {data, isFetching, quickSearchOnOpen, pageIndex, totalCount, invoiceNumber, intl} = this.props;

        const listGroup = (
            <InvoicesList data={data} totalCount={totalCount} fetchData={this.fetchMoreInvoices}
                          isFetching={isFetching}/>
        );

        return (

            <div className="abs-quick-search-open">
                <MainSearchBlock getItemList={this.getItemList} isFetching={isFetching}
                                 fetchData={this.fetchMoreInvoices} quickSearchOnOpen={quickSearchOnOpen}
                                 setInvoiceNumber={this.setInvoiceNumber} cancelFetch={this.cancelFetch}/>
                {((data.size > 0 && !isFetching) || (data.size > 0 && data.size < totalCount && pageIndex > 1)) ? listGroup : isFetching ?
                    <div className="quick-search-loading-block"><I18nMessage id="fw.menu.quickSearch.searchInProgress"
                                                                             className="is-searching-title">Trwa
                        wyszukiwanie...</I18nMessage>
                    </div> : (data.size === 0 && isFetching === false) ?
                        <div className="quick-search-no-results-block"><i
                            className="abs-icon abs_cancel no-results-icon"/><I18nMessage
                            className="no-results-title"
                            id={intl.formatMessage({id: "fw.menu.quickSearch.noResultsFor"}, {invoiceNumber: invoiceNumber})}/>
                        </div> : <div className="quick-search-close"/>}
                <div className="quick-search-advanced-block">
                    <a className="advanced-search"
                       onClick={this.setAdvancedSearch}>
                        <i className="abs-icon abs_launch advanced-search-icon"/>
                        <I18nMessage className="advanced-search-text" id="fw.menu.quickSearch.advancedSearch">Wyszukiwanie
                            zaawansowane</I18nMessage></a>
                </div>
            </div>
        );
    }
}

export default connect(state => ({

    data: state.invoices.data,
    totalCount: state.invoices.totalCount,
    pageIndex: state.invoices.pageIndex,
    isFetching: state.invoices.isFetching,
    invoiceNumber: state.invoices.invoiceNumber

}))(injectIntl(QuickSearch))
