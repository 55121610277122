import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import Config from "../config/Config";

export default class DateComponent extends React.Component {

    componentDidMount() {
        if (_.isNil(this.props.value)) {
            if (this.props.elementIsNull) {
                this.props.elementIsNull();
            }
        }
    }

    render() {
        let {value, format} = this.props;

        if (_.isNil(value)) {
            return null;
        }

        const formatted = moment(value).format(format);

        return (
            <span className="no-break">{formatted}</span>
        )
    }
}

DateComponent.propTypes = {
    value: PropTypes.any,
    format: PropTypes.string
};

DateComponent.defaultProps = {
    format: Config.dateFormat()
};
