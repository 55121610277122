import React from "react";
import {Money, I18nMessage} from "assecobs-faktor-web-common/index";
import {ProgressBar} from "react-bootstrap";

export default class OutstandingProgressBar extends React.Component {

    render() {
        const {outstanding, limit, currency} = this.props;

        let percentage = limit != 0 ? Math.floor(outstanding / limit * 100) : 100;

        if (isNaN(percentage)) {
            percentage = 0;
        }

        return (
            <div className="limit-progress-values">
                <div><I18nMessage id="fw.baseAgreement.outstanding">Zaangażowanie</I18nMessage></div>
                <div>
                    <div className="progress-container">
                        <ProgressBar now={percentage} bsStyle="info"/>
                    </div>
                    <span className="progress-percent">{percentage}%</span>
                </div>
                <div>
                    <Money amount={outstanding}/><span className="light-txt"> <I18nMessage id="fw.agreement.outstandingOfLimit">z</I18nMessage> <Money
                    amount={limit} currency={currency}/></span>
                </div>
            </div>
        )
    }

}
