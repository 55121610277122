// @ts-ignore
import {CHART_COLORS} from "assecobs-faktor-web-profile/ChartColors";
// @ts-ignore
import {COLOR_ORDER_IN_PIE_CHART} from "assecobs-faktor-web-profile/AppProperties";

const setColorsInPieChart = (length: number) => {
    if (COLOR_ORDER_IN_PIE_CHART === 1) {
        return CHART_COLORS.slice(0, length);
    }
    return CHART_COLORS.slice(-length);
};

export default setColorsInPieChart