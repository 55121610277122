import React from "react";
import {DropdownMenu, Link} from "assecobs-faktor-web-common";
import ReactDOM from "react-dom";

const CUSTOM_CLASS_NAME = "dropdown-link";
const ACTIVE_CLASS_NAME = "active";

class ContextDropdownComponentItem extends React.Component {

    onSelect = () => {
        this.props.onSelect(this.props.label);
    };

    render() {
        const {label, url} = this.props;


        return (
            <li>
                <Link text={label} url={url}
                      id={label + "_link"}
                      customClassName={CUSTOM_CLASS_NAME}
                      activeClassName={ACTIVE_CLASS_NAME}
                      onClick={this.onSelect}/>
            </li>
        )
    }
}

class ContextDropdownComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {dropdownSelectedText: ""}
    }

    componentDidMount() {
        this.setState({dropdownSelectedText: document.getElementsByClassName(`${CUSTOM_CLASS_NAME} ${ACTIVE_CLASS_NAME}`)[0].innerText});
    }

    componentDidUpdate(){
        let dropDownMenuId = ReactDOM.findDOMNode(this.refs.dropdownMenuId);
        let dropDownButton = dropDownMenuId.children[0];
        dropDownButton.blur();
    }

    onItemChange = (label) => {
        this.setState({dropdownSelectedText: label});
    };

    render() {

        return (
            <DropdownMenu text={this.state.dropdownSelectedText} buttonClassName="btn btn-xs btn-type-d" ref="dropdownMenuId">
                {this.props.children.map(child => {
                    return React.cloneElement(child, {
                        onSelect: this.onItemChange
                    })
                })}
            </DropdownMenu>
        )
    }
}

ContextDropdownComponent.Item = ContextDropdownComponentItem;

export default ContextDropdownComponent;