import React from "react";
import {I18nMessage, DropdownMenu, ImmutableUtils} from "assecobs-faktor-web-common/index";

export default class AddRequestDropdownButton extends React.PureComponent {

    render() {
        const {requestLinks} = this.props;

        return (
            <DropdownMenu.Sub text={<I18nMessage id="fw.menu.putRequest"/>}
                              rendered={!ImmutableUtils.isEmpty(requestLinks)}
            >
                {requestLinks.map(link =>
                    <DropdownMenu.Item key={link.get("requestTypeId")}
                                       label={link.get("requestType")}
                                       onSelect={() => window.location.href = link.get("link")}/>)}
            </DropdownMenu.Sub>
        );
    }
}
