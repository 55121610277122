import moment from "moment/moment";
import {Config} from "../index";

const formatDate = Symbol();
const localizeDate = Symbol();

export default class DateUtils {

    static startOfDay(date, locale, format) {
        return this[formatDate](this[localizeDate](date, locale), 0, 0, 0, 0, format);
    }

    static endOfDay(date, locale, format) {
        return this[formatDate](this[localizeDate](date, locale), 23, 59, 59, 999, format);
    }

    static getMask() {
        const dateFormat = Config.dateFormat().split('');
        const dateFormatCharacters = ['y', 'Y', 'M', 'm', 'D', 'd'];

        return dateFormat.map(function(item) { return dateFormatCharacters.includes(item) ? /\d/ : '-'; });//[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
    }

    static isValid(dateTextValue, dateFormat) {
        return moment(dateTextValue, dateFormat, true).isValid();
    }

    static [localizeDate](date, locale) {
        if (locale) {
            return date.locale(locale);
        }

        return date;
    }

    static [formatDate](date, hour, minute, second, millisecond, format) {
        let withTimeSet = date.hour(hour).minute(minute).second(second).millisecond(millisecond);

        if (format) {
            return withTimeSet.format(format);
        }

        return withTimeSet;
    }
}
