import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@assecobs-react-components/modal-window/src/Modal';

const noop = () => {
};

const SimpleModal = ({isOpen = false, onClose = noop, bodyContent = "", footerContent = ""}) => (
    <Modal isOpen={isOpen} onClose={onClose} containerClass="modal-dialog"
           contentClass="abs-modal-post-action__content">
        <div className="absui-modal__body abs-modal-post-action__body modal__body">
            {bodyContent}
        </div>
        <div className="absui-modal__footer abs-modal-post-action__footer">
            {footerContent}
        </div>
    </Modal>
);

SimpleModal.propTypes = {
    bodyContent: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    isOpen: PropTypes.bool,
    footerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    onClose: PropTypes.func
};

export default SimpleModal;