import React from "react";
import {injectIntl} from 'react-intl';
import classnames from "classnames";
import I18nMessage from "../i18n/I18nMessage";

const searchInput = Symbol();

class MainSearchBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {showHint: false};
        this._myTimeout = null;
        this[searchInput] = null;
    }

    componentWillReceiveProps(prevProps, nextProps) {

        if (prevProps.quickSearchOnOpen !== nextProps.quickSearchOnOpen) {
            this[searchInput].focus();
            this._myTimeout = null;
        }
    };

    onShowHint = () => {

        this.setState({showHint: true});
    };

    onKeyPress = (e) => {

        if (e.key === "Enter") {

            this.setState({showHint: false});
            if (this._myTimeout) {

                clearTimeout(this._myTimeout);
            }
            this.props.getItemList(this[searchInput].value);
        }
        else if (!this[searchInput].value) {

            this.setState({showHint: false});
            if (this._myTimeout) {

                clearTimeout(this._myTimeout);
            }
        }
        else if (this[searchInput].value) {

            this.setState({showHint: false});
            if (this._myTimeout) {

                clearTimeout(this._myTimeout);
            }
            this._myTimeout = setTimeout(this.onShowHint, 1000);
        }

    };


    searchInvoice = () => {

        this.setState({showHint: false});
        if (this._myTimeout) {

            clearTimeout(this._myTimeout);
        }
        this.props.getItemList(this[searchInput].value);
    };

    clearInputValue = () => {

        this.setState({showHint: false});
        if (this._myTimeout) {

            clearTimeout(this._myTimeout);
        }
        this[searchInput].value = "";

        this.props.cancelFetch();
    };

    checkClearIcon = () => {
        return this[searchInput] && this[searchInput].value.length > 0;
    };


    render() {
        const {isFetching, intl} = this.props;

        return (

            <div className="quick-search-main-search-block">
                <div className="quick-search-search-block">
                    <div className="quick-search-title-text"><I18nMessage id="fw.menu.quickSearch.searchInvoice">Wyszukaj fakturę</I18nMessage></div>
                    <div className="quick-search-input-block">
                        <input ref={c => this[searchInput] = c} className="k-textbox input-field"
                               onKeyUp={this.onKeyPress}
                               disabled={isFetching}
                               placeholder={intl.formatMessage({id: "fw.menu.quickSearch.placeholder"})}/>
                        <i className={classnames("abs-icon abs_close", {
                            "clear-icon": this.checkClearIcon(),
                            "clear-icon-hidden": !this.checkClearIcon()
                        })}
                           onClick={this.clearInputValue}/>
                        <i className="abs-icon abs_search abs_material_search" onClick={this.searchInvoice}/>
                    </div>
                </div>
                <div
                    className={(!this.state.showHint || isFetching) ? "quick-search-close" : "quick-search-help-block-open"}>
                    <i className="abs-icon abs_live_help help-icon"/>
                    <span className="help-hint"><I18nMessage id="fw.menu.quickSearch.toSearchPressEnter">Aby wyszukać naciśnij ENTER</I18nMessage></span>
                </div>
            </div>
        );
    }
}

export default injectIntl(MainSearchBlock);
