import * as actionTypes from "../constants/actionTypes";

function show() {
    return {
        type: actionTypes.SHOW_LOADER,
    }
}

export function showLoader() {
    return (dispatch) => {
        dispatch(show());
    }
}