import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common";
import {Icon} from "@assecobs/react-common-components";

export default (props) => {

    return (
        <div className="quick-settings-placeholder">
            {props.iconClassName && <Icon name={props.iconClassName}/>}
            <div className="quick-settings-placeholder-text">
                <I18nMessage id={props.titleKey}/>
                <div className="quick-settings-placeholder-text__content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
