import {format} from "assecobs-react-utils/stringUtils";
import {MESSAGE_URL} from "../common/Urls"
import {getApplicationLanguage} from "assecobs-react-utils/utils";
import DynamicModuleAbstract from "./DynamicModuleAbstract";
import Storage from "assecobs-utils/Storage";
import restService from "assecobs-react-utils/restService";
import {getI18nVersion} from "../../utils/fwUtils";

const currentVersion = getI18nVersion() || Date.now().toString();//local environment
const LANG = getApplicationLanguage();
const STORAGE_KEY = "i18n.messages";

class DynamicTranslateMessagesModule extends DynamicModuleAbstract {

    async loadDynamicData() {
        const rest = restService.getJSONData(format(MESSAGE_URL, currentVersion, LANG));

        return rest.then((messages) => this.save(messages))
    }

    getModuleData() {
        const storageItem = Storage.getItem(STORAGE_KEY);
        return storageItem ? JSON.parse(storageItem) : storageItem;
    }

    save(messages) {
        Storage.setItem(STORAGE_KEY, JSON.stringify({
            value: messages,
            version: currentVersion,
            language: LANG
        }));
        return messages;
    }

    isNeedUpdate(storageMessages) {
        return !storageMessages ||
            !storageMessages.value ||
            storageMessages.version !== currentVersion ||
            !storageMessages.language ||
            storageMessages.language !== LANG;
    }
}

export default new DynamicTranslateMessagesModule();
