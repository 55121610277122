import React from "react";
import ValuesSequence from "../../dataPresentation/ValuesSequence";

export default ({data}) => {
    if (data) {
        return <div className="agreement-number-container selected-value-template-view">
            <div className="option-main-value">
                <span className="agreement-number-select-item">{data.actualAgreementNo} &nbsp;</span>
                <span className="agreement-currency">({data.currency})</span>
            </div>

            <ValuesSequence values={[data.agreementTypeDesc, data.factoringTypeDesc]} hint/>
        </div>
    }

    return null;
}
