import _ from 'lodash'
import {sendAttachments} from '../actions'
import {hideLoader, showLoader} from 'assecobs-faktor-web-common/loaders/actions/index'
import {showErrorToast} from './showErrorToast'
import {Config} from 'assecobs-faktor-web-common/index'
import {SEND_ATTACHMENTS_TOAST, toast} from 'assecobs-faktor-web-common/toasts/ToastInitializer'
import {isFileFailure} from 'assecobs-faktor-web-common/dropzoneComponent/services/fileFailueService'

const addFiles = async ({
                          files,
                          docTypeId,
                          packageId,
                          elementId,
                          DOCUMENT_TYPE_FOR_PACKAGE,
                          acceptTypeAndWeight,
                          dispatch,
                          contextNumber,
                        }) => {
  const filesToUpload = []

  const formData = new FormData()
  const documentTypeId = docTypeId || DOCUMENT_TYPE_FOR_PACKAGE
  const packId = packageId || (_.isEqual(docTypeId, DOCUMENT_TYPE_FOR_PACKAGE) ? elementId : null)
  const entityElementId = elementId

  let responseJSON

  const acceptedFiles = files.action.filter(file => !file.hasOwnProperty('error'))
  const rejectedFiles = files.action.filter(file => file.hasOwnProperty('error'))

  if (!_.isEmpty(acceptedFiles)) {
    dispatch(showLoader())
    acceptedFiles.map(file => {
      const filesExtensionId = acceptTypeAndWeight.find(type => type.type === file.data.type).extensionId
      filesToUpload.push(file.data)

      formData.append('FileData', file.data)
      if (!_.isNil(filesExtensionId)) {
        formData.append('CommonFileExtensionId', filesExtensionId)
      }

      formData.append('FileName', file.data.name)
      formData.append('FileSize', file.data.size)
      formData.append('EntityElementId', entityElementId)
      formData.append('DocumentTypeId', documentTypeId)

      if (!_.isNil(packId)) {
        formData.append('PackageId', packId)
      }
    })


    if (!_.isEqual(filesToUpload.length, 0)) {
      await dispatch(sendAttachments(formData)).then(async (response) => {
        dispatch(hideLoader())
        if (response.ok) {
          responseJSON = await response.json()

          if (responseJSON.UploadSuccess) {
            toast(SEND_ATTACHMENTS_TOAST({
              filesName: responseJSON.UploadSuccess,
              contextNumber: responseJSON.EntityElementDescription ? responseJSON.EntityElementDescription : contextNumber,
            }))
          }
        }
      })
    }
  }

  if (!_.isEmpty(rejectedFiles) || isFileFailure(responseJSON)) {
    showErrorToast({rejectedFiles, responseJSON, maxFileSize: Config.getMaxRequestSize()})
  }
}

export {addFiles}