import React from "react";
import {Field} from "redux-form";
import {Loadable} from "@assecobs/react-common-components";
import {I18nMessage} from "assecobs-faktor-web-common";
import MediaQuery from "react-responsive";

import EmailInput from "./EmailInput";

export default ({handleSubmit, getFile, emailOnChange, sendFile, loading, errorMessage}) => {

    return <>
        <div key="modal-body-content_1" className="modal-body-content">
            <span className="content-icon abs-edit-icon-style">
                <i className="abs-icon abs_archive"/>
            </span>
            <h4 className="modal-title" id="myModalLabel1"><I18nMessage id="fw.fileRequestPrompt.body.local.title"/>
            </h4>
            <p className="hint"><I18nMessage id="fw.fileRequestPrompt.body.local.text"/></p>
            <p>
                <button type="button" className="btn btn-type-b btn-xs" onClick={getFile}><I18nMessage id="common.get"/>
                </button>
            </p>
        </div>
        <MediaQuery key="mediaQuery" maxWidth={767}>
            {
                (matches) => <hr/>
            }
        </MediaQuery>
        <Loadable key="modal-body-content_2" className="modal-body-content" loading={loading}>
            <span className="content-icon abs-edit-icon-style">
                <i className="abs-icon abs_markunread"/>
            </span>
            <h4 className="modal-title abs-modal-title" id="myModalLabel1"><I18nMessage
                id="fw.fileRequestPrompt.body.mail.title"/>
            </h4>
            <p className="hint"><I18nMessage id="fw.fileRequestPrompt.body.mail.text"/></p>
            <Field id="emailInput" name="emailInput"
                   emailOnChange={emailOnChange}
                   component={EmailInput}
                   submitEmail={handleSubmit(sendFile)}
                   className={"k-textbox col-sm-12 "}
                   errorMessage={errorMessage}
            />
            <p>
                <button type="button" className="btn btn-type-b btn-xs btn-send" onClick={handleSubmit(sendFile)}>
                    <I18nMessage id="common.send"/>
                </button>
            </p>
        </Loadable>
    </>
}