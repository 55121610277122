// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common";
import PropTypes from "prop-types";
import React from "react";

interface FileRequestPromptBodyNoMailProps {
    getFile: () => void
}

const FileRequestPromptBodyNoMail = ({getFile}: FileRequestPromptBodyNoMailProps): React.ReactElement => (

    <div key="modal-body-content" className="modal-body-content-no-mail">
            <span className="content-icon abs-edit-icon-style">
                <i className="abs-icon abs_archive"/>
            </span>
        <h4 className="modal-title" id="myModalLabel1"><I18nMessage id="fw.fileRequestPrompt.body.local.title"/>
        </h4>
        <p className="hint"><I18nMessage id="fw.fileRequestPrompt.body.local.text"/></p>
        <p>
            <button type="button" className="btn btn-type-b btn-xs" onClick={getFile}><I18nMessage id="common.get"/>
            </button>
        </p>
    </div>
)

FileRequestPromptBodyNoMail.propTypes = {
    getFile: PropTypes.func
}

export default FileRequestPromptBodyNoMail