import * as actionTypes from "./actionTypes";

const initialState = {
    promptId: null,
    promptProps: {}
}

export default function(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SHOW_PROMPT:
            return {
                promptId: action.promptId,
                promptProps: action.promptProps
            }
        case actionTypes.HIDE_PROMPT:
            return initialState
        default:
            return state
    }
}
