import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {DateOutput, I18nMessage, Link, ListMoney} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";
// @ts-ignore
import _ from "lodash";

type CorrectiveEdittingToastType = {
    invoiceNumber: string,
    invoiceValue: number,
    invoiceCurrency: string,
    correctiveNumber: string,
    issueDate: string,
    correctiveType: string,
    packageLink: string,
    packageNumber: string
    refresh: boolean
}

interface ToastInfoForCorrectiveEditingTemplateType extends TemplateType {
    templateProps: CorrectiveEdittingToastType;
}

const ToastInfoForCorrectiveEditingTemplate = ({
                                                   templateProps,
                                                   removeToast,
                                                   id,
                                                   timeOut = 5000
                                               }: ToastInfoForCorrectiveEditingTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.correctiveInvoice.editing.toast.header"/>
    const messageBody = <CorrectiveEditingToast {...templateProps}/>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

const CorrectiveEditingToast = ({
                                    invoiceNumber,
                                    invoiceValue,
                                    invoiceCurrency,
                                    correctiveNumber,
                                    issueDate,
                                    correctiveType,
                                    packageLink,
                                    packageNumber
                                }: CorrectiveEdittingToastType): ReactElement => (
    <>
        {!_.isNil(invoiceNumber) &&
            <>
                <I18nMessage id="fw.invoice.no"/>: {invoiceNumber}
                <br/>
            </>}
        <I18nMessage id="fw.correctiveInvoice.adding.correctiveNumber"/>: {correctiveNumber}
        <br/>
        <I18nMessage id="fw.invoice.issueDate"/>: <DateOutput>{issueDate}</DateOutput>
        <br/>
        <I18nMessage id="fw.common.type"/>: {correctiveType}<br/>
        {!_.isNil(invoiceValue) &&
            <>
                <I18nMessage id="fw.invoice.toast.value"/>:
                <ListMoney amount={invoiceValue}
                           currency={invoiceCurrency}/>
                <br/>
            </>}
        {!_.isNil(packageLink) &&
            <>
                <I18nMessage id="fw.package"/>:
                <Link
                    id={packageNumber}
                    url={packageLink}
                    text={packageNumber}
                    external/>
            </>}
    </>
)

ToastInfoForCorrectiveEditingTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForCorrectiveEditingTemplate