import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

const ToastInfoForPasswordChangedTemplate = ({
                                                 removeToast,
                                                 id,
                                                 timeOut = 5000
                                             }: TemplateType): ReactElement => {

    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.authentication.changePassword.changed"/>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{header: messageHeader}}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastInfoForPasswordChangedTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForPasswordChangedTemplate