import React from "react";
import {MultiSelectFilter, AbstractSelectFilter} from "assecobs-react-data-table/index";
import {RestService, fwUrlUtils} from "assecobs-faktor-web-common/index";
import {List} from "immutable";
import {injectIntl} from "react-intl";
import storageManager from "../../../storageManager/storageManager";

const URL = fwUrlUtils.getApiUrl("/webapi/report/reportTypesList");

class ReportTypeFilter extends AbstractSelectFilter {

    constructor(props) {
        super(props);

        this.state = {
            options: new List(),
            loading: false
        }
    }

    componentDidMount = async () => {
        await this.setState({
            loading: true
        });

        const options = await storageManager.checkClientSessionStorageItem("reportTypes", URL);

        await this.setState({
            options: options,
            loading: false
        })
    };

    render() {

        const {intl} = this.props;

        return (
            <MultiSelectFilter
                optionValueProp="docDocumentDictionaryId"
                optionNameProp="documentName"
                filterProp="documentName"
                {...this.props}
                id={this.props.id}
                title={intl.formatMessage({id: "fw.reports.orderReport.reportType"})}
                options={this.state.options}
                loading={this.state.loading}
                maxContent
            />
        )

    }
}

export default injectIntl(ReportTypeFilter);
