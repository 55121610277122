import * as actionTypes from "./actionTypes";
import {fromJS, List} from "immutable";
import {Config} from "assecobs-faktor-web-common/index";

const initState = {
    isFetching: null,
    data: new List(),
    pageIndex: 1,
    totalCount: null,
    invoiceNumber: null

};

export default function (state = initState, action) {

    switch (action.type) {
        case actionTypes.INVOICE_START_SEARCH:
            return {
                ...state,
                isFetching: action.isFetching,
                pageIndex: action.pageIndex
            };

        case actionTypes.INVOICE_END_SEARCH:

            const newData = action.override ? fromJS(action.invoicesList) : state.data.concat(fromJS(action.invoicesList));
            const fetchedPages = Math.ceil(newData.size / Config.pageSize());

            return {
                ...state,
                isFetching: action.isFetching,
                totalCount: action.totalCount,
                data: newData,
                fetchedPages: fetchedPages,
                filtering: action.filtering,
                override: action.override,
                pageIndex: action.pageIndex,
                invoiceNumber: action.invoiceNumber
            };

        case actionTypes.INVOICE_CANCEL_SEARCH:
            return {
                ...state,
                isFetching: action.isFetching
            };

    }

    return state;
}
