import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
//import SendAttachmentToast from "../body/SendAttachmentToast";
import {TemplateType} from "../types/TemplateType";
// @ts-ignore
import _ from "lodash";

type SendAttachmentToastType = {
    fileName: string,
    contextNumber: string,
    refresh: boolean
}

interface ToastInfoForSendAttachmentTemplateType extends TemplateType {
    templateProps: SendAttachmentToastType;
}

const ToastInfoForSendAttachmentTemplate = ({
                                                templateProps,
                                                removeToast,
                                                id,
                                                timeOut = 5000
                                            }: ToastInfoForSendAttachmentTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.attachment.adding.toast.header"/>
    const messageBody = <SendAttachmentToast {...templateProps}/>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

const SendAttachmentToast = ({
                                 fileName,
                                 contextNumber
                             }: SendAttachmentToastType): ReactElement => (
    <>
        <I18nMessage id="fw.attachment.name"/>:
        <ul className="fw-dropzone-toast">
            <li> {fileName}</li>
        </ul>
        {!_.isNil(contextNumber) &&
            <>
                <I18nMessage key="message" id="fw.common.addTo"/>: {contextNumber}
                <br/>
            </>}
    </>
)

ToastInfoForSendAttachmentTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForSendAttachmentTemplate