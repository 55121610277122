export const MAXIMUM_LENGTH_INVOICE_NUMBER = 50;
export const MAXIMUM_LENGTH_INFO = 500;
export const MAXIMUM_LENGTH_NUMBER = 12;
export const MAXIMUM_LENGTH_NUMBER_FOR_NEGATIVE_AMOUNTS = 13
export const MAXIMUM_LENGTH_TEXTAREA = 8000
export const MAXIMUM_LENGTH_INPUT_SELECT = 500
export const MAXIMUM_LENGTH_LONG_TEXTAREA = 8000
export const MAXIMUM_LENGTH_TAX_ID = 50
export const MINIMUM_FRACTION_DIGITS = 0
export const MAXIMUM_FRACTION_DIGITS = 6
export const COLOR_ORDER_IN_PIE_CHART = 0
export const MINIMUM_FRACTION_DIGITS_INTEREST_RATE = 0
export const MAXIMUM_FRACTION_DIGITS_INTEREST_RATE = 6