import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import _ from "lodash";
import ResizeObserver from 'resize-observer-polyfill'

const childElement = Symbol();
const observer = Symbol();

export default class ContainerLoader extends React.Component {

    constructor() {
        super();
        this[childElement] = null;
        this.state = {containerWidth: null};
        this[observer] = null;
    }

    componentDidMount() {
        if (!_.isArray(this.props.children) && !_.isNil(this.props.children) && !_.isNil(this[childElement]) && this.props.children) {
            const observable = ReactDOM.findDOMNode(this[childElement]);
            this[observer] = new ResizeObserver(() => {
                this.countWidth();
            });
            this[observer].observe(observable);
        }
    }

    componentWillUnmount() {
        if (!_.isNil(this[observer])) {
            this[observer].disconnect();
        }
    }

    countWidth = () => {
        if (!_.isNil(this[childElement])) {
            let width = ReactDOM.findDOMNode(this[childElement]).getBoundingClientRect().width;
            if (!_.isEqual(width, 0))
                this.setState({containerWidth: width});
        }
    };

    render() {

        const {children, filter, loading, section, page, withOpacity} = this.props;

        return [
            <div key="loader"
                 className={classnames({
                     "loader": loading,
                     "loader__small": filter,
                     "loader__mid": section,
                     "loader__large": page,
                     "loader--with_opacity": withOpacity && loading
                 })} style={{
                width: (!_.isNil(this.state.containerWidth) && loading) ? this.state.containerWidth + "px" : "auto",
                margin: (!_.isNil(this.state.containerWidth) && loading) ? 0 : "auto"
            }}>
                {loading &&
                <span className="loader-component"/>}
                {(!_.isArray(children) && !_.isNil(children) && children) ? React.Children.map(children, (child) => {
                    return React.cloneElement(child, {ref: c => this[childElement] = c})
                }) : children}
            </div>

        ]

    }

    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.object]),
        loading: PropTypes.bool,
        filter: PropTypes.bool,
        section: PropTypes.bool,
        page: PropTypes.bool,
        withOpacity: PropTypes.bool
    }

};
