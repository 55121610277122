import React from "react";
import {SingleSelectFilterComponent} from "assecobs-react-data-table/index";
import {fwUrlUtils, I18nMessage, Link, Money, RestService} from "assecobs-faktor-web-common/index";
import {Icon} from "@assecobs/react-common-components/index";
import BaseAgreementSingleOptionTemplate from "../filters/baseAgreement/BaseAgreementSingleOptionTemplate";
import BaseAgreementSingleSelectedTemplate from "../filters/baseAgreement/BaseAgreementSingleSelectedTemplate";
import baseAgreementFilterParams from "../filters/baseAgreement/baseAgreementFilterParams";
import _ from "lodash";
import {injectIntl} from 'react-intl';
import {withRouter} from "react-router";

const URL = fwUrlUtils.getApiUrl("/webapi/filter/baseAgreements/all");

const DetailsLink = ({intl, selectedBaseAgreement, showDetailsLink}) => {

    if (showDetailsLink) {
        return (
            <div className="form-select-additional-info">
                {selectedBaseAgreement && [
                    <div key={0} className="abs-icon-link">
                        <Icon name="abs_launch"/>
                        <Link id="baseAgreementDetailsLink"
                              text={intl.formatMessage({id: "fw.agreement.showDetails"})}
                              url={selectedBaseAgreement.baseAgreementLink}
                              openInNewTab
                              external/>
                    </div>,
                    <div key={1} className="clearfix"/>
                ]}
            </div>
        )
    }

    return null;
};

const CurrentLimit = ({selectedBaseAgreement, showCurrentLimit}) => {

    if (showCurrentLimit) {
        return (
            <div className="form-select-additional-info">
                {selectedBaseAgreement && [
                    <span key={0} className="light-txt"><I18nMessage id="fw.limit.current"/>: </span>,
                    <Money key={1} amount={selectedBaseAgreement.limit} currency={selectedBaseAgreement.currency}/>
                ]}
            </div>
        )
    }

    return null;
};

class BaseAgreementFormField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: null,
            loading: false,
            initialValue: null,
            selectedBaseAgreement: null,
            agreementOnChangeForInitialValueLengthOne: false
        }
    }

    getBaseAgreements = async () => {

        await this.setState({
            loading: true
        }, async () => {

            const baseAgreements = await RestService.postJSON(this.props.url || URL);

            this.setState({
                options: baseAgreements,
                loading: false
            }, () => {

                if (_.isEqual(baseAgreements.length, 1)) {
                    this.setState({initialValue: baseAgreements[0], agreementOnChangeForInitialValueLengthOne: true});
                } else {
                    this.setState({initialValue: null});
                }

            });
        });

    };

    componentDidMount = async () => {
        await this.getBaseAgreements();
    };

    componentDidUpdate = async (prevProps) => {
        if (this.props.refresh && !_.isEqual(prevProps.refresh, this.props.refresh)) {
            await this.setState({options: null}, async () => {
                this.onChange(null);
                await this.getBaseAgreements();
            });
        }

        if (_.isNil(this.state.initialValue)) {
            if (this.props.location.query.baseAgreementAnnex0Id) {
                this.setState({initialValue: +this.props.location.query.baseAgreementAnnex0Id});
            }
        }

        if (this.state.agreementOnChangeForInitialValueLengthOne && !_.isNil(this.state.initialValue)) {
            this.props.agreementOnChange(this.state.initialValue);
        }
    };

    onChange = (agreement) => {
        this.setState({selectedBaseAgreement: agreement});
        this.props.agreementOnChange(agreement);
    };

    render() {

        const {label, showDetailsLink, showCurrentLimit, intl} = this.props;

        const selectForm =
            <SingleSelectFilterComponent
                ref={(c) => this._selectComponent = c}
                {...baseAgreementFilterParams}
                searchPlaceholder={intl.formatMessage({id: "fw.filter.baseAgreement.searchPlaceholder"})}
                customClass="custom-select-height"
                initialValue={this.state.initialValue ? this.state.initialValue : undefined}
                title={intl.formatMessage({id: "fw.select.placeholder"})}
                options={this.state.options}
                loading={this.state.loading}
                clearable={false}
                clearSelectedValueOnDataSourceChange={true}
                onChange={this.onChange}
                optionTemplate={BaseAgreementSingleOptionTemplate}
                selectedValueTemplate={BaseAgreementSingleSelectedTemplate}
            />;

        return (
            label ? (
                <div className={"form-horizontal form-widgets col-sm-6 select-with-custom-height"}>
                    <div className="form-group">
                        {label}
                        <div className="col-sm-8 col-md-6 abs-margin-select-column">
                            {selectForm}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="select-with-custom-height">
                    {selectForm}
                    <DetailsLink intl={intl} selectedBaseAgreement={this.state.selectedBaseAgreement}
                                 showDetailsLink={showDetailsLink}/>
                    <CurrentLimit selectedBaseAgreement={this.state.selectedBaseAgreement}
                                  showCurrentLimit={showCurrentLimit}/>
                </div>
            )
        );
    }
}

export default injectIntl(withRouter(BaseAgreementFormField));
