import {getFormValues, formValueSelector} from "redux-form";
import _ from "lodash";

const getForm = (id) => (state => state[`${id}FilterPanel`]);
const selector = (state, id, value) => (state[`${id}FilterPanel`].data[value]);

const isSubmitted = (state, filterPanelId) => {
    const values = getFormValues('data', getForm(filterPanelId))(state);
    return !_.isEmpty(values) && _.some(Object.values(values), value => !_.isEmpty(value))
};

const getFilters = (state, filterPanelId) => {
    return getFormValues('data', getForm(filterPanelId))(state)
};

const getFilter = (state, filterPanelId, filter) => {
    return formValueSelector('data', state => state[`${filterPanelId}FilterPanel`])(state, filter);
};

const isOpen = (state, filterPanelId) => selector(state, filterPanelId, 'open');

export default {
    isSubmitted,
    getFilters,
    getFilter,
    isOpen
}
