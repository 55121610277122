import React from 'react';
import PropTypes from "prop-types";
import {confirmable} from 'react-confirm';
import {Button, Modal} from "react-bootstrap";
import I18nMessage from "../i18n/I18nMessage";

const Prompt2 = ({show, dismiss, proceed, cancel, content, options}) => (
    <Modal show={show} onHide={dismiss} animation={false} autoFocus={true} keyboard={true} backdrop={true}>
        <Modal.Body>
            <i className="abs-icon abs_help"/>
            <div>
                {content}
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Button className="btn btn-type-b" onClick={() => proceed()}><I18nMessage id="fw.invoiceVAT.confirm"/></Button>
            <Button className="btn btn-type-a" onClick={() => cancel()}>Zamknij</Button>
        </Modal.Footer>
    </Modal>
);

Prompt2.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    cancel: PropTypes.func,
    dismiss: PropTypes.func,
    content: PropTypes.string,
    options: PropTypes.object
};

export default confirmable(Prompt2);
