import React from "react";
import classnames from "classnames";

export default class SuffixInputField extends React.PureComponent {

    render() {
        const {suffix, className} = this.props;

        return (
            <div className={classnames("fw-suffix-input-field", className)}>
                {this.props.children}
                <span className="fw-suffix-input-field__suffix">{suffix}</span>
            </div>
        )
    }
}
