import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from "@assecobs/react-components/src/dropzone/Dropzone"
import {connect} from "react-redux";
import {megabytesToBytes, DocumentType} from "../index";
import {Config} from "assecobs-faktor-web-common/index";
import {getAcceptTypeAndWeight} from "./services/getAcceptTypeAndWeight"
import {addFiles} from "./services/addFiles"
import {ERROR_REASON} from "../../../../../../../../packages/src/main/resources/static/scripts/app/components/constants/errorReason"

const SimpleDropZone = ({docTypeId, item, buttonTitle, fileExtensions, dispatch, fileExtensionsFetching, request}) => {

    const DOCUMENT_TYPE_FOR_PACKAGE = DocumentType.DOCUMENT_TYPE_FOR_PACKAGE;
    const contextNumber = request ? item.get("requestNumber") : item.get("packageNumberDesc");//item.get("actualAgreementNo");
    const elementId = request ? item.get("requestId") : item.get("baseAgreementAnnex0Id");
    const packageId = request ? null : item.get("packageId");

    const acceptTypeAndWeight = getAcceptTypeAndWeight(fileExtensions);


    const onAllFilesChange = (files) => {
        addFiles({
            docTypeId,
            packageId,
            elementId,
            DOCUMENT_TYPE_FOR_PACKAGE,
            acceptTypeAndWeight,
            files,
            dispatch,
            contextNumber
        })
    };


    const getPropsAndHelpers = () => ({
        acceptTypeAndWeight,
        maxSumSize: megabytesToBytes(Config.getMaxRequestSize()),
        noDrag: true,
        multiple: true,
        buttonTitle,
        errorMessage: {mime: ERROR_REASON.TYPE, size: ERROR_REASON.SIZE, sumSize: ERROR_REASON.EXCEEDED_FILES_SIZE},
        buttonClass: "dropzone-button",
        containerClass: "dropzone-container",
        onAllFilesChange,
        clearListAfterAdd: true
    });

    if (fileExtensionsFetching) {
        return <div/>;
    }

    return (
        <React.Fragment>
            <Dropzone {...getPropsAndHelpers()}/>
        </React.Fragment>
    );
};

SimpleDropZone.propTypes = {
    docTypeId: PropTypes.number,
    item: PropTypes.object,
    buttonTitle: PropTypes.object,
    fileExtensions: PropTypes.object,
    dispatch: PropTypes.func,
    fileExtensionsFetching: PropTypes.bool
};

export default connect(({fileExtensionReducer}) => ({
    fileExtensionsFetching: fileExtensionReducer.isFetching,
    fileExtensions: fileExtensionReducer.fileExtensions
}), null, null, {withRef: true})(SimpleDropZone)
