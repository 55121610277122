import React from "react";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import _ from "lodash";

export default class I18nMessage extends React.PureComponent {

    renderer = (message) => {
        const className = classnames("fw-i18n", this.props.className, {"fw-i18n--block": this.props.block})

        if (_.isString(message)) {
            const texts = message.split(/\\n/);

            if (texts.length === 1) {
                return <span className={className}
                             data-i18n-key={this.props.id}>{texts[0]}</span>
            }

            return (
                <span className={className}
                      data-i18n-key={this.props.id}>
                {texts.map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                })}
            </span>
            )
        } else {
            return <span className={className}
                  data-i18n-key={this.props.id}>{message}</span>
        }
    };

    render() {
        const {id, values} = this.props;

        return (
            <FormattedMessage id={id} values={values}>{this.renderer}</FormattedMessage>
        )
    }
}

