import React from "react";
import ReactDOM from "react-dom";
import {getApplicationLanguage} from "@assecobs/react-utils/utils";
import Init from "./Init";
import {Provider} from "react-redux";
import {IntlProvider} from "react-intl";
import permissionStorage from "@assecobs/react-utils/permission";
import menuStorage from "./menu/menu";
import footerStorage from "./footer/footer";
import storageManager from "../storageManager/storageManager";
import localStorage from '../localStorage/localStorage';
import {init as initLocale} from '../locale/localeProvider'
import {initInstanceConfigMetaData} from "./services/InstanceConfigMetaDataService";

export default async (initMenu = true, initFooter = true) => {

    storageManager.initializeStorageManager();
    localStorage.initialize();
    initLocale();

    const init = new Init();
    const {messages, permission} = await init.init();

    permissionStorage.init(permission);

    if (initMenu) {
        await menuStorage.init();
    }

    if (initFooter) {
        await footerStorage.init();
    }

    await initInstanceConfigMetaData();

    const localeTag = getApplicationLanguage().substr(0, 2);

    return async (Content, store) => {
        ReactDOM.render(
            <IntlProvider locale={localeTag} messages={messages}>
                <Provider store={store}>
                    <Content/>
                </Provider>
            </IntlProvider>,
            document.getElementById("app")
        );
    }
}
