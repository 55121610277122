import {DefinitionReturn} from "../types/DefinitionReturn";
import {FW_TOAST_BRAND} from "../enums/FWToastBrandEnum";
// @ts-ignore
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty';

type SendAttachmentToastType = {
    refresh: boolean,
    maxFileSize: number
}

export const SEND_ATTACHMENT_ERROR_TOAST = (params: SendAttachmentToastType): DefinitionReturn => ({
    templateProps: params,
    timeOut: 6000,
    refresh: !isNullOrEmpty(params.refresh) ? params.refresh : false,
    type: FW_TOAST_BRAND.DANGER_FOR_SEND_ATTACHMENT_ERROR
})