import React from "react";
import {Link} from "assecobs-faktor-web-common/index";
import shortid from "shortid";
import _ from "lodash";
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty';

const _replaceTag = Symbol();
const VALUE = "value";
const URL = "url";
const TAG = "tag";

export default class ParsableText extends React.PureComponent {

    [_replaceTag] = (source, toReplace, replacer) => {
        const index = _.indexOf(source, toReplace);
        source.splice(index, 1, replacer);
    };

    setParameter = (isValue, element, elements, key) => {
        return isValue === true ? element : elements.get(key);
    };

    render() {
        const {text, metadata} = this.props;

        let textParts = text.split(/(\^[^\^]*\^)/);

        metadata.forEach((p) => {
            const id = shortid.generate();
            const isValue = !isNullOrEmpty(p.value);
            const value = this.setParameter(isValue, p.value, p, VALUE);
            const url = this.setParameter(isValue, p.url, p, URL);
            const tag = this.setParameter(isValue, p.tag, p, TAG);
            const replacer = !isNullOrEmpty(url)
                ? (<Link key={`parsableText-${id}`}
                         id={`parsableTextLink-${id}`}
                         text={value}
                         url={url}
                         external
                />)
                : value;

            this[_replaceTag](textParts, "^" + tag + "^", replacer);
        });

        return (
            <span>{textParts}</span>
        )
    }
}
