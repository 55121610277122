import React from 'react'
import {MultiSelectFilter} from 'assecobs-react-data-table'
import {fwUrlUtils, RestService} from 'assecobs-faktor-web-common'
import segmentNameFilterParams from '../../segmentName/segmentNameFilterParams'
import SegmentNameMultiOptionTemplate from '../../segmentName/SegmentNameMultiOptionTemplate'
import {injectIntl} from 'react-intl'

const URL = fwUrlUtils.getApiUrl('/webapi/segmentName')

class SegmentNameFilter extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      options: null,
      loading: false,
    }
  }

  componentDidMount = async () => {
    await this.setState({
      loading: true,
    })

    const segmentName = (await RestService.getJSON(URL)).list

    await this.setState({
      options: segmentName,
      loading: false,
    })
  }

  render() {
    const {intl} = this.props

    return <MultiSelectFilter
      {...segmentNameFilterParams}
      {...this.props}
      id={this.props.id}
      title={intl.formatMessage({id: 'fw.segment.name'})}
      searchPlaceholder={intl.formatMessage({id: 'fw.common.search.type.searchPlaceholder'})}
      options={this.state.options}
      loading={this.state.loading}
      optionTemplate={SegmentNameMultiOptionTemplate}
    />
  }
}

export default injectIntl(SegmentNameFilter)
