import _ from "lodash";
import {getContext} from "assecobs-faktor-web-common/index";
import restService from "assecobs-react-utils/restService";
import fwUrlUtils from "../../utils/fwUrlUtils";

const STORAGE_KEY = "instance.config";
const FETCH_INSTANCE_CONFIG_METADATA_URL = fwUrlUtils.getApiUrl("/config/user/componentsMeta");

const emptyMetaData = {module: null, window: null, component: null, element: null, data: {}};

const initInstanceConfigMetaData = async () => {
    const contextClientId = await getClientId();

    if (_.isNil(contextClientId) ) {
        return Promise.resolve([])
    }

    const storage = getInstanceConfigMetaData();

    if (!_.isEmpty(storage) && contextClientId === storage.clientId) {
        return Promise.resolve(storage.metaData)
    }

    const fetchedInstanceConfigMetaData = await fetchInstanceConfigMetaData();
    save(contextClientId, fetchedInstanceConfigMetaData);

    return Promise.resolve(fetchedInstanceConfigMetaData)
};

const save = (clientId, metaData) => {
    const _metaData = metaData.map(rec => {
        return {...emptyMetaData, ...rec}
    });

    sessionStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({clientId, metaData: _metaData})
    );

    return metaData;
};

const getInstanceConfigMetaData = () => {
    const storageItem = sessionStorage.getItem(STORAGE_KEY);
    return storageItem ? JSON.parse(storageItem) : storageItem;
};

const fetchInstanceConfigMetaData = async () => {
    return restService.getJSON(FETCH_INSTANCE_CONFIG_METADATA_URL).then(result => {
        return result;
    });
};

const  getClientId = async () => {
    const userContext = await getContext();

    if (_.isEmpty(userContext)) {
        return Promise.resolve(void 0);
    }

    return Promise.resolve(userContext.get("clientId"));
};

export {
    initInstanceConfigMetaData,
    getInstanceConfigMetaData
}