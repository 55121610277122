import React from "react";
import CustomCell from "../common/CustomCell";
import MediaQuery from "react-responsive";
import {injectIntl} from 'react-intl';
import classnames from "classnames";
import _ from "lodash";

class BaseListDataRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    onOneSelectionChange = (id, checked) => {
        this.props.onOneSelectionChange(id);
        this.setState({selected: checked});
        if (this.props.onItemSelect) {
            this.props.onItemSelect();
        }
    };

    render() {
        const {columns, data, multiselect, disabled, selected, id, menu, detailsLink} = this.props;

        const cols = columns.map((column, index) => {
            let dataLabel = "";

            if (_.isString(column.header)) {
                if (!_.isNil(column.header) && !_.isEmpty(column.header)) {
                    dataLabel = this.props.intl.formatMessage({id: column.header});
                }
            } else {
                dataLabel = column.header(data);
            }

            const cell = <CustomCell key={`${column.id}-${id}`} first={index === 0}
                                     id={id}
                                     label={dataLabel}
                                     multiselect={multiselect}
                                     disabled={disabled}
                                     selected={selected}
                                     {...(index === 0 && {onOneSelectionChange: this.onOneSelectionChange})}
                                     {...(column.className || null)}
                                     {...(menu && {menu: menu(data)})}
                                     {...(detailsLink && {detailsLink: detailsLink(data)})}
            >
                {column.cell(data)}
            </CustomCell>;

            if (column.condition) {
                if (!column.condition(data)) {
                    return null;
                }
            }

            if (column.conditionMobile) {
                return <MediaQuery key={`${column.id}-${id}`} minWidth={768}>
                    {(matches) => {

                        if (matches)
                            return cell;
                        if (column.conditionMobile(data))
                            return cell;

                        return <div className="abs-list-table-mobile-empty"/>;
                    }}
                </MediaQuery>
            }

            if (column.mobileOnly) {
                return (
                    <MediaQuery key={`${column.id}-${id}`} maxWidth={767}>
                        {cell}
                    </MediaQuery>
                )
            }

            if (column.customMobileCell) {
                return <MediaQuery key={`${column.id}-${id}`} minWidth={768}>
                    {(matches) => {
                        if (matches)
                            return cell;
                        return column.customMobileCell(data);
                    }}
                </MediaQuery>
            }

            return cell;
        });

        return (
            <div className={classnames("abs-list-row", {"abs-list-row--selected": selected})}>
                {cols}
            </div>
        )
    }
}

export default injectIntl(BaseListDataRow);
