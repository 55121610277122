import React from "react";
import OrderingDirection from "../../common/OrderingDirection";
import SelectCheckbox from "../../SelectCheckbox";
import {I18nMessage} from "assecobs-faktor-web-common/index";
import classnames from "classnames";

export default class HeaderCell extends React.Component {

    onAllSelectionChange = () => {
        this.props.onAllSelectionChange();
    };

    onSort = (key, currentOrderingDirection) => {
        let newDirection;
        if (currentOrderingDirection) {
            if (currentOrderingDirection === OrderingDirection.ASC) {
                newDirection = OrderingDirection.DESC
            }
            else {
                newDirection = undefined;
            }
        }
        else {
            newDirection = OrderingDirection.ASC;
        }
        this.props.onSort({[key]: newDirection});
    };

    render() {
        const {label, first, orderingKey, orderingDirection, selectable, multiselect, isAllSelected, width} = this.props;

        let orderingIconClass = null;
        switch (orderingDirection) {
            case OrderingDirection.ASC:
                orderingIconClass = "abs-icon abs_arrow_upward ico";
                break;
            case OrderingDirection.DESC:
                orderingIconClass = "abs-icon abs_arrow_downward ico";
                break;
        }

        const disabled = selectable.size === 0;
        const checkAll = multiselect &&
            <SelectCheckbox id="selectAll"
                            checked={isAllSelected}
                            disabled={disabled}
                            {...(!disabled && {onChange: () => this.onAllSelectionChange()})} />

        if (!orderingKey) {
            return <div className={classnames(
                "col--header",
                "col-fr-" + width
            )}>{checkAll}<I18nMessage id={label}/></div>
        }

        const orderingIcon = <span className={orderingIconClass}/>;

        return (
            <div id={"sortBy_" + orderingKey}
                 onClick={() => this.onSort(orderingKey, orderingDirection)}
                 className={classnames(
                     "col--header",
                     "sortable",
                     "col-fr-" + width
                 )}>
                <div className="abs-list-table-header-content">
                    {checkAll}
                    <span className={first ? "abs-list-table-check-name" : ""}>
                        {orderingIcon}<I18nMessage id={label}/>
                    </span>
                </div>
            </div>
        );
    }
}
