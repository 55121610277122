import actionTypes from "./actions";

export default (id) => {
    if (!id) {
        throw new Error('itemsProperty must be defined for filterPanelReducer')
    }

    const actions = actionTypes(id);

    const filterChanged = (filterId, selected) => (async (dispatch) => {
        dispatch({
            type: actions.FILTER_CHANGED,
            filterId,
            selected
        });
    });

    const filtersSubmitted = (filters) => (async (dispatch) => {
        dispatch({
            type: actions.FILTERS_SUBMITTED,
            filters: filters
        });
    });

    const clearAllFilters = () => (async (dispatch) => {
        dispatch({
            type: actions.CLEAR_ALL_FILTERS
        });
    });

    return {
        filterChanged,
        filtersSubmitted,
        clearAllFilters
    }
}
