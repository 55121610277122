import React from "react";
import OrderingDirection from "../../common/OrderingDirection";
import SelectCheckbox from "../../common/SelectCheckbox";
import {I18nMessage} from "assecobs-faktor-web-common/index";
import classnames from "classnames";

const FilteredAmount = ({active, amount}) => {
    return active ? <span>{amount}</span> : null;
};

export default class HeaderCell extends React.Component {

    onAllSelectionChange = () => {
        this.props.onAllSelectionChange();
    };

    onSort = (key, currentOrderingDirection) => {
        let newDirection;
        if (currentOrderingDirection) {
            if (currentOrderingDirection === OrderingDirection.ASC) {
                newDirection = OrderingDirection.DESC
            }
            else {
                newDirection = undefined;
            }
        }
        else {
            newDirection = OrderingDirection.ASC;
        }
        this.props.onSort({[key]: newDirection});
    };

    render() {
        const {label, id, first, orderingKey, orderingDirection, selectable, multiselect, isAllSelected, filtered, totalCount} = this.props;

        let orderingIconClass = null;
        switch (orderingDirection) {
            case OrderingDirection.ASC:
                orderingIconClass = "abs-icon abs_arrow_upward ico";
                break;
            case OrderingDirection.DESC:
                orderingIconClass = "abs-icon abs_arrow_downward ico";
                break;
        }

        const disabled = selectable.size === 0;
        const checkAll = multiselect &&
            <SelectCheckbox id={`selectAll_${id}`}
                            checked={isAllSelected}
                            disabled={disabled}
                            {...(!disabled && {onChange: () => this.onAllSelectionChange()})} />;

        if (!orderingKey) {
            return <div className="col">{checkAll}<I18nMessage id={label} className={first ? "checkbox-label" : ""}/>
            </div>
        }

        const orderingIcon = <span className={orderingIconClass}/>;

        return (
            <div
                className="col sortable" {...((!first || !multiselect) && {onClick: () => this.onSort(orderingKey, orderingDirection)})}>
                <div className="col-content">
                    {checkAll}
                    <span
                        id={"sortBy_" + orderingKey} {...(first && multiselect && {onClick: () => this.onSort(orderingKey, orderingDirection)})}
                        className={classnames("header-label", {"checkbox-label": first})}>{orderingIcon}<I18nMessage
                        id={label} className="header-label-text"/>
                        {/*<FilteredAmount active={first && filtered} amount={totalCount}/>*/}
                    </span>
                    <span/>
                </div>
            </div>
        );
    }
}
