import React from "react";
import {IndexRedirect, Route} from "react-router";
import {createRoutesFromReactChildren} from 'react-router/lib//RouteUtils';
import UserTypes from "./UserTypes";
import _ from "lodash";

export default class UserContextRoute extends React.Component {

    static createRouteFromReactElement = (element, parentRoute) => {
        const {userType, component, clientPath, contractorPath, clientRoute, contractorRoute, clientVisibility, contractorVisibility} = element.props;

        const contractorTabVisibility = this.isTabVisibility(userType, UserTypes.CLIENT, contractorVisibility);
        const clientTabVisibility = this.isTabVisibility(userType, UserTypes.CONTRACTOR, clientVisibility);
        const path = _.isEqual(userType, UserTypes.CONTRACTOR) ? contractorPath : clientPath;

        return createRoutesFromReactChildren(
            <Route component={component}>
                <IndexRedirect to={path}/>
                {clientTabVisibility && clientRoute}
                {contractorTabVisibility && contractorRoute}
            </Route>,
            parentRoute
        )[0];
    };

    static isTabVisibility(userType, typeCustomer, visibility) {
        return _.isNil(visibility)
            ? !_.isEqual(userType, typeCustomer)
            : !_.isEqual(userType, typeCustomer) && visibility;
    }
};
