import React from "react";
import {submit as reduxSubmit} from 'redux-form';
import actionCreators from "./action/actionCreators";
import PropTypes from "prop-types";
import FilterPanelComponent from "./FilterPanelComponent";
import _ from "lodash";
import MediaQuery from "react-responsive";
import {injectIntl} from "react-intl";
import {browserHistory} from "react-router";
import BarComponent from "../components/bar/BarComponent";
import {I18nMessage} from "assecobs-faktor-web-common/index";

const actions = Symbol();

class FilterPanel extends React.Component {

    constructor(props) {
        super(props);
        this[actions] = actionCreators(props.filterPanelId);

        this.state = {
            showAdditionalFilters: false,

        }
    }

    toggleAdditionalFilters = () => {
        this.setState({
            showAdditionalFilters: !this.state.showAdditionalFilters
        })
    };

    async componentDidMount() {
        const queryParams = this.props.location.query;
        const filterDate = this.props.filterDate;
        let initialValues = this.props.initialFilterValues || {};

        if (!_.isEmpty(filterDate)) {
            initialValues = filterDate;
        } else if (!_.isEmpty(queryParams)) {
            const valueRegex = /\[.+\]/;
            for (const key of Object.keys(queryParams)) {
                const value = queryParams[key];
                const isArray = valueRegex.test(value);

                if (isArray) {
                    initialValues[key] = JSON.parse(value);
                } else {
                    initialValues[key] = value;
                }
            }
        }

        await this.props.initialize(initialValues);
        if (this.props.submitOnMount) {
            this.submit();
        }

        browserHistory.replace({
            ...this.props.location,
            query: {}
        });
    }

    submit = () => {
        this.props.dispatch(reduxSubmit(this.props.form));

        if (this.props.onSubmitHandler) {
            this.props.onSubmitHandler();
        }
    };

    clear = async () => {
        await this.props.dispatch(this[actions].clearAllFilters());
        await this.props.initialize({});
        this.submit();
    };

    componentDidUpdate(prevProps) {
        if (this.props.needsSubmit && !prevProps.needsSubmit) {
            this.submit();
        }
    }

    getChildContext() {
        return {
            filterPanelId: this.props.filterPanelId,
            autoSubmit: this.props.autoSubmit,
            autoSubmitOnClear: this.props.autoSubmitOnClear
        };
    }

    render() {
        const {mainFilters, additionalFilters, autoSubmit, open, isSubmitted, submitButtonText, intl, selectedFilters, clearable} = this.props;

        let selectedLengthCounter = 0;

        _.forOwn(selectedFilters, (value) => {
            if (value) {
                selectedLengthCounter++;
            }
        });

        const barInfo = (!_.isNil(selectedLengthCounter) && selectedLengthCounter > 0) ?
            <span><I18nMessage id="common.activeFilters"/>: {selectedLengthCounter}</span> : null;

        return (
            <MediaQuery minWidth={768}>
                {(matches) => {

                    if (matches) {
                        return <FilterPanelComponent mainFilters={mainFilters}
                                                     additionalFilters={additionalFilters}
                                                     autoSubmit={autoSubmit}
                                                     showAdditionalFilters={this.state.showAdditionalFilters}
                                                     isSubmitted={isSubmitted}
                                                     isOpen={open}
                                                     onSubmit={this.submit}
                                                     onClear={this.clear}
                                                     clearable={clearable}
                                                     onToggleAdditionalFilters={this.toggleAdditionalFilters}
                                                     submitButtonText={submitButtonText || intl.formatMessage({id: "common.submit"})}
                        />
                    }

                    return <BarComponent titleKey="common.filter" barInfo={barInfo}>
                        <FilterPanelComponent mainFilters={mainFilters}
                                              additionalFilters={additionalFilters}
                                              autoSubmit={autoSubmit}
                                              showAdditionalFilters={this.state.showAdditionalFilters}
                                              isSubmitted={isSubmitted}
                                              isOpen={open}
                                              onSubmit={this.submit}
                                              onClear={this.clear}
                                              clearable={clearable}
                                              onToggleAdditionalFilters={this.toggleAdditionalFilters}
                                              submitButtonText={submitButtonText || intl.formatMessage({id: "common.submit"})}
                        />
                    </BarComponent>

                }}
            </MediaQuery>
        )
    }

    static defaultProps = {
        autoSubmit: true,
        autoSubmitOnClear: true,
        submitOnMount: true,
        clearable: true
    }
}

FilterPanel.childContextTypes = {
    filterPanelId: PropTypes.string,
    autoSubmit: PropTypes.bool,
    autoSubmitOnClear: PropTypes.bool
};


export default injectIntl(FilterPanel);
