import React from 'react';
import withContentLoader from '@assecobs-react-decorators/with-content-loader/src/withContentLoader'
import {Icon} from "@assecobs/react-common-components/index";
import {I18nMessage} from "assecobs-faktor-web-common";

const ControlTemplate = ({dataSource = [], isLoading, controlClassName = '', onClick, placeholder = '', selectedValue = void 0}) => (
    <div className={`absui-form-control ${controlClassName}`} onClick={onClick} tabIndex="0">
        <Icon name="abs_business"/>
        {withContentLoader(ControlTemplateHeader)({
            dataSource,
            isLoading,
            selectedValue,
            placeholder,
            loaderClass: 'absui-loader--small',
            loaderHeight: 0,
        })}
    </div>
);

const ControlTemplateHeader = ({dataSource, selectedValue, placeholder}) => (
    <React.Fragment>
        <div className="client-select-option-placeholder-text">
            <I18nMessage id="fw.company"/>

            <div className="client-select-option-placeholder-text__content">{(selectedValue && getSelectedLabel(dataSource, selectedValue)) ||
            placeholder}</div>
        </div>
        <i className="client-select-option-placeholder-icon abs-icon abs_expand_more abs-rotate"/>

    </React.Fragment>
)

const getSelectedLabel = (dataSource, selectedValue) => {
    return dataSource.find(item => item.value === selectedValue).label
}

export default ControlTemplate;


