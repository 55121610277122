import React from "react";
import LogoutMenuItem from "./LogoutMenuItem";
import Navbar from "../Navbar";
import QuickSearch from "../../quickSearch/QuickSearch";
import AlertsMenuItem from "./AlertsMenuItem";
import ConfigurationMenuItem from "../configurationMenu/ConfigurationMenuItem";

export default class NonCollapsibleMenu extends React.PureComponent {

    alertsToggle = () => {
        this.props.onAlertsToggle();
    };

    quickSearchToggle = () => {
        this.props.onQuickSearchToggle();
    };

    render() {
        const {items} = this.props;

        if (!items) {
            return null;
        }

        return (
            <Navbar.NonCollapse>
                {items.map((item, index) => {
                        switch (item.type) {
                            case "ALERTS":
                                return (
                                    <ul key={index} className="nav navbar-nav">
                                        <Navbar.Dropdown menuClassName="fw-alerts dropdown-menu-right"
                                                         key={index}
                                                         icon={item.icon}
                                                         alertIndicatorVisible={this.props.unreadAlertsExists}
                                                         ignoreArrow={true}
                                                         ignoreContentClick={true}
                                                         changeState={this.alertsToggle}
                                                         open={this.props.alertsOpen}>
                                            <AlertsMenuItem open={this.props.alertsOpen}/>
                                        </Navbar.Dropdown>
                                    </ul>
                                );

                            case "QUICK_SEARCH":
                                return (
                                    <ul key={index} className="nav navbar-nav">
                                        <Navbar.Dropdown menuClassName="abs-quick-search-panel dropdown-menu-right"
                                                         key={index}
                                                         icon={item.icon}
                                                         ignoreArrow={true}
                                                         ignoreContentClick={true}
                                                         changeState={this.quickSearchToggle}
                                                         open={this.props.quickSearchOpen}>
                                            <QuickSearch
                                                quickSearchOnOpen={this.props.quickSearchOpen}/>
                                        </Navbar.Dropdown>
                                    </ul>
                                );

                            case "CONFIGURATION":
                                return (
                                    <ul key={index} className="nav navbar-nav">
                                        <Navbar.Dropdown menuClassName="dropdown-menu-right"
                                                         key={index}
                                                         icon={item.icon}
                                                         ignoreArrow={true}
                                                         ignoreContentClick={true}>
                                            <ConfigurationMenuItem/>
                                        </Navbar.Dropdown>
                                    </ul>
                                );

                            case "LOGOUT":
                                return (
                                    <ul key={index} className="nav navbar-nav">
                                        <li className="navbar-nav-item">
                                            <LogoutMenuItem icon={item.icon} title={item.label}/>
                                        </li>
                                    </ul>
                                );

                            case "LINK":
                                return (
                                    <ul key={index} className="nav navbar-nav">
                                        <li>
                                            <a href={item.url} title={item.title}>
                                                <i className={"abs-icon navbar-nav-icon " + item.icon}/>
                                            </a>
                                        </li>
                                    </ul>
                                );

                            default:
                                return null;
                        }
                    }
                )}
            </Navbar.NonCollapse>
        );

    }
}
