import {megabytesToBytes} from "../../index";

const getAcceptTypeAndWeight = (fileExtensions) => {
    const acceptTypeAndWeight = []

    fileExtensions.toJS().forEach(extension => {
        extension.mimeTypes.forEach(mime => {
            acceptTypeAndWeight.push({
                type: mime,
                maxSize: megabytesToBytes(extension.attachmentMaxSize),
                extensionId: extension.attachmentFileExtensionId
            })
        })
    })

    return acceptTypeAndWeight
}

export {getAcceptTypeAndWeight}