export default {
    NEW_INVOICE: -1,
    REQUIRES_CONFIRMATION: 0,
    CONFIRMED: 1,
    PROCEED: 2,
    NOT_CONFIRM_INCORRECT_DATA: 4,
    DATA_CORRECTED: 5,
    CONFIRM_AFTER_IMPROVEMENT: 6,
    NOT_CONFIRM: 12,
    CONFIRMED_BY_FACTOR: 20,
    CONFIRM: 22
}
