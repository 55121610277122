import React from "react";

export default class InvoiceChartTooltip extends React.Component {

    render() {
        const {data} = this.props;

        return (
            <span>{data.name} {data.percent}%</span>
        );
    }
}
