import React from "react";
import PageHeader from "./PageHeader";
import _ from "lodash";
import {injectIntl} from 'react-intl';
import classnames from "classnames";

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {mounting: true};
    }

    componentWillMount() {
        document.title = this.props.intl.formatMessage({id: "fw.title.application"});

        if (_.isEqual(typeof this.props.title, "string")) {
            document.title += " - " + this.props.intl.formatMessage({id: this.props.title});

            if (this.props.subtitle && _.isEqual(typeof this.props.subtitle, "string")) {
                document.title += " / " + this.props.intl.formatMessage({id: this.props.subtitle});
            }
        }
    }

    componentDidMount() {
        this.setState({mounting: false});
    }

    render() {
        const {title, subtitle, detailsTitle, toolbar, headerPanel, mainPanel, contextDropdown, withChildToolbar, detailsHeader, className} = this.props;
        return (
            <div className={className}>
                <div className={classnames("container-details-panel", {"container-details-panel__no-margin": withChildToolbar})}>
                    <div className="container-nav">
                        <div className="container-fluid">
                            <PageHeader
                                title={_.isEqual(typeof title, "string") ? this.props.intl.formatMessage({id: title}) : title}
                                subtitle={_.isEqual(typeof subtitle, "string") ? this.props.intl.formatMessage({id: subtitle}) : subtitle}
                                detailsTitle={detailsTitle}
                                contextDropdown={contextDropdown}
                                toolbar={toolbar}/>
                            {headerPanel && <div className="page-header-data">{headerPanel}</div>}
                        </div>
                    </div>
                </div>

                <div className="abs-container">
                    {!this.state.mounting && mainPanel}
                </div>
            </div>
        )
    }

}

export default injectIntl(Page);
