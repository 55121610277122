import React from "react";
import NumberFormat from "react-number-format";
import SuffixInputField from "./SuffixInputField";
import classnames from "classnames";

export default class MoneyInputField extends React.PureComponent {

    onChange = (objectValue) => {
        this.props.input.onChange(isNaN(objectValue.floatValue) ? '' : objectValue.floatValue);
    };

    UNSAFE_componentWillMount = () => {
        if (this.props.id === "invoiceValueVat") {
            if(this.props.wasSelectedAggrementVatBefore){
                this.props.input.onChange("")
            }
        }
    };

    render() {
        const {invalid, currency, allowNegative, disabled, decimalScale, maxLength} = this.props;
        const decScale = (decimalScale !== undefined) ? decimalScale : 2;

        return (
            <SuffixInputField suffix={currency} className="fw-currency-suffix-input-field">
                <NumberFormat
                    className={classnames("abs-money-input-field", {
                        "k-invalid": invalid
                    })}
                    onValueChange={this.onChange}
                    value={this.props.input.value}
                    decimalSeparator={','}
                    decimalScale={decScale}
                    fixedDecimalScale={true}
                    disabled={disabled}
                    allowNegative={allowNegative}
                    maxLength={maxLength}

                />
            </SuffixInputField>
        )
    }
}
