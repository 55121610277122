import {DefinitionReturn} from "../types/DefinitionReturn";
import {FW_TOAST_BRAND} from "../enums/FWToastBrandEnum";

type ErrorToastType = {
    bodyText: string,
    headerTextKey: string
}

export const ERROR_TOAST = (params: ErrorToastType): DefinitionReturn => ({
    templateProps: params,
    timeOut: 6000,
    type: FW_TOAST_BRAND.DANGER_FOR_ERROR_TOAST
})