import {RestService, fwUrlUtils} from "../index";

class LogoutHandler {

    logout = () => {
        RestService.post(fwUrlUtils.getApiUrl("/logout"));
    };
}

export default new LogoutHandler();
