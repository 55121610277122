import React from "react";
import ReactDOM from "react-dom";
import ScrollTop from "react-scroll-up";
import Icon from "@assecobs/react-common-components/icon/Icon";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import I18nMessage from "../i18n/I18nMessage";

const scrollUpButton = Symbol();


const CustomTooltip = ({right, bottom}) => {
    return (
        <Tooltip id="tooltip" style={{position: "fixed", right: right + "px", bottom: bottom + "px"}}
                 arrowOffsetLeft="90%"
                 placement="top"
                 className="scroll-tooltip">
            <I18nMessage id='fw.common.scrollUp.button'/>
        </Tooltip>
    );
};

export default class ScrollUpButton extends React.Component {

    constructor() {
        super();
        this.state = {right: 0, bottom: 0};
    }

    componentDidMount() {
        window.addEventListener("scroll", this.countTooltipPosition);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.countTooltipPosition);
    }

    getNumber = (stringDistance) => {
        return +(stringDistance.split("px")[0]);
    };

    countTooltipPosition = () => {

        const element = ReactDOM.findDOMNode(this[scrollUpButton]);

        const right = this.getNumber(element.style.right);
        const bottom = this.getNumber(element.style.bottom) + element.getBoundingClientRect().height;

        this.setState({right, bottom});
    };

    render() {

        const {right, bottom} = this.state;

        return (

            <ScrollTop duration={1000} showUnder={10} ref={c => this[scrollUpButton] = c}
                       style={{zIndex: 1070, bottom: 15, right: 15}}>
                <OverlayTrigger placement="top"
                                overlay={<CustomTooltip id="tooltip-main" right={right} bottom={bottom}/>}>
                    <div className="fw-scroll-up-button">
                        <Icon name="abs_arrow_upward"/>
                    </div>
                </OverlayTrigger>
            </ScrollTop>

        )
    }
}