import React from "react";
import {I18nMessage, CustomRangeCalendar, ErrorTooltip} from "assecobs-faktor-web-common/index";

export default class DateRangeForm extends React.Component {

    onChange = (dateFrom, dateTo) => {
        if (dateFrom) {
            this.props.DateFrom.input.onChange(dateFrom);
            this.props.DateTo.input.onChange(dateTo);
        } else {
            this.props.DateFrom.input.onChange("");
            this.props.DateTo.input.onChange("");
        }
    };

    render() {

        const {invalid, dateOfMigration} = this.props;

        return (
            <CustomRangeCalendar dateOnChange={this.onChange} invalid={invalid} dateOfMigration={dateOfMigration}/>
        )
    }
}