import React from "react";
import OrderingDirection from "../types/OrderingDirection";

export default class HeaderCell extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.orderingDirection !== this.props.orderingDirection || !nextProps.orderingDirection;
    }

    onOrderingChange = () => {
        this.props.onToggleOrdering(this.props.orderingKey);
    };

    componentWillReceiveProps(props) {
        if (props.selectable && props.forceCheckMainCheckbox !== null && props.forceCheckMainCheckbox !== undefined) {
            this.refs.checkAll.checked = props.forceCheckMainCheckbox;
        }
    }

    render() {
        const {label, first, orderingKey, orderingDirection, selectable} = this.props;

        let orderingIconClass = null;
        switch (orderingDirection) {
            case OrderingDirection.ASC:
                orderingIconClass = "abs-icon abs_arrow_upward ico";
                break;
            case OrderingDirection.DESC:
                orderingIconClass = "abs-icon abs_arrow_downward ico";
                break;
        }

        const checkAll = selectable &&
            <div className="block-switch">
                <input ref="checkAll" id="toggle-flip-all" className="toggle toggle-flip" type="checkbox"
                       onClick={() => this.props.toggleCheckAll(this.refs.checkAll.checked)}/>
                <label htmlFor="toggle-flip-all"/>
            </div>;

        if (!orderingKey) {
            return <div className="col">{checkAll}{label}</div>
        }

        const orderingIcon = <span className={orderingIconClass}/>;

        return (
            <div className="col sortable">
                {checkAll}
                <span onClick={this.onOrderingChange}
                      className={first ? "checkbox-label" : ""}>{orderingIcon}{label}</span>
            </div>
        );
    }
}
