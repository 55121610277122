import {fetchFooter} from "./fetchFooter";

const _footer = Symbol();

class Footer {
    async init() {
        this[_footer] = await fetchFooter();
    }

    get footer() {
        return this[_footer];
    }
}

export default new Footer();
