import React from "react";

export default ({option, selectedIds}) => {
    const checked = selectedIds.indexOf(option.segmentId) > -1;

    return (
        <label className="r-ss-dropdown-multiselect-option" htmlFor={"segmentName-" + option.segmentId}>
            <input id={"segmentName-" + option.segmentId} className="toggle toggle-flip" type="checkbox"
                   checked={checked}/>
            <label htmlFor={"segmentName-" + option.segmentId}/>
            <div>
                <div>
                    <span>{option.segmentTypeName}</span>
                </div>
            </div>
        </label>
    );
};
