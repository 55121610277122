import React from "react";
import PropTypes from "prop-types";
import {FormattedNumber} from "react-intl";

export default class Percent extends React.Component {

    componentDidMount() {
        if (this.props.amount === null) {
            this.props.elementIsNull();
        }
    }

    render() {
        const {value, minFractionDigits, maxFractionDigits} = this.props;

        if (value === undefined || value === null) {
            return null;
        }

        return (
            <span>
            <FormattedNumber value={value}
                             minimumFractionDigits={minFractionDigits ? minFractionDigits : 0}
                             maximumFractionDigits={maxFractionDigits ? maxFractionDigits : 2}/>%
            </span>
        )
    }

    static get propTypes() {
        return {
            value: PropTypes.number
        }
    }
}
