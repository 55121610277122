import dynamicTranslateMessagesModule from "./modules/dynamicTranslateMessagesModule";
import configParamsModule from "./modules/configParamsModule";
import {initPermissionModule} from "./modules/initPermissionModule";

export default class Init {
    async init() {
        const promises = await Promise.all([configParamsModule.getData("config"), dynamicTranslateMessagesModule.getData("value"), initPermissionModule()]);

        return {
            config: promises[0],
            messages: promises[1],
            permission: promises[2]
        }
    }
}
