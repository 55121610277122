import React from "react";
import {connect} from "react-redux";
import I18nMessage from "../i18n/I18nMessage";
import {injectIntl} from 'react-intl';
import classnames from "classnames";
import * as action from "./actions";
import {RelativeTime} from "assecobs-faktor-web-common/index";
import moment from "moment";
import {Icon, Loadable} from "@assecobs/react-common-components/index";

const markAllAsReadTitle = Symbol();

const AlertsTitle = ({unreadCount}) => (
    <div className="fw-alerts__title">
        <I18nMessage id="fw.alerts" className="fw-alerts__title-label"/>
        {unreadCount > 0 && (
            <span className="fw-alerts__unread-count">
                <span>{unreadCount}</span>
            </span>
        )}
    </div>
);

const AlertsToolbar = (props) => (
    <div className="fw-alerts__toolbar">
        <button id="markAllAsRead" className="btn btn-xs btn-type-d abs-btn-icon fw-alerts__mark-all-as-read-btn"
                onClick={props.markAllAsRead} title={props.markAllAsReadTitle} disabled={!props.unreadExists}>
            <Icon name="abs_done_all" className="abs-btn-icon_icon"/>
        </button>

        <button id="toggleShowUnreadOnly" className="fw-alerts__toggle-show-unread-only-btn" onClick={props.toggleShowUnreadOnly}>
            <div className={classnames("abs-toggle", {
                "abs-toggle--on": props.showUnreadOnly,
                "abs-toggle--off": !props.showUnreadOnly
            })}/>
            <I18nMessage id="fw.alerts.showUnreadOnly" className="fw-alerts__toggle-show-unread-only-btn-label"/>
        </button>
    </div>
);

const AlertsEmptyInfo = (props) => (
    <div className="fw-alerts__empty">
        <Icon name="abs_notifications_off"/>
        <I18nMessage id="fw.alerts.empty" className="fw-alerts__empty-label"/>
    </div>
);

const AlertsList = (props) => (
    <div className={classnames("fw-alerts__list", {
        "fw-alerts__list--hidden": props.hidden
    })}>
        {props.alerts.map(a => {
            const formattedDateTime = moment(a.get("date")).format(props.dateTimeFormat);

            return (
                <div className="fw-alerts__alert-line" key={a.get("id")} onClick={() => props.showAlert(a)} title={props.showAlertTitle}>
                    <div className={classnames("fw-alerts__alert", {
                        "fw-alerts__alert--read": !a.get("read"),
                        "fw-alerts__alert--unread": a.get("read")
                    })}>
                        <div className="fw-alerts__alert-name">{a.get("name")}</div>
                        <div className="fw-alerts__alert-date" title={formattedDateTime}>
                            <RelativeTime value={a.get("date")} relativeTimeThresholdSeconds={props.relativeTimeThresholdSeconds}/>
                        </div>
                    </div>
                    <Icon name="abs_keyboard_arrow_right2" className="abs-btn-icon_icon fw-alerts__show-details-icon"/>
                </div>
            )
        })}
    </div>
);

class AlertList extends React.Component {

    constructor(props) {
        super(props);
        this[markAllAsReadTitle] = props.intl.formatMessage({id: "fw.alerts.markAllAsRead"});

        this.state = {
            showUnreadOnly: false
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.load && this.props.load) {
            this.props.dispatch(action.loadAlerts());
            this.props.dataLoaded();
        }
    }

    markAllAsRead = async () => {
        await this.props.dispatch(action.markAllAlertsAsRead());
        this.props.dispatch(action.loadAlerts());
    };

    toggleShowUnreadOnly = () => {
        this.setState(prevState => ({showUnreadOnly: !prevState.showUnreadOnly}));
    };

    showAlert = (alert) => {
        if (!alert.get("read")) {
            this.props.dispatch(action.markAlertAsRead(alert.get("id")));
        }
        this.props.showAlert(alert);
    };

    render() {
        const {alerts, loading, unreadCount} = this.props;

        const alertsToShow = this.state.showUnreadOnly ? alerts.filter(a => !a.get("read")) : alerts;
        const showEmptyInfo = alertsToShow.isEmpty() && !loading;
        const showList = !alertsToShow.isEmpty() || loading;

        return (
            <div>
                <AlertsTitle unreadCount={unreadCount}/>

                <Loadable loading={loading} transparent>
                    <AlertsToolbar unreadExists={unreadCount > 0}
                                   showUnreadOnly={this.state.showUnreadOnly}
                                   markAllAsReadTitle={this[markAllAsReadTitle]}
                                   markAllAsRead={this.markAllAsRead}
                                   toggleShowUnreadOnly={this.toggleShowUnreadOnly}
                    />

                    {showEmptyInfo && <AlertsEmptyInfo/>}

                    {showList && (
                        <AlertsList
                            alerts={alertsToShow}
                            hidden={this.props.hidden}
                            dateTimeFormat="YYYY-MM-DD HH:mm:SS"
                            relativeTimeThresholdSeconds={24 * 60 * 60}
                            showAlert={this.showAlert}
                            showAlertTitle={this.props.intl.formatMessage({id: "fw.seeDetails"})}
                        />
                    )}
                </Loadable>
            </div>
        )
    }
}

export default connect(state => ({
    loading: state.alerts.loading,
    alerts: state.alerts.list,
    unreadCount: state.alerts.unreadCount
}))(injectIntl(AlertList));
