import React from "react";

export default class ResponsiveListDataRow extends React.Component {

    checkElementId = (checked) => {
        if (checked)
            this.props.selectId(this.props.id);
        else
            this.props.deselectId(this.props.id);

    };

    render() {
        const childrenWithProps = React.Children.map(this.props.children,
            (child, index) => React.cloneElement(child, index === 0 ? {
                    id: this.props.id,
                    first: index === 0,
                    selectable: this.props.selectable,
                    checkId: this.checkElementId,
                    checked: this.props.checkboxActive,
                    checkboxDisabled: this.props.checkboxDisabled
                } : {})
        );

        return (
            <div className="abs-list-row">
                {childrenWithProps}
            </div>
        );
    }
}
