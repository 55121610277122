import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
  ToastWrapperDefaultTemplatePropTypes,
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from 'assecobs-faktor-web-common/index'
import SendAttachmentsErrorToast from '../body/SendAttachmentsErrorToast'
import {TemplateType} from '../types/TemplateType'

type SendAttachmentsErrorToastType = {
  fileFailure: {
    files: Array<string>,
    filesZIP: Array<string>,
  };
  refresh: boolean
}

interface Props extends TemplateType {
  templateProps: SendAttachmentsErrorToastType;
}

const ToastDangerSendAttachmentsErrorTemplate = ({
                                                   templateProps,
                                                   removeToast,
                                                   id,
                                                   timeOut = 7000,
                                                 }: Props): ReactElement => {

  const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
  const messageHeader = <I18nMessage id="fw.attachment.adding.error.toast.header" />
  const messageBody = <SendAttachmentsErrorToast {...templateProps} />

  return (
    <div className={`toastContainer--danger ${className}`} onAnimationEnd={onAnimationEnd}>
      <ToastContentTemplate onClose={onClose}
                            content={{
                              ...templateProps,
                              header: messageHeader,
                              body: messageBody,
                            }}
                            timeOut={timeOut} />
    </div>
  )
}

ToastDangerSendAttachmentsErrorTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastDangerSendAttachmentsErrorTemplate