import React from "react";

export default ({id, checked, disabled, onChange}) => (
    <div className="block-switch">
        <input
            id={id}
            className="toggle toggle-flip"
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
        />
        <label htmlFor={id} />
    </div>
)
