import React from "react";
import classnames from "classnames";
import _ from "lodash";
import ReadMoreReact from 'react-truncate';
import {I18nMessage} from "assecobs-faktor-web-common/index";
const LINES = 2;

class Item extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {label, rendered, alignValueRight, valueClassName, children} = this.props;

        if (!rendered) {
            return null;
        }

        return (
            children ? <dl className="abs-attributes-list-item">
                <dt className="abs-attributes-list-item__label">{label}</dt>
                <dd className={classnames("abs-attributes-list-item__value", {"text-align-right": alignValueRight}, valueClassName)}>{children}</dd>
            </dl> : null
        )
    }

    static defaultProps = {
        rendered: true,
        alignValueRight: false
    }
}

class Section extends React.Component {

    childrenNotEmpty = () => {

        let allUndefined = true;

        React.Children.forEach(this.props.children, (child) => {
            if (!_.isNil(child)) {
                allUndefined = false;
            }
        });

        return allUndefined;
    };

    render() {
        const {title, withoutHeader, headerLine, children, rendered} = this.props;


        if (rendered === false) {
            return null;
        }

        if (withoutHeader) {
            return children && <div>{children}</div>
        }

        return (
            !this.childrenNotEmpty() ? <div className="abs-attributes-list-section">
                {title && <h2 className="abs-title">{title}</h2>}
                <hr className={classnames({
                    "abs-header-line-without-title": !title,
                    "abs-header-line-invisible": !headerLine
                })}/>
                <div className="abs-attributes-list-section__items">
                    {children}
                </div>
            </div> : null
        )
    }

    static defaultProps = {
        headerLine: true
    }
}

class Column extends React.Component {
    render() {
        if (_.isEmpty(this.props.children)) {
            return null;
        }

        return (
            <div className="abs-attributes-list-column">
                {this.props.children}
            </div>
        )
    }
}


class Info extends React.Component {

    constructor(props) {
        super(props);

        this.state = {expanded: false,};
    }

    toggleLines = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        const {info} = this.props;

        return (
            <div className="abs-attributes-list-info">
                <ReadMoreReact
                    lines={!this.state.expanded && LINES}
                    ellipsis={(
                        <span>...<p className="abs-attributes-list-info-truncate"><a onClick={this.toggleLines}>{<I18nMessage id="fw.show.everything">Pokaż
                            wszystko</I18nMessage>}</a></p></span>
                    )}
                >{info}</ReadMoreReact>
            </div>
        )
    }


}

class AttributesList extends React.Component {
    render() {

        if (this.props.loading) {
            return <div
                className={classnames("abs-attributes-list", "abs-attributes-list-style-a", "container-box-flex", "loader-mask-style-b",
                    this.props.className)}/>
        }


        return (
            <div
                className={classnames("abs-attributes-list", "abs-attributes-list-style-a", "container-box-flex", this.props.className)}>
                {this.props.children}
            </div>
        )
    }
}

AttributesList.Column = Column;
AttributesList.Section = Section;
AttributesList.Item = Item;
AttributesList.Info = Info;

export default AttributesList;
