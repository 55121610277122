import React, {Component} from "react";
import PropTypes from "prop-types";
import _ from "lodash";

export default class TabsNav extends Component {

    static propTypes = {
        parent: PropTypes.string.isRequired
    }

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    render() {
        const context = {path: this.props.parent, query: this.context.router.location.query};

        const childrenWithProps = React.Children.map(this.props.children,
            (child) => !_.isNil(child) ? React.cloneElement(child, {
                context: context
            }) : null
        );

        return (
            <ul className="nav nav-tabs">
                {childrenWithProps}
            </ul>
        )
    }

}
