import React from "react";

export default class FirstCell extends React.Component {

    toggleCheck = () => {
        this.props.checkId(this.refs.checkbox.checked);
    };

    render() {
        const {label, dropdownMenu, selectable, checked, checkboxDisabled}  = this.props;

        if (checked !== null && checked !== undefined && this.refs.checkbox && !this.refs.checkbox.disabled) {
            this.refs.checkbox.checked = checked;
        }

        return (
            <div className={"col " + (this.props.className || "")}>
                <div className="hamburger dropdown abs-dropdown">
                    {selectable &&
                    <div className="block-switch">
                        <input id={"toggle-flip-" + this.props.id} className="toggle toggle-flip" type="checkbox"
                               ref="checkbox" disabled={checkboxDisabled}
                               onClick={this.toggleCheck}/><label
                        htmlFor={"toggle-flip-" + this.props.id}/>
                    </div>
                    }
                    <div className="info">
                        <div className="fw_info">
                            <div className="nr label" data-label={label}>{this.props.children}</div>
                        </div>
                    </div>

                    {dropdownMenu}
                </div>
            </div>
        )
    }
}
