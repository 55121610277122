import React from "react";
import PageHeaderToolbar from "./PageHeaderToolbar";

export default class PageHeader extends React.Component {

    render() {
        const {title, subtitle, detailsTitle, contextDropdown, toolbar} = this.props;

        return (
            <div className="abs-page-header">
                <div className="abs-page-header-title">
                    <span className="abs-page-header-title__main">{title}</span>
                    {detailsTitle ? <span className="abs-page-header-title__subtitle"> : {detailsTitle}</span> : null}
                    {subtitle ? <span className="abs-page-header-title__subtitle"> / {subtitle}</span> : null}

                    {contextDropdown ? [<span key="slash_1"
                                              className="abs-page-header-title__slash">/</span>, contextDropdown] : null}
                </div>

                <PageHeaderToolbar toolbar={toolbar} />
            </div>
        )
    }
}
