import * as actionTypes from "./actionTypes";
import {fromJS, List} from "immutable";

const initState = {
    isFetching: true,
    fileExtensions: new List()
};

export default function (state = initState, action) {
    switch (action.type) {

        case actionTypes.REQUEST_FILE_EXTENSIONS:
            return {
                ...state,
                isFetching: action.isFetching
            };
            break;

        case actionTypes.RECEIVE_FILE_EXTENSIONS:
            return {
                ...state,
                isFetching: action.isFetching,
                fileExtensions: fromJS(action.fileExtensions)
            };
            break;
    }

    return state;


}
