import React from "react";
import PropTypes from 'prop-types';
import Navbar from "../Navbar";
import DropdownMenuContent from "./DropdownMenuContent";
import I18nMessage from "../../i18n/I18nMessage";
import classnames from "classnames";

export default class CollapsibleDropdownMenu extends React.PureComponent {

    render() {
        const {icon, label, styleClass, content, openOnRight} = this.props;

        return (
            <Navbar.Nav>
                <Navbar.Dropdown icon={icon}
                                 label={<I18nMessage id={label}/>}
                                 menuClassName={classnames(styleClass, {
                                     "dropdown-menu-right": openOnRight
                                 })}>

                    <DropdownMenuContent items={content.left}/>
                    <DropdownMenuContent items={content.right} className="actions-panel"/>

                </Navbar.Dropdown>
            </Navbar.Nav>
        )
    }

    static get propTypes() {
        return {
            icon: PropTypes.string,
            label: PropTypes.string,
            styleClass: PropTypes.string,
            content: PropTypes.object,
            openOnRight: PropTypes.bool
        }
    }
}
