import React from "react";
import classnames from "classnames";
import {I18nMessage, DropdownMenu, ImmutableUtils} from "assecobs-faktor-web-common/index";

export default class AddRequestDropdownButton extends React.PureComponent {

    render() {

        const {id, className, requestLinks} = this.props;

        return (
            <DropdownMenu text={<I18nMessage id="fw.menu.putRequest"/>}
                          id={id}
                          buttonClassName={classnames("btn btn-xs btn-type-a", className)}
                          rendered={!ImmutableUtils.isEmpty(requestLinks)}>
                {requestLinks.map(link =>
                    <DropdownMenu.Item key={link.get("requestTypeId")}
                                       label={link.get("requestType")}
                                       onSelect={() => window.location.href = link.get("link")}/>)}
            </DropdownMenu>
        );

    }
    
}
