import React from "react";
import classnames from "classnames";

export default class BasicCell extends React.Component {

    render() {
        const {className, children} = this.props;

        return (
            <div className={classnames("col", className)}>
                {children}
            </div>
        )
    }
}
