import React from "react";
import BaseListHeader from "./BaseListHeader";
import BaseListDataRow from "./BaseListDataRow";
import NoData from "../common/NoData";
import LoadMore from "../common/LoadMore";
import {Loadable} from "@assecobs/react-common-components/index";
import classnames from "classnames";
import _ from "lodash";
import {BarComponent} from "../../index";
import MediaQuery from "react-responsive";
import BarSortingComponent from "../bar/BarSortingComponent";
import Icon from "@assecobs/react-common-components/icon/Icon";
import {I18nMessage} from "assecobs-faktor-web-common";
import shortid from "shortid";

const dataTable = Symbol();
const dataTableBody = Symbol();

export default class BaseListComponent extends React.Component {

    constructor() {
        super();
        this[dataTable] = null;
        this.state = {
            loadMoreWidth: "100%",
            setRightBorder: false,
            columnNameKey: null,
            sortingDirection: null,
            sortableColumns: []
        };
    }

    componentDidMount() {
        if (!_.isNil(this.props.columns)) {

            const sortableArray = [];

            _.forEach(this.props.columns, (column) => {
                if (column.sortable) {
                    sortableArray.push(column);
                }
            });

            _.forEach(sortableArray, (data) => {
                data.upArrowActive = false;
                data.downArrowActive = false;
            });

            this.setState({sortableColumns: [...sortableArray]});

        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.loading && prevProps.loading && !_.isNil(this[dataTable])) {
            const dataTableWidth = this[dataTable].getBoundingClientRect().width;
            const dataTableBodyWidth = this[dataTableBody].getBoundingClientRect().width;
            const setRightBorder = dataTableWidth > dataTableBodyWidth;
            const additionalWidth = setRightBorder ? 2 : 1;
            const loadMoreStyleInfo = {
                setRightBorder
            };
            if (dataTableWidth !== 0) {
                loadMoreStyleInfo.loadMoreWidth = dataTableWidth + additionalWidth + "px"
            }
            this.setState(loadMoreStyleInfo);
        }
    }

    onSort = (sorting, columnNameKey) => {
        this.props.onSort(sorting);
        this.setState({columnNameKey, sortingDirection: sorting[Object.keys(sorting)[0]]})
    };

    onLoadMore = (page) => {
        this.props.onLoadMore(page);
    };

    onAllSelectionChange = () => {
        this.props.onAllSelectionChange();
    };

    onOneSelectionChange = (id) => {
        this.props.onOneSelectionChange(id);
    };

    render() {
        const {
            id, data, columns, multiselect, loading, totalCount, loadedPages, itemIdProperty, selected, isAllSelected, selectable, sorting,
            menu, filtered, detailsLink, onItemSelect
        } = this.props;

        const {columnNameKey, sortingDirection, sortableColumns} = this.state;

        const barInfo = (!_.isNil(columnNameKey) && !_.isNil(sortingDirection) ? <div className="abs-sorting-bar-info">
            <Icon name={_.isEqual(sortingDirection, "ASC") ? "abs_arrow_upward" : "abs_arrow_downward"}/>
            <I18nMessage id={columnNameKey}/>
        </div> : null);

        return [
            sortableColumns.length > 0 && <MediaQuery maxWidth={767} key="bar-component">
                <BarComponent titleKey="common.sort" barInfo={barInfo}>
                    <BarSortingComponent columns={sortableColumns} onSort={this.onSort}/>
                </BarComponent>
            </MediaQuery>,
            <Loadable loading={loading} transparent key="table-component">
                <div className="abs-list-table-body" ref={c => this[dataTableBody] = c}>
                    <div id={"dataTable_" + id}
                         ref={c => this[dataTable] = c}
                         className={classnames(
                             "abs-list-table",
                             {
                                 "not-selectable": !multiselect,
                                 "with-right-border": this.state.setRightBorder
                             },
                             this.props.className)}>

                        <BaseListHeader columns={columns}
                                        multiselect={multiselect}
                                        selectAllIds={this.selectAllIds}
                                        selectable={selectable}
                                        isAllSelected={isAllSelected}
                                        onAllSelectionChange={this.onAllSelectionChange}
                                        sorting={sorting}
                                        id={id}
                                        onSort={this.onSort}
                                        filtered={filtered}
                                        totalCount={totalCount}
                        />

                        {data.map((row) => {
                                const id = itemIdProperty ? row.getIn(itemIdProperty.split(".")) : undefined;
                                return (
                                    <BaseListDataRow key={id || shortid.generate()}
                                                     id={id}
                                                     data={row}
                                                     columns={columns}
                                                     multiselect={multiselect}
                                                     disabled={!selectable.has(id)}
                                                     selected={selected.has(id)}
                                                     onOneSelectionChange={this.onOneSelectionChange}
                                                     {...onItemSelect && {onItemSelect : () => onItemSelect(row)}}
                                                     detailsLink={detailsLink}
                                                     menu={menu}
                                    />
                                )
                            }
                        )}
                    </div>
                </div>

                <NoData visible={!loading && data.size === 0}/>

                <LoadMore totalCount={totalCount}
                          loadedRecordsCount={data.size}
                          loadedPagesCount={loadedPages}
                          onLoadMore={this.onLoadMore}
                          width={this.state.loadMoreWidth}
                />
            </Loadable>
        ]
    }
}
