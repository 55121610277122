import {getMetaData} from "./instanceConfigMetaData";

const METADATA_VISIBLE = 'visible';

const isVisible = (search) => {
    const metaData = getMetaData(search);

    if (_.has(metaData, METADATA_VISIBLE)) {
        return Boolean(metaData[METADATA_VISIBLE]);
    }

    return false;
};

export {
    isVisible
}