import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {fwUrlUtils, I18nMessage} from "assecobs-faktor-web-common";
import {reduxForm} from "redux-form";
import {injectIntl} from 'react-intl';
import {validators} from "assecobs-faktor-web-common/forms";
import RestService, {HTTP_GONE} from "assecobs-faktor-web-common/rest/RestService"

import FileRequestPromptHeader from "./FileRequestPromptHeader";
import FileRequestPromptBodyFull from "./FileRequestPromptBodyFull";
import {isVisible} from "assecobs-faktor-web-common/instanceConfigMetaData/instanceConfigMetaDataHelpers";
import {ERROR_TOAST, INFO_TOAST, toast} from "assecobs-faktor-web-common/toasts/ToastInitializer";
import DownloadFileNoModalCondition from './componentMetaCondition/DownloadFileNoModalCondition';
import _ from "lodash";

const GET_FILE_URL = fwUrlUtils.getApiUrl("/webapi/fileRequest/get");
const SEND_FILE_URL = fwUrlUtils.getApiUrl("/webapi/fileRequest/send");
const emailInput = Symbol();
const CONTENT_DISPOSITION_HEADER = 'content-disposition';
const DEFAULT_FILE_NAME = 'default.pdf';
const EMPTY_STRING = "";

class FileRequestPrompt extends React.Component {

    constructor(props) {
        super(props);
        this[emailInput] = "";
        this.state = {
            emailBlockLoading: false, errorMessage: null, downloadFileNoModal: isVisible(DownloadFileNoModalCondition)
        };
    }

    componentDidMount = async () => {
        if (this.state.downloadFileNoModal && !_.isNil(this.props.fileOptions)) {
            await this.getFile();
        }
    }

    getFile = async () => {

        try {
            const response = await RestService.get(GET_FILE_URL, this.props.fileOptions);
            if (response) {
                if (response && response.status !== HTTP_GONE) {
                    const fileContentDisposition = response.headers.get(CONTENT_DISPOSITION_HEADER);
                    const fileName = this.extractFileName(fileContentDisposition);
                    await this.downloadFileIfAvailable(response, fileName);
                }

                if (!this.state.downloadFileNoModal) {
                    this.props.close();
                }
            } else {
                this.props.close();
            }
        } catch (error) {
            this.props.close();
        }
    }

    downloadFileIfAvailable = async (response, fileName) => {
        if (fileName !== EMPTY_STRING) {
            const fileBlob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(fileBlob);
            this.downloadFile(fileName, downloadUrl);
        } else {
            window.location.reload();
        }
    }

    extractFileName = (contentDisposition) => {
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (match && match[1]) {
            return decodeURIComponent(match[1].replace(/['"]/g, '')).replace(/^UTF-8/, '');
        }

        return DEFAULT_FILE_NAME;
    }

    downloadFile = (fileName, downloadUrl) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(downloadUrl);
        downloadLink.remove();
    };

    sendFile = async () => {
        const errorMessage = validators.emailFileRequest(this[emailInput])

        if (errorMessage) {
            this.setState({errorMessage})
            return
        }

        const params = {
            ...this.props.fileOptions, mail: this[emailInput]
        };

        await this.setState({emailBlockLoading: true});

        await RestService.post(SEND_FILE_URL, params).then(response => {
            if (response.ok) {
                this[emailInput] = "";
                this.props.close();
                this.setState({errorMessage: null})

                toast(INFO_TOAST({
                    headerTextKey: "fw.fileRequestPrompt.toast.info.sent"
                }));
            } else {
                toast(ERROR_TOAST({}));
            }
        });

        await this.setState({emailBlockLoading: false});

    };

    emailOnChange = (value) => {
        this[emailInput] = value;
    };

    closeModal = value => {
        this.setState({errorMessage: null})
        this[emailInput] = ''
        this.props.close(value)
    }

    render() {
        if (this.state.downloadFileNoModal) {
            return null;
        }

        const {show, handleSubmit} = this.props;
        const isVisibleSendFile = isVisible({module: null, window: null, component: 'send.mail', element: null});
        const isClassName = isVisibleSendFile ? 'file-request-modal' : 'file-request-modal-no-mail';

        return (
            <Modal className={`${isClassName}`} show={show} onHide={this.closeModal} animation={false} autoFocus={true}
                   keyboard={true} backdrop={true}>
                <Modal.Header>
                    <FileRequestPromptHeader close={this.closeModal}/>
                </Modal.Header>
                <Modal.Body>
                    <FileRequestPromptBodyFull handleSubmit={handleSubmit} emailOnChange={this.emailOnChange}
                                               getFile={this.getFile} sendFile={this.sendFile}
                                               loading={this.state.emailBlockLoading}
                                               errorMessage={this.state.errorMessage}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-type-a btn-xs" onClick={this.closeModal}><I18nMessage
                        id="common.closeWindow"/></Button>
                </Modal.Footer>
            </Modal>)
    }

    static get propTypes() {
        return {
            show: PropTypes.bool, close: PropTypes.func, fileOptions: PropTypes.object
        }
    }
}

const form = reduxForm({
    form: "FileRequestPrompt"
});

export default connect()(form(injectIntl(FileRequestPrompt)));
