import React from "react";
import PropTypes from "prop-types";
import {FormattedNumber} from "react-intl";

export default class ChartMoney extends React.Component {

    componentDidMount() {
        if(this.props.amount === null) {
            this.props.elementIsNull();
        }
    }

    renderContent = (formattedNumber) => {
        const integer = formattedNumber.substring(0, formattedNumber.length - 3);
        const fraction = formattedNumber.substring(formattedNumber.length - 3, formattedNumber.length);
        const {currency} = this.props;
        const fractionWithCurrency = `${fraction}\u00A0${currency}`;

        return (
            <span>
                <b>{integer}</b>{fractionWithCurrency}
            </span>
        )
    };

    render() {
        let {amount, minimumFractionDigits, maximumFractionDigits} = this.props;

        if (amount === undefined || amount === null) {
            return null;
        }

        minimumFractionDigits = minimumFractionDigits === undefined ? 2 : this.props.minimumFractionDigits;
        maximumFractionDigits = maximumFractionDigits === undefined ? 2 : this.props.maximumFractionDigits;

        if (minimumFractionDigits > maximumFractionDigits) {
            minimumFractionDigits = maximumFractionDigits;
        }

        return (
            <FormattedNumber value={amount} minimumFractionDigits={minimumFractionDigits} maximumFractionDigits={maximumFractionDigits}>{this.renderContent}</FormattedNumber>
        )
    }
}

ChartMoney.propTypes = {
    amount: PropTypes.number,
    currency: PropTypes.string,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
    colorize: PropTypes.bool
};

ChartMoney.defaultProps = {
    colorize: false
};
