import {ReactElement} from 'react'
// @ts-ignore
import {I18nMessage} from 'assecobs-faktor-web-common/index'

interface Props {
  onClick: () => void;
}

const AttachmentShowMoreLink = ({onClick}: Props): ReactElement => (
  <a onClick={onClick}>
    <I18nMessage id="fw.attachment.adding.error.toast.additional.information.1" />
  </a>
)

export default AttachmentShowMoreLink