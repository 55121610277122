import {useState, useEffect} from "react";

type DsItem = {value: string | number, label: string | number, groupKey: string | number }
type DataSource = Array<DsItem>

interface LoadMoreItemsProps {
    currentPosition: string,
    previousPosition: string,
    event?: Event,
    waypointTop: number,
    viewportTop: number,
    viewportBottom: number
}

interface UseListProps {
    dataSource: DataSource,
    initialNumberOfElements: number,
    stepNumberOfElements: number
}

type UseListReturn = {
    itemToShow: DataSource,
    loadMoreItems: ({}: LoadMoreItemsProps) => void
}

const INITIAL_COUNT_START = 0

const useList = ({dataSource, initialNumberOfElements, stepNumberOfElements}: UseListProps): UseListReturn => {

    const getPartOfDS = (count: number) => dataSource.slice(INITIAL_COUNT_START, count)

    const [itemToShow, setItemsToShow] = useState(getPartOfDS(initialNumberOfElements))

    useEffect(() => {
        setItemsToShow(getPartOfDS(initialNumberOfElements))
    }, [dataSource])

    const loadMoreItems = ({previousPosition}: LoadMoreItemsProps): void => {
        if (previousPosition === undefined || itemToShow.length >= dataSource.length) {
            return
        }

        const newItems = [...getPartOfDS(itemToShow.length + stepNumberOfElements)]

        setItemsToShow(newItems)
    }

    return {itemToShow, loadMoreItems}
}

export {useList}