import {ReactElement} from "react";
// @ts-ignore
import {fwUrlUtils, I18nMessage, Link} from "assecobs-faktor-web-common/index"
import PropTypes from "prop-types";
// @ts-ignore
import _ from "lodash";

type ContractorType = { contractorId: number, shortName: string, contractorClientId: number };
type ContractorsType = Array<ContractorType>;
type BaseAgreementType = { baseAgreementAnnex0Id: number, actualAgreementNo: string };

interface TemplateForBaseAgreementProps {
    baseAgreementAnnex0Id: number,
    actualAgreementNo: string
}

const TemplateForBaseAgreement = ({
                                      baseAgreementAnnex0Id,
                                      actualAgreementNo
                                  }: TemplateForBaseAgreementProps): ReactElement => (
    <>
        <strong><I18nMessage id="fw.agreement"/>: </strong>
        <Link
            url={fwUrlUtils.getApiUrl("/agreements/details?baseAgreementAnnex0Id=") + baseAgreementAnnex0Id}
            text={actualAgreementNo}
            external/>
    </>
);


interface TemplateForContractorsProps {
    contractors: ContractorsType,
    baseAgreementAnnex0Id: number
}

const TemplateForContractors = ({contractors, baseAgreementAnnex0Id}: TemplateForContractorsProps): ReactElement => (
    <>
        <strong><I18nMessage id="fw.contractor"/>: </strong>
        {contractors.map(
            (contractor, index, contractors) =>
                <>
                    <Link id={contractor.contractorId}
                          text={contractor.shortName}
                          url={fwUrlUtils.getApiUrl("/contractors/details?baseAgreementAnnex0Id=") +
                              baseAgreementAnnex0Id + "&contractorClientId=" + contractor.contractorClientId}
                          external/>
                    {index < contractors.length - 1 && ", "}
                </>)
        }
        <br/>
    </>
);


interface OrderReportToastProps {
    baseAgreement: BaseAgreementType,
    cyclicityType: string,
    fileExtensionDesc: string,
    reportType: string,
    contractors: ContractorsType,
}

const OrderReportToast = ({
                              baseAgreement,
                              cyclicityType,
                              fileExtensionDesc,
                              reportType,
                              contractors
                          }: OrderReportToastProps): ReactElement => (
    <>
        <><strong><I18nMessage id="fw.orderReport.notification.reportType"/>: </strong>{reportType}</>
        <br/>
        {!_.isNil(baseAgreement) && TemplateForBaseAgreement}
        <br/>
        {!_.isNil(contractors) && contractors.length > 0 && TemplateForContractors}
        <><strong><I18nMessage id="fw.requestedReports.reportFormat"/>: </strong>{fileExtensionDesc}</>
        <br/>
        {!_.isNil(cyclicityType) && <><strong><I18nMessage id="fw.cyclicity"/>: </strong>{cyclicityType}</>}
    </>
)


OrderReportToast.propTypes = {
    baseAgreement: PropTypes.object,
    cyclicityType: PropTypes.string,
    fileExtensionDesc: PropTypes.string,
    reportType: PropTypes.string,
    contractors: PropTypes.array,
}

export default OrderReportToast
