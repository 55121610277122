export default class {

    async getData(parameter) {
        const data = this.getModuleData();
        if (this.isNeedUpdate(data)) {
            return await this.loadDynamicData();
        }

        return Promise.resolve(data[parameter]);
    }

    loadDynamicData() {
        throw new Error("Abstract method must be override");
    }

    getModuleData() {
        throw new Error("Abstract method must be override");
    }

    isNeedUpdate() {
        throw new Error("Abstract method must be override");
    }
}
