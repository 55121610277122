import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";
// @ts-ignore
import _ from "lodash";

type SendAttachmentsToastType = {
    filesName: Array<string>,
    contextNumber: string,
    refresh: boolean
}

interface ToastInfoForSendAttachmentsTemplateType extends TemplateType {
    templateProps: SendAttachmentsToastType;
}

const ToastInfoForSendAttachmentsTemplate = ({
                                                 templateProps,
                                                 removeToast,
                                                 id,
                                                 timeOut = 5000
                                             }: ToastInfoForSendAttachmentsTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.attachment.adding.toast.header"/>
    const messageBody = <SendAttachmentsToast {...templateProps}/>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

const SendAttachmentsToast = ({
                                  filesName,
                                  contextNumber
                              }: SendAttachmentsToastType): ReactElement => (
    <>
        <I18nMessage id="fw.attachment.name"/>:
        <ul className="fw-dropzone-toast fw-dropzone-toast-ul">
            {filesName.map((item, key) =>
                <li key={key}><span className="fw-dropzone-toast-li"> {item}</span></li>
            )}
        </ul>
        {!_.isNil(contextNumber) &&
            <>
                <I18nMessage key="message" id="fw.common.addTo"/>: {contextNumber}
                <br key="br"/>
            </>}
    </>
)

ToastInfoForSendAttachmentsTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForSendAttachmentsTemplate