import React from "react";
import ChartMoney from "../dataPresentation/ChartMoney";

export default class InvoiceChartLegend extends React.Component {

    render() {
        const {data, color} = this.props;

        return [
            <span key="point" className="chart-legend-item-point"><span style={{background: color}}/></span>,
            <span key="name" className="chart-legend-item-name">{data.name}</span>,
            <span key="value" className="chart-legend-item-value"><ChartMoney amount={data.amount} currency={data.currency}/></span>
        ];
    }
}
