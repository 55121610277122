import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type ErrorToastType = {
    bodyText: string,
    headerTextKey: string
}

interface ToastDangerErrorTemplateType extends TemplateType {
    templateProps: ErrorToastType;
}

const ToastDangerErrorTemplate = ({
                                      templateProps,
                                      removeToast,
                                      id,
                                      timeOut = 5000
                                  }: ToastDangerErrorTemplateType): ReactElement => {

    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <>{templateProps.headerTextKey ? templateProps.headerTextKey :
        <I18nMessage id="fw.error.header"/>}</>
    const messageBody = <>{templateProps.bodyText ? templateProps.bodyText : <I18nMessage id="fw.error.body"/>}</>

    return (
        <div className={`toastContainer--danger ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastDangerErrorTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastDangerErrorTemplate