import actionTypes from "./actions";

export default (id) => {
    if (!id) {
        throw new Error('itemsProperty must be defined for filterPanelReducer')
    }

    const actions = actionTypes(id);

    const toggleFilterPanel = () => (async (dispatch) => {
        dispatch({
            type: actions.TOGGLE_FILTER_PANEL
        });
    });

    return {
        toggleFilterPanel
    }
}
