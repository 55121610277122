import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common";

export default (props) => (
    props.visible && (
        <div className="abs-list-table-no-results">
            <div><I18nMessage id="common.noDataToRender" /></div>
        </div>
    )
)
