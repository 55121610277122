import React from 'react';
import {ButtonDropdown} from 'simple-react-bootstrap';
import NonCollapsibleMenu from "./items/NonCollapsibleMenu";
import classnames from "classnames";

const COLLAPSE_TIMEOUT = 355;

const spreadClassNames = (baseCssClasses = '', ...userClasses) => `${baseCssClasses} ${userClasses.join(' ')}`;

const NavBarForm = props => {
    let {className, style, ...rest} = props;

    return (
        <form onSubmit={evt => evt.preventDefault()} className={spreadClassNames(className, 'navbar-form')}
              style={style} {...rest}>
            {props.children}
        </form>
    );
};

const NavBarBrand = props => React.cloneElement(props.children, {className: 'navbar-brand', key: 'nav-bar-brand'});

class NavBarToggle extends React.Component {

    constructor() {
        super();
        this.state = {collapsed: true};
    }

    onToggle = () => {
        this.setState(prevState => ({collapsed: !prevState.collapsed}), () => {
            if (this.props.onClick) {
                this.props.onClick();
            }
        });
    };

    render() {

        const {key} = this.props;

        return (<button key={key} type="button"
                        className={classnames("navbar-toggle", {"collapsed": this.state.collapsed})}
                        onClick={this.onToggle}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
            <span className="icon-bar"/>
        </button>);

    }
}

class NavBarHeader extends React.Component {
    render() {
        let {children, onClick} = this.props;
        return (
            <div className="navbar-header" ref={el => this.el = el}>{
                React.Children.map(children, child =>
                    child.type === NavBarToggle
                        ? React.cloneElement(child, {onClick: onClick, key: 'nav-bar-toggle'})
                        : child)
            }</div>
        )
    }
}


const NavBarItem = props => {
    let {disabled, className, active, href, children, ...rest} = props;

    return (
        <li disabled={!!disabled}
            className={spreadClassNames(className, !!disabled ? 'disabled' : '', active ? 'active' : '')} {...rest}><a
            href={href}>{children}</a></li>
    );
};

const NavBarItemDivider = props => <li role="separator" className="divider"/>

const NavBarDropdown = props => {
    let {toggleClassName, menuClassName, style, disabled, icon, alertIndicatorVisible, label, children, ignoreArrow, changeState, ignoreContentClick = true, open, ...rest} = props;

    return (
        <ButtonDropdown containerElementType="li" clean={true} ignoreContentClick={ignoreContentClick}
                        className={`dropdown ${!!disabled ? 'disabled' : ''}`} disabled={!!disabled}
                        open={open} onToggle={changeState}>
            <a className={spreadClassNames(toggleClassName, 'dropdown-toggle navbar-menu-item')} style={style || {}} {...rest}>
                <i className={"abs-icon navbar-nav-icon " + icon}/>
                {alertIndicatorVisible && <div className="notification-alert style-a"/>}
                <span className="dropdown-title-span">{label}</span>
                {!ignoreArrow && <i className="abs-icon navbar-nav-icon abs_expand_more"/>}</a>
            <ul className={spreadClassNames(menuClassName, 'dropdown-menu sub-parent')}>
                {children}
            </ul>
        </ButtonDropdown>
    );
};

const Nav = ({className = '', ...props}) =>
    <ul {...props} className={"nav navbar-nav " + className}>
        {props.children}
    </ul>;

const NavBarNonCollapse = props => {
    let {children} = props;

    return (
        <div className="navbar-non-collapse">
            {children}
        </div>
    )
}

class Navbar extends React.Component {
    state = {collapsed: true, heightExpanded: false, collapseHeight: null};
    _clearAnimation = () => {
        if (this._pendingAnimationClear) {
            clearTimeout(this._pendingAnimationClear);
            this._pendingAnimationClear = null;
        }
    };
    closeIfOpen = () => {
        if (this.state.expanding || this.state.expanded) {
            this.close();
        }
    };
    close = () => {
        this.setState({collapsing: true, collapseHeight: null, expanding: false, expanded: false});
        this._pendingAnimationClear = setTimeout(() => {
            this.setState({collapsing: false, collapseHeight: null});
            this._cachedHeight = null;
        }, COLLAPSE_TIMEOUT);
    };
    expand = () => {
        if (!this._cachedHeight) {
            let collapseContentToToggle = this.collapseEl;

            collapseContentToToggle.style.visibility = 'hidden';
            collapseContentToToggle.classList.add('in');
            let offsetHeight = collapseContentToToggle.offsetHeight;
            collapseContentToToggle.style.visibility = '';
            collapseContentToToggle.classList.remove('in');

            this._cachedHeight = offsetHeight;
        }

        this.setState({collapsing: true, expanding: true});
        setTimeout(() => this.setState({collapseHeight: this._cachedHeight}), 2);

        this._pendingAnimationClear = setTimeout(() => this.setState({
            collapsing: false,
            expanded: true,
            expanding: false
        }), COLLAPSE_TIMEOUT);
    };

    toggleMobileCollapse() {
        this._clearAnimation();
        if (this.state.expanded || this.state.expanding) {
            this.close();
        } else {
            this.expand();
        }
    }

    componentWillUnmount() {
        clearTimeout(this._pendingAnimationClear);
    }

    render() {
        let header = this.props.children.filter(c => c.type === NavBarHeader)[0],
            nonCollapse = this.props.children.filter(c => c.type === NonCollapsibleMenu)[0],
            navSubItems = this.props.children.filter(el => el != header && el != nonCollapse);

        if (header) {
            header = React.cloneElement(header, {
                onClick: this.toggleMobileCollapse.bind(this),
                ref: el => this.headerEl = el
            });
        }

        let {style, className = '', ...rest} = this.props;

        return (
            <nav className={'navbar navbar-default ' + className} style={style} {...rest}>
                <div className="container-fluid">
                    {header || null}
                    {nonCollapse || null}
                    <div ref={el => this.collapseEl = el}
                         className={(this.state.collapsing ? ' collapsing ' : ' collapse ') + ' navbar-collapse ' + (this.state.expanded ? ' in ' : '')}>
                        {navSubItems}
                    </div>
                </div>
            </nav>
        );
    }
}

Navbar.Nav = Nav;
Navbar.NonCollapse = NavBarNonCollapse;
Navbar.Item = NavBarItem;
Navbar.ItemDivider = NavBarItemDivider;
Navbar.Header = NavBarHeader;
Navbar.Brand = NavBarBrand;
Navbar.Toggle = NavBarToggle;
Navbar.Dropdown = NavBarDropdown;
Navbar.Form = NavBarForm;

export default Navbar;
