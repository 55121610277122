import React from "react";
import ReactDOM from 'react-dom';
import ReactSuperSelect from "react-super-select";
import _ from "lodash";
import classnames from "classnames";
import {injectIntl} from 'react-intl';
import {Config} from "assecobs-faktor-web-common/index";

const superSelect = Symbol();

class SuperSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {selectedCount: 0, selectedIds: [], searchIsEmpty: true, notEmpty: false};
        this[superSelect] = null;
        this.isFilterValueShouldBeCleared = false;
    }

    componentDidUpdate(prevProps) {
        this.isFilterValueShouldBeCleared = !_.isEmpty(prevProps.input) && !_.isEmpty(prevProps.input.value) && _.isEmpty(this.props.input.value)
    }

    customFilterFunction = (option, index, collection, searchTerm) => {
        if (this.props.onSearchInputChange) {
            return true;
        } else {
            if (typeof this.props.filterProp === "string") {
                return option[this.props.filterProp].toLowerCase().indexOf(searchTerm) > -1;
            } else {
                let result = false;
                this.props.filterProp.forEach(prop => {
                    result = result || option[prop].toLowerCase().indexOf(searchTerm) > -1;
                });
                return result;
            }

        }
    };

    onChange = async (list) => {
        if (this.props.onChange) {
            this.props.onChange(list);
        }

        this.setState({
            selectedCount: list ? list.length : 0,
            selectedIds: (list && list.length) ? list.map((option) => option[this.props.optionValueProp]) : [],
            notEmpty: Array.isArray(list) && list.length !== 0,
        });
    };

    onOpenDropdown = () => {

        if (window.innerWidth >= 768) {
            const dropdown = ReactDOM.findDOMNode(this[superSelect]).getElementsByClassName('r-ss-dropdown');
            const dropdownWrap = ReactDOM.findDOMNode(this[superSelect]).getElementsByClassName('r-ss-options-wrap');
            let rect = dropdown[0].getBoundingClientRect();
            /*window.console.log(rect);*/
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            /*window.console.log(viewHeight);*/
            const parentRect = dropdown[0].parentElement.getBoundingClientRect();
            /*window.console.log(parentRect);*/
            if (rect.bottom > viewHeight) {
                if (parentRect.top >= (viewHeight - parentRect.bottom)) {
                    /*window.console.log("change style bottom to " + `${(parentRect.height + 4)}px`);*/
                    dropdown[0].style.bottom = `${(parentRect.height + 4)}px`;
                    if (parentRect.top < rect.height) {
                        dropdown[0].style.height = `${(parentRect.top - 4)}px`;
                        dropdownWrap[0].style.height = `${(parentRect.top - 51)}px`;
                    }
                } else {
                    /*window.console.log("height")*/
                    if ((viewHeight - parentRect.bottom) < rect.height) {
                        dropdown[0].style.height = `${((viewHeight - parentRect.bottom) - 4)}px`;
                        dropdownWrap[0].style.height = `${((viewHeight - parentRect.bottom) - 51)}px`;
                    }
                }
            }

        }

        if (this.props.maxContent) {
            this.addClassName();
        }
    };

    onCloseDropdown = () => {
        this.setState({
            open: false
        });

        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    groupBy = (option) => {
        return option[this.props.optionGroupByProp];
    };

    customOptionTemplateFunction = (option) => {

        const CustomOptionTemplate = this.props.optionTemplate;

        return React.cloneElement(<CustomOptionTemplate/>, {
            option,
            selectedIds: this.state.selectedIds
        });

    };

    customSelectedValueTemplateFunction = (data) => {
        const CustomSelectedValueTemplate = this.props.selectedValueTemplate;

        if (data.length > 1) {
            return React.cloneElement(<CustomSelectedValueTemplate/>, {
                data: data,
                name: this.props.placeholder
            });
        } else {
            return React.cloneElement(<CustomSelectedValueTemplate/>, {
                data: data[0],
                name: this.props.placeholder
            });
        }

    };

    superSelectRef = c => {
        this[superSelect] = c;
    };

    onSearchInputChange = (term) => {
        if (this.props.onSearchInputChange) {
            this.props.onSearchInputChange(term);
        }
        this.setState({
            searchIsEmpty: (!(term && term.length > 0))
        });
    };

    addClassName = () => {
        let items = ReactDOM.findDOMNode(this[superSelect]).getElementsByClassName('r-ss-dropdown');
        for (let index = 0; index < items.length; index++) {
            items[index].className += " r-ss-dropdown-max-content";
        }
    }

    render() {
        const {
            optionGroupByProp, placeholder, searchPlaceholder, clearSearchOnSelection, multiple,  deselectOnSelectedOptionClick,
            initialValue, clearable, keepOpenOnSelection, optionValueProp, options,
            clearSelectedValueOnDataSourceChange, disabled, closeOnSelectedOptionClick, forceDefaultBrowserScrolling,
            customClass, pageDataFetch, hasMorePages, intl, searchable, originalOptionsLength
        } = this.props;

        let initial = undefined;

        const initialValueId = (_.isObject(initialValue) && !_.isArray(initialValue)) ? initialValue[optionValueProp] : initialValue;

        if (options) {
            if (multiple && _.isArray(initialValueId) && !_.isEmpty(initialValueId)) {
                initial = options.filter(option => _.includes(initialValueId, option[optionValueProp]))
            } else if (!_.isNil(initialValueId) && multiple && Array.isArray(initialValueId) && initialValueId.length > 0) {
                initial = options.filter(option => _.isEqual(option[optionValueProp], initialValueId));
            } else if (!_.isNil(initialValueId) && !multiple) {
                initial = options.filter(option => _.isEqual(option[optionValueProp], initialValueId));
            } else if (this.isFilterValueShouldBeCleared && this.state.selectedCount !== 0) {
                initial = []
            }
        }

        return (
            <ReactSuperSelect
                ref={this.superSelectRef}
                dataSource={options}
                optionValueKey={optionValueProp}
                optionGroupByProp={optionGroupByProp}
                placeholder={placeholder}
                searchable={!_.isNil(searchable) ? searchable : (originalOptionsLength
                    ? originalOptionsLength > Config.getHowManyToSearch()
                    : options && options.length > Config.getHowManyToSearch())}
                searchPlaceholder={searchPlaceholder || intl.formatMessage({id: "common.search"})}
                customClass={classnames(customClass, {"search-active": !this.state.searchIsEmpty})}
                pageDataFetch={pageDataFetch}
                hasMorePages={hasMorePages}
                onSearchInputChange={this.onSearchInputChange}
                customFilterFunction={this.customFilterFunction}
                clearSearchOnSelection={clearSearchOnSelection}
                multiple={multiple}
                deselectOnSelectedOptionClick={deselectOnSelectedOptionClick}
                initialValue={initial}
                clearable={clearable}
                onChange={this.onChange}
                onOpenDropdown={this.onOpenDropdown}
                onCloseDropdown={this.onCloseDropdown}
                groupBy={optionGroupByProp && this.groupBy}
                keepOpenOnSelection={keepOpenOnSelection}
                customOptionTemplateFunction={this.customOptionTemplateFunction}
                customSelectedValueTemplateFunction={this.customSelectedValueTemplateFunction}
                clearSelectedValueOnDataSourceChange={clearSelectedValueOnDataSourceChange}
                disabled={disabled}
                closeOnSelectedOptionClick={closeOnSelectedOptionClick}
                forceDefaultBrowserScrolling={forceDefaultBrowserScrolling}
                onClear={this.props.onClear}
                noResultsString={intl.formatMessage({id: "fw.common.noData"})}

            />

        )

    }

}

export default injectIntl(SuperSelect);
