import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {Dropdown, MenuItem} from "react-bootstrap";
import {Icon} from "@assecobs/react-common-components/index";
import classnames from "classnames";
import ReactDOM from "react-dom";

class DropdownMenuItem extends React.Component {
    render() {
        const {rendered, label, onSelect, href, active} = this.props;

        if (!rendered) {
            return null;
        }

        return <MenuItem onSelect={onSelect} href={href} active={active}>{label || this.props.children}</MenuItem>
    }

    static get propTypes() {
        return {
            rendered: PropTypes.bool,
            label: PropTypes.any,
            onSelect: PropTypes.func
        }
    }

    static get defaultProps() {
        return {
            rendered: true
        }
    }
}

class DropdownMenuSub extends React.Component {

    componentDidMount() {
        if (!_.isNil(this._dropdown)) {
            const observable = ReactDOM.findDOMNode(this._dropdown);
            this[observer] = new MutationObserver(this.checkMenu);
            this[observer].observe(observable, {attributes: true});
        }
    }

    componentWillUnmount() {
        if (!_.isNil(this[observer])) {
            this[observer].disconnect();
        }
    }

    checkMenu = () => {
        if (!_.isNil(this._dropdownMenu) && !_.isNil(this._dropdown)) {
            const dropdown = ReactDOM.findDOMNode(this._dropdown);
            const dropdownMenu = ReactDOM.findDOMNode(this._dropdownMenu);
            if (dropdownMenu.getBoundingClientRect().width + dropdown.getBoundingClientRect().right > window.innerWidth) {
                dropdownMenu.style.left = "auto";
                dropdownMenu.style.right = "99%";
            } else {
                dropdownMenu.style.left = "calc(100% + 2px)";
                dropdownMenu.style.right = "auto";
            }
        }
    };

    render() {
        const {id, rendered, text} = this.props;

        if (!rendered) {
            return null;
        }

        return (
            <li className="dropdown-submenu">
                <Dropdown id={"dropdownMenu_" + id} ref={c => this._dropdown = c}>
                    <Dropdown.Toggle noCaret className="dropdown-link">
                        {text}
                        <Icon name="abs_expand_more abs-rotate"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu ref={c => this._dropdownMenu = c}>
                        {this.props.children}
                    </Dropdown.Menu>
                </Dropdown>
            </li>

        )
    }

    static get propTypes() {
        return {
            rendered: PropTypes.bool
        }
    }

    static get defaultProps() {
        return {
            rendered: true
        }
    }
}

const observer = Symbol();

class DropdownMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {openAbove: false};
        this._dropdown = null;
        this[observer] = null;
    }

    componentDidMount() {
        if (!_.isNil(this._dropdown)) {
            const observable = ReactDOM.findDOMNode(this._dropdown);
            this[observer] = new MutationObserver(mutations => {
                this.checkMenu(mutations);
            });
            this[observer].observe(observable, {attributes: true});
            window.addEventListener("resize", this.checkMenu);
        }
        if (this.props.isListDropdown) {

            this.checkListDropdown();

            window.addEventListener("scroll", this.checkListDropdown);

        }
        if (!this.props.rendered) {
            if (this.props.disableMenu) {
                this.props.disableMenu();
            }
        }
    }

    componentWillUnmount() {
        if (!_.isNil(this[observer])) {
            this[observer].disconnect();
        }
        window.removeEventListener("resize", this.checkMenu);
        window.removeEventListener("scroll", this.checkListDropdown);
    }

    checkMenu = (mutations) => {
        if (_.isArray(mutations) && window.innerWidth >= 768) {
            if (_.isEqual(mutations[0].attributeName, "class") && mutations[0].target.className.includes("open")) {
                const dropdown = ReactDOM.findDOMNode(this._dropdown).getElementsByClassName('dropdown-menu');
                const rect = dropdown[0].getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                if (rect.bottom > viewHeight) {
                    this.setState({openAbove: true});
                }
            }
        }
        if (this.props.isListDropdown) {
            this.checkListDropdown();
        }
    };

    checkListDropdown = () => {

        if (this.props.isListDropdown && window.innerWidth >= 768) {

            const dropdown = ReactDOM.findDOMNode(this._dropdown);
            const dropdownMenu = ReactDOM.findDOMNode(this._dropdownMenu);

            if (dropdownMenu) {
                dropdownMenu.style.top = dropdown.getBoundingClientRect().top + dropdown.getBoundingClientRect().height + "px";
                if (this.props.right) {
                    dropdownMenu.style.left = dropdown.getBoundingClientRect().left + dropdown.getBoundingClientRect().width - dropdownMenu.getBoundingClientRect().width + "px";
                    dropdownMenu.style.right = "auto";
                } else {
                    dropdownMenu.style.left = dropdown.getBoundingClientRect().left + "px";
                }
            }

        }

    };

    onToggleMenu = () => {
        if (this.props.onToggleMenu)
            this.props.onToggleMenu();
    };

    render() {
        const {id, rendered, text, className, buttonClassName, right, isListDropdown, forceOpen, disabled} = this.props;

        if (!rendered) {
            return null;
        }

        return (
            <Dropdown id={"dropdownMenu_" + id}
                      className={classnames("dropdown abs-dropdown", className, {"no-text": !text})}
                      ref={c => this._dropdown = c}
                      dropup={this.state.openAbove} onToggle={this.onToggleMenu}
                      {...forceOpen && {open: true}}
                      disabled={disabled}
            >
                <Dropdown.Toggle noCaret className={classnames(buttonClassName, {"btn-type-d": isListDropdown})}>
                    {text && <span className="abs-dropdown-btn-text">{text}</span>}
                    {!text && <Icon name="abs_more_horiz" aria-hidden="true"/>}
                    {text && <Icon name="abs_expand_more abs-rotate"/>}
                </Dropdown.Toggle>
                <Dropdown.Menu className={(right || !isListDropdown) ? "dropdown-menu-right" : ""} ref={c => this._dropdownMenu = c}>
                    {this.props.children}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    static get propTypes() {
        return {
            rendered: PropTypes.bool
        }
    }

    static get defaultProps() {
        return {
            rendered: true
        }
    }
}

DropdownMenu.Sub = DropdownMenuSub;
DropdownMenu.Item = DropdownMenuItem;
export default DropdownMenu;
