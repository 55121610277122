import _ from "lodash";
import {Config, DateUtils} from "../index";
import {isNullOrEmpty} from "@assecobs-js-utils/typeguards";

const MAX_AMOUNT = 999999999.00;
const MIN_AMOUNT = -999999999.00;
const ZERO = 0.00;
const REGON_9_LENGTH = 9;
const REGON_14_LENGTH = 14;
const INVALID_REGON_MESSAGE = 'fw.regon.invalid';
const DIGITS_ONLY_REGEX = /^\d+$/;
const SPACES_AND_DASHES_REGEX = /[\ \-]/gi;
const REGON_9_WEIGHTS = [8, 9, 2, 3, 4, 5, 6, 7];
const REGON_14_WEIGHTS = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];

export const required = value => (_.isNil(value) || value === "" ? "fw.form.error.required" : undefined);

export const requiredObj = value => (_.isNil(value) || _.isNil(value.isValue) ? "fw.form.error.required" : undefined);

export const emailFileRequest = value => {
    if (_.isNil(value) || value <= 0) {
        return "fw.email.invalid"
    }

    return email(value)
}

export const email = value => {
    if (!value) {
        return
    }

    const emailParts = value.split('@');

    if (emailParts.length !== 2) {
        return "fw.email.invalid"
    }

    const [emailIdentifier, emailDomain] = emailParts;

    const emailDomainParts = [emailDomain.substring(0, emailDomain.lastIndexOf(".")),
        emailDomain.substring(emailDomain.lastIndexOf(".") + 1, emailDomain.length)];

    if (emailDomainParts[0].empty && emailDomainParts[1].empty) {
        return "fw.email.invalid"
    }

    if (emailDomainParts.filter(domainPart => domainPart.length === 0).length > 0) {
        return "fw.email.invalid"
    }

    if (!emailIdentifierValidation(emailIdentifier) || !emailDomainValidation(emailDomainParts)) {
        return "fw.email.invalid"
    }

    return;
}

const emailDomainValidation = (emailDomainParts) => {
    const mainDomain = emailDomainParts.pop()
    const ret = emailDomainParts.filter(subDomain => emailSubDomainValidation(subDomain) === false)

    return ret.length === 0 && emailEnlargementValidation(mainDomain);
};

const emailIdentifierValidation = (value) => {
    return !value.startsWith(".") && /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+$/i.test(value)
}

const emailSubDomainValidation = (value) => {
    return /^[A-Z0-9-.]+$/i.test(value) && !value.startsWith("-") && !value.endsWith("-")
}

const emailEnlargementValidation = (value) => {
    return /^[a-zA-Z]+$/.test(value)
}

export const nip = value => {
    if (value) {
        const preparedNip = value.replace(SPACES_AND_DASHES_REGEX, '');
        if (/^[0-9]{10}$/.test(preparedNip) && checkNipValidationSum(preparedNip)) {
            return;
        }
    }
    return "fw.nip.invalid"
}

const checkNipValidationSum = (valueToCheck) => {
    const moduleCheckNumber = 11;
    const controlNumber = parseInt(valueToCheck.substring(9, 10));
    const stringToCheck = valueToCheck.split("", 9);
    const importance = new Map([[0, 6], [1, 5], [2, 7], [3, 2], [4, 3], [5, 4], [6, 5], [7, 6], [8, 7]]);

    const nipSum = stringToCheck.reduce((previousValue, currentValue, index) => {
        return parseInt(previousValue) + (parseInt(currentValue) * importance.get(index));
    }, 0);

    return nipSum % moduleCheckNumber === controlNumber;
};

export const regonValidator = value => {
    return regon(value).errorMessage;
};

const regon = value => {
    const supportsRegon9 = true;
    const supportsRegon14 = false;

    if (isNullOrEmpty(value)) {
        return RegonValidationResult(true)
    }

    const preparedRegon = value.replace(SPACES_AND_DASHES_REGEX, '');
    if (hasInvalidCharacters(preparedRegon)) {
        return RegonValidationResult(false, INVALID_REGON_MESSAGE);
    }

    switch (preparedRegon.length) {
        case REGON_9_LENGTH:
            return supportsRegon9 && checkRegonValidationSum(preparedRegon, REGON_9_LENGTH, REGON_9_WEIGHTS)
                ? RegonValidationResult(true)
                : RegonValidationResult(false, INVALID_REGON_MESSAGE);
        case REGON_14_LENGTH:
            return supportsRegon14 && checkRegonValidationSum(preparedRegon, REGON_14_LENGTH, REGON_14_WEIGHTS)
                ? RegonValidationResult(true)
                : RegonValidationResult(false, INVALID_REGON_MESSAGE);
        default:
            return RegonValidationResult(false, INVALID_REGON_MESSAGE);
    }
}

const RegonValidationResult = (isValid, errorMessage = undefined) => ({
    isValid,
    errorMessage
});

const hasInvalidCharacters = value => !DIGITS_ONLY_REGEX.test(value);

const checkRegonValidationSum = (valueToCheck, length, weights) => {
    const {controlNumber, stringToCheck} = getControlDetails(valueToCheck, length);
    const regonSum = stringToCheck.split('').reduce((sum, digit, index) => sum + parseInt(digit) * weights[index], 0);
    const controlSum = regonSum % 11;

    return controlSum === controlNumber || (controlSum === 10 && controlNumber === 0);
};

const getControlDetails = (value, length) => {
    return {
        controlNumber: parseInt(value.charAt(length - 1)),
        stringToCheck: value.substring(0, length - 1)
    };
}

export const number = value =>
    (_.isNil(value) ? "fw.form.error.required" : (isNaN(Number(value)) ? "fw.number.invalid" : undefined));

export const date = value => (value && !DateUtils.isValid(value, Config.apiDateFormat()) ? "fw.date.invalid" : undefined);

export const dateRequired = value => (!DateUtils.isValid(value, Config.apiDateFormat()) ? "fw.date.invalid" : undefined);

export const isInt = value => ((value > 2147483647)
    ? "fw.int.invalid"
    : undefined);

export const positiveAmount = value => (value <= ZERO) ? "fw.amount.positive" : invalidPositiveAmount(value);

export const notNegativeAmount = value => (value < ZERO) ? "fw.amount.not.negative" : invalidPositiveAmount(value);

export const negativeAmount = value => (value >= ZERO) ? "fw.amount.negative" : invalidNegativeAmount(value);

export const notPositiveAmount = value => (value > ZERO) ? "fw.amount.not.positive" : invalidNegativeAmount(value);

const invalidPositiveAmount = (value) => {
    if (value > MAX_AMOUNT) {
        return "fw.exceeds.max.value";
    }
    return undefined;
}

const invalidNegativeAmount = (value) => {
    if (value < MIN_AMOUNT) {
        return "fw.exceeds.min.value";
    }
    return undefined;
}