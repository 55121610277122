import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type RequestSentToastType = {
    requestTypeKey: string,
    requestId: number
}

interface ToastInfoForRequestSentTemplateType extends TemplateType {
    templateProps: RequestSentToastType;
}

const ToastInfoForRequestSentTemplate = ({
                                             templateProps,
                                             removeToast,
                                             id,
                                             timeOut = 5000
                                         }: ToastInfoForRequestSentTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.request.message.sent"/>
    const messageBody = <><I18nMessage id="fw.request.message.type"/>: <I18nMessage
        id={templateProps.requestTypeKey}/><br/></>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastInfoForRequestSentTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForRequestSentTemplate