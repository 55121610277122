import {fetchMenu} from "./fetchMenu";

const _menu = Symbol();

class Menu {
    async init() {
        this[_menu] = await fetchMenu();
    }

    get menu() {
        return this[_menu];
    }
}

export default new Menu();
