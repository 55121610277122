import React from "react";
import ReactDOM from 'react-dom';
import {Config, CustomCalendar, I18nMessage} from "assecobs-faktor-web-common/index";
import MaskedInput from 'react-text-mask';
import moment from "moment";
import {DateUtils} from "../../index";
import TodayI18n from "../utils/TodayI18n";
import _ from "lodash";
import classnames from "classnames";
import {closest} from "@assecobs/react-utils/DOM";
import {Icon} from "@assecobs/react-common-components/index";
import {getApplicationLanguage} from "assecobs-react-utils/utils";

const LANG = getApplicationLanguage();

const CalendarInput = ({invalid, className, customLabel, clearable, clearSelectedDate, dateTextValue, onDateInputChange, toggleCalendar, disabled, style}) => {

    const dateMask = DateUtils.getMask();

    return (
        <div className={classnames("abs-custom-calendar abs-custom-calendar--single", className, {
            "invalid": invalid
        })}>
            <MaskedInput
                className={classnames(style, "abs-custom-calendar-input-field abs-custom-calendar-input-field--single", {
                    "k-invalid": invalid
                })}
                mask={dateMask}
                value={dateTextValue}
                onChange={onDateInputChange}
                placeholder={customLabel}
                disabled={disabled}/>
            {clearable && <i className="abs-icon abs_close abs-calendar-clear-icon" onClick={clearSelectedDate}/>}
            <Icon name="abs_insert_invitation" className="abs-calendar-trigger-icon" onClick={toggleCalendar}/>
        </div>
    )

};

class CalendarDropdown extends React.PureComponent {

    stopEvent = (event) => {
        event.nativeEvent.stopImmediatePropagation();
    };

    renderFooter = () => {

        const selectToday = () => {
            this.props.onDateSelect(moment())
        };

        return (
            <a onClick={selectToday}>
                {this.props.today}, {moment().format(Config.dateFormat())}
            </a>
        )
    };

    render() {
        const {value, calendarOpen, onDateSelect, disabledStartDate} = this.props;

        return (
            <div
                className="calendar-dropdown dropdown-menu abs-filter-form-container abs-filter-calendar-complex
                            abs-filter-calendar-complex--single"
                style={{display: calendarOpen ? "block" : "none"}} onClick={this.stopEvent}>
                <div className="abs-filter-form">
                    <div className="abs-start-calendar">
                        <CustomCalendar locale={LANG}
                                        onSelect={onDateSelect}
                                        defaultValue={value}
                                        renderFooter={this.renderFooter}
                                        disabledDate={disabledStartDate}
                                        additionalTranslations={{monthFormat: "MMMM"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

const dateFormat = Config.dateFormat();
const calendar = Symbol();
const calendarDropdown = Symbol();
const inputTiming = Symbol();

export default class SimpleCalendar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            calendarOpen: false,
            dateTextValue: "",
            currentDate: DateUtils.startOfDay(moment(), LANG),
            currentDateUnchanged: DateUtils.startOfDay(moment(), LANG)
        };

        this[calendar] = null;
        this[calendarDropdown] = null;
        this[inputTiming] = null;

    }

    componentDidMount = () => {
        document.addEventListener('click', this.closeCalendar);
        if (this.props.customInitialDate) {
            this.onDateValueChange(DateUtils.startOfDay(moment(this.props.customInitialDate, dateFormat), LANG));
        }
    };

    componentDidUpdate = async (prevProps) => {

        if (!_.isNil(this.props.calendarState)) {
            if (!this.props.calendarState && this.state.calendarOpen) {
                await this.setState({calendarOpen: false});
            }
        }

        if (_.isEqual(this.state.calendarOpen, true)) {
            const dropdown = ReactDOM.findDOMNode(this[calendarDropdown]);
            const rect = dropdown.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            if (rect.bottom > viewHeight) {
                dropdown.style.bottom = `${(dropdown.parentElement.offsetHeight + 2)}px`;
                dropdown.style.top = "auto";
            }
        }

        if (!_.isNil(this.props.customInitialDate) && !_.isEqual(prevProps.customInitialDate, this.props.customInitialDate)) {
            if (!_.isEqual(this.props.customInitialDate, "")) {
                this.onDateValueChange(DateUtils.startOfDay(moment(this.props.customInitialDate, dateFormat), LANG));
            }
        }

    };

    componentWillReceiveProps(nextProps) {
        if (!nextProps.customInitialDate && !_.isEqual(nextProps.customInitialDate, this.props.customInitialDate)) {
            this.setState({dateTextValue: ""});
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeCalendar);
    }

    onDateValueChange = (date, closeCalendar) => {

        const postFormat = Config.apiDateFormat();

        this.setState({currentDate: date}, () => {

            if (!_.isNil(date)) {
                const dateTextValue = date.format(dateFormat);
                this.props.dateHandler(date.format(postFormat));
                this.setState({dateTextValue});
            } else {
                this.props.dateHandler(undefined);
            }

            if (closeCalendar)
                this.toggleCalendar();

        });

    };

    onDateSelect = (date) => {

        this.onDateValueChange(date, true);

    };

    onDateInputChange = ({target}) => {

        const dateTextValue = target.value;

        this.setState({dateTextValue}, () => {

            if (this[inputTiming]) {
                clearTimeout(this[inputTiming]);
            }

            this[inputTiming] = setTimeout(() => {
                if (DateUtils.isValid(dateTextValue, dateFormat)) {
                    this.onDateValueChange(moment(dateTextValue, dateFormat));
                } else {
                    this.onDateValueChange(null);
                }
            }, 500);

        });

    };

    closeCalendar = ({target}) => {
        if (!closest(target, this[calendar]) && this.state.calendarOpen) {
            this.toggleCalendar();
        }
    };

    toggleCalendar = () => {
        this.setState((prevState) => ({calendarOpen: !prevState.calendarOpen}));
    };

    clearSelectedDate = async () => {

        this.setState({date: null, dateTextValue: ""}, () => {
            this.props.dateHandler(null);
        });

    };

    render() {
        const today = <TodayI18n i18n={this.props.todayI18n} component={I18nMessage}/>;
        const {disabled, disabledStartDate, clean, style, isClean} = this.props;

        return (
            <div className="calendar-form calendar-form--single">
                <div className="dropdown abs-dropdown-form" ref={c => this[calendar] = c} disabled={disabled}>

                    <CalendarInput {...this.props} onDateInputChange={this.onDateInputChange}
                                   clearSelectedDate={this.clearSelectedDate}
                                   toggleCalendar={this.toggleCalendar}
                                   dateTextValue={isClean ? "" : this.state.dateTextValue}
                                   disabled={disabled}
                                   style={style}/>

                    {this.state.calendarOpen &&
                    <CalendarDropdown ref={c => this[calendarDropdown] = c}
                                      value={isClean ? this.state.currentDateUnchanged : this.state.currentDate} today={today}
                                      calendarOpen={this.state.calendarOpen}
                                      onDateSelect={this.onDateSelect}
                                      disabledStartDate={disabledStartDate}
                                      clean={clean}/>}

                </div>
            </div>
        )

    }
}
