import React from "react";

export default class ResponsiveListHeader extends React.Component {

    toggleCheckAll = (checked) => {
        if (checked) {
            this.props.selectAllIds();
        }
        else {
            this.props.deselectAllIds();
        }
    };

    render() {
        const childrenWithProps = React.Children.map(this.props.children,
            (child, index) => React.cloneElement(child, index === 0 ? {
                    first: index === 0,
                    selectable: this.props.selectable,
                    toggleCheckAll: this.toggleCheckAll,
                    forceCheckMainCheckbox: this.props.forceCheckMainCheckbox
                } : {})
        );

        return <div className="header">
            {childrenWithProps}
        </div>
    }
}
