import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type InfoToastType = {
    headerTextKey: string
}

interface ToastInfoTemplateType extends TemplateType {
    templateProps: InfoToastType;
}

const ToastInfoTemplate = ({
                               templateProps,
                               removeToast,
                               id,
                               timeOut = 5000
                           }: ToastInfoTemplateType): ReactElement => {

    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id={templateProps.headerTextKey}/>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{...templateProps, header: messageHeader}}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastInfoTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoTemplate