import _ from "lodash";
import {getApplicationSessionStorageUUID, getClientSessionStorageUUID, getLocalStorageUUID} from "../utils/fwUtils";
import RestService from "../rest/RestService";

const GET_SESSION_STORAGE_EVENT = 'getSessionStorage';
const SET_SESSION_STORAGE_EVENT = 'setSessionStorage';
const EXCEPTION_LOCAL_STORAGE_KEY = "i18n.messages";
const LOCAL_STORAGE_KEY = getLocalStorageUUID();
const APP_SESSION_STORAGE_KEY = getApplicationSessionStorageUUID();
const CLIENT_SESSION_STORAGE_KEY = getClientSessionStorageUUID();

class StorageManager {

    initializeStorageManager() {

        if (!sessionStorage.length) {
            this.initStorage();         // Ask other tabs for session storage
        }

        window.addEventListener('storage', function (event) {

            switch (event.key) {
                case GET_SESSION_STORAGE_EVENT:
                    if (!_.isNil(event.newValue))
                        StorageManager.syncStorage();             //Send session storage to all tabs of same domain
                    break;
                case SET_SESSION_STORAGE_EVENT:
                    if (!sessionStorage.length) {
                        StorageManager.setSessionStorage(event.newValue);         //Initiate session storage
                    }
                    break;
                default:
                    break;
            }
        });

    }

    initStorage() {
        localStorage.setItem(GET_SESSION_STORAGE_EVENT, Date.now());
        localStorage.removeItem(GET_SESSION_STORAGE_EVENT);
    }

    setNewSessionStorageItem(key, item) {
        sessionStorage.setItem(key, item);
        StorageManager.syncStorage();
    }

    getSessionStorageItem(key) {
        const sessionStorageItem = sessionStorage.getItem(key);
        return sessionStorageItem ? JSON.parse(sessionStorageItem) : sessionStorageItem;
    }

    clearAppSessionStorage() {
        for (let i = 0; i < sessionStorage.length; i++) {
            if (!_.isEqual(sessionStorage.key(i), CLIENT_SESSION_STORAGE_KEY)) {
                sessionStorage.removeItem(sessionStorage.key(i));
            }
        }
    }

    updateAppSessionStorageItem(propName, newValue) {

        let data = this.getSessionStorageItem(APP_SESSION_STORAGE_KEY);

        if (!data) {
            this.clearAppSessionStorage();
            data = {
                [propName]: newValue
            }
        } else {
            data[propName] = newValue;
        }

        this.setNewSessionStorageItem(APP_SESSION_STORAGE_KEY, JSON.stringify(data));

        return data[propName];

    }

    checkAppSessionStorageItem = async (propName, srcUrl) => {

        if (this.isNeedUpdateItem(this.getSessionStorageItem(APP_SESSION_STORAGE_KEY), propName)) {
            const data = await RestService.getJSON(srcUrl);
            this.updateAppSessionStorageItem(propName, data);
            return data;
        }

        return this.getSessionStorageItem(APP_SESSION_STORAGE_KEY)[propName];

    };

    clearClientSessionStorage() {
        for (let i = 0; i < sessionStorage.length; i++) {
            if (!_.isEqual(sessionStorage.key(i), APP_SESSION_STORAGE_KEY)) {
                sessionStorage.removeItem(sessionStorage.key(i));
            }
        }
    }

    updateClientSessionStorageItem(propName, newValue) {

        let data = this.getSessionStorageItem(CLIENT_SESSION_STORAGE_KEY);

        if (!data) {
            this.clearClientSessionStorage();
            data = {
                [propName]: newValue
            }
        } else {
            data[propName] = newValue;
        }

        this.setNewSessionStorageItem(CLIENT_SESSION_STORAGE_KEY, JSON.stringify(data));

        return data[propName];

    }

    checkClientSessionStorageItem = async (propName, srcUrl) => {

        if (this.isNeedUpdateItem(this.getSessionStorageItem(CLIENT_SESSION_STORAGE_KEY), propName)) {
            const data = await RestService.getJSON(srcUrl);
            this.updateClientSessionStorageItem(propName, data);
            return data;
        }

        return this.getSessionStorageItem(CLIENT_SESSION_STORAGE_KEY)[propName];

    };


    setLocalStorageItem(item) {
        localStorage.setItem(LOCAL_STORAGE_KEY, item);
    }

    getLocalStorageItem() {
        const localStorageItem = localStorage.getItem(LOCAL_STORAGE_KEY);
        return localStorageItem ? JSON.parse(localStorageItem) : localStorageItem;
    }

    clearLocalStorage() {
        for (let i = 0; i < localStorage.length; i++) {
            if (!_.isEqual(localStorage.key(i), EXCEPTION_LOCAL_STORAGE_KEY)) {
                localStorage.removeItem(localStorage.key(i));
            }
        }
    }

    updateLocalStorageItem(propName, newValue) {

        let data = this.getLocalStorageItem(LOCAL_STORAGE_KEY);

        if (!data) {
            this.clearLocalStorage();
            data = {
                [propName]: newValue
            }
        } else {
            data[propName] = newValue;
        }

        this.setLocalStorageItem(JSON.stringify(data));

        return data[propName];

    }

    isNeedUpdateItem(item, propName) {
        return !item ||
            !item[propName]
    }

    static syncStorage() {
        localStorage.setItem(SET_SESSION_STORAGE_EVENT, JSON.stringify(sessionStorage));
        localStorage.removeItem(SET_SESSION_STORAGE_EVENT);
    }

    static setSessionStorage(value) {
        if (!value) {
            return;
        }

        const data = JSON.parse(value);

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                sessionStorage.setItem(key, data[key]);
            }
        }

    }

}

export default new StorageManager();