import {getContextPath} from "./fwUtils";

class FwUrlUtils {

    getApiUrl = (url) => {
        return this.getContextUrl(url);
    };

    getContextUrl = (url) => {
        return getContextPath() + url;
    };
}

export default new FwUrlUtils();
