import React from "react";
import {Tooltip} from "react-bootstrap";
import {Overlay} from "react-overlays";
import _ from "lodash";
import classnames from "classnames";

export default class ErrorTooltip extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectHover: false
        }
    }


    setHoverEnabled = () => {
        this.setState({
            selectHover: true
        })
    };

    setHoverDisabled = () => {
        this.setState({
            selectHover: false
        })
    };

    render() {

        const {error, className} = this.props;

        const tooltip = <Tooltip id="tooltip-top">{error}</Tooltip>;

        return (
            <div ref={(c) => this._container = c} className={classnames(className, {"invalid-field": error})}>
                <Overlay show={this.state.selectHover && !_.isNil(error)}
                         placement="top"
                         container={this._container}
                         target={this._selectComponent}>
                    {tooltip}
                </Overlay>
                <div ref={(c) => this._selectComponent = c}
                     onMouseEnter={this.setHoverEnabled}
                     onMouseLeave={this.setHoverDisabled}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}