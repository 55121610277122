import {RestService, ExportApi} from "assecobs-faktor-web-common/index";
import actionTypes from "./actions";

export default (id, filteringEnabled = true, pagingEnabled = true, orderingEnabled = true) => {
    if (!id) {
        throw new Error('itemsProperty must be defined for listReducer')
    }

    const actions = actionTypes(id);
    const addSearchParams = filteringEnabled || pagingEnabled || orderingEnabled;

    const init = (filtering, pageSize, sorting, itemIdProperty, itemSelectableProperty) => (async (dispatch) => {
        dispatch({
            type: actions.INIT,
            filtering: filtering,
            pageSize: pageSize,
            sorting: sorting,
            itemIdProperty: itemIdProperty,
            itemSelectableProperty: itemSelectableProperty
        });
    });

    const load = (url, additionalSearchParams, filtering, pageSize, sorting) => (async (dispatch) => {

        dispatch({
            type: actions.LOAD,
            filtering: filtering,
            sorting: sorting
        });

        const params = {
            ...additionalSearchParams,
            ...addSearchParams && {
                searchParams: {
                    filtering: filtering,
                    paging: {index: 1, size: pageSize},
                    ordering: sorting
                }
            }
        };

        const result = await RestService.post(url, params);
        const resultJson = await result.json();

        dispatch({
            type: actions.LOAD_SUCCESS,
            totalCount: resultJson.totalCount,
            data: resultJson.list
        });

    });

    const loadMore = (url, additionalSearchParams, filtering, paging, sorting) => (async (dispatch) => {
        dispatch({
            type: actions.LOAD_MORE,
            filtering: filtering,
            sorting: sorting
        });

        const params = {
            ...additionalSearchParams,
            ...addSearchParams && {
                searchParams: {
                    filtering: filtering,
                    paging: paging,
                    ordering: sorting
                }
            }
        };

        const result = await RestService.post(url, params);
        const resultJson = await result.json();

        dispatch({
            type: actions.LOAD_MORE_SUCCESS,
            totalCount: resultJson.totalCount,
            data: resultJson.list
        });

    });

    const toggleSelectAll = () => (async (dispatch) => {
        dispatch({
            type: actions.TOGGLE_SELECT_ALL
        });
    });

    const toggleSelect = (id) => (async (dispatch) => {
        dispatch({
            type: actions.TOGGLE_SELECT,
            id: id
        });
    });

    const exportData = (exportFormat, exportKey, additionalSearchParams, filtering, sorting) => (async (dispatch) => {
        dispatch({
            type: actions.EXPORT_DATA
        });

        const params = {
            ...additionalSearchParams,
            ...addSearchParams && {
                searchParams: {
                    filtering: filtering,
                    ordering: sorting
                }
            }
        };

        await ExportApi.exportWithParams(exportFormat, exportKey, params);
    });
    return {
        init,
        load,
        loadMore,
        toggleSelectAll,
        toggleSelect,
        exportData
    }
}
