import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
// @ts-ignore
import _ from "lodash";
import {TemplateType} from "../types/TemplateType";

type InvoiceConfirmationToastType = {
    invoiceNumber: string,
    invoicesAmount: number
    newStatus: string
}

interface ToastInfoForInvoiceConfirmationTemplateType extends TemplateType {
    templateProps: InvoiceConfirmationToastType;
}

const ToastInfoForInvoiceConfirmationTemplate = ({
                                                     templateProps,
                                                     removeToast,
                                                     id,
                                                     timeOut = 5000
                                                 }: ToastInfoForInvoiceConfirmationTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id={!_.isNil(templateProps.invoiceNumber)
        ? "fw.invoice.confirmation.changeStatus"
        : "fw.invoices.confirmation.changeStatus"}/>
    const messageBody = <InvoiceConfirmationToast {...templateProps} />

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{...templateProps, header: messageHeader, body: messageBody}}
                                  timeOut={timeOut}/>
        </div>
    )
}

const InvoiceConfirmationToast = ({
                                      invoiceNumber,
                                      invoicesAmount,
                                      newStatus
                                  }: InvoiceConfirmationToastType): ReactElement => {

    const invoiceNumberLabel = <><I18nMessage id="fw.invoice.no"/>: {invoiceNumber}</>;
    const invoicesAmountLabel = <><I18nMessage id="fw.common.invoiceAmount"/>: {invoicesAmount}</>;

    return (
        <>
            {!_.isNil(invoiceNumber) ? invoiceNumberLabel : invoicesAmountLabel}
            <br/>
            <I18nMessage id="fw.invoice.confirmation.newStatus"/>: {newStatus}
        </>
    )
}

ToastInfoForInvoiceConfirmationTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForInvoiceConfirmationTemplate