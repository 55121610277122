import Const from "../const/Const";

const body = document.querySelector('body');

export function getI18nVersion() {
    return body.getAttribute('data-i18n-version');
}

export function getContextPath() {
    return body.getAttribute('data-context-path') ? body.getAttribute('data-context-path') : "";
}

export function getLocalStorageUUID() {
    return body.getAttribute('data-local-storage-uuid');
}

export function getApplicationSessionStorageUUID() {
    return body.getAttribute('data-application-session-storage-uuid');
}

export function getClientSessionStorageUUID() {
    return body.getAttribute('data-client-session-storage-uuid');
}

export function megabytesToBytes(sizeInMegabytes) {
    return sizeInMegabytes * Const.MEGABYTE;
}

export function bytesToMegabytes(sizeInBytes) {
    return sizeInBytes / Const.MEGABYTE;
}
