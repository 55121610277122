import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {FormattedRelativeTime} from "react-intl";
import Config from "../config/Config";

export default class RelativeTime extends React.Component {

    render() {
        let {value, format, relativeTimeThresholdSeconds} = this.props;

        const elapsed = moment().diff(moment(value), "seconds");

        if (elapsed < relativeTimeThresholdSeconds) {
            return <FormattedRelativeTime value={-elapsed} unit="second" updateIntervalInSeconds={1000*60} numeric="auto" />
        }

        const formatted = moment(value).format(format);

        return (
            <span>{formatted}</span>
        )
    }
}

RelativeTime.propTypes = {
    value: PropTypes.any,
    format: PropTypes.string,
    relativeTimeThresholdSeconds: PropTypes.number
};

RelativeTime.defaultProps = {
    format: Config.dateFormat()
};
