import {ReactElement} from 'react'
// @ts-ignore
import {Config, I18nMessage} from 'assecobs-faktor-web-common/index'
import AttachmentErrorList from './AttachmentErrorList'
import AttachmentShowMore from './AttachmentShowMore'

interface Props {
  fileFailure: {
    files: Array<string>,
    filesZIP: Array<string>,
  };
}

const SendAttachmentsErrorToast = ({fileFailure}: Props): ReactElement => (
  <>
    <AttachmentErrorList files={fileFailure.files} messageId="fw.attachment.adding.invalidType.sizeFile" />
    <AttachmentErrorList files={fileFailure.filesZIP} messageId="fw.attachment.adding.invalidType.zipFile" />
    <AttachmentShowMore />
  </>
)

export default SendAttachmentsErrorToast