import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

const PostActionModalBody = ({iconClassName = "", title = "", message = ""}) => (
    <React.Fragment>
        <i className={classnames("abs-icon abs-modal-post-action__icon", iconClassName)}/>
        <div>
            <div className="abs-modal-post-action">{title}</div>
            <div>{message}</div>
        </div>
    </React.Fragment>
);

PostActionModalBody.propTypes = {
    iconClassName: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default PostActionModalBody;