import React from "react";
import {fwUrlUtils, I18nMessage, RestService} from "assecobs-faktor-web-common";
import {injectIntl} from 'react-intl';
import _ from "lodash";
import {connect} from "react-redux";
import {PACKAGE_SAVE_INFO_TOAST, toast} from "assecobs-faktor-web-common/toasts/ToastInitializer";
import {isNil} from "ramda";

const UNSENT_STATS_URL = fwUrlUtils.getApiUrl("/webapi/unsentStats");
const PACKAGES_UNSENT_PATH = "/packages/unsent";

class BottomButtonPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {additionalButtonPanelClass: " on-scroll"};
    }

    componentDidMount = async () => {
        window.addEventListener("scroll", this.windowScroll, false);
        window.addEventListener("resize", this.windowScroll, false);
        this.windowScroll();
        this.forceUpdate();
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.windowScroll);
        window.removeEventListener("resize", this.windowScroll);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.clearCheckedValue, this.props.clearCheckedValue) && this.props.clearCheckedValue) {
            this.clearCheckboxValue();
        }
    }

    windowScroll = () => {
        const element = this._bottomPanel;
        if (isNil(element)) {
            return null;
        }

        let height = element.parentElement.offsetHeight;

        for (let currentElement = element.parentElement; currentElement; currentElement = currentElement.parentElement) {
            if (currentElement.offsetParent !== currentElement) {
                height += currentElement.offsetTop;
            }
        }

        const isScrolled = window.innerHeight + document.documentElement.scrollTop >= height;
        const additionalButtonPanelClass = isScrolled ? " on-scroll" : "";
        this.setState({additionalButtonPanelClass});
    };

    checkboxLabelClicked = () => {
        this._checkbox.checked = !this._checkbox.checked;
        this.props.onChangeChecked(this._checkbox.checked);
    };

    clearCheckboxValue = () => {
        this._checkbox.checked = false;
        this.props.onChangeChecked(false);
    };

    onClick = async (cancelLink) => {
        if (cancelLink === PACKAGES_UNSENT_PATH) {
            const {packagesAmount} = await RestService.getJSON(UNSENT_STATS_URL);
            packagesAmount && toast(PACKAGE_SAVE_INFO_TOAST({refresh: true}));
        }

        window.location.href = fwUrlUtils.getContextUrl(cancelLink);
    }

    getActionButton = () => {
        const {submitButtonTextId, submitButtonActive, handleSubmit, postRequest} = this.props;

        if (isNil(submitButtonTextId)) {
            return null;
        }

        return (<button
            disabled={!submitButtonActive}
            className="k-button k-state-default btn btn-type-b btn-xs"
            onClick={handleSubmit ? handleSubmit(postRequest) : postRequest}>
            <I18nMessage id={submitButtonTextId}/>
        </button>);
    }

    getCancelButton = () => {
        const {cancelLink, cancelLinkTextId} = this.props;

        if (isNil(cancelLink)) {
            return null;
        }

        return (<button className="k-button k-state-default btn btn-type-a btn-xs"
                        onClick={() => this.onClick(cancelLink)}>
            <I18nMessage id={cancelLinkTextId}/>
        </button>)
    }

    getAddNextField = () => {
        const {addNextField, submitButtonActive, onChangeChecked, addNextFieldTextId} = this.props;

        if (isNil(addNextField)) {
            return null;
        }

        const className = "abs-add-next-field " + (!submitButtonActive ? "abs-add-next-field--low-visible" : "");
        return (
            <div className={className}>
                <input
                    type="checkbox"
                    className="toggle toggle-flip"
                    id="addNextField"
                    ref={c => this._checkbox = c}
                    onChange={(e) => onChangeChecked(e.target.checked)}
                    disabled={!submitButtonActive}
                />
                <label htmlFor="addNextField" className="abs-add-next-field-checkbox-opaque"/>
                <span
                    className="abs-add-next-label"
                    onClick={submitButtonActive ? this.checkboxLabelClicked : () => false}
                >
                    <I18nMessage id={addNextFieldTextId}/>
                </span>
            </div>
        );
    }

    getAdditionalText = () => {
        const {additionalText} = this.props;

        if (!additionalText) {
            return null;
        }

        return (<div className="abs-add-next-field">{additionalText}</div>);
    }

    getOverlayComponent = () => {
        const {OverlayComponent} = this.props;

        if (!OverlayComponent) {
            return this.getActionButton();
        }

        return React.cloneElement(OverlayComponent, {}, this.getActionButton());
    }

    render() {
        const {submitButtonActive, customButton} = this.props;
        const {additionalButtonPanelClass} = this.state;
        const mainClassName = "container button-wrap navbar-fixed-bottom abs-fixed-bottom"
            + additionalButtonPanelClass + (!submitButtonActive ? " container--to-top" : "");

        return (
            <div ref={(c) => this._bottomPanel = c}
                 className={mainClassName}>
                <div className="abs-bottom-btn-panel">
                    <div>
                        {this.getCancelButton()}
                        {customButton && customButton}
                    </div>
                    {this.getAddNextField()}
                    {this.getAdditionalText()}
                    {this.getOverlayComponent()}
                </div>
            </div>)
    }

    static get defaultProps() {
        return {
            cancelLinkTextId: "common.cancel",
            submitButtonTextId: "common.save",
            addNextFieldTextId: "common.add.another"
        }
    }
}

export default connect()(injectIntl(BottomButtonPanel));