import * as actionTypes from "../constants/actionTypes";

function hide() {
    return {
        type: actionTypes.HIDE_LOADER,
    }
}

export function hideLoader() {
    return (dispatch) => {
        dispatch(hide());
    }
}
