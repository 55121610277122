import * as actionTypes from "./actionTypes";
import {Config, RestService} from "assecobs-faktor-web-common/index";
import Url from "./Url";

let cancelRequest = false;
let isFetching = false;

export function startSearch(pageIndex) {
    return {
        type: actionTypes.INVOICE_START_SEARCH,
        isFetching: true,
        pageIndex
    }
}

export function endSearch(invoicesList, invoiceNumber, override, pageIndex) {
    return {
        type: actionTypes.INVOICE_END_SEARCH,
        isFetching: false,
        totalCount: invoicesList.totalCount,
        invoicesList: invoicesList.list,
        invoiceNumber,
        override,
        pageIndex
    }
}

export function cancelSearch() {

    return {
        type: actionTypes.INVOICE_CANCEL_SEARCH,
        isFetching: null
    }
}

export function searchInvoices(invoiceNumber, override, pageIndex) {
    return async (dispatch) => {


        if (override) {

            pageIndex = 1;
        }

        dispatch(startSearch(pageIndex));

        let params = {
            searchParams: {
                paging: {
                    index: pageIndex,
                    size: Config.pageSize()
                },
                filtering: {
                    invoiceNumber: invoiceNumber
                }
            }
        };

        isFetching = true;

        let data = await RestService.post(Url.SEARCH_INVOICES, params);

        let dataJson = await data.json();

        if (!cancelRequest) {
            dispatch(endSearch(dataJson, invoiceNumber, override, pageIndex + 1));
        } else {
            cancelRequest = false;
        }
    }
}

export function cancelFetch() {

    return async (dispatch) => {
        if (isFetching) {
            cancelRequest = true;
            dispatch(cancelSearch());
        }
    }
}
