import _ from "lodash";
import {getInstanceConfigMetaData} from "../application/services/InstanceConfigMetaDataService"
import {MODULE, WINDOW, COMPONENT, ELEMENT, META_DATA} from "./consts";

const emptyMetaData = {module: null, window: null, component: null, element: null, [META_DATA]: {}};

const getMetaData = (search) => {
    const stored = getInstanceConfigMetaData();

    if (_.isEmpty(stored)) {
        throw new Error("Instance MetaData has not yet been initialized!")
    }

    const instanceConfigMetaData = stored.metaData.reduce((acc, curr) => {
        if (isNotAttributeFit(search, curr)) {
            return acc
        }

        const currImportance = getImportance(curr);
        const accImportance = getImportance(acc);

        return (currImportance > accImportance) ? curr : acc
    }, emptyMetaData);

    return instanceConfigMetaData[META_DATA];
};

const isNotAttributeFit = (searchMetaData, comparedMetaData) => {
    const isAttributeMatching = (attributeId) => {
        return searchMetaData[attributeId] === comparedMetaData[attributeId] || comparedMetaData[attributeId] === null;
    };

    return !(isAttributeMatching(MODULE.name) && isAttributeMatching(WINDOW.name) && isAttributeMatching(COMPONENT.name) && isAttributeMatching(ELEMENT.name))
};

const getImportance = ({module, window, component, element}) => {
    const getAttributeImportance = (attributeId, attributeImportance) => {
        return (attributeId !== null) ? attributeImportance : 0
    };

    return getAttributeImportance(module, MODULE.importance)
        + getAttributeImportance(window, WINDOW.importance)
        + getAttributeImportance(component, COMPONENT.importance)
        + getAttributeImportance(element, ELEMENT.importance)
};

export {
    getMetaData
}