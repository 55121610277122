import React from "react";
import ContextDropdownComponent from "./ContextDropdownComponent";
import {injectIntl} from 'react-intl';

class ContextDropdown extends React.PureComponent {

    render() {

        const {parentUrl, intl} = this.props;

        return (
            <ContextDropdownComponent key="context_dropdown">
                <ContextDropdownComponent.Item label={intl.formatMessage({id: "fw.common.AsClient"})}
                                      url={parentUrl + "/client"} key="client_item"/>
                <ContextDropdownComponent.Item label={intl.formatMessage({id: "fw.common.AsContractor"})}
                                      url={parentUrl + "/contractor"} key="contractor_item"/>
            </ContextDropdownComponent>
        )
    }
}

export default injectIntl(ContextDropdown);
