import React from "react";
import PropTypes from "prop-types";

export default class Address extends React.Component {

    render() {
        const {value, customClassName} = this.props;

        const locumNumber = value.get("locumNumber");

        return (
            <div className={customClassName}>
                <div>{value.get("street")} {value.get("buildingNumber")}{locumNumber ? "/" + locumNumber : null}</div>
                <div>{value.get("postCode")} {value.get("town")}</div>
                {value.get("country") && <div>{value.get("country")}</div>}
            </div>
        )
    }

    static get propTypes() {
        return {
            value: PropTypes.object
        }
    }
}
