import React from "react";
import {Button, Modal} from "react-bootstrap";
import I18nMessage from "../i18n/I18nMessage";

export default class Prompt extends React.Component {

    confirm = async () => {
        await this.props.promptProps.confirm();
        this.close();
    };

    close = () => {
        this.props.onClose();
    };

    render() {

        const {show, promptProps} = this.props;

        return (
            <Modal show={show} animation={false} autoFocus={true} keyboard={true} backdrop={true}>
                <Modal.Body>
                    <i className="abs-icon abs_help"/>
                    <div>
                        {promptProps && promptProps.promptContent}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-type-b" onClick={this.confirm}><I18nMessage id="fw.invoiceVAT.confirm"/></Button>
                    <Button className="btn btn-type-a" onClick={this.close}><I18nMessage id="common.cancel"/></Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
