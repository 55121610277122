import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {ToastDefaultTemplateType} from '@assecobs-react-components/toaster/src/types/ToastDefaultTemplateType'
import {ToastWrapperDefaultTemplatePropTypes} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'

const ToastInfoTemplate = ({
                               templateProps,
                               removeToast,
                               id,
                               children,
                               timeOut = 5000
                           }: ToastDefaultTemplateType): ReactElement => {

    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})

    const contentTemplate = (
        <
            // @ts-ignore
            ToastContentTemplate onClose={onClose}
                                 content={templateProps}
                                 timeOut={timeOut}/>
    )

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            {children !== undefined ? children : contentTemplate}
        </div>
    )
}

ToastInfoTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoTemplate