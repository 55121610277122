import {fwUrlUtils} from "assecobs-faktor-web-common/index";
import {fromJS} from "immutable";
import storageManager from "../storageManager/storageManager";

export default async () => {

    const CONTEXT_URL = fwUrlUtils.getApiUrl("/webapi/userContext/getContext");

    const data = await storageManager.checkClientSessionStorageItem("userContext", CONTEXT_URL);

    return fromJS(data);
}
