import React from "react";
import {Loadable} from "@assecobs/react-common-components";
import {BottomButtonPanel} from "assecobs-faktor-web-common/index";
import {Icon} from "@assecobs/react-common-components/index";

const formContainer = Symbol();

export default class FormComponent extends React.PureComponent {

    render() {
        const {loading, submitting, error, onSubmit, ...bottomPanelProps} = this.props;

        return (
            <div ref={c => this[formContainer] = c} className="fw-form-container container-box container-box-a">
                {error && (
                    <section className="validation-header container">
                        <Icon name="abs_announcement" className="alert-icon"/>
                        <h1>{error}</h1>
                    </section>
                )}

                <div className={"abs-col-1 abs-form-panel fw-form container"}>
                    <Loadable loading={loading || submitting}>
                        {this.props.children}
                    </Loadable>
                </div>

                <BottomButtonPanel postRequest={onSubmit} {...bottomPanelProps} container={this[formContainer]}/>
            </div>
        );
    }
}

