const body = document.querySelector("body");

export const getSupportedLocales = () => {
    const stringSupportedLocales = body.getAttribute("data-supported-locale");

    return stringSupportedLocales.split(',');
};

export const getLocale = () => {
    return body.getAttribute("data-locale");
};

export const isLanguages = () => {
    const languages = body.getAttribute("data-supported-locale").indexOf(",");

    return languages > -1;
};