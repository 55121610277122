import React from "react";

export default class ContractorSelectedValueTemplate extends React.Component {
    render() {
        const {data} = this.props;

        const selectedData = Array.isArray(data) ? data : [data];

        let fullDisplayedValue;

        if (selectedData) {
            fullDisplayedValue = selectedData.map(item => item["shortName"]).join();
        }

        return (
            <div className="selected-value-template-view">
                <span>({selectedData.length}) {fullDisplayedValue}</span>
            </div>
        );
    }
}
