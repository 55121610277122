import React from "react";
import {Collapse} from "react-bootstrap";
import MediaQuery from "react-responsive";
import classnames from "classnames";

const addKey = (filters) => {
    if (filters) {
        return filters.map((filter, index) =>
            React.cloneElement(filter, {
                key: filter.props.id || `${filter.type.name}-${index}`
            })
        )
    }
};

export default ({mainFilters, additionalFilters, autoSubmit, showAdditionalFilters, isSubmitted, isOpen, onSubmit, onClear, clearable, onToggleAdditionalFilters, submitButtonText}) => {

    const keyedMainFilters = addKey(mainFilters);
    const keyedAdditionalFilters = addKey(additionalFilters);

    return (
        <Collapse in={isOpen}>
            <div className="abs-filter-panel">
                <div className="abs-filter-panel__filters">
                    <div className="abs-filter-panel__main-filters">
                        {keyedMainFilters}
                    </div>

                    <Collapse in={showAdditionalFilters}>
                        <div className="abs-filter-panel__additional-filters">
                            {keyedAdditionalFilters}
                        </div>
                    </Collapse>
                </div>

                <div className={classnames("abs-filter-panel__buttons", {"abs-filter-panel__buttons--pull-right": !isSubmitted})}>
                    {additionalFilters && (
                        <button onClick={onToggleAdditionalFilters}
                                className={
                                    "abs-filter-panel__toggle-additional-filters" +
                                    (showAdditionalFilters ? " abs-filter-panel__toggle-additional-filters-shown" : "")
                                }
                        >
                            <i className="abs-icon abs_angle_down"/>
                        </button>
                    )}

                    {!autoSubmit && (
                        <MediaQuery minWidth={768}>
                            <div className="abs-filter-panel__submit">
                                <button className="btn btn-xs btn-primary btn-type-b"
                                        onClick={onSubmit}>{submitButtonText}
                                </button>
                            </div>
                        </MediaQuery>
                    )}

                    {isSubmitted && clearable &&
                    <button onClick={onClear} className="abs-filter-panel__clear"><i className="abs-icon abs_close"/>
                    </button>}
                    {!autoSubmit && (
                        <MediaQuery maxWidth={767}>
                            <button className="btn btn-xs btn-primary btn-type-b"
                                    onClick={onSubmit}>{submitButtonText}
                            </button>
                        </MediaQuery>
                    )}
                </div>
            </div>
        </Collapse>
    )
}

