import {DefinitionReturn} from "../types/DefinitionReturn";
import {FW_TOAST_BRAND} from "../enums/FWToastBrandEnum";

type InfoToastType = {
    headerTextKey: string
}

export const INFO_TOAST = (params: InfoToastType): DefinitionReturn => ({
    templateProps: params,
    type: FW_TOAST_BRAND.INFO_FOR_INFO
})