import React from "react";

export default class NotBreakable extends React.PureComponent {

    render() {
        return (
            <span className="no-break">{this.props.children}</span>
        )
    }
}
