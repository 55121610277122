import restService from "@assecobs/react-utils/restService";
import {urlWithParams} from "@assecobs/react-utils/urlUtils";
import Enums from "@assecobs/react-utils/Enums";
import fwUrlUtils from "../utils/fwUrlUtils";
import RestUtils from "./RestUtils";
import {REQUEST_ERROR} from "@assecobs/react-utils/common/Events";
import {REQUEST_POST_ACTION} from './ExtraEvents';

import _ from "lodash";

const _fetch = Symbol();
const _fetchJSON = Symbol();
const _handleResponse = Symbol();
const _handleRedirect = Symbol();
const _handlePostAction = Symbol();
const _handleException = Symbol();
const _configWithCsrfHeader = Symbol();
const _getPostFileHeaders = Symbol();

const defaultHeaders = {
    'Content-Type': Enums.CONTENT_TYPE.APPLICATION_JSON,
    'Accept-Charset': Enums.CHARSET.UTF8,
    'X-AJAX-Request': true
};

const CSRF_HEADER_NAME = "X-CSRF-TOKEN";
const REDIRECT_URL_HEADER_NAME = "X-Redirect-Url";

const HTTP_GONE = 410;

const defaultConfig = {
    cache: Enums.REQUEST_CACHE.RELOAD,
    headers: defaultHeaders,
    credentials: Enums.REQUEST_CREDENTIALS.SAME_ORIGIN
};

class RestService {

    static get(url, params) {
        return this[_fetch](urlWithParams(url, params), Enums.REQUEST_METHOD.GET)
    }

    static post(url, params, headers) {
        return this[_fetch](url, Enums.REQUEST_METHOD.POST, JSON.stringify(params), this[_configWithCsrfHeader](headers));
    }

    static postFormUrlEncoded(url, params, headers) {
        const restConfig = _.merge({}, headers, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'X-AJAX-Request': true
            }
        });
        
        const body = 'username' + '=' + params.username + '&' + 'password' + '=' + escape(params.password);
        return this[_fetch](url, Enums.REQUEST_METHOD.POST, body, restConfig);
    }

    static postJSON(url, params, headers) {
        return this[_fetchJSON](url, Enums.REQUEST_METHOD.POST, params ? JSON.stringify(params) : null, this[_configWithCsrfHeader](headers))
            .catch((response) => {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                setTimeout(() => {
                    restService.emit(REQUEST_ERROR, response);
                    return [];
                }, 100);
            });
    }

    static delete(url, params) {
        return this[_fetch](url, Enums.REQUEST_METHOD.DELETE, JSON.stringify(params));
    }

    static getJSON(url, params = {}) {
        return this[_fetchJSON](urlWithParams(url, params), Enums.REQUEST_METHOD.GET).catch((response) => {
            if (response.redirected) {
                window.location.href = response.url;
            }
            setTimeout(() => {
                restService.emit(REQUEST_ERROR, response);
                return [];
            }, 100);
        });
    }

    static postFile(url, formData) {
        return fetch(url, this[_configWithCsrfHeader](this[_getPostFileHeaders](formData))).then((response) => {
            return this[_handleResponse](response);
        }).catch(() => {
            this[_handleException]();
        });
    }

    static [_fetch](url, method, body, config = {}) {

        const restConfig = _.merge({}, defaultConfig, config);

        restConfig.method = method;
        if (body) {
            restConfig.body = body;
        }

        const rc = this[_configWithCsrfHeader](restConfig);
        return fetch(url, rc).then((response) => {
            return this[_handleResponse](response);
        }).catch((response) => {
            this[_handleException](response);
        });
    }

    static [_fetchJSON](url, method, body, config = {}) {
        return this[_fetch](url, method, body, config).then(response => response.json());
    }

    static [_configWithCsrfHeader](config = {}) {
        if (config.headers && config.headers[CSRF_HEADER_NAME]) {
            return config;
        }

        const csrfToken = RestUtils.getCsrfHeader();
        return _.merge({}, config, {headers: {...csrfToken}});
    }

    static [_handleResponse](response) {
        if (!response.ok) {
            if (RestUtils.isResponse4xx(response.status)) {
                if (response.status === 401) {
                    return response;
                }

                if (response.status === HTTP_GONE) {
                    this[_handlePostAction](response);

                    return response.clone();
                }

                window.location.href = fwUrlUtils.getContextUrl("/error404");
                return;
            }
            throw response;
        }

        return this[_handleRedirect](response);
    }

    static [_handleRedirect](response) {
        const redirectUrlHeader = response.headers.get(REDIRECT_URL_HEADER_NAME);

        if (redirectUrlHeader) {
            window.location.href = fwUrlUtils.getContextUrl(redirectUrlHeader);
        } else {
            return response;
        }
    }

    static [_handlePostAction](response) {
        setTimeout(() => {
            restService.emit(REQUEST_POST_ACTION, response);
        }, 100);
    }

    static [_handleException](response) {
        setTimeout(() => {
            restService.emit(REQUEST_ERROR, response);
        }, 100);
    }

    static [_getPostFileHeaders](formData) {
        return {
            method: Enums.REQUEST_METHOD.POST,
            body: formData,
            credentials: Enums.REQUEST_CREDENTIALS.SAME_ORIGIN,
            headers: {
                'X-AJAX-Request': true
            }
        }
    }
}

export {
    HTTP_GONE
}

export default RestService;

