import React from "react";
import PropTypes from "prop-types";
import Percent from "./Percent";
import * as AppProperties from "assecobs-faktor-web-profile/AppProperties";
import isNil from '@ramda/isnil'

export default class InterestRate extends React.Component {

    render() {
        const {name, percent} = this.props;
        const isSign = !isNil(name) && !isNil(percent);

        return (
            <span>
                {name}
                {isSign && " + "}
                <Percent value={percent}
                         minFractionDigits={AppProperties.MINIMUM_FRACTION_DIGITS_INTEREST_RATE}
                         maxFractionDigits={AppProperties.MAXIMUM_FRACTION_DIGITS_INTEREST_RATE}/>
            </span>
        )
    }

    static get propTypes() {
        return {
            name: PropTypes.string,
            percent: PropTypes.number
        }
    }
}
