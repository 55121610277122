import React from "react";
import footerStorage from "../application/footer/footer";
import {I18nMessage} from "assecobs-faktor-web-common";
import Hyphen from "../hyphen/Hyphen";
import classnames from "classnames";
import _ from "lodash";

const FOOTER_CONTAINER_MARGIN = 580;
const SPACE = " ";

export default class Footer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {footer: footerStorage.footer, isVerticalView: false};
        window.addEventListener('resize', this.checkContainerWidth);
    }

    componentDidMount() {
        this.checkContainerWidth()
    }

    checkContainerWidth = () => {
        const containersWidthTotal =
            (_.isUndefined(this.nameContainer) ? 0 : this.nameContainer.offsetWidth)
            + (_.isUndefined(this.phoneContainer) ? 0 : this.phoneContainer.offsetWidth)
            + (_.isUndefined(this.emailContainer) ? 0 : this.emailContainer.offsetWidth);

        const isVerticalView = containersWidthTotal > this.container.offsetWidth - FOOTER_CONTAINER_MARGIN;
        this.setState({...this.state, isVerticalView: isVerticalView});
    };

    render() {
        return (
            <footer>
                <div className="container-box">
                    <div className="footer">
                        <div className="container-fluid footer-content" ref={el => (this.container = el)}>
                            {this.state.footer && [
                                <div
                                    className={classnames("footer-content--left", {"footer-container--vertical": this.state.isVerticalView})}
                                    key={1}>
                                    {this.state.footer.helpDeskName &&
                                    <p className={classnames("helpdesk-name", {"footer-item--vertical": this.state.isVerticalView})}
                                       title={this.state.footer.helpDeskName}>
                                        <i className={classnames("abs-icon abs_life_ring", {"footer-icon--vertical": this.state.isVerticalView})}/>
                                        <span ref={el => (this.nameContainer = el)}>
                                                {this.state.footer.helpDeskName}
                                        </span>
                                    </p>}
                                    {this.state.footer.helpDeskPhone &&
                                    <p className={classnames("helpdesk-phone", {"footer-item--vertical": this.state.isVerticalView})}
                                       title={this.state.footer.helpDeskPhone}>
                                        <I18nMessage id="fw.footer.phone"/>
                                        <span ref={el => (this.phoneContainer = el)}>
                                            {SPACE + this.state.footer.helpDeskPhone}
                                        </span>
                                    </p>}
                                    {this.state.footer.helpDeskEmail &&
                                    <p className={classnames("helpdesk-email", {"footer-item--vertical": this.state.isVerticalView})}
                                       title={this.state.footer.helpDeskEmail}>
                                        <I18nMessage id="fw.footer.email"/>
                                        <a href={`mailto:${this.state.footer.helpDeskEmail}`}>
                                            <span ref={el => (this.emailContainer = el)}>
                                                {SPACE + this.state.footer.helpDeskEmail}
                                            </span>
                                        </a>
                                    </p>}
                                </div>,
                                <div
                                    className={classnames("footer-content--right", {"footer-content--vertical": this.state.isVerticalView})}
                                    key={2}>
                                    <I18nMessage
                                        id="fw.footer.copyright"/><Hyphen/><span>{this.state.footer.copyrightYears}</span>
                                </div>
                            ]}
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
