import {createConfirmation} from 'react-confirm';
import Prompt2 from './Prompt2';

const confirm = createConfirmation(Prompt2);

// This is optional. But I recommend to define your confirm function easy to call.
export default function (content, options = {}) {
    // You can pass whatever you want to the component. These arguments will be your Component's props
    return confirm({content, options});
}
