export enum FW_TOAST_BRAND {
    INFO_FOR_INFO = "INFO_FOR_INFO",
    INFO_FOR_SETTING_SENT = "INFO_FOR_SETTING_SENT",
    INFO_FOR_PACKAGE_SEND = "INFO_FOR_PACKAGE_SEND",
    INFO_FOR_PACKAGE_SAVE = "INFO_FOR_PACKAGE_SAVE",
    INFO_FOR_PACKAGE_SAVE_INFO = "INFO_FOR_PACKAGE_SAVE_INFO",
    INFO_FOR_SEND_ATTACHMENT = "INFO_FOR_SEND_ATTACHMENT",
    INFO_FOR_SEND_ATTACHMENTS = "INFO_FOR_SEND_ATTACHMENTS",
    INFO_FOR_CORRECTIVE_ADDING = "INFO_FOR_CORRECTIVE_ADDING",
    INFO_FOR_CORRECTIVE_EDITING = "INFO_FOR_CORRECTIVE_EDITING",
    INFO_FOR_INVOICE_ADDING_INFO = "INFO_FOR_INVOICE_ADDING_INFO",
    INFO_FOR_INVOICE_ADDING = "INFO_FOR_INVOICE_ADDING",
    INFO_FOR_INVOICE_EDITING = "INFO_FOR_INVOICE_EDITING",
    INFO_FOR_INVOICE_CONFIRMATION = "INFO_FOR_INVOICE_CONFIRMATION",
    INFO_FOR_INVOICE_FOR_FINANCING = "INFO_FOR_INVOICE_FOR_FINANCING",
    INFO_FOR_PASSWORD_CHANGED = "INFO_FOR_PASSWORD_CHANGED",
    INFO_FOR_ORDER_REPORT = "INFO_FOR_ORDER_REPORT",
    INFO_FOR_REQUEST_SENT = "INFO_FOR_REQUEST_SENT",
    DANGER_FOR_ERROR_TOAST = "DANGER_FOR_ERROR_TOAST",
    DANGER_FOR_INVALID_ATTACHMENT_SIZE = "DANGER_FOR_INVALID_ATTACHMENT_SIZE",
    DANGER_FOR_INVALID_ATTACHMENT_TYPE = "DANGER_FOR_INVALID_ATTACHMENT_TYPE",
    DANGER_FOR_SEND_ATTACHMENT_ERROR = "DANGER_FOR_SEND_ATTACHMENT_ERROR",
    DANGER_FOR_SEND_ATTACHMENTS_ERROR = "DANGER_FOR_SEND_ATTACHMENTS_ERROR",
}