import React from "react";
import {Collapse} from "react-bootstrap";
import classnames from "classnames";

import AccordionExportButton from "./AccordionExportButton";

export default class Accordion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {collapsed: false, mouseOver: false};
    }

    onMouseOver = () => {
        this.setState({mouseOver: true});
    };

    onMouseLeave = () => {
        this.setState({mouseOver: false});
    };

    closeCollapse = () => {
        this.setState({collapsed: false});
    };

    onChangeCollapse = () => {

        this.setState(prevState => ({
            collapsed: !prevState.collapsed
        }));

        if (this.props.onChangeCollapse)
            this.props.onChangeCollapse();

    };

    onExportButtonMouseOver = (event) => {
        event.stopPropagation();
        this.setState({mouseOver: false});
    };

    render() {

        const {panelContent, collapseContent, incorrect, exportButton, onExport} = this.props;

        return (
            <div>
                <div className={classnames("fw-accordion-panel", {
                    "incorrect": incorrect,
                    "collapsed": this.state.collapsed,
                    "mouseOver": this.state.mouseOver
                })}
                     onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}
                     onClick={this.onChangeCollapse}>
                    {panelContent}
                    {exportButton &&
                    <AccordionExportButton onClick={onExport} onHover={this.onExportButtonMouseOver}/>}
                    <i className={classnames("abs-icon fw-accordion-panel-expand-icon", {
                        "abs_expand_more": !this.state.collapsed,
                        "abs_expand_less": this.state.collapsed
                    })}/>
                </div>
                <Collapse in={this.state.collapsed}>
                    <div className="fw-accordion-panel-grid">
                        {collapseContent}
                    </div>
                </Collapse>
            </div>
        )
    }

    static get defaultProps() {
        return {
            incorrect: false
        }
    }
}