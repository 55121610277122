import React from "react";
import TaxNumberAndCode from "../../dataPresentation/TaxNumberAndCode";

export default ({option, selectedIds}) => {
    const checked = selectedIds.indexOf(option.contractorClientId) > -1;

    return (
        <label className="r-ss-dropdown-multiselect-option" htmlFor={"contractor-" + option.contractorId}>
            <input id={"contractor-" + option.contractorId} className="toggle toggle-flip" checked={checked}
                   type="checkbox"/>
            <label htmlFor={"contractor-" + option.contractorId}/>
            <div className="option-template-view option-template-view--multiline">
                <div className="option-main-value">{option.shortName}</div>
                <TaxNumberAndCode taxNumberTypeDesc={option.taxNumberTypeDesc}
                                            taxNumber={option.taxNumber}
                                            code={option.code}
                />
            </div>
        </label>
    );
}
