import React from "react";
import {fwUrlUtils, I18nMessage, Link} from "assecobs-faktor-web-common/index";
import {Field} from "redux-form";
import AuthenticationPageComponent from "../../common/AuthenticationPageComponent";
import {getLocale} from 'assecobs-faktor-web-common/utils/attributesUtils';
import setLocaleCookie from "assecobs-faktor-web-common/services/setLocaleCookie";
import {isLanguages} from 'assecobs-faktor-web-common/utils/attributesUtils';

const InputComponent = ({input, name, label, placeholder, type, autoFocus}) => (
    <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input id={name} {...input} placeholder={placeholder} type={type} className="form-control k-textbox"
               autoFocus={autoFocus}/>
    </div>
);

export default class LoginPageComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        setLocaleCookie(getLocale());
    }

    render() {
        const {msg, submitting, handleSubmit, submit} = this.props;

        return (
            <AuthenticationPageComponent title="fw.authentication.login.formTitle" submitting={submitting} msg={msg}
                                         isLanguage={isLanguages()}>
                <form id="loginForm"
                      onSubmit={handleSubmit(submit)}
                      className="authentication-form"
                >

                    <Field
                        name="username"
                        type="text"
                        component={InputComponent}
                        label={<I18nMessage id="fw.authentication.login.username"/>}
                        autoFocus
                    />

                    <Field
                        name="password"
                        type="password"
                        component={InputComponent}
                        label={<I18nMessage id="fw.authentication.login.password"/>}
                    />

                    <div>
                        <Link url={fwUrlUtils.getContextUrl("/resetPassword")}
                              id="resetPasswordLink"
                              customClassName="fw-reset-password__link"
                              text={<I18nMessage id="fw.authentication.login.forgotPassword"/>} external/>
                    </div>

                    <button type="submit" className="btn btn-lg btn-type-d login-btn">
                        <I18nMessage id="fw.authentication.login.submit"/>
                    </button>

                    <div className="clearfix"/>
                </form>
            </AuthenticationPageComponent>
        );
    }

}
