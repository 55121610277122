import * as actionTypes from "./actionTypes";
import {RestService, fwUrlUtils} from "assecobs-faktor-web-common/index";

function load() {
    return {
        type: actionTypes.LOAD
    }
}

function onLoadSuccess(alerts) {
    return {
        type: actionTypes.LOAD_SUCCESS,
        alerts: alerts
    }
}

export function loadAlerts() {
    return async(dispatch) => {

        dispatch(load());

        const data = await RestService.getJSON(fwUrlUtils.getApiUrl("/webapi/alerts"));

        dispatch(onLoadSuccess(data.list));
    }
}


function loadStats() {
    return {
        type: actionTypes.LOAD_STATS
    }
}

function onLoadStatsSuccess(stats) {
    return {
        type: actionTypes.LOAD_STATS_SUCCESS,
        stats: stats
    }
}

export function loadAlertStats() {
    return async(dispatch) => {

        dispatch(loadStats());

        const data = await RestService.getJSON(fwUrlUtils.getApiUrl("/webapi/alerts/stats"));

        dispatch(onLoadStatsSuccess(data));
    }
}

function markAsRead(alertId) {
    return {
        type: actionTypes.MARK_ONE_AS_READ,
        alertId: alertId
    }
}

export function markAlertAsRead(alertId) {
    return async(dispatch) => {

        await RestService.post(fwUrlUtils.getApiUrl("/webapi/alerts/markAlertAsRead"), {alertId: alertId});
        dispatch(markAsRead(alertId));
        dispatch(loadAlertStats());
    }
}

function markAllAsRead() {
    return {
        type: actionTypes.MARK_ALL_AS_READ
    }
}

export function markAllAlertsAsRead() {
    return async(dispatch) => {

        await RestService.post(fwUrlUtils.getApiUrl("/webapi/alerts/markAllAlertsAsRead"));
        dispatch(markAllAsRead());
        dispatch(loadAlertStats());
    }
}
