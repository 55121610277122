import {dateFormat} from "assecobs-faktor-web-common/index";

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
const DEFAULT_API_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_LANGUAGES = "pl_PL,en_US";
const DEFAULT_MAX_REQUEST_SIZE = 10;
const DEFAULT_REPORT_FILE_FORMAT = 'XLSX';
const DEFAULT_SEARCHABLE = 5;

const body = document.querySelector('body');

const config = JSON.parse(body.getAttribute('data-frontend-config'));

export default class Config {

    static dateFormat() {
        return dateFormat.j2m(config.dateFormat || DEFAULT_DATE_FORMAT);
    }

    static apiDateFormat() {
        return dateFormat.j2m(config.apiDateFormat || DEFAULT_API_DATE_FORMAT);
    }

    static pageSize() {
        return config.pageSize || DEFAULT_PAGE_SIZE;
    }

    static supportedLanguages() {
        return config.supportedLanguages || DEFAULT_LANGUAGES
    }

    static getMaxRequestSize() {
        return parseInt(config.maxRequestSize) || DEFAULT_MAX_REQUEST_SIZE;
    }

    static getDefaultReportFileFormat() {
        return config.reportFileFormat || DEFAULT_REPORT_FILE_FORMAT;
    }

    static getHowManyToSearch(){
        return DEFAULT_SEARCHABLE;
    }
}
