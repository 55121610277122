import React from "react";
import onClickOutside from "react-onclickoutside";
import setLocaleCookie from "assecobs-faktor-web-common/services/setLocaleCookie";
import {fwUrlUtils} from "assecobs-faktor-web-common/index";
import Header from './headers/Header';
import {getSupportedLocales, getLocale} from 'assecobs-faktor-web-common/utils/attributesUtils';
import List from "./lists/List";
import buttonTypeClassName from "assecobs-faktor-web-profile/LoginLanguageButtonTypeClassName";

const _getOptions = Symbol();

class Dropdown extends React.PureComponent {

    state = {
        isOpen: false,
        locale: ''
    };

    componentDidMount() {
        this.setState({locale: getLocale()});
    }

    handleHeaderClick = () => {
        this.setState((state) => ({
            isOpen: !state.isOpen
        }));
    };

    handleOptionClick = (locale) => {
        this.setState((state) => ({
            isOpen: !state.isOpen
        }));

        if (locale === this.state.locale) {
            return;
        }

        setLocaleCookie(locale);
        window.location.href = fwUrlUtils.getContextUrl("/login");
    };

    handleClickOutside = event => {
        this.setState({isOpen: false});
    };

    render() {
        const {isOpen, locale} = this.state;
        const isOpenClassName = isOpen ? 'open' : '';

        return (
            <div className={`dropdown abs-dropdown login-language-select ${isOpenClassName}`}>
                <Header buttonTypeClassName={buttonTypeClassName} label={locale.substr(0, 2).toUpperCase()} onClick={this.handleHeaderClick}/>
                <List options={this[_getOptions]()} onOptionClick={this.handleOptionClick}/>
            </div>
        );
    }

    [_getOptions]() {
        const locales = getSupportedLocales();

        return locales.map(locale => ({label: locale.substr(0, 2).toUpperCase(), value: locale}));
    }
}

export default onClickOutside(Dropdown);