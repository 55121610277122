import React from "react";
import NumberFormat from "react-number-format";
import classnames from "classnames";

export default class PositiveNumberInputField extends React.PureComponent {

    onChange = (value) => {
        const newValue = isNaN(value.value) ? undefined : +value.value;
        this.props.input.onChange(newValue || null);
    };

    render() {
        const {invalid, disabled, maxLength} = this.props;

        return (
            <NumberFormat
                className={classnames("abs-money-input-field", {
                    "invalid-positive-number": invalid
                })}
                onValueChange={this.onChange}
                value={this.props.input.value}
                disabled={disabled}
                decimalScale={0}
                allowNegative={false}
                maxLength={maxLength}
                isAllowed={val => {
                    return !(val.value === '0')
                }}
            />
        )
    }
}
