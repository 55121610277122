import React from "react";
import {RestService} from "assecobs-faktor-web-common/index";
import {injectIntl} from 'react-intl';
import classnames from "classnames";
import _ from "lodash";
import SingleSelectComponent from "../SingleSelectComponent";

const _baseAgreementId0 = Symbol();
const _contractorClientId = Symbol();
const _isBaseAgreementId0AndContractorClientId = Symbol();

class AccountSelectInputField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: props.options || null,
            loading: false,
            initialValue: null
        }
    }

    componentDidMount = async () => {

        if (this.props.url) {
            await this.setState({
                loading: true
            });
            let options;

            if (this.props.parameters && !_.isEmpty(this.props.parameters)) {
                options = (await RestService.postJSON(this.props.url, this.props.parameters)).list;
            } else if (!this.props.parameters) {
                options = (await RestService.getJSON(this.props.url)).list;
            }

            await this.setState({
                options: options,
                loading: false
            });
        }
    };

    componentDidUpdate = async (prevProps) => {
        if (this.props.parameters && !_.isEmpty(this.props.parameters) &&
            (!_.isNil(prevProps.parameters) ||
                (_.isNil(prevProps.parameters) || this.props.uploadAccounts))) {

            if (this[_isBaseAgreementId0AndContractorClientId]()) {
                this[_baseAgreementId0] = this.props.parameters.baseAgreementId0;
                this[_contractorClientId] = this.props.parameters.contractorClientId;

                const result = await RestService.postJSON(this.props.url, this.props.parameters);

                if (_.isEqual(result, undefined)) {
                    return;
                }

                await this.setState({
                    options: result.list,
                    loading: false
                });
            }

        } else if (this.props.clear && !_.isEqual(this.props.clear, prevProps.clear)) {
            const tempOptions = this.state.options;

            await this.setState({
                options: null
            });

            this.setState({
                options: tempOptions
            });
        }
    };

    onChange = (option) => {
        if (option && option.id) {
            this.props.input.onChange(option[this.props.optionValueProp]);
        } else {
            this.props.input.onChange(null);
            this.setState({
                initialValue: null
            });
        }
    };

    render() {
        const {input, optionValueProp, optionNameProp, invalid, initialDate} = this.props;

        return (
            <SingleSelectComponent
                optionValueProp={optionValueProp}
                optionNameProp={optionNameProp}
                searchable={_.size(this.state.options) > 5}
                filterProp={optionNameProp}
                clearable={false}
                initialValue={initialDate ? initialDate : input.value}
                title=""
                options={this.state.options}
                loading={this.state.loading}
                onChange={this.onChange}
                customClass={classnames({
                    "k-invalid": invalid
                })}
            />
        );
    }

    [_isBaseAgreementId0AndContractorClientId]() {
        const {baseAgreementId0, contractorClientId} = this.props.parameters;
        return (baseAgreementId0 !== undefined && contractorClientId !== undefined)
            && (baseAgreementId0 !== this[_baseAgreementId0] || contractorClientId !== this[_contractorClientId]);
    }

    static get defaultProps() {
        return {
            widthLevel: 2
        }
    }
}

export default injectIntl(AccountSelectInputField);
