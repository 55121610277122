import React from "react";
import FirstCell from "./FirstCell";
import classnames from "classnames";
import _ from "lodash";

export default class CustomCell extends React.Component {

    render() {
        const {first, ...rest} = this.props;

        if (first) {
            return (
                <FirstCell {...rest} />
            )
        }

        return (
            <div className={classnames("col", rest.className)}>
                <div className={classnames({"abs-label": !_.isNil(rest.label) && !_.isEmpty(rest.label)})} data-label={rest.label}>
                    {rest.children}
                </div>
            </div>
        )
    }
}
