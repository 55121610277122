import React from "react";
import PropTypes from "prop-types";

export default class BooleanIcon extends React.Component {

    render() {
        const {value} = this.props;
        let name = (value)?"abs-icon abs_check abs-boolean-icon abs-boolean-icon-positive":"abs-icon abs_close abs-boolean-icon abs-boolean-icon-negative";

        return (
            <span className="abs-boolean">
                <i className={name} />
            </span>
        )
    }

    static get propTypes() {
        return {
            value: PropTypes.bool
        }
    }
}
