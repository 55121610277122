import fwUrlUtils from "../utils/fwUrlUtils";
import RestService from "./RestService";

const CSRF_URL = fwUrlUtils.getContextUrl("/csrf");

export default class RestUtils {

    static getCsrfHeader() {
        const csrf = document.querySelector('meta[name="_csrf"]');
        const csrfContent = csrf && csrf.getAttribute("content");

        const csrfHeader = document.querySelector('meta[name="_csrf_header"]');
        const csrfHeaderContent = csrfHeader && csrfHeader.getAttribute("content");

        return {
            [csrfHeaderContent]: csrfContent
        }
    }

    static getCsrf() {
        return RestService.getJSON(CSRF_URL);
    }

    static isResponse4xx(status) {
        return status >= 400 && status <= 499;
    }
}
