import React from "react";
import {SingleSelectFilterComponent} from "assecobs-react-data-table/index";
import {fwUrlUtils, I18nMessage, Link, Money, RestService} from "assecobs-faktor-web-common/index";
import {Icon} from "@assecobs/react-common-components/index";
import BaseAgreementSingleOptionTemplate from "../filters/baseAgreement/BaseAgreementSingleOptionTemplate";
import BaseAgreementSingleSelectedTemplate from "../filters/baseAgreement/BaseAgreementSingleSelectedTemplate";
import baseAgreementFilterParams from "../filters/baseAgreement/baseAgreementFilterParams";
import _ from "lodash";
import {injectIntl} from 'react-intl';

const URL = fwUrlUtils.getApiUrl("/webapi/filter/baseAgreements/all");

const DetailsLink = ({intl, selectedBaseAgreement, showDetailsLink}) => {

    if (showDetailsLink) {
        return (
            <div className="form-select-additional-info">
                {selectedBaseAgreement && [
                    <div key={0} className="abs-icon-link">
                        <Icon name="abs_launch"/>
                        <Link id="baseAgreementDetailsLink"
                              text={intl.formatMessage({id: "fw.agreement.showDetails"})}
                              url={selectedBaseAgreement.baseAgreementLink}
                              openInNewTab
                              external/>
                    </div>,
                    <div key={1} className="clearfix"/>
                ]}
            </div>
        )
    }

    return null;
};

const CurrentLimit = ({selectedBaseAgreement, showCurrentLimit}) => {

    if (showCurrentLimit) {
        return (
            <div className="form-select-additional-info">
                {selectedBaseAgreement && [
                    <span key={0} className="light-txt"><I18nMessage id="fw.limit.current"/>: </span>,
                    <Money key={1} amount={selectedBaseAgreement.limit} currency={selectedBaseAgreement.currency}/>
                ]}
            </div>
        )
    }

    return null;
};

class BaseAgreementSimpleForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: null,
            loading: false,
            initialValue: null,
            selectedBaseAgreement: null
        }
    }

    componentDidMount = async () => {

        await this.setState({
            loading: true
        }, async () => {

            const baseAgreements = await RestService.postJSON(this.props.url || URL);

            await this.setState({
                options: baseAgreements,
                loading: false
            });

            if (_.isEqual(baseAgreements.length, 1)) {
                this.props.input.onChange(baseAgreements[0].baseAgreementAnnex0Id);
                this.props.onFieldValueChange(baseAgreements[0]);
            }

        });

    };

    onChange = (baseAgreement) => {
        this.setState({selectedBaseAgreement: baseAgreement});

        if (!_.isNil(baseAgreement)) {
            this.props.input.onChange(baseAgreement.baseAgreementAnnex0Id);
            this.props.onFieldValueChange(baseAgreement);
        }
    };

    getLoading = () => {
        if (_.isNil(this.props.loading)) {
            return this.state.loading;
        }
        return this.props.loading || this.state.loading;
    }

    render() {
        const {showDetailsLink, showCurrentLimit, intl, input, disabled} = this.props;
        const loading = this.getLoading();

        return (
            <div>
                <SingleSelectFilterComponent
                    {...baseAgreementFilterParams}
                    customClass="fw-select--double-sized"
                    initialValue={(input && input.value) ? input.value : undefined}
                    title={intl.formatMessage({id: "fw.select.placeholder"})}
                    searchPlaceholder={intl.formatMessage({id: "fw.filter.baseAgreement.searchPlaceholder"})}
                    options={this.state.options}
                    loading={loading}
                    clearable={false}
                    onChange={this.onChange}
                    optionTemplate={BaseAgreementSingleOptionTemplate}
                    selectedValueTemplate={BaseAgreementSingleSelectedTemplate}
                    disabled={disabled}
                />
                <DetailsLink intl={intl} selectedBaseAgreement={this.state.selectedBaseAgreement}
                             showDetailsLink={showDetailsLink}/>
                <CurrentLimit selectedBaseAgreement={this.state.selectedBaseAgreement}
                              showCurrentLimit={showCurrentLimit}/>
            </div>
        );
    }
}

export default injectIntl(BaseAgreementSimpleForm);
