import ToastFactory from '@assecobs-react-components/toaster/src/toaster'
import {TOAST_BRAND} from "@assecobs-react-components/toaster/src/models/ToastBrandEnum"
import {FW_TOAST_BRAND} from "./enums/FWToastBrandEnum"
import {INVOICE_ADDING_TOAST} from './definitions/InvoiceAddingToast'
import {INVOICE_EDITING_TOAST} from './definitions/InvoiceEdittingToast'
import {INVOICE_ADDING_INFO_TOAST} from './definitions/InvoiceAddingInfoToast'
import {CORRECTIVE_ADDING_TOAST} from './definitions/CorrectiveAddingToast'
import {CORRECTIVE_EDITING_TOAST} from './definitions/CorrectiveEditingToast'
import {INVOICE_CONFIRMATION_TOAST} from './definitions/InvoiceConfirmationToast'
import {INVOICE_FOR_FINANCING_TOAST} from './definitions/InvoiceForFinancingToast'
import {SEND_ATTACHMENT_TOAST} from './definitions/SendAttachmentToast'
import {SEND_ATTACHMENTS_TOAST} from './definitions/SendAttachmentsToast'
import {SEND_ATTACHMENT_ERROR_TOAST} from './definitions/SendAttachmentErrorToast'
import {SEND_ATTACHMENTS_ERROR_TOAST} from './definitions/SendAttachmentsErrorToast'
import {SETTING_SENT_TOAST} from './definitions/SettingSentToast'
import {INFO_TOAST} from './definitions/InfoToast'
import {ERROR_TOAST} from './definitions/ErrorToast'
import {PACKAGE_SEND_TOAST} from './definitions/PackageSendToast'
import {PACKAGE_SAVE_TOAST} from './definitions/PackageSaveToast'
import {PACKAGE_SAVE_INFO_TOAST} from './definitions/PackageSaveInfoToast'
import {INVALID_ATTACHMENT_SIZE_TOAST} from './definitions/InvalidAttachmentSizeToast'
import {INVALID_ATTACHMENT_TYPE_TOAST} from './definitions/InvalidAttachmentTypeToast'
import {PASSWORD_CHANGED_TOAST} from './definitions/PasswordChangedToast'
import {REQUEST_SENT_TOAST} from './definitions/RequestSentToast'
import {ORDER_REPORT_TOAST} from './definitions/OrderReportToast'
import ToastInfoTemplate from "./templates/ToastInfoTemplate"
import ToastDangerTemplate from "./templates/ToastDangerTemplate"
import ToastInfoForInfoTemplate from "./templates/ToastInfoForInfoTemplate"
import ToastInfoForPackageSendTemplate from "./templates/ToastInfoForPackageSendTemplate"
import ToastInfoForSettingSentTemplate from "./templates/ToastInfoForSettingSentTemplate"
import ToastInfoForSendAttachmentTemplate from "./templates/ToastInfoForSendAttachmentTemplate"
import ToastInfoForSendAttachmentsTemplate from "./templates/ToastInfoForSendAttachmentsTemplate"
import ToastInfoForCorrectiveAddingTemplate from "./templates/ToastInfoForCorrectiveAddingTemplate";
import ToastInfoForCorrectiveEditingTemplate from "./templates/ToastInfoForCorrectiveEditingTemplate";
import ToastInfoForInvoiceAddingInfoTemplate from "./templates/ToastInfoForInvoiceAddingInfoTemplate";
import ToastInfoForInvoiceAddingTemplate from "./templates/ToastInfoForInvoiceAddingTemplate";
import ToastInfoForInvoiceConfirmationTemplate from "./templates/ToastInfoForInvoiceConfirmationTemplate";
import ToastInfoForPasswordChangedTemplate from "./templates/ToastInfoForPasswordChangedTemplate";
import ToastInfoForPackageSaveTemplate from "./templates/ToastInfoForPackageSaveTemplate";
import ToastInfoForInvoiceForFinancingTemplate from "./templates/ToastInfoForInvoiceForFinancingTemplate";
import ToastInfoForPackageSaveInfoTemplate from "./templates/ToastInfoForPackageSaveInfoTemplate";
import ToastDangerErrorTemplate from "./templates/ToastDangerErrorTemplate";
import ToastInfoForOrderReportTemplate from "./templates/ToastInfoForOrderReportTemplate";
import ToastInfoForRequestSentTemplate from "./templates/ToastInfoForRequestSentTemplate";
import ToastInfoForInvoiceEdittingTemplate from "./templates/ToastInfoForInvoiceEdittingTemplate";
import ToastDangerInvalidAttachmentSizeTemplate from "./templates/ToastDangerInvalidAttachmentSizeTemplate";
import ToastDangerInvalidAttachmentTypeTemplate from "./templates/ToastDangerInvalidAttachmentTypeTemplate";
import ToastDangerSendAttachmentErrorTemplate from "./templates/ToastDangerSendAttachmentErrorTemplate";
import ToastDangerSendAttachmentsErrorTemplate from "./templates/ToastDangerSendAttachmentsErrorTemplate";

const customDefinition = {
    [TOAST_BRAND.INFO]: ToastInfoTemplate,
    [TOAST_BRAND.DANGER]: ToastDangerTemplate,
    [FW_TOAST_BRAND.INFO_FOR_INFO]: ToastInfoForInfoTemplate,
    [FW_TOAST_BRAND.INFO_FOR_SETTING_SENT]: ToastInfoForSettingSentTemplate,
    [FW_TOAST_BRAND.INFO_FOR_PACKAGE_SEND]: ToastInfoForPackageSendTemplate,
    [FW_TOAST_BRAND.INFO_FOR_PACKAGE_SAVE]: ToastInfoForPackageSaveTemplate,
    [FW_TOAST_BRAND.INFO_FOR_PACKAGE_SAVE_INFO]: ToastInfoForPackageSaveInfoTemplate,
    [FW_TOAST_BRAND.INFO_FOR_SEND_ATTACHMENT]: ToastInfoForSendAttachmentTemplate,
    [FW_TOAST_BRAND.INFO_FOR_SEND_ATTACHMENTS]: ToastInfoForSendAttachmentsTemplate,
    [FW_TOAST_BRAND.INFO_FOR_CORRECTIVE_ADDING]: ToastInfoForCorrectiveAddingTemplate,
    [FW_TOAST_BRAND.INFO_FOR_CORRECTIVE_EDITING]: ToastInfoForCorrectiveEditingTemplate,
    [FW_TOAST_BRAND.INFO_FOR_INVOICE_ADDING_INFO]: ToastInfoForInvoiceAddingInfoTemplate,
    [FW_TOAST_BRAND.INFO_FOR_INVOICE_ADDING]: ToastInfoForInvoiceAddingTemplate,
    [FW_TOAST_BRAND.INFO_FOR_INVOICE_EDITING]: ToastInfoForInvoiceEdittingTemplate,
    [FW_TOAST_BRAND.INFO_FOR_INVOICE_CONFIRMATION]: ToastInfoForInvoiceConfirmationTemplate,
    [FW_TOAST_BRAND.INFO_FOR_INVOICE_FOR_FINANCING]: ToastInfoForInvoiceForFinancingTemplate,
    [FW_TOAST_BRAND.INFO_FOR_PASSWORD_CHANGED]: ToastInfoForPasswordChangedTemplate,
    [FW_TOAST_BRAND.INFO_FOR_ORDER_REPORT]: ToastInfoForOrderReportTemplate,
    [FW_TOAST_BRAND.INFO_FOR_REQUEST_SENT]: ToastInfoForRequestSentTemplate,
    [FW_TOAST_BRAND.DANGER_FOR_ERROR_TOAST]: ToastDangerErrorTemplate,
    [FW_TOAST_BRAND.DANGER_FOR_INVALID_ATTACHMENT_SIZE]: ToastDangerInvalidAttachmentSizeTemplate,
    [FW_TOAST_BRAND.DANGER_FOR_INVALID_ATTACHMENT_TYPE]: ToastDangerInvalidAttachmentTypeTemplate,
    [FW_TOAST_BRAND.DANGER_FOR_SEND_ATTACHMENT_ERROR]: ToastDangerSendAttachmentErrorTemplate,
    [FW_TOAST_BRAND.DANGER_FOR_SEND_ATTACHMENTS_ERROR]: ToastDangerSendAttachmentsErrorTemplate,
};

const {
    toast,
    ToastContainer,
    removeToast
} = ToastFactory(customDefinition)

export {
    toast,
    ToastContainer,
    removeToast,
    INVOICE_ADDING_TOAST,
    INVOICE_EDITING_TOAST,
    INVOICE_ADDING_INFO_TOAST,
    CORRECTIVE_ADDING_TOAST,
    CORRECTIVE_EDITING_TOAST,
    INVOICE_CONFIRMATION_TOAST,
    INVOICE_FOR_FINANCING_TOAST,
    SEND_ATTACHMENT_TOAST,
    SEND_ATTACHMENTS_TOAST,
    SEND_ATTACHMENT_ERROR_TOAST,
    SEND_ATTACHMENTS_ERROR_TOAST,
    SETTING_SENT_TOAST,
    INFO_TOAST,
    ERROR_TOAST,
    PACKAGE_SEND_TOAST,
    PACKAGE_SAVE_TOAST,
    PACKAGE_SAVE_INFO_TOAST,
    INVALID_ATTACHMENT_SIZE_TOAST,
    INVALID_ATTACHMENT_TYPE_TOAST,
    PASSWORD_CHANGED_TOAST,
    REQUEST_SENT_TOAST,
    ORDER_REPORT_TOAST
}