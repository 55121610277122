import React, {Component} from "react";
import PropTypes from "prop-types";
import {browserHistory} from "react-router";
import I18nMessage from "../i18n/I18nMessage";
import _ from "lodash";
import classnames from "classnames";
import permission from "@assecobs/react-utils/permission";

class TabTitle extends React.Component {

    static propTypes = {
        label: PropTypes.any,
        icon: PropTypes.string,
        itemCount: PropTypes.number
    };

    render() {
        const {label, icon, itemCount} = this.props;

        const tabTitleLabel = _.isString(label) ? <I18nMessage id={label}/> : label;

        return (
            <div>
                <div className="tab-title-value">{icon && <i className={"abs-icon " + icon}/>} {tabTitleLabel}</div>
                {(itemCount || itemCount === 0) && <div className="tab-title-additional-info"><I18nMessage
                    id="fw.quantity">Ilość</I18nMessage>: {itemCount}</div>}
            </div>
        )
    }

}

export default class Tab extends Component {

    static propTypes = {
        to: PropTypes.string,
        context: PropTypes.object,
        onlyActiveOnIndex: PropTypes.bool,
        label: PropTypes.any,
        icon: PropTypes.string,
        itemCount: PropTypes.number,
        alignRight: PropTypes.bool
    };

    static defaultProps = {
        alignRight: false
    };

    // pull in the router context
    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    handleReplaceClick = (toRoute) => {
        if (!permission.has("ROLE_ANONYMOUS"))
            browserHistory.replace(toRoute);
        else
            window.location.href = this.props.context + "/logout";
    };

    render() {
        const {to, context, label, icon, onlyActiveOnIndex, itemCount, customClassName} = this.props;
        const path = context.path + "/" + to;

        // determine if the route is active, router.isActive function
        // receives the "to" and onlyActiveOnIndex props
        const isActive = this.context.router.isActive(path, onlyActiveOnIndex);

        // add the bootstrap active class to the active links containing <li>
        let className = classnames(customClassName, {
            "active": isActive,
            "abs-pull-right-margin": this.props.alignRight
        });

        return (
            <li className={className}>
                <a onClick={() => this.handleReplaceClick({pathname: path, query: context.query})}>
                    <TabTitle label={label} icon={icon} itemCount={itemCount}/>
                </a>
            </li>
        );
    }
}

