import {DefinitionReturn} from "../types/DefinitionReturn";
import {FW_TOAST_BRAND} from "../enums/FWToastBrandEnum";
// @ts-ignore
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty';

type SendAttachmentToastType = {
    fileName: string,
    contextNumber: string,
    refresh: boolean
}

export const SEND_ATTACHMENT_TOAST = (params: SendAttachmentToastType): DefinitionReturn => ({
    templateProps: params,
    timeOut: 6000,
    refresh: !isNullOrEmpty(params.refresh) ? params.refresh : false,
    type: FW_TOAST_BRAND.INFO_FOR_SEND_ATTACHMENT
})