import React from "react";
import {connect} from "react-redux";
import {actionCreators} from "assecobs-react-data-table/index";

const clearSelection = Symbol();
const reload = Symbol();
const filter = Symbol();
const doExportDataXLS = Symbol();
const doExportDataCSV = Symbol();

export default (dataTableId) => {

    return (WrappedDataTableComponent) => {
        class EnhancedDataTableComponent extends React.Component {

            constructor(props) {
                super(props);
                const actions = actionCreators(dataTableId, props.filteringEnabled, props.pagingEnabled, props.orderingEnabled);
                this[clearSelection] = actions.clearSelection;
                this[reload] = actions.reload;
                this[filter] = actions.filter;
                this[doExportDataXLS] = actions.doExportDataXLS;
                this[doExportDataCSV] = actions.doExportDataCSV;
            }

            clearSelection = () => {
                this.props.dispatch(this[clearSelection]());
            };

            reload = () => {
                this.props.dispatch(this[reload]());
            };

            filter = (filtering) => {
                this.props.dispatch(this[filter](filtering));
            };

            doExportDataXLS = () => {
                this.props.dispatch(this[doExportDataXLS]());
            };

            doExportDataCSV = () => {
                this.props.dispatch(this[doExportDataCSV]());
            };

            render() {
                const injectedProps = {
                    reload: this.reload,
                    filter: this.filter,
                    clearSelection: this.clearSelection,
                    doExportDataXLS: this.doExportDataXLS,
                    doExportDataCSV: this.doExportDataCSV
                };

                if (React.Component.isPrototypeOf(WrappedDataTableComponent) || React.PureComponent.isPrototypeOf(WrappedDataTableComponent))
                    return <WrappedDataTableComponent {...this.props} {...injectedProps}/>;

                return WrappedDataTableComponent({...this.props, ...injectedProps});

            }
        }

        return connect(state => ({
            selected: state[dataTableId].selected
        }))(EnhancedDataTableComponent)
    }
}
