import React from "react";
import {Collapse} from "react-bootstrap";
import CustomCell from "../common/CustomCell";
import MediaQuery from "react-responsive";
import {injectIntl} from 'react-intl';
import classnames from "classnames";
import {Icon} from "@assecobs/react-common-components/index";
import _ from "lodash";

class ExpandableListDataRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mobile: false
        }
    }

    componentDidMount() {
        if (window.innerWidth < 768) {
            this.setState({mobile: true});
        }
        window.addEventListener("resize", this.checkMobileMode);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkMobileMode);
    }

    checkMobileMode = () => {
        if (window.innerWidth < 768) {
            this.setState({mobile: true});
        } else {
            this.setState({mobile: false});
        }
    };

    toggle = () => {
        if (!this.state.open) {
            this.props.onExpand(this.props.id);
        }
        this.setState((prevState) => ({open: !prevState.open}));
    };

    onOneSelectionChange = (id) => {
        this.props.onOneSelectionChange(id);
    };

    render() {
        const {columns, data, multiselect, disabled, selected, id, expandable, menu, SlaveComponent, loadingSlaves, detailsLink} = this.props;

        const cols = columns.map((column, index) => {
            let dataLabel = "";

            if (_.isString(column.header) && column.header) {
                if (!_.isNil(column.header) && !_.isEmpty(column.header)) {
                    dataLabel = this.props.intl.formatMessage({id: column.header});
                }
            } else {
                dataLabel = column.header(data);
            }

            const cell = (
                <CustomCell key={`${column.id}-${id}`}
                            first={index === 0}
                            id={id}
                            label={dataLabel}
                            multiselect={multiselect}
                            disabled={disabled}
                            selected={selected}
                            {...(index === 0 && {onOneSelectionChange: () => this.onOneSelectionChange(id)})}
                            {...(menu && {menu: menu(data)})}
                            className={classnames(column.className, column.width ? "col-fr-" + column.width : null)}
                            expandable={expandable}
                            {...(detailsLink && {detailsLink: detailsLink(data)})}
                >
                    {column.cell(data)}
                </CustomCell>
            );

            if (column.mobileOnly) {
                return (
                    <MediaQuery key={`${column.id}-${id}`} maxWidth={767}>
                        {cell}
                    </MediaQuery>
                )
            }

            if (column.customMobileCell) {
                return <MediaQuery key={`${column.id}-${id}`} minWidth={768}>
                    {(matches) => {
                        if (matches)
                            return cell;
                        return column.customMobileCell(data);
                    }}
                </MediaQuery>
            }

            return cell;
        });

        if (expandable) {
            const isLoading = loadingSlaves.has(id);

            return (
                <div className="collapse-container">
                    <div
                        className={classnames("collapse-row abs-list-table_row abs-list-table_row--border", {"loader-mask-style-b": isLoading})}
                        {...(expandable && !this.state.mobile && {onClick: this.toggle})}
                        aria-expanded={this.state.open && !isLoading}
                    >
                        {cols}
                    </div>

                    <MediaQuery maxWidth={767}>
                        <div
                            className={classnames("abs-row-expandable-zone", {"abs-row-expandable-zone--expanded": this.state.open})}
                            onClick={this.toggle}>
                            <Icon name={!this.state.open ? "abs_expand_more" : "abs_expand_less"}
                                  className="abs-row-expandable-zone__arrow"/>
                        </div>
                    </MediaQuery>

                    <Collapse in={this.state.open && !isLoading}>
                        <div>
                            <SlaveComponent id={id} open={this.state.open}/>
                        </div>
                    </Collapse>
                </div>
            );
        }

        return (
            <div className="abs-list-table_row abs-list-table_row--border abs-list-row">
                {cols}
            </div>
        )
    }
}

export default injectIntl(ExpandableListDataRow);
