import React from "react";
import CollapsibleMenu from "./items/CollapsibleMenu";
import NonCollapsibleMenu from "./items/NonCollapsibleMenu";
import Navbar from "./Navbar";
import {connect} from "react-redux";
import menuStorage from "../application/menu/menu";
import {fwUrlUtils} from "assecobs-faktor-web-common/index";

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menu: menuStorage.menu, alertsOpen: false, quickSearchOpen: false};
    }

    alertsToggle = () => {
        this.setState((prevState) => ({
            alertsOpen: !prevState.alertsOpen
        }));
    };

    quickSearchToggle = () => {
        this.setState((prevState) => ({
            quickSearchOpen: !prevState.quickSearchOpen
        }));
    };

    render() {
        return (
            <div className="container-menu">
                <div className="container-nav">
                    <Navbar className="abs-menu abs-menu-type-a">
                        <Navbar.Header>
                            <Navbar.Brand>
                                <a href={fwUrlUtils.getApiUrl("/dashboard")}>
                                    <div className="navbar-logo"/>
                                </a>
                            </Navbar.Brand>
                            <Navbar.Toggle/>
                        </Navbar.Header>

                        <NonCollapsibleMenu items={this.state.menu.nonCollapsible}
                                            alertsOpen={this.state.alertsOpen}
                                            onAlertsToggle={this.alertsToggle}
                                            unreadAlertsExists={this.props.unreadAlertsExists}
                                            quickSearchOpen={this.state.quickSearchOpen}
                                            onQuickSearchToggle={this.quickSearchToggle}
                        />
                        <CollapsibleMenu items={this.state.menu.collapsible} />

                    </Navbar>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    unreadAlertsExists: state.alerts.unreadCount > 0
}))(Menu);
