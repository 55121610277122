import TaxNumberAndCode from "../../dataPresentation/TaxNumberAndCode";
import * as React from "react";

type Option = { clientId: number, clientShortName: string, clientTaxNumberTypeDesc: string, clientTaxNumber: number }

interface ClientOptionTemplateProps {
    option: Option,
    selectedIds: Array<number>
}

export default ({option, selectedIds}: ClientOptionTemplateProps): React.ReactElement => {
    const checked = selectedIds.indexOf(option.clientId) > -1;

    return (
        <label className="r-ss-dropdown-multiselect-option" htmlFor={"client-" + option.clientId}>
            <input id={"client-" + option.clientId} className="toggle toggle-flip" checked={checked}
                   type="checkbox"/>
            <label htmlFor={"client-" + option.clientId}/>
            <div>
                <div>
                    <span className="agreement-number-select-data">{option.clientShortName}</span>
                </div>
                <div className="option-additional-info">
                    <TaxNumberAndCode taxNumberTypeDesc={option.clientTaxNumberTypeDesc}
                                      taxNumber={option.clientTaxNumber}
                    />
                </div>
            </div>
        </label>
    );
};
