import React from "react";
import classnames from "classnames";
import _ from "lodash";
import {Collapse} from "react-bootstrap";
import Icon from "@assecobs/react-common-components/icon/Icon";
import {I18nMessage} from "assecobs-faktor-web-common";

export default class BarComponent extends React.Component {

    constructor() {
        super();
        this.state = {collapsed: false};
    }

    toggleBar = () => {
        this.setState(prevState => ({collapsed: !prevState.collapsed}));
    };

    render() {

        const {titleKey, children, barInfo} = this.props;

        return (
            <div className={classnames("abs-table-bar", {
                "abs-table-bar--collapsed": this.state.collapsed,
                "abs-table-bar--show-active": !_.isNil(barInfo) && !this.state.collapsed
            })}>
                <div className="abs-table-bar__main-panel" onClick={this.toggleBar}>
                    <I18nMessage className="abs-table-bar__title" id={titleKey}/>
                    <div className="abs-table-bar__main-panel__info">
                        {barInfo && barInfo}
                        <Icon name="abs_expand_more abs-rotate"/>
                    </div>
                </div>
                <Collapse in={this.state.collapsed} >
                    <div className="abs-table-bar__children-panel">
                        {children}
                    </div>
                </Collapse>
            </div>
        )
    }

}