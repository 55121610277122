import React from "react";
import PropTypes from "prop-types";

const Header = ({buttonTypeClassName, label, onClick}) => {
    return (
        <button className={`btn btn-xs ${buttonTypeClassName}`} type="button" data-toggle="dropdown" onClick={onClick}>
            <span className="name">{label}</span>
            <span className="abs-icon abs_expand_more abs-rotate"/>
        </button>
    );
};

Header.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default Header;