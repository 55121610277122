import DynamicModuleAbstract from "./DynamicModuleAbstract";
import storageManager from "../../storageManager/storageManager";

class ConfigParamsModule extends DynamicModuleAbstract {

    async loadDynamicData() {}

    getModuleData() {
        return storageManager.getLocalStorageItem();
    }

    isNeedUpdate(storageMessages) {
        return storageManager.isNeedUpdateItem(storageMessages, "config");
    }
}

export default new ConfigParamsModule();
