import React from 'react'

interface useTileProps {
    onChangeValue?: (value: boolean) => void,
    value: boolean
}

type UseTileReturn = {
    selectedValue: boolean
}

const useTile = ({value, onChangeValue = () => null}: useTileProps): UseTileReturn => {

    const [selectedValue, setSelectedValue] = React.useState(value)

    React.useEffect(() => {
        if (selectedValue !== value) {
            setSelectedValue(value)
        }
    }, [value])

    React.useEffect(() => {
        if (selectedValue !== value) {
            onChangeValue(selectedValue)
        }
    }, [selectedValue])

    return {selectedValue}
}

export {useTile}