import React from "react";
import {Link, RelativeTime} from "assecobs-faktor-web-common/index";
import moment from "moment";
import _ from "lodash";
import ParsableText from "../parsableText/ParsableText";

const AlertHeader = (props) => (
    <div className="fw-alert__header">
        <button className="btn btn-type-d abs-btn-icon fw-alert__back-to-list" onClick={props.backToList}>
            <span className="abs-icon abs-btn-icon_icon abs_keyboard_arrow_left2"/>
        </button>

        <div className="fw-alert__title">
            <div className="fw-alert__name">{props.name}</div>
            <div className="fw-alert__date" title={props.formattedDateTime}>
                <RelativeTime value={props.date} relativeTimeThresholdSeconds={props.relativeTimeThresholdSeconds}/>
            </div>
            <div className="fw-alert__text">{props.header}</div>
        </div>
    </div>
);

const AlertBody = (props) => (
    <div className="fw-alert__body">
        {props.attributes.map((attr, index) => (
            <AlertAttribute key={`alertAttr-${index}`}
                            name={attr.get("name")}
                            values={attr.get("values")}
            />
        ))}

        {props.links.map((link, index) => (
            <AlertLink key={`alertLink-${index}`}
                       id={`alertLink-${index}`}
                       text={link.get("text")}
                       url={link.get("url")}
            />
        ))}
    </div>
);

const AlertAttribute = (props) => (
    <div className="fw-alert__attribute">
        <div className="fw-alert__attribute-name">{props.name}</div>
        {props.values.map((attrValue, index) => (
            <div key={`alertAttrVal-${index}`} className="fw-alert__attribute-value">
                {attrValue.get("url") ? <Link id={props.id}
                                              text={attrValue.get("value")}
                                              url={attrValue.get("url")}
                                              external
                /> : attrValue.get("value")}
            </div>
        ))}
    </div>
);

const AlertLink = (props) => (
    <div className="fw-alert__link">
        <Link id={props.id}
              text={props.text}
              url={props.url}
              external
        />
    </div>
);

export default class AlertDetails extends React.Component {

    backToList = () => {
        this.props.backToList();
    };

    replaceTag = (source, toReplace, replacer) => {
        const index = _.indexOf(source, toReplace);
        source.splice(index, 1, replacer);
    };

    render() {
        const {alert} = this.props;

        const formattedDateTime = moment(alert.get("date")).format("YYYY-MM-DD HH:mm:SS");
        const alertHeader = alert.get("header");
        const headerTextParts = <ParsableText text={alertHeader.get("text")} metadata={alertHeader.get("metadata")} />;

        return (
            <div className="fw-alert">
                <AlertHeader name={alert.get("name")}
                             date={alert.get("date")}
                             header={headerTextParts}
                             backToList={this.backToList}
                             formattedDateTime={formattedDateTime}
                             relativeTimeThresholdSeconds={24 * 60 * 60}
                />

                <AlertBody attributes={alert.get("attributes")}
                           links={alert.get("links")}
                />
            </div>
        )
    }
}
