import React from "react";
import _ from "lodash";
import classnames from "classnames";
import {I18nMessage} from "assecobs-faktor-web-common";
import Icon from "@assecobs/react-common-components/icon/Icon";

const ASC = "ASC";
const DESC = "DESC";

class BarSortingItem extends React.PureComponent {

    onSortDesc = () => {
        this.props.onSortDesc(this.props.index);
    };

    onSortAsc = () => {
        this.props.onSortAsc(this.props.index);
    };

    render() {

        const {titleKey, upArrowActive, downArrowActive} = this.props;

        const activeItem = upArrowActive || downArrowActive;

        return (
            <div className={classnames("abs-bar-item", {"abs-bar-item--active": activeItem})}>
                <I18nMessage id={titleKey} className="abs-bar-item__title"/>
                <div className="abs-bar-item__arrows">
                    <div
                        className={classnames("abs-bar-item__arrows__arrow", {"abs-bar-item__arrows__arrow--active": downArrowActive})}
                        onClick={this.onSortDesc}>
                        <Icon name="abs_arrow_downward"/>
                    </div>
                    <div
                        className={classnames("abs-bar-item__arrows__arrow", {"abs-bar-item__arrows__arrow--active": upArrowActive})}
                        onClick={this.onSortAsc}>
                        <Icon name="abs_arrow_upward"/>
                    </div>
                </div>
            </div>
        )
    }
}

const currentActiveIndex = Symbol();
const currentActiveArrow = Symbol();

export default class BarSortingComponent extends React.Component {

    constructor() {
        super();
        this.state = {columns: []};
        this[currentActiveIndex] = null;
        this[currentActiveArrow] = null;
    }

    componentDidMount() {
        if (!_.isNil(this.props.columns)) {
            this.setState({columns: [...this.props.columns]});
        }
    }

    onSort = (id, direction, columnNameKey) => {

        const sorting = {};
        sorting[id] = direction;
        this.props.onSort(sorting, columnNameKey);

    };

    onSortDirection = (index, activeArrow, direction) => {

        const arrayTemp = [...this.state.columns];

        if (!_.isNil(this[currentActiveIndex])) {
            if (!_.isEqual(this[currentActiveIndex], index) || !_.isEqual(this[currentActiveArrow], activeArrow)) {
                arrayTemp[this[currentActiveIndex]][this[currentActiveArrow]] = false;
            }
        }

        if (!arrayTemp[index][activeArrow]) {
            arrayTemp[index][activeArrow] = true;
            this[currentActiveIndex] = index;
            this[currentActiveArrow] = activeArrow;
            this.setState({columns: [...arrayTemp]}, () => {
                this.onSort(this.state.columns[index].id, direction, this.state.columns[index].header);
            });
        } else {
            arrayTemp[index][activeArrow] = false;
            this[currentActiveIndex] = null;
            this[currentActiveArrow] = null;
            this.setState({columns: [...arrayTemp]}, () => {
                this.onSort(this.state.columns[index].id);
            });
        }

    };

    onSortAsc = (index) => {
        this.onSortDirection(index, "upArrowActive", ASC);
    };

    onSortDesc = (index) => {
        this.onSortDirection(index, "downArrowActive", DESC);
    };

    render() {

        const {columns} = this.state;

        return (
            <div className="abs-filter-panel abs-filter-panel--sortable">
                <div className="abs-filter-panel__filters">
                    <div className="abs-filter-panel__main-filters">
                        {_.map(columns, (data, index) => {
                            return <BarSortingItem titleKey={data.header} key={`item_${index}`}
                                                   index={index} upArrowActive={data.upArrowActive}
                                                   downArrowActive={data.downArrowActive}
                                                   onSortDesc={this.onSortDesc}
                                                   onSortAsc={this.onSortAsc}/>;
                        })}
                    </div>
                </div>
            </div>
        )
    }

};