import React from "react";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import _ from "lodash";

const EMPTY_FILE = -1;
const UNCHECKED = 0
const CHECKED = 1

export default class PositiveNumberWithCheckboxInputField extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {checked: true, refresh: true};
    }

    componentDidMount = () => {
        if (!_.isNil(this.props.input.value) && (this.props.input.value.isValue === EMPTY_FILE)) {
            this.setState({checked: false});
        }
    }

    componentDidUpdate = () => {
        if (!_.isNil(this.props.input.value) && this.props.input.value.isValue === EMPTY_FILE) {
            this.props.input.onChange({isValue: undefined, isCheck: this.props.input.value.isCheck});
            if (this.state.refresh) {
                this.setState({
                    checked: false,
                    refresh: false
                });
            }

        }
    }

    onCheckboxChange = (event) => {
        this.onChangeReplaceObject(EMPTY_FILE, event.target.checked ? CHECKED : UNCHECKED);
        this.props.changeOfState(event.target.checked);
        this.setState({
            checked: event.target.checked,
            refresh: false
        });
    }

    onChange = (value) => {
        const newValue = (isNaN(value.value) ? undefined : value.value) || undefined;
        this.props.input.onChange({isValue: newValue, isCheck: this.props.isCheckbox ? CHECKED : UNCHECKED});
    };

    onChangeReplaceObject = async (value, check) => {
        await this.props.input.onChange({isValue: value, isCheck: check});
    }

    render() {
        const {input, invalid, maxLength, isCheckbox} = this.props;
        const value = _.isNil(input.value.isValue) || _.isEqual(input.value.isValue, EMPTY_FILE) ? '' : input.value.isValue;

        return (
            <div className="abs-settings-format-fields">
                <NumberFormat
                    className={classnames("abs-money-input-field", {
                        "invalid-positive-number": invalid
                    })}
                    onValueChange={this.onChange}
                    value={value}
                    decimalScale={0}
                    allowNegative={false}
                    maxLength={maxLength}
                    disabled={!this.state.checked}
                    isAllowed={val => {
                        return !(val.value === '0')
                    }}
                />

                {isCheckbox &&
                <div>
                    <input
                        id={input.name}
                        type="checkbox"
                        className="abs-settings-format-fields-checkbox-content"
                        checked={this.state.checked}
                        onChange={this.onCheckboxChange}
                    />
                    <label htmlFor={input.name} className="abs-settings-format-fields-label"></label>
                </div>}
            </div>
        )
    }
}
