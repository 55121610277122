import React from "react";
import {MultiSelectFilter, AbstractSelectFilter} from "assecobs-react-data-table/index";
import {createFilteringParams} from "assecobs-react-data-table/dataTableUtils";
import contractorFilterParams from "../../contractor/contractorFilterParams";
import _ from "lodash";
import {RestService, fwUrlUtils} from "assecobs-faktor-web-common/index";
import {List} from "immutable";

import {injectIntl} from "react-intl";

import ContractorMultiOptionTemplate from "../../contractor/ContractorMultiOptionTemplate";
import {formValueSelector} from "redux-form";
import PropTypes from "prop-types";
import {connect} from "react-redux";

const loadOptions = Symbol();

const CONTRACTOR_CLIENT_CONTEXT_URL = fwUrlUtils.getApiUrl("/webapi/filter/contractors/contractorClientContext");
const BASE_AGREEMENT_CONTEXT_WITHOUT_DUPLICATIONS_URL = fwUrlUtils.getApiUrl("/webapi/filter/contractors/baseAgreementContextWithoutDuplications");
const isMounted = Symbol();

class ContractorFilterComponent extends AbstractSelectFilter {

    constructor(props) {
        super(props);

        this.state = {
            options: new List(),
            loading: false
        };
        this[isMounted] = false;
    }

    componentDidMount() {
        this[isMounted] = true;
        this[loadOptions]();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.selectedBaseAgreements, prevProps.selectedBaseAgreements) && this.props.filterContractorsByAgreements || !_.isEqual(this.props.filterContractorsByAgreements, prevProps.filterContractorsByAgreements) && this.props.filterContractorsByAgreements) {
            this[loadOptions]();
        } else if (!_.isEqual(this.props.filterContractorsByAgreements, prevProps.filterContractorsByAgreements) && !this.props.filterContractorsByAgreements) {
            this[loadOptions]();
        }
    }

    componentWillUnmount() {
        this[isMounted] = false;
    }

    async [loadOptions]() {

        this.setState({
            loading: true
        }, async () => {

            let contractors = null;

            if (!_.isNil(this.props.selectedBaseAgreements) && !_.isEmpty(this.props.selectedBaseAgreements) && this.props.filterContractorsByAgreements) {

                contractors = await RestService.postJSON(BASE_AGREEMENT_CONTEXT_WITHOUT_DUPLICATIONS_URL, createFilteringParams({
                    baseAgreements: this.props.selectedBaseAgreements
                }));

            } else {
                contractors = await RestService.getJSON(CONTRACTOR_CLIENT_CONTEXT_URL);
            }

            if (this[isMounted]) {
                this.setState({
                    options: contractors,
                    loading: false
                });
            }
        });

    };

    render() {

        const {selectedContractors} = this.props;

        return (
            <MultiSelectFilter
                {...contractorFilterParams}
                {...this.props}
                id={this.props.id || "contractors"}
                initialValue={selectedContractors || undefined}
                title={this.props.intl.formatMessage({id: "fw.contractor"})}
                options={this.state.options}
                loading={this.state.loading}
                optionTemplate={ContractorMultiOptionTemplate}
            />
        )

    }
}

const ContractorFilterComponentWrapper = connect((state, props) => ({
    selectedBaseAgreements: formValueSelector('data', state => state[`${props.filterPanelId}FilterPanel`])(state, 'baseAgreements'),
    selectedContractors: formValueSelector('data', state => state[`${props.filterPanelId}FilterPanel`])(state, 'contractors')
}))(injectIntl(ContractorFilterComponent));

const ContractorFilter = (props, context) => (
    <ContractorFilterComponentWrapper {...props} filterPanelId={context.filterPanelId}/>
);

ContractorFilter.contextTypes = {
    filterPanelId: PropTypes.string
};

export default injectIntl(ContractorFilter);
