import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common";
import {Icon} from "@assecobs/react-common-components/index";

export default ({close}) => {

    return [
        <button key="close_button" type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
            <span aria-hidden="true">
                <Icon name="abs_close"/>
            </span>
        </button>,

        <h4 key="header" className="modal-title" id="myModalLabel1">
            <I18nMessage id="fw.fileRequestPrompt.header.title"/>
        </h4>
    ]
}
