import React from "react";
import ValuesSequence from "../../dataPresentation/ValuesSequence";

export default ({option, selectedIds}) => {
    const checked = selectedIds.indexOf(option.baseAgreementAnnex0Id) > -1;
    return (
        <label className="r-ss-dropdown-multiselect-option" htmlFor={"agreement-" + option.baseAgreementAnnex0Id}>
            <input id={"agreement-" + option.baseAgreementAnnex0Id} className="toggle toggle-flip" type="checkbox"
                   checked={checked}/>
            <label htmlFor={"agreement-" + option.baseAgreementAnnex0Id}/>
            <div>
                <div>
                    <span className="agreement-number-select-item">{option.actualAgreementNo}</span>
                    <span className="currency-select-item"> ({option.currency})</span>
                </div>
                <div className="agreement-additional-info-select-item">
                    <ValuesSequence values={[option.agreementTypeDesc, option.baseAgreementFactoringType]} hint/>
                </div>
            </div>
        </label>
    );
};
