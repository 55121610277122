import React from "react";
import OrderingDirection from "./types/OrderingDirection";
import {Collapse} from "react-bootstrap";

export default class CollapsibleSortingHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isSortingBoxOpen: false};
    }

    toggleSortingBox = () => {
        this.setState({isSortingBoxOpen: !this.state.isSortingBoxOpen});
    }

    onOrderingChange = (key) => {
        this.props.onToggleOrdering(key);
    }

    render() {
        const {columns} = this.props;
        let sortingEnabled = false;

        columns.forEach((column) => {
                if (column.sorting) {
                    sortingEnabled = true;
                    return;
                }
            }
        );

        if (!sortingEnabled) {
            return null;
        }

        const ordering = this.props.ordering ? this.props.ordering.toJS() : undefined;

        return (
            <div className={"box-type-c box-toggleable box-sort " + (this.state.isSortingBoxOpen ? "box-toggle-opened" : "box-toggle-closed")}
                 onClick={this.toggleSortingBox}>
                <div className="box-header" title="Zwiń">
                    <div className="box-icon-square">
                        <div className="box-icon"></div>
                    </div>
                    <div className="box-title">Sortuj po</div>
                    {
                        ordering &&
                        <div className="box-toggleable-headfoot-content-outer">
                            <div className="box-toggleable-headfoot-content-inner">
                                <div className="box-toggleable-headfoot-content">
                                    <ul className="nav nav-pills">
                                        {Object.entries(ordering).map(([key, value], index) =>
                                            <li key={key} role="presentation" className="active"><a><span className="att-filter-value">{columns.filter(col => {
                                                return col.sorting && col.sorting.key === key
                                            })[0].label}</span>
                                                <span className="badge"> <span
                                                    className={"glyphicon " + (value === OrderingDirection.ASC ? "glyphicon-triangle-top" : "glyphicon-triangle-bottom")}></span> </span>
                                            </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className="box-toggleable-headfoot-content-placeholder"></div>
                                <div className="clear"></div>
                            </div>
                        </div>
                    }
                </div>
                <Collapse in={this.state.isSortingBoxOpen}>
                    <div className="box-body ">
                        <div className="box-toggleable-body-outer">
                            <div className="box-toggleable-body-inner row">
                                <div className="col-xs-12">
                                    <ul className="nav nav-pills nav-stacked">
                                        {columns.map((column, index) =>
                                            column.sorting && <li key={index}><a onClick={() => this.onOrderingChange(column.sorting.key)}>{column.label} <i
                                                className={"fa " + (ordering && ordering[column.sorting.key] === OrderingDirection.ASC ? "fa-sort-asc" : (ordering && ordering[column.sorting.key] === OrderingDirection.DESC ? "fa-sort-desc" : ""))}
                                                aria-hidden="true"></i> </a></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }
}
