import React from "react";
import _ from "lodash";
import classnames from "classnames";

export default class PaymentDelay extends React.PureComponent {

    render() {
        return this.props.desc ? <div className={classnames(
            "hint",
            {
                "danger": _.gt(this.props.value, 0)
            },
            this.props.className)}>
            {this.props.desc}
        </div> : null;
    }
}
