import React from "react";
import ValuesSequence from "../../dataPresentation/ValuesSequence";

export default ({option}) => (
    <div className="option-template-view option-template-view--multiline">
        <div className="option-main-value">
            <span>{option.actualAgreementNo} &nbsp;</span>
            <span>({option.currency})</span>
        </div>

        <ValuesSequence values={[option.agreementTypeDesc, option.factoringTypeDesc]} hint/>
    </div>
)
