import React from "react";
import IconWithText from "../dataPresentation/IconWithText";
import Status from "./ConfirmationStatus";
export default class InvoiceCorrectiveStatus extends React.Component {

    render() {
        const {id, text} = this.props;

        let iconClass = "";
        switch (id) {
            case Status.NEW_INVOICE:
            case Status.REQUIRES_CONFIRMATION:
            case Status.PROCEED:
            case Status.DATA_CORRECTED:
                iconClass = "abs_help_outline info-icon";
                break;
            case Status.NOT_CONFIRM:
            case Status.NOT_CONFIRM_INCORRECT_DATA:
                iconClass = "abs_close danger-icon";
                break;
            case Status.CONFIRM:
            case Status.CONFIRMED:
            case Status.CONFIRM_AFTER_IMPROVEMENT:
            case Status.CONFIRMED_BY_FACTOR:
                iconClass = "abs_check success-icon";
                break;
            default:
                iconClass = "abs_close danger-icon";
        }
        return <IconWithText icon={iconClass} text={text} mobile className="invoice-corrective-status-icon"/>
    }

}
