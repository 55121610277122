import React from "react";
import {CustomCalendar} from "assecobs-faktor-web-common/index";
import moment from "moment";
import {DateUtils} from "../../index";
import TodayI18n from "../utils/TodayI18n";
import FromI18n from "../utils/FromI18n";
import ToI18n from "../utils/ToI18n";
import OKI18n from "../utils/OKI18n";
import CancelI18n from "../utils/CancelI18n";
import {I18nMessage, Config} from "assecobs-faktor-web-common/index";
import _ from "lodash";
import classnames from "classnames";
import {Icon} from "@assecobs/react-common-components/index";
import ReactDOM from "react-dom";
import {getApplicationLanguage} from "assecobs-react-utils/utils";

const LANG = getApplicationLanguage();

const isClearable = Symbol();

export default class CustomRangeCalendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            calendarActive: false, dateObj: {}
        };
        this._prevDates = {
            dateFrom: null,
            dateTo: null
        }

    }

    componentDidMount = async () => {
        document.addEventListener('click', this.closeCalendar);
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.closeCalendar);
    }

    componentDidUpdate() {
        if (_.isEqual(this.state.calendarActive, true)) {
            const dropdown = ReactDOM.findDOMNode(this.refs.calendarDropdown).getElementsByClassName('dropdown-menu');
            const rect = dropdown[0].getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            if (rect.bottom > viewHeight) {
                dropdown[0].style.bottom = `${(dropdown[0].parentElement.offsetHeight + 2)}px`;
                dropdown[0].style.top = "auto";
            }
        }
    }

    onDateFromSelect = (date) => {
        this.setState((prevState => ({dateObj: {...prevState.dateObj, dateFrom: date}})));
    };

    onDateToSelect = (date) => {
        this.setState((prevState => ({dateObj: {...prevState.dateObj, dateTo: date}})));
    };

    toggleCalendar = (event) => {
        this.setState((prevState) => ({calendarActive: !prevState.calendarActive}));
        this.stopEvent(event);
    };

    closeCalendar = (event) => {
        if (!_.isEqual(event.target, this.refs.calendarOutput) && this.state.calendarActive) {
            this.toggleCalendar();
        }
    };

    stopEvent = (event) => {
        event.nativeEvent.stopImmediatePropagation();
    };

    disabledEndDate = (endValue) => {
        if (!endValue) {
            return false;
        }
        const startValue = this.state.dateObj.dateFrom;
        if (!startValue) {
            return false;
        }
        return endValue.isBefore(startValue);
    };

    disabledStartDate = (startValue) => {
        if (!startValue) {
            return false;
        }
        const endValue = this.state.dateObj.dateTo;
        if (!endValue) {
            return false;
        }
        return endValue.isBefore(startValue);
    };

    submitDate = (format) => {
        const postFormat = Config.apiDateFormat();
        if (this.state.dateObj.dateFrom && this.state.dateObj.dateTo) {
            this.refs.calendarOutput.value = this.state.dateObj.dateFrom.format(format)
                + "→" + this.state.dateObj.dateTo.format(format);
        }
        else if (this.state.dateObj.dateFrom) {
            this.refs.calendarOutput.value = this.state.dateObj.dateFrom.format(format);
        }
        else if (this.state.dateObj.dateTo) {
            this.refs.calendarOutput.value = this.state.dateObj.dateTo.format(format);
        }
        else
            this.refs.calendarOutput.value = "";

        this.state.calendarActive = false;
        this._prevDates.dateFrom = this.state.dateObj.dateFrom;
        this._prevDates.dateTo = this.state.dateObj.dateTo;
        this.forceUpdate();
        this.props.dateOnChange(this.state.dateObj.dateFrom.format(postFormat), this.state.dateObj.dateTo.format(postFormat));
    };

    clearSelectedDate = () => {

        this.setState({dateObj: {}});
        this.state.calendarActive = false;
        this.refs.calendarOutput.value = "";
        this.forceUpdate();
        this.props.dateOnChange(null);
    };

    cancel = () => {
        this.setState({dateObj: {dateFrom: this._prevDates.dateFrom, dateTo: this._prevDates.dateTo}});
        this.state.calendarActive = false;
    };

    [isClearable]() {
        return this.state.dateObj.dateFrom && this.state.dateObj.dateTo;
    };

    render() {

        const {invalid} = this.props;

        const now = DateUtils.startOfDay(moment(), LANG);
        const then = DateUtils.endOfDay(moment(), LANG);

        const today = <TodayI18n i18n={this.props.todayI18n} component={I18nMessage}/>;

        const format = Config.dateFormat();

        const clearable = this[isClearable]();

        return (
            <div className="calendar-form">
                <div className="dropdown abs-dropdown-form" ref="calendarDropdown">
                    <div className={classnames("abs-custom-calendar", {
                        "invalid": invalid
                    })}>
                        <input ref="calendarOutput" className={classnames("abs-custom-calendar-input-field", {
                            "k-invalid": invalid
                        })}
                               readOnly
                               onClick={this.toggleCalendar}
                               placeholder={this.props.customLabel}/>
                        {clearable &&
                        <i className="abs-icon abs_close abs-calendar-clear-icon" onClick={this.clearSelectedDate}/>}
                        <Icon name="abs_insert_invitation" className="abs-calendar-trigger-icon" onClick={this.toggleCalendar}/>
                    </div>
                    {this.state.calendarActive && (
                        <div
                            className="calendar-dropdown dropdown-menu abs-filter-form-container abs-filter-calendar-complex
                            abs-filter-calendar-complex--range"
                            style={{display: this.state.calendarActive ? "block" : "none"}}
                            onClick={this.stopEvent}>
                            <div className="abs-filter-form">
                                <div className="abs-start-calendar">
                                    <div>
                                        <FromI18n i18n={this.props.fromI18n} component={I18nMessage}/>
                                        <input className="abs-filter-calendar-complex-date-start-text k-textbox"
                                               ref="dateFrom"
                                               value={this.state.dateObj.dateFrom ? this.state.dateObj.dateFrom.format(format) : ""}
                                               readOnly/>
                                        <a onClick={() => this.onDateFromSelect(null)}
                                           className="abs-filter-calendar-date-complex__clear"><span
                                            className="abs-icon abs_clear_filter"/></a>

                                        <a onClick={() => this.onDateFromSelect(now)}
                                           className="abs-filter-calendar-date-complex__calendar"
                                           disabled={this.disabledStartDate(now)}>{today}</a>
                                    </div>
                                    <CustomCalendar locale={LANG} onSelect={this.onDateFromSelect} defaultValue={now}
                                                    selectedValue={this.state.dateObj.dateFrom}
                                                    disabledDate={this.disabledStartDate}/>
                                </div>
                                <div className="abs-end-calendar">
                                    <div>
                                        <ToI18n i18n={this.props.toI18n} component={I18nMessage}/>
                                        <input className="abs-filter-calendar-complex-date-start-text k-textbox"
                                               ref="dateTo"
                                               value={this.state.dateObj.dateTo ? this.state.dateObj.dateTo.format(format) : ""}
                                               readOnly/>
                                        <a onClick={() => this.onDateToSelect(null)}
                                           className="abs-filter-calendar-date-complex__clear"><span
                                            className="abs-icon abs_clear_filter"/></a>

                                        <a onClick={() => this.onDateToSelect(then)}
                                           className="abs-filter-calendar-date-complex__calendar"
                                           disabled={this.disabledEndDate(then)}>{today}</a>
                                    </div>
                                    <CustomCalendar locale={LANG} onSelect={this.onDateToSelect} defaultValue={then}
                                                    selectedValue={this.state.dateObj.dateTo}
                                                    disabledDate={this.disabledEndDate}/>
                                </div>
                            </div>

                            <div className="button-panel">
                                <button className="k-button k-state-default btn btn-type-b btn-xs submit-button"
                                        onClick={() => this.submitDate(format)} disabled={!clearable}><OKI18n
                                    i18n={this.props.okI18n}
                                    component={I18nMessage}/></button>
                                <button className="k-button k-state-default btn btn-type-a btn-xs cancel-button"
                                        onClick={this.cancel}><CancelI18n
                                    i18n={this.props.cancelI18n} component={I18nMessage}/></button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
