import React from "react";
import {connect} from "react-redux";
import MediaQuery from "react-responsive";
import {filterPanelActionCreators, filterPanelSelectors} from "assecobs-react-data-table/index";
import Button from "../button/Button";
import classnames from "classnames";
import _ from "lodash";
import {I18nMessage} from "../index";
import DropdownMenu from "../dropdownMenu/DropdownMenu";

const toggleFilterPanel = Symbol();
const toolbarElement = Symbol();
const selectionToolsElement = Symbol();
const defaultToolsElement = Symbol();

const SelectedCount = ({selectedCount}) => (
    <div className="abs-list-toolbar__selection-tools__checked-section__checked__selected-count">{selectedCount}</div>
);

class ListToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {selectionMode: false, selectionTools: null, dropdownComponents: []};

        if (props.filterPanelId) {
            const actions = filterPanelActionCreators(props.filterPanelId);
            this[toggleFilterPanel] = actions.toggleFilterPanel;
        }
        this[toolbarElement] = null;
        this[selectionToolsElement] = null;
        this[defaultToolsElement] = null;
    }

    componentDidMount() {
        if (!_.isNil(this.props.selectionTools)) {
            this.setState({selectionTools: this.props.selectionTools}, () => {
                this.checkToolsMenu();
                window.addEventListener("resize", this.checkToolsMenu);
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.checkToolsMenu);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextProps.selectedCount, this.props.selectedCount)
            || !_.isEqual(nextState.selectionMode, this.state.selectionMode)
            || !_.isEqual(nextProps.filterPanelSubmitted, this.props.filterPanelSubmitted)
            || !_.isEqual(nextProps.additionalInformation, this.props.additionalInformation)
            || !_.isEqual(nextProps.filterPanelOpen, this.props.filterPanelOpen)
            || !_.isEqual(nextState.selectionTools, this.state.selectionTools)
            || !_.isEqual(nextProps.actionsListCount, this.props.actionsListCount)
            || !_.isEqual(nextProps.areAllSelected, this.props.areAllSelected)
            || !_.isEqual(nextProps.showWarningLabel, this.props.showWarningLabel);
    }

    componentDidUpdate() {
        this.setState({selectionMode: this.props.selectedCount > 0, selectionTools: this.props.selectionTools});
        this.checkToolsMenu();
    }

    clearSelection = () => {
        this.props.onClearSelection();
    };

    handleToggleFilterPanel = () => {
        this.props.dispatch(this[toggleFilterPanel]());
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    checkToolsMenu = async () => {

        if (window.innerWidth < 768 && !_.isEmpty(this.state.selectionTools)) {
            const toolbarElementWidth = this[toolbarElement].getBoundingClientRect().width;
            const selectionToolsElementWidth = this[selectionToolsElement].getBoundingClientRect().width;
            const defaultToolsElementWidth = this[defaultToolsElement].getBoundingClientRect().width;

            let newTools = null;
            let lastToolsElement = null;

            if (_.isArray(this.state.selectionTools)) {
                newTools = [...this.state.selectionTools];
                lastToolsElement = newTools.pop();
            }
            else {
                lastToolsElement = this.state.selectionTools;
            }

            let newDropdownComponents = [...this.state.dropdownComponents];

            if (selectionToolsElementWidth + defaultToolsElementWidth + 10 >= toolbarElementWidth) {

                newDropdownComponents.push(lastToolsElement);

                await this.setState({selectionTools: newTools, dropdownComponents: newDropdownComponents}, async () => {
                    await this.checkToolsMenu();
                });

            }

        }

    };

    render() {
        const {title, bigToolbar, selectedCount, defaultTools, filterPanelId, filterPanelOpen, filterPanelSubmitted, additionalInformation, selectionInformation} = this.props;

        return (
            <div className={classnames("row vcenter abs-list-toolbar", {"big-toolbar": bigToolbar})}
                 ref={c => this[toolbarElement] = c}>
                <div className="col-xs-8 col-md-4 abs-list-toolbar__selection-tools"
                     ref={c => this[selectionToolsElement] = c}>
                    {!this.state.selectionMode && <div className="abs-inline-box abs-back abs-header-upp">{title}</div>}
                    {!this.state.selectionMode && <div
                        className="abs-inline-box abs-back abs-toolbar-additionalInformation">{additionalInformation}</div>}
                    {this.state.selectionMode && (
                        <div className="abs-list-toolbar__selection-tools__checked-section">

                            <div
                                className="abs-inline-box abs-back abs-list-toolbar__selection-tools__checked-section__checked">
                                <a
                                    className="abs-icon abs_keyboard_arrow_left2 middle"
                                    onClick={this.clearSelection}/>
                                <div
                                    className="abs-list-toolbar__selection-tools__checked-section__checked__text middle">
                                    <I18nMessage id="common.selected"/> <SelectedCount
                                    selectedCount={selectedCount}/></div>
                            </div>
                            <div className="abs-list-toolbar__selection-tools__checked-section__divider"/>
                        </div>
                    )}
                    <div>
                        {this.state.selectionMode && this.state.selectionTools}
                        {!_.isEmpty(this.state.dropdownComponents) ?
                            <DropdownMenu buttonClassName="btn-type-a">
                                {this.state.dropdownComponents.map((element, index) => (
                                    <DropdownMenu.Item key={`dropdown_item_${index}`}>
                                        {element}
                                    </DropdownMenu.Item>
                                ))}
                            </DropdownMenu> : null}
                    </div>
                    {selectionInformation}
                </div>
                <div className="col-md-9 col-xs-9 abs-list-toolbar__default-tools">
                    <div className="pull-right" ref={c => this[defaultToolsElement] = c}>
                        {defaultTools}
                        <MediaQuery minWidth={768}>
                            {filterPanelId && (
                                <Button styleType="A"
                                        onClick={this.handleToggleFilterPanel}
                                        icon="abs_filter"
                                        className={classnames(
                                            "pull-right",
                                            {
                                                "abs-filter-panel__toggle--open": filterPanelOpen,
                                                "abs-filter-panel__toggle--submitted": filterPanelSubmitted
                                            }
                                        )}
                                />
                            )}
                        </MediaQuery>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state, props) => ({
    ...props.filterPanelId && {
        filterPanelOpen: filterPanelSelectors.isOpen(state, props.filterPanelId),
        filterPanelSubmitted: filterPanelSelectors.isSubmitted(state, props.filterPanelId)
    }
}), null, null, {withRef: true})(ListToolbar)
