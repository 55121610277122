import React from "react";
import {fromJS, Map} from "immutable";
import ResponsiveList from "./ResponsiveList";
import OrderingDirection from "./types/OrderingDirection";


export default class ResponsiveListContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {ordering: new Map()};
    }

    fetchData = () => {

        this.props.fetchData(this.state.ordering, false);
    };

    onToggleOrdering = async(key) => {
        let direction = this.state.ordering.get(key);
        if (direction) {

            if (direction === OrderingDirection.ASC) {
                direction = OrderingDirection.DESC
            }
            else {
                direction = null;
            }
        }
        else {
            direction = OrderingDirection.ASC;
            await this.setState({ordering: new Map()});
        }
        await this.setState({ordering: this.state.ordering.set(key, fromJS(direction))});
        this.props.fetchData(this.state.ordering, true);
    };

    getSelectedIds = (elementsIds) => {

        this.props.getSelectedIds(elementsIds);
    };

    render() {

        const {config, rows, totalCount, className} = this.props;

        return (
            <ResponsiveList config={config} rows={rows} ordering={this.state.ordering} totalCount={totalCount}
                            fetchData={this.fetchData} toggleOrdering={(key) => this.onToggleOrdering(key)}
                            getSelectedIds={this.getSelectedIds} className={className}/>
        );
    }
}
