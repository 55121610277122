import React from "react";
import PropTypes from "prop-types";
import {CustomSuperSelect} from "assecobs-react-data-table/index";
import {ContainerLoader} from "assecobs-faktor-web-common";

class SelectedValue extends React.Component {
    render() {
        const {data, template, name} = this.props;

        let fullDisplayedValue;
        let displayedValue;

        if (data) {
            fullDisplayedValue = template(data);
            displayedValue = fullDisplayedValue;

            if (fullDisplayedValue.length > 10) {
                displayedValue = fullDisplayedValue.substring(0, 10) + "...";
            }
        }

        return (
            <span className="abs-multiselect-filter__placeholder">
                <span className="abs-multiselect-filter__placeholder-title">{name}</span>
                {data && <span className="abs-multiselect-filter__placeholder-value">: {displayedValue}</span>}
            </span>
        );
    }
}

export default class SingleSelectFilterComponent extends React.Component {

    static propTypes = {
        clearSearchOnSelection: PropTypes.bool
    };

    static defaultProps = {
        clearSearchOnSelection: true
    };

    render() {
        const {title, optionNameProp, selectedValueTemplate, optionTemplate, clearable, loading, disabled, clearSearchOnSelection, ...rest} = this.props;

        const customSelectedValueTemplate = selectedValueTemplate ? selectedValueTemplate : props => <SelectedValue
            data={props.data}
            template={item => item[optionNameProp]} name={title}/>;

        const customOptionTemplate = optionTemplate ? optionTemplate : ({option}) => (
            <div>{option[this.props.optionNameProp]}</div>
        );

        return (
            <ContainerLoader filter={true} loading={loading}>
                <CustomSuperSelect
                    {...rest}
                    placeholder={title}
                    optionTemplate={customOptionTemplate}
                    selectedValueTemplate={customSelectedValueTemplate}
                    multiple={false}
                    deselectOnSelectedOptionClick={false}
                    clearable={clearable}
                    clearSearchOnSelection={clearSearchOnSelection}
                    clearSelectedValueOnDataSourceChange={true}
                    closeOnSelectedOptionClick={false}
                    keepOpenOnSelection={false}
                    disabled={disabled}
                />
            </ContainerLoader>
        )

    }
}
