import React from "react";
import {SingleSelectFilterComponent} from "assecobs-react-data-table/index";
import {createFilteringParams} from "assecobs-react-data-table/dataTableUtils";
import {fwUrlUtils, RestService} from "assecobs-faktor-web-common/index";
import ContractorSingleSelectedValueTemplate from "../filters/contractor/ContractorSingleSelectedValueTemplate";
import ContractorSingleOptionTemplate from "../filters/contractor/ContractorSingleOptionTemplate";
import contractorFilterParams from "../filters/contractor/contractorFilterParams";
import {injectIntl} from 'react-intl';
import _ from "lodash";

const URL = fwUrlUtils.getApiUrl("/webapi/filter/contractors/baseAgreementContext");

class ContractorSimpleForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: null,
            loading: false,
            selectedContractor: null
        }
    }

    componentDidMount = async () => {
        if (!_.isNil(this.props.baseAgreements)) {

            await this.setState({
                loading: true
            });

            const contractors = await RestService.postJSON(URL, createFilteringParams({
                baseAgreements: [this.props.baseAgreements]
            }));

            if (!_.isNil(this.props.contractorId)) {
                contractors.forEach(contractor => {
                    if (_.isEqual(contractor.contractorClientId, this.props.contractorId)) {
                        this.state.selectedContractor = contractor;
                    }
                })
            }

            await this.setState({
                options: contractors,
                loading: false
            });

            if (_.isEqual(contractors.length, 1)) {
                this.props.input.onChange(contractors[0].contractorClientId);
                this.props.onFieldValueChange(contractors[0]);
            }
        }
    };


    componentWillReceiveProps = async (nextProps) => {
        if (!_.isEqual(this.props.baseAgreements, nextProps.baseAgreements) && !_.isNil(nextProps.baseAgreements)) {

            await this.setState({
                loading: true
            });

            const contractors = await RestService.postJSON(URL, createFilteringParams({
                baseAgreements: [nextProps.baseAgreements]
            }));

            await this.setState({
                options: contractors,
                loading: false
            });

            if (_.isEqual(contractors.length, 1)) {
                this.props.input.onChange(contractors[0].contractorClientId);
                this.props.onFieldValueChange(contractors[0]);
            }
        }
    };

    onChange = (contractor) => {

        if (this.props.contractorsOnChange)
            this.props.contractorsOnChange(contractor);

        if (this.props.input) {
            if (!_.isNil(contractor)) {
                this.props.input.onChange(contractor.contractorClientId);
                this.props.onFieldValueChange(contractor);
            }
        }

    };

    render() {
        const {input} = this.props;
        this.props.setLoading(this.state.loading);

        return <SingleSelectFilterComponent
            title={this.props.intl.formatMessage({id: "fw.select.placeholder"})}
            {...contractorFilterParams}
            customClass="fw-select--double-sized"
            options={this.state.options}
            loading={this.state.loading}
            initialValue={(input && input.value) ? input.value : undefined}
            clearable={false}
            onChange={this.onChange}
            selectedValueTemplate={ContractorSingleSelectedValueTemplate}
            optionTemplate={ContractorSingleOptionTemplate}
        />;
    }
}

export default injectIntl(ContractorSimpleForm);
