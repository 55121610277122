import React from "react";
import PropTypes from "prop-types";
import {Icon} from "@assecobs/react-common-components/index";
import classnames from "classnames";
import _ from "lodash";

export default class Button extends React.PureComponent {

    render() {
        const {text, icon, type, styleType, disabled, animation, onClick, className, ...rest} = this.props;

        const iconComponent = icon ? <Icon name={icon} className="abs-btn-icon_icon"/> : null;
        const textComponent = text ? <span className={classnames({"abs-btn-icon_title": icon})}>{text}</span> : null;

        return (
            <button
                type={type}
                className={classnames(
                    "btn btn-xs btn-default",
                    className,
                    {
                        "btn-type-a": _.isEqual(styleType, "A"),
                        "btn-type-b": _.isEqual(styleType, "B"),
                        "btn-type-d": _.isEqual(styleType, "D"),
                        "btn-anim": animation,
                        "abs-btn-icon": icon
                    })}
                disabled={disabled}
                onClick={onClick}
                {...rest}
            >
                {iconComponent}
                {textComponent}
            </button>
        );
    }

    static get propTypes() {
        return {
            text: PropTypes.object,
            icon: PropTypes.string,
            type: PropTypes.string,
            styleType: PropTypes.oneOf(["A", "B", "D"]).isRequired,
            disabled: PropTypes.bool,
            animation: PropTypes.bool,
            onClick: PropTypes.any
        }
    };

    static get defaultProps() {
        return {
            disabled: false,
            animation: false,
            type: "button"
        }
    };
}
