import {ReactElement} from 'react'
// @ts-ignore
import {Config, I18nMessage} from 'assecobs-faktor-web-common/index'

const AttachmentShowMoreDetails = (): ReactElement => (
  <>
    <I18nMessage id="fw.attachment.adding.error.toast.additional.information.2" />
    <ul className="fw-dropzone-toast fw-dropzone-toast-ul">
      <li>
          <span className="fw-dropzone-toast-li">pdf, doc, docx, xls, xlsx, bmp, jpg, jpeg, gif, png, tiff, tif, csv:
            <b> <I18nMessage id="fw.attachment.adding.error.toast.additional.information.4" /></b>
          </span>
      </li>
      <li>
          <span className="fw-dropzone-toast-li">zip:
            <b> <I18nMessage id="fw.attachment.adding.error.toast.additional.information.5" /></b>
          </span>
      </li>
    </ul>
    <>
      <I18nMessage id="fw.attachment.adding.error.toast.additional.information.3" />
      <b>{Config.getMaxRequestSize()}<I18nMessage
        id="fw.attachment.adding.error.toast.additional.information.6" /></b>
    </>
  </>
)

export default AttachmentShowMoreDetails