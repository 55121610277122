import {ReactElement, useEffect} from 'react'
import PropTypes from 'prop-types'
// @ts-ignore
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty'
import {TemplatePropsPropTypes} from '@assecobs-react-components/toaster/src/types/TemplatePropsType'
import {Stringable} from "@assecobs-js-utils/common-types/src/types/Stringable";
import {Nullable} from "@assecobs-js-utils/common-types/src/types/Nullable";

interface ToastContentTemplateProps {
    content?: ContentTemplateType,
    onClose?: () => void,
    timeOut?: number
}

interface ContentTemplateType {
    header?: Stringable<ReactElement> | undefined,
    body?: Stringable<ReactElement> | undefined,
    footer?: Stringable<ReactElement> | undefined
}

const ToastContentTemplate = ({
                                  content,
                                  onClose = () => {
                                  },
                                  timeOut = 0
                              }: ToastContentTemplateProps): ReactElement => {
    useEffect(() => {
        if (timeOut !== 0) {
            setTimeout(() => {
                onClose()
            }, timeOut)
        }
    }, [])

    return (
        <div className='toastContainer__content'>
            <button type="button" className='close toastContainer-close' onClick={onClose}>
                <i className='abs-icon abs_close'/>
            </button>
            <TemplateContent content={content}/>
        </div>
    )
}

// @ts-ignore
interface TemplateContentType {
    content?: ContentTemplateType | undefined;
}

const TemplateContent = ({content}: TemplateContentType): Nullable<ReactElement> => {
    if (content === undefined) {
        return null;
    }

    return (
        <>
            {getHeader(content.header)}
            {getBody(content.body)}
            {getFooter(content.footer)}
        </>
    );
};

const getHeader = (header: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => !isNullOrEmpty(header) &&
    <div className='toastContainer__header'>{header}</div>
const getBody = (body: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => !isNullOrEmpty(body) &&
    <div className='toastContainer__body'>{body}</div>
const getFooter = (footer: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => !isNullOrEmpty(footer) &&
    <div className='toastContainer__footer'>{footer}</div>

ToastContentTemplate.propTypes = {
    message: TemplatePropsPropTypes,
    onClose: PropTypes.func,
    timeOut: PropTypes.number
}

export default ToastContentTemplate