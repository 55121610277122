import React from "react";
import {ButtonDropdown} from 'simple-react-bootstrap';
import {Icon} from "@assecobs/react-common-components/index";
import {connect} from "react-redux";
import {Field} from 'redux-form';
import AbstractSelectFilter from "./filters/AbstractSelectFilter";
import actionCreators from "./action/actionCreators";
import PropTypes from "prop-types";
import _ from "lodash";

const actions = Symbol();

const isSelect = (component) => {
    return (AbstractSelectFilter.isPrototypeOf(component)
        || component.type instanceof AbstractSelectFilter
        || (component.type instanceof Function && AbstractSelectFilter.isPrototypeOf(component.type.WrappedComponent)));
};

class FilterContent extends React.Component {

    constructor(props, context) {
        super(props);
        this[actions] = actionCreators(context.filterPanelId);
        this.state = {
            open: false,
            value: props.input.value
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.input.value
        })
    }

    toggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    close = () => {
        this.setState({
            open: false
        });
    };

    updateFilterValue = (value) => {
        this.setState({
            value
        });

        this.props.input.onChange(value);
    };

    clear = () => {
        if (_.isArray(this.state.value)) {
            this.updateFilterValue([]);
        } else if (_.isObject(this.state.value)) {
            this.updateFilterValue({});
        } else {
            this.updateFilterValue("");
        }

        if (this.context.autoSubmitOnClear) {
            this.submit();
        }
    };

    submit = (value) => {
        this.props.dispatch(this[actions].filterChanged(this.props.id, value && value.length > 0));
    };

    handleSubmitFilter = async (value, notClose = false) => {
        await this.updateFilterValue(value);

        if (!notClose) {
            this.close();
        }

        if (this.context.autoSubmit) {
            this.submit(value);
        }
    };

    handleCancelFilter = () => {
        this.toggle();
    };

    handleClearFilter = () => {
        this.clear();
    };

    render() {
        const {Content, dropdownClass, ...rest} = this.props;
        const ContentComponent = <Content {...rest} {...{
            open: this.state.open,
            onCancel: this.handleCancelFilter,
            onSubmit: this.handleSubmitFilter,
            onClear: this.handleClearFilter
        }} />;

        let fullDisplayedValue;
        let displayedValue;

        if (this.state.value) {
            if (this.props.selectedValueFormatter) {
                fullDisplayedValue = this.props.selectedValueFormatter(this.state.value);
                displayedValue = fullDisplayedValue;
            } else {
                fullDisplayedValue = Array.isArray(this.state.value) ? this.state.value.join() : this.state.value;
                displayedValue = fullDisplayedValue;
                if (fullDisplayedValue.length > 10) {
                    displayedValue = fullDisplayedValue.substring(0, 10) + "...";
                }
            }
        }

        if (isSelect(this.props.Content)) {
            return <div>{ContentComponent}</div>
        }

        const activeClass = !_.isEmpty(this.state.value) ? " abs-dropdown-form-activated" : "";

        return (
            <ButtonDropdown ignoreContentClick={true}
                            className={"dropdown abs-dropdown-form" + activeClass}
                            onToggle={this.toggle}
                            open={this.state.open}>

                <button className="btn btn-default dropdown-toggle abs-filter-panel-dropdown-btn" type="button">
                    <span>{this.props.title}</span>
                    {displayedValue && <span>: {displayedValue}</span>}
                </button>

                <div className={"dropdown-menu abs-filter-form-container " + (dropdownClass)}>
                    {ContentComponent}
                </div>

                {!_.isEmpty(this.state.value) && <span className="abs-reset-filter" onClick={this.clear}>
                    <Icon name="abs_close"/>
                </span>}

            </ButtonDropdown>
        )
    }
}

FilterContent.contextTypes = {
    filterPanelId: PropTypes.string,
    autoSubmit: PropTypes.bool,
    autoSubmitOnClear: PropTypes.bool
};

const connectedFilterContent = connect()(FilterContent);

export default (props) => (
    <Field name={props.id}
           {...props}
           component={connectedFilterContent}
    />
)
