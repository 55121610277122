import React from "react";
import {connect} from "react-redux";

class PromptRoot extends React.Component {

    render() {
        if (!this.props.promptId) {
            return null;
        }

        const DisplayedPrompt = this.props.prompts[this.props.promptId];
        return <DisplayedPrompt {...this.props.promptProps} />
    }
}

export default connect(state => ({
    promptId: state.prompt.promptId,
    promptProps: state.prompt.promptProps
}))(PromptRoot)
