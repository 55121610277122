const ACTION_PREFIX = 'FILTER_PANEL';

const ACTIONS = [
    'TOGGLE_FILTER_PANEL',
    'FILTERS_SUBMITTED',
    'FILTER_CHANGED',
    'CLEAR_ALL_FILTERS'
];

export default (id) => {
    if (!id) {
        throw new Error('itemsProperty must be defined for filterPanelReducer')
    }

    return ACTIONS.reduce((actions, name) => ({
        ...actions,
        [name]: `${id}/${ACTION_PREFIX}_${name}`
    }), {})
}
