import React from "react";
import {SingleSelectFilterComponent} from "assecobs-react-data-table/index";
import {fwUrlUtils, I18nMessage, Link, Money, RestService} from "assecobs-faktor-web-common/index";
import {Icon} from "@assecobs/react-common-components/index";
import ContractorSingleOptionTemplate from "../../filters/contractor/ContractorSingleOptionTemplate";
import ContractorSingleSelectedValueTemplate from "../../filters/contractor/ContractorSingleSelectedValueTemplate";
import contractorFilterParams from "../../filters/contractor/contractorFilterParams";
import _ from "lodash";
import {injectIntl} from 'react-intl';
import {createFilteringParams} from "assecobs-react-data-table/dataTableUtils";

const URL_WITH_SIMPLE_DATA = fwUrlUtils.getApiUrl("/webapi/filter/contractors/baseAgreementContext");
const URL_WITH_FULL_DATA = fwUrlUtils.getApiUrl("/webapi/filter/contractors/baseAgreementContextWithFullData");

const DetailsLink = ({intl, selectedContractor, showDetailsLink}) => {

    if (showDetailsLink) {
        return (
            <div className="form-select-additional-info">
                {selectedContractor && [
                    <div key={0} className="abs-icon-link">
                        <Icon name="abs_launch"/>
                        <Link id="contractorDetailsLink"
                              text={intl.formatMessage({id: "fw.contractor.showDetails"})}
                              url={selectedContractor.contractorLink}
                              openInNewTab
                              external/>
                    </div>,
                    <div key={1} className="clearfix"/>
                ]}
            </div>
        )
    }

    return null;
};

const CurrentLimit = ({selectedContractor, showCurrentLimit}) => {

    if (showCurrentLimit) {
        return (
            <div className="form-select-additional-info">
                {selectedContractor && [
                    <span key={0} className="light-txt"><I18nMessage id="fw.limit.current"/>: </span>,
                    <Money key={1} amount={selectedContractor.limit} currency={selectedContractor.currency}/>
                ]}
            </div>
        )
    }

    return null;
};

class ContractorSelectInputField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: null,
            loading: false,
            initialValue: props.initialContractorClientId || null,
            selectedContractor: null
        }
    }

    loadContractors = async (baseAgreements, fullData) => {

        await this.setState({
            loading: true
        }, async () => {

            const contractors = await RestService.postJSON(fullData ? URL_WITH_FULL_DATA : URL_WITH_SIMPLE_DATA, createFilteringParams({
                baseAgreements: _.isArray(baseAgreements) ? baseAgreements : [baseAgreements]
            }));

            await this.setState({
                options: contractors,
                loading: false
            });

            if (!this.state.initialValue) {
                if (_.isEqual(contractors.length, 1)) {
                    this.setState({initialValue: contractors[0].contractorClientId});
                } else {
                    this.setState({initialValue: null});
                }
            } else {
                if (!_.some(contractors, c => c.contractorClientId === this.state.initialValue)) {
                    this.setState({initialValue: null});
                }
            }
        });

    };

    componentDidMount = async () => {
        if (!_.isNil(this.props.baseAgreements)) {
            await this.loadContractors(this.props.baseAgreements, this.props.fullData);
        }
    };

    componentDidUpdate = async (prevProps) => {
        if (!_.isNil(this.props.baseAgreements) && !_.isEqual(this.props.baseAgreements, prevProps.baseAgreements)) {
            await this.loadContractors(this.props.baseAgreements, this.props.fullData);
        }
    };

    onChange = (contractor) => {
        this.setState({selectedContractor: contractor});
        this.props.onFieldValueChange(contractor);
    };

    render() {
        const {showDetailsLink, showCurrentLimit, intl} = this.props;
        this.props.setLoading(this.state.loading);

        return (
            <div>
                <SingleSelectFilterComponent
                    ref={(c) => this._selectComponent = c}
                    {...contractorFilterParams}
                    customClass="fw-select--double-sized"
                    initialValue={this.state.initialValue ? this.state.initialValue : undefined}
                    title={intl.formatMessage({id: "fw.select.placeholder"})}
                    options={this.state.options}
                    loading={this.state.loading}
                    clearable={false}
                    onChange={this.onChange}
                    optionTemplate={ContractorSingleOptionTemplate}
                    selectedValueTemplate={ContractorSingleSelectedValueTemplate}
                />
                <DetailsLink intl={intl} selectedContractor={this.state.selectedContractor}
                             showDetailsLink={showDetailsLink}/>
                <CurrentLimit selectedContractor={this.state.selectedContractor} showCurrentLimit={showCurrentLimit}/>
            </div>
        );
    }
}

export default injectIntl(ContractorSelectInputField);
