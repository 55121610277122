import React from "react";
import PropTypes from 'prop-types';
import I18nMessage from "../../i18n/I18nMessage";
import classnames from "classnames";

export default class LinkMenuItem extends React.PureComponent {

    render() {
        const {label, url, styleClass} = this.props;

        return (
            <li>
                <a href={url} className={classnames(styleClass)}><I18nMessage id={label}/></a>
            </li>
        )
    }

    static get propTypes() {
        return {
            label: PropTypes.string,
            url: PropTypes.string,
            styleClass: PropTypes.string
        }
    }
}
