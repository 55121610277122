import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage, Link} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type PackageSendToastType = {
    packageNumber: string,
    packageLink: string,
    refresh: boolean
}

interface ToastInfoForPackageSendTemplateType extends TemplateType {
    templateProps: PackageSendToastType;
}

const ToastInfoForPackageSendTemplate = ({
                                             templateProps,
                                             removeToast,
                                             id,
                                             timeOut = 5000
                                         }: ToastInfoForPackageSendTemplateType): ReactElement => {

    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.import.packageSend.toast.header"/>
    const messageBody = <><I18nMessage id="fw.package.no"/>: {templateProps.packageNumber}<br/></>
    const messageFooter = <Link url={templateProps.packageLink}
                                text={<I18nMessage id="fw.import.package.toast.linkText"/>} external/>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody,
                                      footer: messageFooter
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastInfoForPackageSendTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForPackageSendTemplate