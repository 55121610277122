import {
  ERROR_REASON,
} from '../../../../../../../../../packages/src/main/resources/static/scripts/app/components/constants/errorReason'
import _ from 'lodash'
import {
  SEND_ATTACHMENT_ERROR_TOAST,
  SEND_ATTACHMENTS_ERROR_TOAST,
  toast,
} from 'assecobs-faktor-web-common/toasts/ToastInitializer'
import {getFilesFailureAndFilesRejected} from 'assecobs-faktor-web-common/dropzoneComponent/services/fileFailueService'

const showErrorToast = ({rejectedFiles, responseJSON = {}, maxFileSize}) => {
  const fileFailure = getFilesFailureAndFilesRejected({
    UploadFailure: responseJSON.UploadFailure,
    UploadFailureZIP: responseJSON.UploadFailureZIP,
    RejectedFiles: rejectedFiles
      .filter(file => file.error === ERROR_REASON.TYPE || file.error === ERROR_REASON.SIZE)
      .map(file => file.data.name),
  })

  const filesWithErrorSumSize = rejectedFiles.filter(file => file.error === ERROR_REASON.EXCEEDED_FILES_SIZE)

  if (!_.isEmpty(filesWithErrorSumSize)) {
    toast(SEND_ATTACHMENT_ERROR_TOAST({
      maxFileSize,
    }))
  }

  if (!_.isEmpty(fileFailure)) {
    toast(SEND_ATTACHMENTS_ERROR_TOAST({
      fileFailure: fileFailure,
    }))
  }
}

export {showErrorToast}