import React from "react";
import TaxNumberAndCode from "../../dataPresentation/TaxNumberAndCode";

export default class ContractorSingleSelectedValueTemplate extends React.Component {
    render() {
        const {data} = this.props;

        if (!data) {
            return null;
        }

        return (
            <div className="option-template-view option-template-view--multiline">
                <div className="option-main-value">{data.shortName}</div>
                <TaxNumberAndCode taxNumberTypeDesc={data.taxNumberTypeDesc}
                                            taxNumber={data.taxNumber}
                                            code={data.code}
                />
            </div>
        );
    }
}
