import React from "react";
import _ from "lodash";
import Slider from "react-slick";
import AlertList from "./AlertList";
import AlertDetails from "./AlertDetails";

export default class Alerts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            selectedAlert: undefined
        }
    }

    showAlert = async (alert) => {
        await this.setState({selectedAlert: alert, currentPage: 1});
        this.refs.slider.slickGoTo(1);
    };

    backToList = async () => {
        await this.setState({currentPage: 0});
        this.refs.slider.slickGoTo(0);
    };

    dataLoaded = () => {
        this.forceUpdate();
    };

    render() {
        const sliderSettings = {
            dots: false,
            infinite: false,
            arrows: false,
            draggable: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: false
        };

        return (
            <div className="fw-alerts-box">
                <Slider ref="slider" {...sliderSettings}>
                    <div key={0}>
                        <AlertList showAlert={(alert) => this.showAlert(alert)} hidden={_.isEqual(this.state.currentPage, 1)} load={this.props.load}
                        dataLoaded={this.dataLoaded}/>
                    </div>

                    {this.state.selectedAlert && <div key={1}>
                        <AlertDetails alert={this.state.selectedAlert} backToList={this.backToList}/>
                    </div>}
                </Slider>
            </div>
        )
    }
}
