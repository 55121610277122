import React from "react";
import ReactDOM from "react-dom";
import MediaQuery from "react-responsive";
import _ from "lodash";

export default (WrappedComponent) => {

    const scrolledComponent = Symbol();
    const wrappedDomNode = Symbol();
    const wrappedDomNodePrevScrollLeft = Symbol();
    const scrollbarBottom = Symbol();
    const scrollbar = Symbol();
    const scrollbarPrevScrollLeft = Symbol();

    return class FloatScrollbar extends React.Component {

        constructor(props) {
            super(props);
            this.state = {containerWidth: "auto", containerLeft: null, width: "auto"};
            this[scrolledComponent] = null;
            this[wrappedDomNode] = null;
            this[wrappedDomNodePrevScrollLeft] = null;
            this[scrollbar] = null;
            this[scrollbarBottom] = null;
            this[scrollbarPrevScrollLeft] = null;
        }

        componentDidMount() {

            if (!_.isNil(this[scrollbar]) && !_.isNil(this[scrolledComponent])) {

                this[wrappedDomNode] = ReactDOM.findDOMNode(this[scrolledComponent]);

                const overflowXVisible = this.props.overflowXVisible ? "visible" : "auto";
                this[wrappedDomNode].style.overflowX = overflowXVisible;

                this.setState({
                    containerWidth: this[wrappedDomNode].offsetWidth,
                    containerLeft: this[wrappedDomNode].offsetLeft
                });

                this.checkScrollbarParameters();

                this[scrollbar].addEventListener("scroll", this.onScrollbarScroll);
                this[wrappedDomNode].addEventListener("scroll", this.onContentScroll);
                window.addEventListener("scroll", this.checkScrollVisibility);
                window.addEventListener("resize", this.checkScrollbarParameters);
            }
        }

        componentWillUnmount() {
            if (!_.isNil(this[scrollbar]))
                this[scrollbar].removeEventListener("scroll", this.onScrollbarScroll);
            if (!_.isNil(this[wrappedDomNode]))
                this[wrappedDomNode].removeEventListener("scroll", this.onContentScroll);
            window.removeEventListener("scroll", this.checkScrollVisibility);
            window.removeEventListener("resize", this.checkScrollbarParameters);
        }

        checkScrollbarParameters = () => {
            if (!_.isNil(this[scrollbar])) {
                this[scrollbar].scrollLeft = this[wrappedDomNode].scrollLeft;
                this[scrollbarBottom] = this[scrollbar].getBoundingClientRect().bottom;
            }
        };

        updateContentWidth = (width) => {
            if (!_.isNil(this[wrappedDomNode])) {
                this.setState({
                    containerWidth: this[wrappedDomNode].offsetWidth,
                    containerLeft: this[wrappedDomNode].getBoundingClientRect().left,
                    width: width
                });
            }
        };

        onScrollbarScroll = () => {

            if (this[scrollbar] && !_.isEqual(this[wrappedDomNode].scrollLeft, this[scrollbar].scrollLeft)
                && !_.isEqual(this[scrollbarPrevScrollLeft], this[scrollbar].scrollLeft)) {

                this[wrappedDomNode].scrollLeft = this[scrollbar].scrollLeft;
                this[wrappedDomNodePrevScrollLeft] = this[wrappedDomNode].scrollLeft;
                this[scrollbarPrevScrollLeft] = -1;

            }

        };

        onContentScroll = () => {

            if (!_.isNil(this[wrappedDomNode]) && !_.isNil(this[scrollbar]) && !_.isEqual(this[wrappedDomNode].scrollLeft, this[scrollbar].scrollLeft)
                && !_.isEqual(this[wrappedDomNodePrevScrollLeft], this[wrappedDomNode].scrollLeft)) {

                this[scrollbar].scrollLeft = this[wrappedDomNode].scrollLeft;
                this[scrollbarPrevScrollLeft] = this[scrollbar].scrollLeft;
                this[wrappedDomNodePrevScrollLeft] = -1;

            }

        };

        checkScrollVisibility = () => {
            if (!_.isNil(this[scrollbar])) {
                if (this[wrappedDomNode].getBoundingClientRect().bottom < this[scrollbarBottom]) {
                    this[scrollbar].style.visibility = "hidden";
                } else {
                    if (_.isEqual(this[scrollbar].style.visibility, "hidden")) {
                        this[scrollbar].style.visibility = "visible";
                    }
                }
            }
        };

        render() {

            return [
                <WrappedComponent ref={c => this[scrolledComponent] = c} key="scrolledComponent"
                                  updateScrollbar={this.updateContentWidth} {...this.props}/>,
                <MediaQuery minWidth={768} key="media_query">
                    <div style={{
                        position: "fixed",
                        bottom: "0px",
                        height: "30px",
                        overflowX: "auto",
                        overflowY: "hidden",
                        width: this.state.containerWidth,
                        left: this.state.containerLeft
                    }} key="scroll" ref={c => this[scrollbar] = c}>
                        <div style={{border: "1px solid #FFF", opacity: "0.01", width: this.state.width}}>

                        </div>
                    </div>
                </MediaQuery>
            ]
        }
    }

};