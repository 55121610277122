import React from "react";
import MediaQuery from "react-responsive";
import {Commission, I18nMessage, Money, ValuesSequence} from "assecobs-faktor-web-common";
import {injectIntl} from "react-intl";
import BasicCell from "../../common/BasicCell";

export default class ChargeMethodCell extends React.PureComponent {

    render() {

        const {item} = this.props;

        return (
            <MediaQuery minWidth={768}>
                {(matches) => {
                    if (matches) {
                        return (
                            <div>
                                <Commission amount={item.get("paymentAmount")} currency={item.get("currency")}
                                            percent={item.get("paymentRate")} type="PERCENT_PLUS_AMOUNT"/>
                                {item.get("chargeMinAmount") &&
                                <div className="hint"><I18nMessage id="fw.charge.min">Minimalna kwota
                                    opłaty</I18nMessage>: <Money amount={item.get("chargeMinAmount")}
                                                                 currency={item.get("currency")}
                                                                 maximumFractionDigits={0}/></div>}
                                {item.get("chargeMaxAmount") &&
                                <div className="hint"><I18nMessage id="fw.charge.max">Maksymalna kwota
                                    opłaty</I18nMessage>: <Money amount={item.get("chargeMaxAmount")}
                                                                 currency={item.get("currency")}
                                                                 maximumFractionDigits={0}/></div>}
                            </div>
                        );
                    }
                    return (
                        <BasicCell>
                            <ValuesSequence hint
                                            titleKey="fw.charge.methodOfCalculation"
                                            values={[
                                                <Commission amount={item.get("paymentAmount")}
                                                            currency={item.get("currency")}
                                                            percent={item.get("paymentRate")}
                                                            type="PERCENT_PLUS_AMOUNT"/>,
                                                item.get("chargeMinAmount") &&
                                                <div className="light-txt"><I18nMessage id="fw.charge.min">Minimalna
                                                    kwota
                                                    opłaty</I18nMessage>: <Money
                                                    amount={item.get("chargeMinAmount")}
                                                    currency={item.get("currency")}
                                                    maximumFractionDigits={0}/></div>,
                                                item.get("chargeMaxAmount") &&
                                                <div className="light-txt"><I18nMessage id="fw.charge.max">Maksymalna
                                                    kwota
                                                    opłaty</I18nMessage>: <Money
                                                    amount={item.get("chargeMaxAmount")}
                                                    currency={item.get("currency")}
                                                    maximumFractionDigits={0}/></div>
                                            ]}/>
                        </BasicCell>
                    )
                }}
            </MediaQuery>
        )
    }
}