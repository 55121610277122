import React, {Component} from "react";
import PropTypes from "prop-types";
import TabsNav from "./TabsNav";
import Tab from "./Tab";
import classnames from "classnames";

class TabContent extends Component {

    render() {
        return (
            <div className="tab-content">
                {this.props.children}
            </div>
        )
    }

}

class Tabs extends Component {

    static propTypes = {
        type: PropTypes.oneOf(['context', 'nested'])
    };

    render() {
        const className = classnames(this.props.className, {
            "abs-tabs-context": this.props.type === 'context',
            "abs-tabs-nested": this.props.type === 'nested'
        });

        return (
            <div id={this.props.id} className={className}>
                {this.props.children}
            </div>
        )
    }

}

Tabs.Navbar = TabsNav;
Tabs.Tab = Tab;
Tabs.Content = TabContent;

export default Tabs;
