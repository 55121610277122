import {List, Set, fromJS} from 'immutable';
import actionTypes from "../action/actions";
import filterActionTypes from "../filterPanel/action/actions";
import {Config} from "assecobs-faktor-web-common";
import _ from "lodash";

const initialState = {
    data: List(),
    totalCount: undefined,
    loadedPages: 0,
    loading: false,
    pageSize: Config.pageSize(),
    filtering: fromJS({}),
    sorting: fromJS({}),
    selected: Set(),
    itemIdProperty: undefined,
    itemSelectableProperty: undefined,
    needsReload: false,
    filtered: false,
    doExportDataXLS: false,
    doExportDataCSV: false,
    filterable: undefined,
    areAllSelected: false
};

export default (listId, filterId) => {

    const actions = actionTypes(listId);
    const filtersSubmittedAction = filterId ? filterActionTypes(filterId).FILTERS_SUBMITTED : null;

    return (state = initialState, action) => {
        switch (action.type) {
            case actions.INIT:
                return {
                    ...state,
                    filtering: action.filtering ? fromJS(action.filtering) : state.filtering,
                    pageSize: action.pageSize || state.pageSize,
                    sorting: action.sorting ? fromJS(action.sorting) : state.sorting,
                    itemIdProperty: action.itemIdProperty,
                    itemSelectableProperty: action.itemSelectableProperty || state.itemSelectableProperty,
                    filterable: !_.isNil(filterId)
                };
            case actions.RELOAD:
                return {
                    ...state,
                    needsReload: true
                };
            case actions.LOAD:
            case actions.LOAD_MORE:
                return {
                    ...state,
                    loading: true,
                    filtering: fromJS(action.filtering),
                    sorting: fromJS(action.sorting),
                    needsReload: false
                };
            case actions.LOAD_SUCCESS:
                return {
                    ...state,
                    data: fromJS(action.data),
                    totalCount: action.totalCount,
                    loadedPages: 1,
                    loading: false,
                    selected: Set()
                };
            case actions.LOAD_MORE_SUCCESS: {
                
                return {
                    ...state,
                    data: fromJS(action.data),
                    totalCount: action.totalCount,
                    loadedPages: state.loadedPages + 1,
                    loading: false,
                }
            }
            case actions.TOGGLE_SELECT_ALL: {
                const selectableItems = !state.itemSelectableProperty ? state.data : Set(state.data.filter(item => !state.itemSelectableProperty || item.get(state.itemSelectableProperty)));

                if (state.selected.size === selectableItems.size) {
                    return {
                        ...state,
                        selected: state.selected.clear(),
                        areAllSelected: false
                    }
                }

                return {
                    ...state,
                    selected: Set(selectableItems.map(item => item.get(state.itemIdProperty))),
                    areAllSelected: true
                }
            }
            case actions.TOGGLE_SELECT:
                const newSelected = state.selected.has(action.id) ? state.selected.delete(action.id) : state.selected.add(action.id);
                const selectableItems = !state.itemSelectableProperty ? state.data : Set(state.data.filter(item => !state.itemSelectableProperty || item.get(state.itemSelectableProperty)));

                return {
                    ...state,
                    selected: newSelected,
                    areAllSelected: newSelected.size === selectableItems.size
                };
            case actions.CLEAR_SELECTION: {
                return {
                    ...state,
                    selected: state.selected.clear(),
                    areAllSelected: false
                }
            }
            case actions.FILTER: {
                return {
                    ...state,
                    filtering: action.filtering,
                    needsReload: true,
                    filtered: true
                }
            }
            case filtersSubmittedAction: {
                return {
                    ...state,
                    filtering: action.filters,
                    needsReload: true,
                    filtered: true
                }
            }
            case actions.DO_EXPORT_DATA_XLS: {
                return {
                    ...state,
                    doExportDataXLS: true,
                }
            }
            case actions.DO_EXPORT_DATA_CSV: {
                return {
                    ...state,
                    doExportDataCSV: true,
                }
            }
            case actions.EXPORT_DATA: {
                return {
                    ...state,
                    doExportDataXLS: false,
                    doExportDataCSV: false
                }
            }
        }

        return state;
    }
}
