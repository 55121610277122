import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage, Money} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type InvoiceEditingToastType = {
    invoiceNumber: string,
    invoiceValue: number,
    invoiceCurrency: string,
    refresh: boolean
}

interface ToastInfoForInvoiceEdittingTemplateType extends TemplateType {
    templateProps: InvoiceEditingToastType;
}

const ToastInfoForInvoiceEdittingTemplate = ({
                                                 templateProps,
                                                 removeToast,
                                                 id,
                                                 timeOut = 5000
                                             }: ToastInfoForInvoiceEdittingTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.invoice.editing.toast.header"/>
    const messageBody = <InvoiceEditingToast {...templateProps} />

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

const InvoiceEditingToast = ({invoiceNumber, invoiceValue, invoiceCurrency}: InvoiceEditingToastType): ReactElement => (
    <>
        <I18nMessage id="fw.invoice.no"/>: {invoiceNumber}<br/>
        <I18nMessage id="fw.invoice.toast.value"/>: <Money amount={invoiceValue} currency={invoiceCurrency}/>
    </>
)

ToastInfoForInvoiceEdittingTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForInvoiceEdittingTemplate