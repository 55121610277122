import React from "react";
import PropTypes from 'prop-types';
import {browserHistory} from "react-router";
import {Button} from "react-bootstrap";
import {fwUrlUtils} from "assecobs-faktor-web-common/index";
import getCase from '@mparulski/scripts-library'

const PostActionButton = ({buttonInfo, closeModal}) => {

    const redirectAction = () => {
        browserHistory.push({pathname: fwUrlUtils.getContextUrl(buttonInfo.ButtonLink)});
        closeModal();
    };

    const refreshAction = () => window.location.reload();

    const postActionConfig = {
        "REFRESH": refreshAction,
        "CLOSE": closeModal,
        "REDIRECT": redirectAction
    };

    const handleOnClick = () => {
        getAction(buttonInfo.ButtonAction)()
    };

    const getAction = action => getCase(postActionConfig, closeModal, action);

    return (
        <Button className="btn btn-type-b abs-modal-post-action__button" onClick={handleOnClick}>
            {buttonInfo.ButtonText}
        </Button>
    )
};

PostActionButton.propTypes = {
    buttonInfo: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default PostActionButton
