import React from "react";
import {withRouter} from "react-router";
import {injectIntl} from 'react-intl';
import LoginPageComponent from "../components/LoginPageComponent";
import {reduxForm, SubmissionError} from "redux-form";
import {fwUrlUtils, RestService, RestUtils} from "assecobs-faktor-web-common/index";
import Url from "../common/Urls";
import messageFactory from "../../common/messageFactory";
import _ from "lodash";

const doLogin = Symbol();
const handleLoginResponse = Symbol();
const getSSOFailedMsg = Symbol();

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loginSuccess: false,
            sessionExpired: _.has(props.location.query, 'expired'),
            noApproval: _.has(props.location.query, 'noApproval'),
            multipleLogins: _.has(props.location.query, 'multipleLogins'),
            ssoFailedMsg: this[getSSOFailedMsg](props.location.query)
        }
    }

    async [doLogin](authParams, csrf) {
        const loginResponse = await RestService.postFormUrlEncoded(Url.LOGIN, authParams, {
            headers: {
                [csrf.headerName]: csrf.token
            }
        });

        return loginResponse.json();
    }

    [getSSOFailedMsg](queryParams) {
        if (_.has(queryParams, "sso-failed-msg")) {
            return decodeURIComponent(atob(queryParams["sso-failed-msg"]));
        }
    }

    [handleLoginResponse](loginResponse) {
        if (loginResponse.authenticated) {
            window.location.href = fwUrlUtils.getContextUrl(loginResponse.redirectUrl);
            this.setState({loginSuccess: true});
        } else {
            throw new SubmissionError({
                _error: loginResponse.errorMessages
            })
        }
    }

    submit = async (values) => {

        await this.setState({sessionExpired: false});

        if (_.isEmpty(values) || _.isEmpty(values.username)) {
            throw new SubmissionError({
                _error: this.props.intl.formatMessage({id: "fw.authentication.username.empty"})
            })
        } else if (_.isEmpty(values.password)) {
            throw new SubmissionError({
                _error: this.props.intl.formatMessage({id: "fw.authentication.password.empty"})
            })
        }

        const csrf = await RestUtils.getCsrf();
        const loginResponse = await this[doLogin](values, csrf);
        this[handleLoginResponse](loginResponse);

    };

    render() {
        const {error, submitting, handleSubmit, intl} = this.props;

        let msg;
        if (error) {
            msg = messageFactory.error(error);
        } else if (this.state.sessionExpired) {
            msg = messageFactory.info(intl.formatMessage({id: "fw.session.expired"}));
        } else if (this.state.noApproval) {
            msg = messageFactory.info(intl.formatMessage({id: "fw.session.noApproval"}));
        } else if (this.state.multipleLogins) {
            msg = messageFactory.info(intl.formatMessage({id: "fw.session.multipleLogins"}));
        } else if (this.state.ssoFailedMsg) {
            msg = messageFactory.error(this.state.ssoFailedMsg);
        }

        return <LoginPageComponent msg={msg}
                                   submitting={submitting || this.state.loginSuccess}
                                   submit={this.submit}
                                   handleSubmit={handleSubmit}
        />;
    }
}

export default injectIntl(withRouter(reduxForm({
    form: 'loginForm'
})(LoginPage)));
