import React from "react";
import {Fields} from "redux-form";
import FormFieldsComponent from "./FormFieldsComponent";
import {validators} from "../../forms";

export default class FormFields extends React.PureComponent {

    render() {
        const {component, required, validate, ...rest} = this.props;

        let validate_arr = validate || [];

        if (required) {
            validate_arr.push(validators.required);
        }

        return (
            <Fields {...rest}
                    component={FormFieldsComponent}
                    required={required}
                    validate={validate_arr}
                    InputComponent={component}
            />
        )

    }
}
