import {DefinitionReturn} from "../types/DefinitionReturn";
import {FW_TOAST_BRAND} from "../enums/FWToastBrandEnum";

type InvalidAttachmentSizeToastType = {
    acceptedTypes: string,
    maxFileSize: number | string
}

export const INVALID_ATTACHMENT_SIZE_TOAST = (params: InvalidAttachmentSizeToastType): DefinitionReturn => ({
    templateProps: params,
    timeOut: 6000,
    type: FW_TOAST_BRAND.DANGER_FOR_INVALID_ATTACHMENT_SIZE
})