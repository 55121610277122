import fwUrlUtils from "../utils/fwUrlUtils";
import RestService from "./RestService";

export default class ExportApi {

    static exportWithParams = async (exportFormat, exportKey, params) => {

        const registerUrl = fwUrlUtils.getApiUrl("/webapi/export/registerRequest");
        const jsonRequest = JSON.stringify(params);

        const key = await RestService.postJSON(registerUrl, {
            jsonRequest,
            requestKey: exportKey
        });

        if (key) {
            window.location.href = fwUrlUtils.getApiUrl("/webapi/export/" + exportFormat + "/" + exportKey + "/") + key;
        }
    };

    static exportWithoutParams = (exportFormat, exportKey) => {

        window.location.href = fwUrlUtils.getApiUrl("/webapi/export/" + exportFormat + "/") + exportKey;
    }
}
