import React from "react";
import PropTypes from "prop-types";
import {I18nMessage,} from "assecobs-faktor-web-common/index";
import {Icon} from "@assecobs/react-common-components/index";
import classnames from "classnames";
import _ from "lodash";

export default class FormFieldSection extends React.PureComponent {

    render() {
        const icon = this.props.complete ? "abs_check" : this.props.iconName;

        return (
            <div className={classnames("fw-form-section", {
                "fw-form-section--enabled": !this.props.disabled,
                "fw-form-section--disabled": this.props.disabled,
                "fw-form-section--divide-half": _.isEqual(this.props.styleType, "DIVIDE_HALF"),
                "fw-form-section--table-centered": _.isEqual(this.props.styleType, "TABLE_CENTERED")
            })}>
                {this.props.titleKey && (
                    <div className="fw-form-section__header">
                    <span className={classnames("fw-form-section__header__icon", {
                        "fw-form-section__header__icon--checked": this.props.complete
                    })}>
                        <Icon name={icon}/>
                    </span>
                        <h2 className="fw-form-section__header__title">
                            <I18nMessage id={this.props.titleKey}/>
                        </h2>
                        {this.props.subtitleKey && (
                            <h6 className="fw-form-section__header__subtitle">
                                <I18nMessage id={this.props.subtitleKey}/>
                            </h6>
                        )}
                    </div>
                )}

                <div className={classnames("fw-form-section__body", this.props.bodyClassName)}>
                    {this.props.children}
                </div>
                {this.props.extraComponent}
            </div>
        )
    }

    static get propTypes() {
        return {
            styleType: PropTypes.oneOf(["DIVIDE_HALF", "TABLE_CENTERED"])
        }
    }

    static get defaultProps() {
        return {
            styleType: "DIVIDE_HALF"
        }
    }
}
