import React from "react";
import classnames from "classnames";

export default class CheckboxField extends React.Component {

    componentDidMount() {
        if (this.props.input && (_.isNil(this.props.input.value) || this.props.input.value === "")) {
            this.props.input.onChange(false);
        }
    }

    render() {
        const {input, invalid, id} = this.props;

        return (
            <div>
                <input {...input}
                       id={id}
                       className={"toggle toggle-flip k-checkbox" + classnames({
                           "k-invalid": invalid
                       })}
                       type="checkbox"
                       checked={input.value}
                />
                <label htmlFor={id}/>
            </div>
        )

    }

}
