import React from "react";
import {connect} from "react-redux";
import * as action from "../../alerts/actions";
import Alerts from "../../alerts/Alerts";

class AlertsMenuItem extends React.Component {

    componentDidMount() {
        this.loadAlertStats();
    }

    loadAlertStats = () => {
        this.props.dispatch(action.loadAlertStats());
    };

    render() {
        return (
            <Alerts load={this.props.open} />
        )
    }
}

export default connect()(AlertsMenuItem);
