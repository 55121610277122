import React from "react";
import I18nMessage from "../i18n/I18nMessage";
import {Link} from "@assecobs/react-common-components/index";

export default class InvoicesList extends React.Component {

    render() {

        const {data, totalCount, fetchData, isFetching} = this.props;

        return (
            <ul className={data.size > 0 ? "quick-search-results-list-block" : "quick-search-results-list-block-hidden"}>
                {data.map((item, index) => <li className="result" key={item.get("invoiceId")}>
                    <div className="number">
                        <Link id={`quickSearchInvoiceLink-${index}`}
                              url={item.get("invoiceDetailsLink")}
                              text={"" + item.get("invoiceNumber")} external/>
                    </div>
                    <div className="hint">
                        <span className="status">{item.get("invoiceStatusDesc")}</span>
                        <span className="values-sequence-separator separator"/>
                        <Link id={`quickSearchPackageLink-${index}`}
                              url={item.get("packageDetailsLink")}
                              text={item.get("packageNumberDesc")}
                              textClassName="status" external/>
                    </div>
                    <div className="currency">
                        <I18nMessage id="fw.invoice.amount">Kwota faktury</I18nMessage>:
                        <b> {item.get("invoiceAmount")}</b> {item.get("invoiceCurrency")}</div>
                </li>)}
                <div
                    className={(data.size < totalCount && !isFetching) ? "load-more quick-search-more-button-padding-none" : "load-more quick-search-button-hidden"}
                    onClick={fetchData}><a className="abs-quick-search-load-more"><I18nMessage id="fw.menu.quickSearch.loadMore">Wczytaj więcej faktur...</I18nMessage></a>
                </div>
                {(data.size < totalCount) && isFetching && <div className="quick-search-fetch-more-loading"/>}
            </ul>
        )
    }
}
