import {createSelector} from 'reselect';
import {Set} from 'immutable';

const getData = (state, props) => state[props.id].data;
const getSelected = (state, props) => state[props.id].selected;
const getItemIdProperty = (state, props) => state[props.id].itemIdProperty;
const getItemSelectableProperty = (state, props) => state[props.id].itemSelectableProperty;

const getSelectable = createSelector(
    [getData, getItemIdProperty, getItemSelectableProperty],
    (data, itemIdProperty, itemSelectableProperty) => {
        const selectable = !itemSelectableProperty ? data : Set(data.filter(item => !itemSelectableProperty || item.get(itemSelectableProperty)));
        return Set(selectable.map(item => item.get(itemIdProperty)))
    }
);

export const makeGetSelectable = () => {
    return getSelectable;
};

export const makeIsAllSelected = () => {
    return createSelector(
        [getSelected, getSelectable],
        (selected, selectable) => {
            return selected.size > 0 && selected.size === selectable.size
        }
    )
};
