import React from "react";
import HeaderCell from "./cells/HeaderCell";
import MediaQuery from "react-responsive";

export default class BaseListHeader extends React.Component {

    render() {
        const {columns, id, selectable, isAllSelected, sorting, onSort, filtered, totalCount} = this.props;

        return (
            <MediaQuery minWidth={768}>
                <div className="header">
                    {columns.map((column, index) => {
                            const cell = <HeaderCell key={index}
                                                     label={column.header}
                                                     orderingKey={column.sortable && column.id}
                                                     orderingDirection={column.sortable && sorting ? sorting.get(column.id) : undefined}
                                                     selectable={selectable}
                                                     isAllSelected={isAllSelected}
                                                     onSort={onSort}
                                                     id={id}
                                                     filtered={filtered}
                                                     totalCount={totalCount}
                                                     {...(index === 0 && {
                                                         first: true,
                                                         multiselect: this.props.multiselect,
                                                         onAllSelectionChange: this.props.onAllSelectionChange
                                                     })}
                            />;

                            if (column.mobileOnly) {
                                return (
                                    <MediaQuery key={index}
                                                maxWidth={767}>
                                        {cell}
                                    </MediaQuery>
                                )
                            }

                            return cell;
                        }
                    )}
                </div>
            </MediaQuery>
        )
    }
}
