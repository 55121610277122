import {DefinitionReturn} from '../types/DefinitionReturn'
import {FW_TOAST_BRAND} from '../enums/FWToastBrandEnum'
// @ts-ignore
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty'

type SendAttachmentsErrorToastType = {
  files: Array<string>,
  filesZIP: Array<string>,
  refresh: boolean
}

export const SEND_ATTACHMENTS_ERROR_TOAST = (params: SendAttachmentsErrorToastType): DefinitionReturn => ({
  templateProps: params,
  timeOut: 6000,
  refresh: !isNullOrEmpty(params.refresh) ? params.refresh : false,
  type: FW_TOAST_BRAND.DANGER_FOR_SEND_ATTACHMENTS_ERROR,
})