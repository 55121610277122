import PropTypes from "prop-types";
import React from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {FilterFieldsContainer} from "assecobs-react-data-table/index";
import {BaseAgreementFilter, ContractorFilter} from "assecobs-faktor-web-common/index";
import MediaQuery from "react-responsive";

import _ from "lodash";
import {formValueSelector} from "redux-form";

class BaseAgreementContractorFilterComponent extends FilterFieldsContainer {

    constructor(props) {
        super(props);

        this.state = {
            filterContractorsByAgreements: false,
            selectedBaseAgreements: []
        }
    }

    componentDidMount() {

        if (this.props.location) {
            const queryParams = this.props.location.query;

            if (!_.isEmpty(queryParams)) {
                if (_.has(queryParams, "baseAgreements") && !_.isEmpty(queryParams["baseAgreements"])) {
                    if (_.has(queryParams, "contractors") && !_.isEmpty(queryParams["contractors"])) {
                        this.setState({
                            filterContractorsByAgreements: true
                        });
                    }
                }
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (_.isEmpty(this.props.selectedBaseAgreements) && !_.isEqual(prevProps.selectedBaseAgreements, this.props.selectedBaseAgreements)) {
            this.setState({
                filterContractorsByAgreements: false
            })
        }
    }

    filterContractorsByAgreements = () => {
        this.setState(prevState => ({
            filterContractorsByAgreements: !prevState.filterContractorsByAgreements
        }))
    };

    render() {
        const {intl, ...other} = this.props;

        const contractorFilter = [
            <button key={1}
                    onClick={this.filterContractorsByAgreements}
                    disabled={_.isEmpty(this.props.selectedBaseAgreements)}
                    className="abs-filter-icon-btn"
                    title={intl.formatMessage({id: "fw.filterPanel.filterContractorsByAgreements"})}>
                <span className={"abs-icon " + (this.state.filterContractorsByAgreements ? "abs_connect" : "abs_disconnect")}/>
            </button>,
            <ContractorFilter key={2} id="contractors"
                              filterContractorsByAgreements={this.state.filterContractorsByAgreements} {...other} />];

        return (
            <div className="abs-filter-fields-container">

                <BaseAgreementFilter id="baseAgreements" {...other}/>

                <MediaQuery maxWidth={767} className="contractor-filter-wrapper">
                    {contractorFilter}
                </MediaQuery>

                <MediaQuery minWidth={768}>
                    {contractorFilter}
                </MediaQuery>

            </div>
        )
    }
}

const BaseAgreementContractorFilterComponentWrapper = connect((state, props) => ({
    selectedBaseAgreements: formValueSelector('data', state => state[`${props.filterPanelId}FilterPanel`])(state, 'baseAgreements')
}))(injectIntl(BaseAgreementContractorFilterComponent));

const BaseAgreementContractorFilter = (props, context) => (
    <BaseAgreementContractorFilterComponentWrapper {...props} filterPanelId={context.filterPanelId}/>
);

BaseAgreementContractorFilter.contextTypes = {
    filterPanelId: PropTypes.string
};

export default injectIntl(withRouter(BaseAgreementContractorFilter));
