export const MODULE = {
    name: "module",
    importance: 1
};

export const WINDOW = {
    name: "window",
    importance: 2
};

export const COMPONENT = {
    name: "component",
    importance: 4
};

export const ELEMENT = {
    name: "element",
    importance: 8
};

export const META_DATA = "data";