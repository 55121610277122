import React from "react";
import PropTypes from "prop-types";
import {FormattedNumber} from "react-intl";

export default class ListMoney extends React.Component {

    renderContent = (formattedNumber) => {
        const integer = formattedNumber.substring(0, formattedNumber.length - 3);
        const fraction = formattedNumber.substring(formattedNumber.length - 3, formattedNumber.length);
        let prefix = this.props.colorize && +this.props.amount > 0 ? "+" : "";

        return [
            <span key={0} className="abs-list__price-bold">{prefix + integer}</span>,
            <span key={1} className="abs-list__price">{fraction}</span>
        ]
    };

    render() {
        let {amount, minimumFractionDigits, maximumFractionDigits, prefix} = this.props;

        if (amount === undefined || amount === null) {
            return null;
        }

        minimumFractionDigits = minimumFractionDigits === undefined ? 2 : this.props.minimumFractionDigits;
        maximumFractionDigits = maximumFractionDigits === undefined ? 2 : this.props.maximumFractionDigits;

        if (minimumFractionDigits > maximumFractionDigits) {
            minimumFractionDigits = maximumFractionDigits;
        }

        const {currency} = this.props;
        let colorClass = "";

        if (this.props.colorize) {
            colorClass = " info";

            if (this.props.amount > 0) {
                colorClass = " success";
            }

            if (this.props.amount < 0) {
                colorClass = " danger";
            }
        }

        return (
            <span className={"abs-list__price--a" + colorClass}>
                {prefix && <span>{prefix}&nbsp;</span>}
                <FormattedNumber value={amount} minimumFractionDigits={minimumFractionDigits}
                                 maximumFractionDigits={maximumFractionDigits}>{this.renderContent}</FormattedNumber>
                {currency && <span className="abs-list__price"> {currency}</span>}
            </span>
        )
    }
}

ListMoney.propTypes = {
    amount: PropTypes.number,
    currency: PropTypes.string,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
    colorize: PropTypes.bool
};

ListMoney.defaultProps = {
    colorize: false
};
