import React from "react";
import Calendar from 'rc-calendar';
import _ from 'lodash';
import plPL from 'rc-calendar/lib/locale/pl_PL';
import enUS from 'rc-calendar/lib/locale/en_US';

const CustomCalendar = ({defaultValue, locale, onSelect, selectedValue, disabledDate, additionalTranslations = {}}) => {
    const baseTranslations = _.isEqual(locale, "pl_PL") ? plPL : enUS;
    const translations = {...baseTranslations, ...additionalTranslations, ...{dayFormat: "dddd"}};

    return (
        <Calendar defaultValue={defaultValue}
                  locale={translations}
                  showDateInput={false}
                  showToday={false}
                  onSelect={onSelect}
                  selectedValue={selectedValue}
                  disabledDate={disabledDate}
        />
    );
};

export default CustomCalendar;