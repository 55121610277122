import {DefinitionReturn} from "../types/DefinitionReturn";
import {FW_TOAST_BRAND} from "../enums/FWToastBrandEnum";
// @ts-ignore
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty';

type InvoiceAddingToastType = {
    invoiceNumber: string,
    invoiceValue: number,
    invoiceCurrency: string,
    refresh: boolean
}

export const INVOICE_ADDING_TOAST = (params: InvoiceAddingToastType): DefinitionReturn => ({
    templateProps: params,
    timeOut: 6000,
    refresh: !isNullOrEmpty(params.refresh) ? params.refresh : false,
    type: FW_TOAST_BRAND.INFO_FOR_INVOICE_ADDING
})