import {DefinitionReturn} from "../types/DefinitionReturn";
import {FW_TOAST_BRAND} from "../enums/FWToastBrandEnum";
// @ts-ignore
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty';

type PackageSaveInfoToastType = {
    refresh: boolean
}

export const PACKAGE_SAVE_INFO_TOAST = (params: PackageSaveInfoToastType): DefinitionReturn => ({
    timeOut: 6000,
    refresh: !isNullOrEmpty(params.refresh) ? params.refresh : false,
    type: FW_TOAST_BRAND.INFO_FOR_PACKAGE_SAVE_INFO
})