import _ from "lodash";

export default class ImmutableUtils {

    static contains(collection, item) {
        return !!collection.find((value) => value === item);
    }

    static isEmpty(collection) {
        return _.isNil(collection) || collection.size === 0
    }
}

