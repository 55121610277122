import React from "react";
import classnames from "classnames";
import IconWithText from "../dataPresentation/IconWithText";
import I18nMessage from "../i18n/I18nMessage";
import _ from "lodash";

export default class ErrorMessage extends React.PureComponent {

    render() {
        const {message, messageParams, className} = this.props;

        if (_.isString(message)) {
            return (
                <div className={classnames("abs-form-field-error-message", className)}>
                    <IconWithText icon="abs_error" text={<I18nMessage id={message} {...messageParams && {values: messageParams}}/>}/>
                </div>
            )
        }

        return (
            <div className={classnames("abs-form-field-error-message", className)}>
                <IconWithText icon="abs_error" text={message}/>
            </div>
        )
    }
}
