import React from "react";
import {Tooltip, OverlayTrigger} from "react-bootstrap";
import {I18nMessage, Button} from "assecobs-faktor-web-common";

export default class AccordionExportButton extends React.Component {

    render() {
        const {onClick, onHover} = this.props;

        const exportTooltip = (
            <Tooltip id="tooltip"><I18nMessage id="common.export"/></Tooltip>
        );

        return (
            <OverlayTrigger placement="top" overlay={exportTooltip} delay={100} animation={false}>
                <Button styleType="D"
                        icon="abs_get_app"
                        onClick={onClick}
                        onMouseOver={onHover}
                        className="fw-accordion-panel-export-icon"
                />
            </OverlayTrigger>
        );
    }
}
