import React from "react";
import Money from "../dataPresentation/Money";

export default class AnalyticalSummaryAdditionalData extends React.Component {

    render() {
        const {data} = this.props;

        return (
            <div className="text-chart-item additional-item">
                <p className="item-info">{data.title}</p>
                <p className="item-info-main"><Money amount={data.amount} currency={data.currency}/></p>
                <p className="item-info-sub">{data.subtitle}<span>{data.quantity} </span></p>
            </div>

        )
    }

}



