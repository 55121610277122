import React from "react";
import {MultiSelectFilterComponent} from "assecobs-react-data-table/index";
import {createFilteringParams} from "assecobs-react-data-table/dataTableUtils";
import {fwUrlUtils, RestService} from "assecobs-faktor-web-common/index";
import ContractorSelectedValueTemplate from "../filters/contractor/ContractorSelectedValueTemplate";
import ContractorMultiOptionTemplate from "../filters/contractor/ContractorMultiOptionTemplate";
import contractorFilterParams from "../filters/contractor/contractorFilterParams";
import {injectIntl} from 'react-intl';
import _ from "lodash";
import classnames from "classnames";

const URL_BASE_AGREEMENT_CONTEXT = fwUrlUtils.getApiUrl("/webapi/filter/contractors/baseAgreementContext");
const URL_ALL_CONTRACTORS_BASE_AGREEMENT_CONTEXT = fwUrlUtils.getApiUrl("/webapi/filter/contractors/allContractorsBaseAgreementContext");
const URL_CONTRACTOR_CONTEXT = fwUrlUtils.getApiUrl("/webapi/filter/contractors/contractorClientContext");

const getContractors = async (isAllContractors, baseAgreements) => {
    if (isAllContractors) {
        return await RestService.postJSON(URL_ALL_CONTRACTORS_BASE_AGREEMENT_CONTEXT, createFilteringParams({
            baseAgreements: baseAgreements
        }));
    }

    return await RestService.postJSON(URL_BASE_AGREEMENT_CONTEXT, createFilteringParams({
        baseAgreements: baseAgreements
    }));
};

class ContractorsSelectForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: null,
            loading: false
        }
    }

    componentDidMount = async () => {
        await this.setState({
            loading: true
        });

        const contractors = await RestService.getJSON(URL_CONTRACTOR_CONTEXT);

        await this.setState({
            options: contractors,
            loading: false
        })
    };

    componentDidUpdate = async (prevProps) => {
        if (!_.isEqual(prevProps.baseAgreements, this.props.baseAgreements)) {

            await this.setState({
                loading: true
            });

            const contractors = await getContractors(this.props.allContractors, this.props.baseAgreements);

            await this.setState({
                options: contractors,
                loading: false
            });
        }
    };

    onChange = (contractors) => {
        if (!_.isNil(contractors)) {
            let contractorsIds = [];
            let contractorsNames = [];
            contractors.forEach((contractor) => {
                contractorsNames.push(contractor.shortName);
                contractorsIds.push({
                    "ContractorClientId": contractor.contractorClientId
                })
            });
            this.props.input.onChange(contractorsIds, contractorsNames);
            this.props.contractorsOnChange(contractors);
        } else {
            this.props.input.onChange(null);
        }
    };

    render() {
        const {customClass} = this.props;
        this.props.setLoading(this.state.loading);

        return <MultiSelectFilterComponent
            title={<div
                className="custom-select-placeholder">{this.props.intl.formatMessage({id: "fw.everybody"})}</div>}
            {...contractorFilterParams}
            customClass={classnames("k-dropdown", customClass)}
            options={this.state.options}
            loading={this.state.loading}
            onChange={this.onChange}
            selectedValueTemplate={ContractorSelectedValueTemplate}
            optionTemplate={ContractorMultiOptionTemplate}
        />;
    }
}

export default injectIntl(ContractorsSelectForm);
