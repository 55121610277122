import React from 'react';
import PropTypes from "prop-types";
const noob = () => {};
const Spinner = ({
                     onChange,
                     stepValue,
                     value,
                     className = void 0,
                     inputRef = null,
                     maxValue = void 0,
                     minValue = void 0,
                     onKeyDown = noob,
                 }) => {

    const onIncrement = () => {
        let newValue;
        if (value) {
            newValue = Number.parseInt(value) + stepValue;
        } else {
            newValue = minValue;
        }
        onChangeValueStep(incrementCondition, maxValue, newValue)
    };

    const onDecrement = () => {
        let newValue;
        if (value) {
            newValue = Number.parseInt(value) - stepValue;
        } else {
            newValue = minValue;
        }
        onChangeValueStep(decrementCondition, minValue, newValue);
    };

    const decrementCondition = (minValue, newValue) => minValue !== void 0 && newValue < minValue;

    const incrementCondition = (maxValue, newValue) => maxValue !== void 0 && newValue > maxValue;

    const onChangeValueStep = (condition, limit, newValue) => {
        if (condition(limit, newValue)) {
            onChange(limit);
        } else {
            onChange(newValue);
        }
    };

    const onChangeValue = event => {
        onChange(event.target.value);
    };

    return (
        <div className={`absui-spinner ${className}`}>
            <div className="absui-form-control">
                <input type='text' value={value} onChange={onChangeValue} onKeyDown={onKeyDown}
                       ref={inputRef}/>
                <span className="absui-spinner__controls-wrap">
                    <span className="absui-spinner__controls" onClick={onIncrement}>
                        <i className="abs-icon abs_expand_less fw-spinner-icon fw-spinner-icon--less"/>
                    </span>
                    <span className="absui-spinner__controls" onClick={onDecrement}>
                        <i className="abs-icon abs_expand_less fw-spinner-icon fw-spinner-icon--more"/>
                    </span>
                </span>
            </div>
        </div>
    )
};

Spinner.propTypes = {
    onChange: PropTypes.func.isRequired,
    stepValue: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    className: PropTypes.text,
    inputRef: PropTypes.object,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onKeyDown: PropTypes.func,
    regex: PropTypes.object,
};

export default Spinner;