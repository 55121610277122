import React from "react";
import AbstractSelectFilter from "./AbstractSelectFilter";
import FilterField from "../FilterField";
import _ from "lodash";
import MultiSelectFilterComponent from "./MultiSelectFilterComponent";

class MultiSelectFilter extends AbstractSelectFilter {

    constructor(props) {
        super(props);

        this.applyOnChange = props.applyOnChange === true;

        this.state = {
            selectedValues: props.input.value
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.input.value.sort(), nextProps.input.value.sort())) {
            this.setState({
                selectedValues: nextProps.input.value
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options && prevProps.options.length > 0 && !_.isEqual(prevProps.options, this.props.options)) {
            this.props.input.onChange([]);
        }

        if (!_.isEmpty(prevProps.input.value) && _.isEmpty(this.props.input.value)) {
            this.props.input.onChange([]);
        }

        if (this.applyOnChange && !_.isEqual(this.props.input.value.sort(), this.state.selectedValues.sort())) {
            this.submit();
        }
    }

    onClose = () => {
        if (!_.isEqual(this.props.input.value.sort(), this.state.selectedValues.sort())) {
            this.submit();
        }
    };

    submit = () => {
        this.props.onSubmit(this.state.selectedValues);
    };

    onChange = (selected) => {
        const ids = selected ? selected.map(item => {
            return item[this.props.optionValueProp]
        }) : [];

        this.setState({
            selectedValues: ids
        });
    };

    render() {
        const className = "abs-dropdown-form" + (this.state && this.state.selectedValues.length > 0 ? " abs-dropdown-form-activated" : "");

        return (
            <MultiSelectFilterComponent {...this.props} onClose={this.onClose} onChange={this.onChange}
                                        customClass={className}/>
        );
    }

}

export default (props) => (
    <FilterField {...props}
                 Content={MultiSelectFilter}
                 format={value => value || []}
    />
)
