type FileFailureType = {
  UploadFailure: Array<string>
  UploadFailureZIP: Array<string>
}

interface FileFailureAndRejectedType extends FileFailureType {
  RejectedFiles: Array<string>
}

type FileFailureOutputType = {
  files: Array<string>
  filesZIP: Array<string>
}

export const getFilesFailure = ({
                                  UploadFailure,
                                  UploadFailureZIP,
                                }: FileFailureType): FileFailureOutputType => ({
  files: UploadFailure,
  filesZIP: UploadFailureZIP,
})

export const getFilesFailureAndFilesRejected = ({
                                                  UploadFailure = [],
                                                  UploadFailureZIP = [],
                                                  RejectedFiles = [],
                                                }: FileFailureAndRejectedType): FileFailureOutputType => ({
  files: Array.from(new Set([...UploadFailure, ...RejectedFiles])),
  filesZIP: UploadFailureZIP,
})

export const isFileFailure = ({UploadFailureZIP, UploadFailure}: FileFailureType): boolean => {
  const hasUploadFailure = isNonEmptyArray(UploadFailure)
  const hasUploadFailureZIP = isNonEmptyArray(UploadFailureZIP)

  return hasUploadFailure || hasUploadFailureZIP
}

const isNonEmptyArray = (array?: string[]): boolean => Array.isArray(array) && array.length > 0