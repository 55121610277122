import {ReactElement, useState} from 'react'
// @ts-ignore
import {Config, I18nMessage} from 'assecobs-faktor-web-common/index'
import AttachmentShowMoreDetails from './AttachmentShowMoreDetails'
import AttachmentShowMoreLink from './AttachmentShowMoreLink'

const AttachmentShowMore = (): ReactElement => {
  const [showMore, setShowMore] = useState(false)
  const handleClick = () => {
    setShowMore(true)
  }

  if (showMore) {
    return (<AttachmentShowMoreDetails />)
  }

  return (<AttachmentShowMoreLink onClick={handleClick} />)
}

export default AttachmentShowMore