import React from "react";
import {CustomSuperSelect} from "assecobs-react-data-table/index";

class SelectedValue extends React.Component {
    render() {
        const {data, template} = this.props;

        if (data) {
            return <span className="abs-select__selected-value">{template(data)}</span>
        }
        return null;
    }
}

export default class SingleSelectFilterComponent extends React.Component {

    render() {
        const {title, optionNameProp, selectedValueTemplate, optionTemplate, clearable, ...rest} = this.props;

        const customSelectedValueTemplate = selectedValueTemplate ? selectedValueTemplate : props => <SelectedValue
            data={props.data}
            template={item => item[optionNameProp]} name={title}/>;

        const customOptionTemplate = optionTemplate ? optionTemplate : ({option}) => (
            <div>{option[this.props.optionNameProp]}</div>
        );

        return (
            <CustomSuperSelect
                {...rest}
                placeholder={title}
                optionTemplate={customOptionTemplate}
                selectedValueTemplate={customSelectedValueTemplate}
                multiple={false}
                deselectOnSelectedOptionClick={false}
                clearable={clearable}
                clearSearchOnSelection={true}
                clearSelectedValueOnDataSourceChange={true}
                closeOnSelectedOptionClick={false}
                keepOpenOnSelection={false}
            />
        )

    }
}
