import React from "react";
import {CustomSuperSelect} from "assecobs-react-data-table/index";
import {ContainerLoader} from "assecobs-faktor-web-common";

class SelectedValue extends React.Component {
    render() {
        const {data, template, name} = this.props;

        let fullDisplayedValue;
        let displayedValue;

        if (data) {
            fullDisplayedValue = data.map(item => template(item)).join();
            displayedValue = fullDisplayedValue;

            if (fullDisplayedValue.length > 10) {
                displayedValue = fullDisplayedValue.substring(0, 10) + "...";
            }
        }

        return (
            <span className="abs-multiselect-filter__placeholder">
                <span className="abs-multiselect-filter__placeholder-title">{name}</span>
                {data &&
                <span className="abs-multiselect-filter__placeholder-value">: ({data.length}) {displayedValue}</span>}
            </span>
        );
    }
}

export default class MultiSelectFilterComponent extends React.Component {

    render() {
        const {title, selectedValueTemplate, optionTemplate, optionNameProp, loading, ...rest} = this.props;

        const selectedValue = selectedValueTemplate ? selectedValueTemplate : item => item[optionNameProp];
        const customSelectedValueTemplate = selectedValueTemplate ? selectedValueTemplate : props => <SelectedValue
            data={Array.isArray(props.data) ? props.data : [props.data]}
            template={selectedValue} name={title}/>;

        const customOptionTemplate = optionTemplate ? optionTemplate : ({option, selectedIds}) => {
            const checked = selectedIds.indexOf(option[this.props.optionValueProp]) > -1;
            const checkboxId = "option-" + option[this.props.optionValueProp];
            return (
                <label className="r-ss-dropdown-multiselect-option" htmlFor={checkboxId}>
                    <input id={checkboxId} className="toggle toggle-flip" type="checkbox" checked={checked}/>
                    <label htmlFor={checkboxId}/>

                    <div>{option[this.props.optionNameProp]}</div>
                </label>
            );
        };

        return (
            <ContainerLoader filter={true} loading={loading}>
                <CustomSuperSelect
                    {...rest}
                    placeholder={title}
                    optionTemplate={customOptionTemplate}
                    selectedValueTemplate={customSelectedValueTemplate}
                    multiple={true}
                    deselectOnSelectedOptionClick={true}
                    clearable={true}
                    clearSearchOnSelection={false}
                    clearSelectedValueOnDataSourceChange={true}
                    closeOnSelectedOptionClick={false}
                    keepOpenOnSelection={true}
                />
            </ContainerLoader>
        )

    }
}
