import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage, Money} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type InvoiceAddingToastType = {
    invoiceNumber: string,
    invoiceValue: number,
    invoiceCurrency: string,
    refresh: boolean
}

interface ToastInfoForInvoiceAddingTemplateType extends TemplateType {
    templateProps: InvoiceAddingToastType;
}

const ToastInfoForInvoiceAddingTemplate = ({
                                               templateProps,
                                               removeToast,
                                               id,
                                               timeOut = 5000
                                           }: ToastInfoForInvoiceAddingTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.invoice.adding.toast.header"/>
    const messageBody = <InvoiceAddingToast {...templateProps} />

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

const InvoiceAddingToast = ({
                                invoiceNumber,
                                invoiceValue,
                                invoiceCurrency
                            }: InvoiceAddingToastType): ReactElement => (
    <>
        <I18nMessage id="fw.invoice.no"/>: {invoiceNumber}<br/>
        <I18nMessage id="fw.invoice.toast.value"/>: <Money amount={invoiceValue} currency={invoiceCurrency}/>
    </>
)

ToastInfoForInvoiceAddingTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForInvoiceAddingTemplate