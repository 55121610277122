import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {I18nMessage,} from "assecobs-faktor-web-common/index";
import {ErrorMessage} from "assecobs-faktor-web-common/forms";
import {Collapse} from "react-bootstrap";

export default class FormFieldComponent extends React.PureComponent {

    render() {
        const {id, meta, required, labelKey, className, customClass, InputComponent, size, collapseActive, open, ...rest} = this.props;

        const invalid = meta.touched && meta.error;

        const label = labelKey && (
            <label className={classnames("fw-form-field__label", {
                "fw-form-field__label--required": (collapseActive && required) ? open : required
            }, customClass)}>
                {collapseActive ? (
                    <Collapse in={open}>
                        <div>
                            <I18nMessage id={labelKey}/>
                        </div>
                    </Collapse>
                ) : (
                    <I18nMessage id={labelKey}/>
                )}
            </label>
        );

        const component = (
            <div className={classnames("fw-form-field__control", {"fw-form-field--invalid": invalid}, `fw-form-field__control--size-${size}`)}>

                {collapseActive ? (
                    <Collapse in={open}>
                        <div><InputComponent id={id} meta={meta} invalid={invalid} customClass={customClass} {...rest} /></div>
                    </Collapse>
                ) : (
                    <InputComponent id={id} meta={meta} invalid={invalid} customClass={customClass} {...rest} />
                )}

                {invalid && <ErrorMessage message={meta.error}/>}
            </div>
        )

        return (
            <div className={classnames("fw-form-field", {"fw-form-field--no-label": !labelKey}, className)}>
                {label}

                {component}
            </div>
        )

    }

    static get propTypes() {
        return {
            size: PropTypes.oneOf(["S", "M", "L"])
        }
    }

    static get defaultProps() {
        return {
            size: "M"
        }
    }
}
