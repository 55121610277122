import React from "react";
import MediaQuery from "react-responsive";
import _ from "lodash";
import classnames from "classnames";

import {Icon} from "@assecobs/react-common-components/index";

import SelectCheckbox from "./SelectCheckbox";

const FirstColFlex = ({menuExist, children}) => {
    return <MediaQuery minWidth={768}>
        {(matches) => matches ? <div className={classnames("flex-col", {"with-dropdown": menuExist})}>
            {children}
        </div> : children
        }
    </MediaQuery>
};

export default class FirstCell extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menuExist: !!props.menu};
    }

    onOneSelectionChange = (id) => {
        this.props.onOneSelectionChange(id);
    };

    disableMenu = () => {
        this.setState({menuExist: false});
    };

    goToDetails = () => {
        if (this.props.detailsLink && window.innerWidth < 768) {
            window.location.href = this.props.detailsLink;
        }
    };

    render() {
        const {label, menu, multiselect, disabled, id, selected, className, expandable, detailsLink} = this.props;
        const children = React.Children.toArray(React.Children.toArray(this.props.children)[0].props.children);

        const content = (
            <div className="info" onClick={this.goToDetails}>
                <div className="fw_info">
                            <span>
                                <div className="nr abs-label" data-label={label}>{children[0]}</div>
                                {children.slice(1)}
                            </span>
                </div>
                {!_.isNil(detailsLink) && <MediaQuery maxWidth={767}>
                    <Icon name="abs_keyboard_arrow_right2" className="details-arrow"/>
                </MediaQuery>}
            </div>
        );

        if (expandable) {
            return (
                <div className={classnames("col abs-list-table_item abs-list-table_head", className)}>
                    <Icon name="abs_expand_more abs-mobile-none"/>
                    {content}
                </div>
            )
        }

        return (
            <div className={classnames("col abs-flexbox", className)}>
                <FirstColFlex menuExist={this.state.menuExist}>
                    <div className="before-dropdown">
                        {multiselect &&
                        <SelectCheckbox id={"select_" + id}
                                        checked={selected}
                                        disabled={disabled}
                                        {...(!disabled && {onChange: () => this.onOneSelectionChange(id)})}
                        />
                        }

                        {content}
                    </div>

                    {(!!menu && this.state.menuExist) ?
                        <MediaQuery minWidth={768}>
                            {(match) => {
                                return match ? <div className="hamburger dropdown abs-dropdown">
                                    {React.cloneElement(menu, {
                                        isListDropdown: true,
                                        disableMenu: this.disableMenu
                                    })}
                                </div> : React.cloneElement(menu, {
                                    isListDropdown: true,
                                    disableMenu: this.disableMenu,
                                    right: true
                                })
                            }}

                        </MediaQuery> : null}
                </FirstColFlex>
            </div>
        )
    }
}
