import React from "react";
import FromI18n from "./FromI18n";
import ToI18n from "./ToI18n";

export default (valueFrom, valueTo, fromI18n, toI18n, component) => {
    const from = <FromI18n i18n={fromI18n} component={component} />;
    const to = <ToI18n i18n={toI18n} component={component} />;

    if (valueFrom && valueTo) {
        return <span>{from} {valueFrom} {to} {valueTo}</span>;
    }

    if (valueFrom) {
        return <span>{from} {valueFrom}</span>;
    }

    if (valueTo) {
        return <span>{to} {valueTo}</span>;
    }

    return null;
};
