import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import {injectIntl} from "react-intl";

const Value = ({value, hint}) => {
    if (value) {
        return <span className={classnames("values-sequence-value", {"hint": hint})}>{value}</span>
    }

    return null;
};

class ValuesSequence extends React.Component {

    render() {
        const {values, titleKey, hint, separateLines, intl} = this.props;

        return (
            <div className={classnames("values-sequence", {"values-sequence--separate-lines": separateLines})}>
                {titleKey && <div
                    className="mobile-column-label">{intl.formatMessage({id: titleKey})}:</div>}
                {values.map((value, index) =>
                    <Value key={index} value={value} hint={hint}/>
                )}
            </div>
        )
    }

    static get propTypes() {
        return {
            text: PropTypes.string,
            url: PropTypes.string,
            external: PropTypes.bool
        }
    }

    static get defaultProps() {
        return {
            external: false
        }
    }
}

export default injectIntl(ValuesSequence);
