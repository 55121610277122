import React from "react";
import NotBreakable from "./NotBreakable";

export default class DateOutput extends React.PureComponent {

    render() {
        return (
            <NotBreakable>{this.props.children}</NotBreakable>
        )
    }
}
