import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import OrderReportToast from "../body/OrderReportToast";
import {TemplateType} from "../types/TemplateType";

type ContractorType = { contractorId: number, shortName: string, contractorClientId: number };
type ContractorsType = Array<ContractorType>;
type BaseAgreementType = { baseAgreementAnnex0Id: number, actualAgreementNo: string };
type OrderReportToastProps = {
    baseAgreement: BaseAgreementType,
    cyclicityType: string,
    fileExtensionDesc: string,
    reportType: string,
    contractors: ContractorsType,
    refresh: boolean
}

interface ToastInfoForOrderReportTemplateType extends TemplateType {
    templateProps: OrderReportToastProps;
}

const ToastInfoForOrderReportTemplate = ({
                                             templateProps,
                                             removeToast,
                                             id,
                                             timeOut = 5000
                                         }: ToastInfoForOrderReportTemplateType): ReactElement => {
    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.orderReport.notification.success"/>
    const messageBody = <OrderReportToast {...templateProps} />

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastInfoForOrderReportTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForOrderReportTemplate