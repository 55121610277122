import {AnimationEvent, useState} from 'react'
// @ts-ignore
import classnames from 'classnames'
import {Callable} from "@assecobs-js-utils/common-types/src/types/Callable";

interface UseToastWrapperTemplate {
    id: string,
    removeToast: (id: string) => void
}

interface UseToastWrapperDefaultTemplateReturn {
    className: string,
    onAnimationEnd: (event: AnimationEvent<HTMLDivElement>) => void,
    onClose: Callable
}

const useToastWrapperTemplate = ({
                                     id,
                                     removeToast
                                 }: UseToastWrapperTemplate): UseToastWrapperDefaultTemplateReturn => {
    const [isAnimationOut, setIsAnimationOut] = useState(false)

    const onClose = () => {
        setIsAnimationOut(true)
    }

    const onAnimationEnd = (event: AnimationEvent<HTMLDivElement>) => {
        if (event['animationName'] === 'slide-out') {
            removeToast(id)
        }
    }

    const className = classnames('toastContainer-toast toastContainer-slide-in', {'toastContainer-slide-out': isAnimationOut})

    return {className, onAnimationEnd, onClose}
}

export {useToastWrapperTemplate}