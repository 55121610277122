import React from "react";
import FilterComponent from "./FilterComponent";
import FilterField from "../FilterField";
import {CustomCalendar} from "assecobs-faktor-web-common/index";
import moment from "moment";
import TodayI18n from "./utils/TodayI18n";
import FromI18n from "./utils/FromI18n";
import ToI18n from "./utils/ToI18n";
import OKI18n from "./utils/OKI18n";
import CancelI18n from "./utils/CancelI18n";
import rangeValueFormatter from "./utils/rangeValueFormatter";
import {Config, DateUtils} from "assecobs-faktor-web-common/index";
import {getApplicationLanguage} from "assecobs-react-utils/utils";
const LANG = getApplicationLanguage();

class DateRange extends FilterComponent {

    constructor(props) {
        super(props);

        this.state = {
            from: null,
            to: null
        }
    }

    onDateFromSelect = (date) => {
        this.setState({
                from: date
            }
        );
    };

    onDateToSelect = (date) => {
        this.setState({
                to: date
            }
        );
    };

    disabledEndDate = (endValue) => {
        if (!endValue) {
            return false;
        }
        const startValue = this.state.from;
        if (!startValue) {
            return false;
        }
        return endValue.isBefore(startValue);
    };

    disabledStartDate = (startValue) => {
        if (!startValue) {
            return false;
        }
        const endValue = this.state.to;
        if (!endValue) {
            return false;
        }
        return endValue.isBefore(startValue);
    };

    submit = async (format) => {
        await this.props.onSubmit({
            from: this.state.from || null,
            to: this.state.to || null
        });
    };

    cancel = () => {
        this.props.onCancel();
    };

    stopEvent = (event) => {
        event.nativeEvent.stopImmediatePropagation();
    };

    render() {
        const today = <TodayI18n i18n={this.props.todayI18n} component={this.props.i18nComponent} />;

        const now = DateUtils.startOfDay(moment(), LANG);
        const then = DateUtils.endOfDay(moment(), LANG);
        const format = Config.dateFormat();

        return (
            <div>
                <div onClick={this.stopEvent}>
                    <div className="abs-filter-form">
                        <div className="abs-start-calendar">
                            <div>
                                <FromI18n i18n={this.props.fromI18n} component={this.props.i18nComponent} />
                                <input ref="dateFrom"
                                       className="abs-filter-calendar-complex-date-start-text k-textbox"
                                       value={this.state.from ? this.state.from.format(format) : ""}
                                       readOnly/>

                                <a onClick={() => this.onDateFromSelect(null)}
                                   className="abs-filter-calendar-date-complex__clear"><span className="abs-icon abs_clear_filter"/></a>

                                <a onClick={() => this.onDateFromSelect(now)}
                                   className="abs-filter-calendar-date-complex__calendar"
                                   disabled={this.disabledStartDate(now)}>{today}</a>
                            </div>
                            <CustomCalendar locale={LANG} onSelect={this.onDateFromSelect}
                                      selectedValue={this.state.from}
                                      disabledDate={this.disabledStartDate}
                            />
                        </div>
                        <div className="abs-end-calendar">
                            <div>
                                <ToI18n i18n={this.props.toI18n} component={this.props.i18nComponent} />
                                <input ref="to"
                                       className="abs-filter-calendar-complex-date-end-text k-textbox"
                                       value={this.state.to ? this.state.to.format(format) : ""}
                                       readOnly/>

                                <a onClick={() => this.onDateToSelect(null)}
                                   className="abs-filter-calendar-date-complex__clear"><span className="abs-icon abs_clear_filter"/></a>

                                <a onClick={() => this.onDateToSelect(then)}
                                   className="abs-filter-calendar-date-complex__calendar"
                                   disabled={this.disabledEndDate(then)}>{today}</a>
                            </div>
                            <CustomCalendar locale={LANG} onSelect={this.onDateToSelect}
                                      selectedValue={this.state.to}
                                      disabledDate={this.disabledEndDate}/>
                        </div>
                    </div>
                </div>
                <div className="abs-filter-actions">
                    <button onClick={() => this.submit(Config.apiDateFormat())} className="k-button k-state-default btn btn-type-b btn-xs"><OKI18n i18n={this.props.okI18n} component={this.props.i18nComponent}/></button>
                    <button onClick={this.cancel} className="k-button k-state-default btn btn-type-a btn-xs"><CancelI18n i18n={this.props.cancelI18n} component={this.props.i18nComponent}/></button>

                    {/*TODO: Do usunięcia po naprawie styli*/}
                    <button className="btn" style={{display: "none"}}/>
                </div>
            </div>
        )
    }
}

const selectedDateRangeFormatter = (value, fromI18n, toI18n, component) => {
    const format = Config.dateFormat();

    let from = value.from ? moment(value.from).format(format) : undefined;
    let to = value.to ? moment(value.to).format(format) : undefined;

    return rangeValueFormatter(from, to, fromI18n, toI18n, component);
};

export default (props) => (
    <FilterField {...props}
                 Content={DateRange}
                 dropdownClass="calendar-dropdown abs-filter-calendar-complex"
                 normalize={value => {
                     return value ? {
                         ...value.from && {from: DateUtils.startOfDay(value.from, null, Config.apiDateFormat())},
                         ...value.to && {to: DateUtils.endOfDay(value.to, null, Config.apiDateFormat())}
                     } : null
                 }}
                 format={value => {
                     return value ? {
                         ...value.from && {from: moment(value.from)},
                         ...value.to && {to: moment(value.to)}
                     } : null
                 }
                 }
                 selectedValueFormatter={value => selectedDateRangeFormatter(value, props.fromI18n, props.toI18n, props.i18nComponent)}/>
)
