import React from "react";
import PropTypes from "prop-types";

const Option = ({label, onClick, value}) => {

    const handleClick = () => {
        onClick(value);
    };

    return (
        <li onClick={handleClick}><a href="#">{label}</a></li>
    );
};

Option.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

export default Option;