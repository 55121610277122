import React from "react";
import FirstCell from "./FirstCell";

export default class CustomCell extends React.Component {

    render() {
        if (this.props.first) {
            return (
                <FirstCell {...this.props} />
            )
        }

        return (
            <div className={"col col-a " + (this.props.className || "")}>
                <div className="label-a" data-label={this.props.label + ":"}>{this.props.children}</div>
            </div>
        )
    }
}
