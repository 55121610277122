import React from 'react';
import PropTypes from 'prop-types';
import {I18nMessage} from "assecobs-faktor-web-common/index";

const RemainingSignsLabel = ({maxLength, valueLength}) => (
    <div className="light-txt remaining-signs-creatable-select-input">
        <I18nMessage id="fw.remainingChars"
                     values={{
                         remaining: maxLength - valueLength,
                         max: maxLength
                     }}/>
    </div>
)
RemainingSignsLabel.propTypes = {
    maxLength: PropTypes.number.isRequired,
    valueLength: PropTypes.number.isRequired
};

export default RemainingSignsLabel;