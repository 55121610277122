import React from "react";
import PropTypes from 'prop-types';
import I18nMessage from "../../i18n/I18nMessage";
import classnames from "classnames";
import _ from "lodash";
import MediaQuery from "react-responsive";

export default class ButtonMenuItem extends React.PureComponent {

    render() {
        const {label, additionalLabel, url, style, styleClass} = this.props;

        return (
            <li>
                <MediaQuery minWidth={768}>
                    {(matches) => {
                        return <button onClick={() => window.location = url}
                                       className={classnames({
                                           "btn btn-type-b": _.isEqual(style, "BUTTON_PRIMARY") && matches,
                                           "btn btn-type-a": _.isEqual(style, "BUTTON_SECONDARY") && matches,
                                           "btn-type-menu-mobile": _.isEqual(style, "BUTTON_SECONDARY") && !matches,
                                           "btn-type-menu-mobile btn-primary": _.isEqual(style, "BUTTON_PRIMARY") && !matches
                                       }, styleClass)}>

                            <I18nMessage id={label} className="btn-label"/>

                            {additionalLabel && (matches ? (
                                <div className="button-menu-additional-label">
                                    <I18nMessage id={additionalLabel} className="btn-additional-label"/>
                                </div>
                            ) : <I18nMessage id={additionalLabel} className="btn-label-mobile"/>)}
                        </button>
                    }}
                </MediaQuery>
            </li>
        );

    }

    static get propTypes() {
        return {
            label: PropTypes.string,
            additionalLabel: PropTypes.string,
            url: PropTypes.string,
            style: PropTypes.string,
            styleClass: PropTypes.string
        }
    }
}
