import React from "react";
import {connect} from "react-redux";
import {MultiSelectFilter, AbstractSelectFilter} from "assecobs-react-data-table/index";
import baseAgreementFilterParams from "../../baseAgreement/baseAgreementFilterParams";
import {RestService, fwUrlUtils} from "assecobs-faktor-web-common/index";
import {List} from "immutable";
import BaseAgreementMultiOptionTemplate from "../../baseAgreement/BaseAgreementMultiOptionTemplate";

import {injectIntl} from "react-intl";
import {formValueSelector} from "redux-form";
import {withRouter} from "react-router";
import PropTypes from "prop-types";

const URL = fwUrlUtils.getApiUrl("/webapi/filter/baseAgreements/all");
const isMounted = Symbol();

class BaseAgreementFilterComponent extends AbstractSelectFilter {

    constructor(props) {
        super(props);

        this.applyOnChange = props.applyOnChange || false;

        this.state = {
            options: new List(),
            loading: false
        };
        this[isMounted] = false;
    }

    componentDidMount = async () => {
        this[isMounted] = true;
        await this.setState({
            loading: true
        }, async () => {
            const baseAgreements = await RestService.postJSON(URL);

            if (this[isMounted]) {
                this.setState({
                    options: baseAgreements,
                    loading: false
                })
            }
        });
    };

    componentWillUnmount() {
        this[isMounted] = false;
    }

    render() {

        const {selectedBaseAgreements} = this.props;

        return (
            <MultiSelectFilter
                {...baseAgreementFilterParams}
                {...this.props}
                id={this.props.id || "baseAgreements"}
                initialValue={selectedBaseAgreements || undefined}
                title={this.props.intl.formatMessage({id: "fw.agreement"})}
                searchPlaceholder={this.props.intl.formatMessage({id: "fw.filter.baseAgreement.searchPlaceholder"})}
                options={this.state.options}
                loading={this.state.loading}
                optionTemplate={BaseAgreementMultiOptionTemplate}
                applyOnChange={this.props.applyOnChange}
            />
        )

    }
}

const BaseAgreementFilterComponentWrapper = connect((state, props) => ({
    selectedBaseAgreements: formValueSelector('data', state => state[`${props.filterPanelId}FilterPanel`])(state, 'baseAgreements')
}))(injectIntl(BaseAgreementFilterComponent));

const BaseAgreementFilter = (props, context) => (
    <BaseAgreementFilterComponentWrapper {...props} filterPanelId={context.filterPanelId}/>
);

BaseAgreementFilter.contextTypes = {
    filterPanelId: PropTypes.string
};

export default injectIntl(withRouter(BaseAgreementFilter));
