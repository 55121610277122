import React from "react";
import MediaQuery from "react-responsive";
import _ from "lodash";
import {Loadable} from "@assecobs/react-common-components/index";
import classnames from "classnames";

class DetailValue extends React.PureComponent {

    render() {
        const {value, primary, additional, noBreak} = this.props;

        const className = "detail-title-truncate-number" + (noBreak ? " no-break " : "");

        if (primary) {
            return <div>
                <div className="detail-title title-primary" title={value}>
                    <div className={className}>{value}</div>
                </div>
            </div>
        }

        return (
            <div>
                <div className={"detail-value" + (additional ? " additional-info" : "")}>
                    <span className={className}>{value}</span>
                </div>
            </div>
        )
    }

    static defaultProps = {
        primary: false,
        additional: false,
        noBreak: false
    }
}

class DetailSection extends React.PureComponent {

    render() {
        const {title, rendered} = this.props;

        if (!rendered) {
            return null;
        }

        return (
            <div className="detail-section">
                {title ? <span className="detail-title">{title}</span> : null}

                {this.props.children}
            </div>
        )
    }

    static defaultProps = {
        rendered: true
    }
}

const detailSectionsBlock = Symbol();

class DetailSectionBlock extends React.Component {

    constructor() {
        super();
        this.state = {scrollable: false};
        this[detailSectionsBlock] = null;
    }

    componentDidMount() {
        if (!_.isNil(this[detailSectionsBlock]) && this[detailSectionsBlock].getBoundingClientRect().width > window.innerWidth) {
            this.setState({scrollable: true});
        }
    }

    componentDidUpdate() {
        if (!this.props.scrollable) {
            if (!_.isNil(this[detailSectionsBlock]) && this[detailSectionsBlock].getBoundingClientRect().width > window.innerWidth) {
                this.setState({scrollable: true});
            }
        }
    }

    render() {

        const {children} = this.props;

        return <MediaQuery maxWidth={768}>
            {(matches) => {
                if (matches) {

                    return (
                        <div className={classnames("detail-sections-block", {"scrollable": this.state.scrollable})}
                             ref={c => this[detailSectionsBlock] = c}>
                            {children}
                        </div>
                    );

                }
                return children;
            }}
        </MediaQuery>
    }

}

class DetailMainSection extends React.PureComponent {

    render() {
        const {icon, title} = this.props;

        return (
            <div className="detail-section detail-section-main">
                <div className="detail-section__icon">
                    <span className="icon-placeholder">
                        {icon}
                    </span>
                </div>

                {this.props.children}

                <div>
                    <div className="detail-title">{title}</div>
                </div>
            </div>
        )
    }
}

class DetailsPanel extends React.PureComponent {

    render() {
        const {loading} = this.props;

        return (
            <Loadable loading={loading} className="details-panel">
                <div className="details-panel-sections col-md-12 col-sm-12 details-invoice-panel" role="listbox">
                    <div className="detail-section-container container-box-flex">
                        {this.props.children}
                    </div>
                </div>
            </Loadable>
        )
    }
}

DetailsPanel.MainSection = DetailMainSection;
DetailsPanel.Section = DetailSection;
DetailsPanel.Value = DetailValue;
DetailsPanel.SectionBlock = DetailSectionBlock;

export default DetailsPanel;
