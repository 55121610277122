import React from "react";
import ValuesSequence from "./ValuesSequence";
import {injectIntl} from "react-intl";
import _ from "lodash";

class TaxNumberAndCode extends React.PureComponent {

    render() {
        const {taxNumberTypeDesc, taxNumber, code, separateLines, hint, intl} = this.props;

        if (_.isNil(taxNumberTypeDesc) && _.isNil(taxNumber) && _.isNil(code)) {
            return null;
        }

        const taxNumberTypeDescLabel = taxNumberTypeDesc ? `${taxNumberTypeDesc}: ` : "";
        const taxNumberWithLabel = `${taxNumberTypeDescLabel}${taxNumber}`;
        const codeWithLabel = code ? `${intl.formatMessage({id: "fw.contractor.code"})}: ${code}` : null;

        return <ValuesSequence values={[taxNumberWithLabel, codeWithLabel]} hint={hint} separateLines={separateLines}/>;
    }

    static get defaultProps() {
        return {
            hint: true,
            separateLines: true
        }
    }
}

export default injectIntl(TaxNumberAndCode);
