const ACTION_PREFIX = 'DATA_TABLE';

const ACTIONS = [
    'INIT',
    'LOAD',
    'RELOAD',
    'LOAD_SUCCESS',
    'LOAD_MORE',
    'LOAD_MORE_SUCCESS',
    'TOGGLE_SELECT_ALL',
    'TOGGLE_SELECT',
    'CLEAR_SELECTION',
    'FILTER',
    'DO_EXPORT_DATA_XLS',
    'DO_EXPORT_DATA_CSV',
    'EXPORT_DATA'
];

export default (id) => {
    if (!id) {
        throw new Error('itemsProperty must be defined for dataTableReducer')
    }

    return ACTIONS.reduce((actions, name) => ({
        ...actions,
        [name]: `${id}/${ACTION_PREFIX}_${name}`
    }), {})
}
