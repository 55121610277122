import React from "react";
import {connect} from "react-redux";
import BaseListComponent from './baseList/BaseListComponent';
import ExpandableListComponent from './expandableList/ExpandableListComponent';
import actionCreators from "../action/actionCreators";
import ExportFormat from "../constants/ExportFormat";
import {makeIsAllSelected, makeGetSelectable} from "../reducer/selectors";
import _ from "lodash";

import {tableFloatScrollbar} from "assecobs-faktor-web-common";

const actions = Symbol();

class DataTable extends React.Component {

    constructor(props) {
        super(props);
        this[actions] = actionCreators(props.id, props.filteringEnabled, props.pagingEnabled, props.orderingEnabled);
    }

    componentDidMount = () => {
        if (this.props.loadOnMount) {
            const pageSize = this.props.initialPageSize || this.props.pageSize;
            this.props.dispatch(this[actions].init(this.props.filtering, pageSize, this.props.sorting, this.props.itemIdProperty, this.props.itemSelectableProperty))
                .then(() => {
                    if (this.props.filterable === false) {
                        this.load(this.props.pageSize);
                    }
                })
        }
        window.addEventListener("resize", this.updateScrollbar);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateScrollbar);
    }

    componentDidUpdate(prevProps) {
        if (this.props.needsReload && !prevProps.needsReload) {
            this.load(this.props.pageSize);
        }

        if (!_.isEqual(this.props.additionalSearchParams, prevProps.additionalSearchParams)) {
            this.load(this.props.pageSize);
        }

        if (this.props.doExportDataXLS) {
            this.exportDataXLS();
        }

        if (this.props.doExportDataCSV) {
            this.doExportDataCSV();
        }

        if (this.props.defaultSelectAll && !_.isEqual(prevProps.data, this.props.data)) {
            this.onAllSelectionChange();
        }
        if (!this.props.loading && !_.isEqual(prevProps.loading, this.props.loading)) {
            this.updateScrollbar();
        }

        if (this.props.onAllItemsSelected) {
            this.props.onAllItemsSelected({
                areAllItemsSelected: this.props.isAllSelected,
                selectedIds: this.props.selected,
                selectable: this.props.selectable,
                dataSource: this.props.data
            });
        }
    }

    updateScrollbar = () => {
        if (this.props.updateScrollbar) {
            this.props.updateScrollbar(document.getElementById("dataTable_" + this.props.id).offsetWidth);
        }
    };

    load = (pageSize) => {
        this.props.dispatch(this[actions].load(this.props.url, this.props.additionalSearchParams, this.props.filtering, pageSize, this.props.sorting));
    };

    onSort = (sorting) => {
        this.props.dispatch(this[actions].load(this.props.url, this.props.additionalSearchParams, this.props.filtering, this.props.pageSize, sorting));
    };

    onLoadMore = (page) => {
        this.props.dispatch(this[actions].loadMore(this.props.url, this.props.additionalSearchParams, this.props.filtering, {
            index: 1,
            size: this.props.pageSize * page
        }, this.props.sorting));
    };

    exportDataXLS = () => {
        this.props.dispatch(this[actions].exportData(ExportFormat.XLS, this.props.exportKey, this.props.additionalSearchParams, this.props.filtering, this.props.sorting));
    };

    exportDataCSV = () => {
        this.props.dispatch(this[actions].exportData(ExportFormat.CSV, this.props.exportKey, this.props.additionalSearchParams, this.props.filtering, this.props.sorting));
    };

    onAllSelectionChange = () => {
        this.props.dispatch(this[actions].toggleSelectAll());
    };

    onOneSelectionChange = (id) => {
        this.props.dispatch(this[actions].toggleSelect(id));
    };

    render() {
        const {Component, expandable, ...rest} = this.props;

        const DataTableComponent = Component || (expandable ? ExpandableListComponent : BaseListComponent);

        return (
            <DataTableComponent {...rest}
                                expandable={expandable}
                                onSort={this.onSort}
                                onLoadMore={this.onLoadMore}
                                onAllSelectionChange={this.onAllSelectionChange}
                                onOneSelectionChange={this.onOneSelectionChange}
            />
        )
    }

    static defaultProps = {
        loadOnMount: true,
        defaultSelectAll: false
    }
}

const makeMapStateToProps = () => {
    const isAllSelected = makeIsAllSelected();
    const getSelectable = makeGetSelectable();

    const mapStateToProps = (state, props) => {
        return {
            data: state[props.id].data,
            filterable: state[props.id].filterable,
            filtering: state[props.id].filtering,
            filtered: state[props.id].filtered,
            loading: state[props.id].loading,
            sorting: state[props.id].sorting,
            loadedPages: state[props.id].loadedPages,
            totalCount: state[props.id].totalCount,
            pageSize: state[props.id].pageSize,
            selected: state[props.id].selected,
            isAllSelected: isAllSelected(state, props),
            selectable: getSelectable(state, props),
            needsReload: state[props.id].needsReload,
            doExportDataXLS: state[props.id].doExportDataXLS,
            doExportDataCSV: state[props.id].doExportDataCSV,
            exportKey: props.exportKey,
            overflowXVisible:props.overflowXVisible
        }
    };
    return mapStateToProps
};

const Component = window.innerWidth > 768 ? tableFloatScrollbar(DataTable) : DataTable;

export default connect(makeMapStateToProps)(Component);
