import React from "react";
import PropTypes from "prop-types";
import Option from '../options/Option';

const List = ({options, onOptionClick}) => {
    return (
        <ul className="dropdown-menu login-language-select-list">
            {options.map(option => <Option key={option.value} label={option.label} onClick={onOptionClick} value={option.value}/>)}
        </ul>
    );
};

List.propTypes = {
    options: PropTypes.array.isRequired,
    onOptionClick: PropTypes.func.isRequired
};

export default List;