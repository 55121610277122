import React from "react";
import classnames from "classnames";
import _ from "lodash";
import {Field} from "redux-form";
import FormFieldComponent from "./FormFieldComponent";
import {validators} from "../../forms";

export default class FormField extends React.PureComponent {

    render() {
        const {component, required, requiredObj, validate, rowSize, ...rest} = this.props;

        let validate_arr = validate || [];

        if (required) {
            validate_arr.push(validators.required);
        }

        if (requiredObj) {
            validate_arr.push(validators.requiredObj);
        }

        return (
            <Field {...rest}
                   customClass={classnames({"double-sized": _.isEqual(rowSize, "DOUBLE_SIZED")})}
                   required={required || requiredObj}
                   validate={validate_arr}
                   component={FormFieldComponent}
                   InputComponent={component}
            />
        )

    }
}
