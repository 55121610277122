import React from "react";
import {connect} from "react-redux";
import Dropzone from "react-dropzone";
import _ from "lodash";
import classnames from "classnames";
import {Collapse} from "react-bootstrap";
import {Config} from "assecobs-faktor-web-common/index";
import {Icon} from "@assecobs/react-common-components";

import {I18nMessage, ExtensionsInfoComponent} from "assecobs-faktor-web-common";
import {ErrorMessage} from "assecobs-faktor-web-common/forms";
import {megabytesToBytes} from "../index";

import {fetchFileExtensions} from "./actions";

const dropZone = Symbol();
const _isAcceptedFileExtension = Symbol();
const _isAppropriateFileSize = Symbol();
const _isFileExceedsMaximumFilesSize = Symbol();
const _addFilesSize = Symbol();
const _subFilesSize = Symbol();

const ERROR_TYPE = -1;
const ERROR_SIZE = -2;
const ERROR_SIZE_ZIP = -3;
const ERROR_EXCEEDED_FILES_SIZE = -4;
const MAX_FILE_SIZE = 5;
const MAX_FILE_SIZE_ZIP = 10;
const ERROR_REASON_SIZE = 'ERROR_REASON_SIZE';
const ERROR_REASON_EXCEEDED_FILES_SIZE = 'ERROR_REASON_EXCEEDED_FILES_SIZE';
const ERROR_REASON_TYPE = 'ERROR_REASON_TYPE';

class DropZoneComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rejectedClassNameActive: false,
            rejectReason: null,
            _files: [],
            _filesExtensions: [],
            acceptedFileExtensions: [],
            maxFileSize: 0,
            acceptedTypes: "",
        };

        this.countFilesSize = 0;
        this[dropZone] = null;
    }

    componentDidMount = () => {
        this.props.dispatch(fetchFileExtensions());
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.FileData) {
            if (!_.isEqual(this.props.FileData.input.value, prevProps.FileData.input.value) && !this.props.FileData.input.value) {
                this.setState({file: null});
            }
        }
    };

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.fileExtensions, nextProps.fileExtensions) || (this.props.fileExtensions && this.props.fileExtensions.size > 0)) {
            let acceptedTypes = "";
            let maxSize = 0;
            nextProps.fileExtensions.forEach((fileExtension, index) => {

                acceptedTypes += "." + fileExtension.get("fileExtensionName");

                if (!_.isEqual(index, nextProps.fileExtensions.length - 1)) {
                    acceptedTypes += ", ";
                }

                if (fileExtension.get("attachmentMaxSize") > maxSize) {
                    maxSize = fileExtension.get("attachmentMaxSize");
                }

            });

            maxSize = megabytesToBytes(maxSize);
            this.setState({
                acceptedFileExtensions: nextProps.fileExtensions.toJS(),
                maxFileSize: maxSize,
                acceptedTypes: acceptedTypes
            });
        }
    }

    externalFileChange = (file, fileExtensionId) => {

        if (!_.isNull(file)) {
            this.props.FileData.input.onChange(file);
            this.props.FileName.input.onChange(file.name);
            this.props.FileSize.input.onChange(file.size);
            this.props.CommonFileExtensionId.input.onChange(fileExtensionId);
        } else {
            this.props.FileData.input.onChange("");
            this.props.FileName.input.onChange("");
            this.props.FileSize.input.onChange("");
            this.props.CommonFileExtensionId.input.onChange("");
        }

    };

    acceptFile = (files) => {
        let fileAccepted = false;
        let typeAccepted = false;
        let maxFileSize = null;
        let fileExtensions = null;
        let isExceededMaximumFilesSize = false;

        for (let j = 0; j < files.length; j++) {

            if (!this.props.multiple && this.state._files.length > 0) {
                this.state._files.pop();
                this.state._filesExtensions.pop();
            }

            this.state._files.push(files[j]);

            for (let i = 0; i < this.state.acceptedFileExtensions.length; i++) {
                if (this[_isAcceptedFileExtension](this.state.acceptedFileExtensions[i]["mimeTypes"], files[j].type)) {
                    typeAccepted = true;
                    maxFileSize = this.state.acceptedFileExtensions[i]["attachmentMaxSize"];
                    fileExtensions = this.state.acceptedFileExtensions[i]["attachmentFileExtensionId"];

                    if (this[_isFileExceedsMaximumFilesSize](files[j].size)) {
                        isExceededMaximumFilesSize = true;

                        this.state._files[j].INVALID_FILE = true;
                        this.state._files[j].ERROR_REASON = ERROR_REASON_EXCEEDED_FILES_SIZE;
                        this.state._filesExtensions.push(ERROR_EXCEEDED_FILES_SIZE);
                        this.setState({file: files[j], rejectedClassNameActive: false, rejectReason: null});
                        if (this.props.fileOnChange) {
                            this.props.fileOnChange(files[j], ERROR_EXCEEDED_FILES_SIZE);
                        }

                    } else if (this[_isAppropriateFileSize](maxFileSize, files[j].size)) {
                        this[_addFilesSize](files[j].size);

                        if (this.props.fileOnChange)
                            this.props.fileOnChange(files[j], this.state.acceptedFileExtensions[i]["attachmentFileExtensionId"]);

                        this.setState({file: files[j], rejectedClassNameActive: false, rejectReason: null});

                        this.state._filesExtensions.push(this.state.acceptedFileExtensions[i]["attachmentFileExtensionId"]);

                        if (this.props.isFormField)
                            this.externalFileChange(files[j], this.state.acceptedFileExtensions[i]["attachmentFileExtensionId"]);

                        fileAccepted = true;

                    } else {
                        this.state._filesExtensions.push(_.isEqual(fileExtensions, 14) ? ERROR_SIZE_ZIP : ERROR_SIZE);

                        this.setState({file: files[j], rejectedClassNameActive: false, rejectReason: null});
                        if (this.props.fileOnChange) {
                            this.props.fileOnChange(files[j], ERROR_SIZE);
                        }
                    }
                    break;
                } else {
                    if (_.isEqual(i, this.state.acceptedFileExtensions.length - 1)) {
                        this.state._files[j].INVALID_FILE = true;
                        this.state._files[j].ERROR_REASON = ERROR_REASON_TYPE;
                        this.state._filesExtensions.push(ERROR_TYPE);
                        this.setState({file: files[j], rejectedClassNameActive: false, rejectReason: null});
                        if (this.props.fileOnChange)
                            this.props.fileOnChange(files[j], ERROR_TYPE);
                    }
                }
            }
            /*for i*/
        }
        /*for j*/

        if (this.props.filesOnChange) {
            this.props.filesOnChange(this.state._files, this.state._filesExtensions);
        }

        if (this.props.multiple && isExceededMaximumFilesSize) {
            this.props.onExceededFilesSize(Config.getMaxRequestSize());
        }
    };

    openFileWindow = () => {
        this[dropZone].open();
    };

    rejectFile = (fileTypeRejected, reason, reasonParams) => {
        console.log(fileTypeRejected, reason, reasonParams);
    };

    clearFile(item, index) {
        if (this.props.isFormField)
            this.externalFileChange(null);
        if (this.props.fileOnChange)
            this.props.fileOnChange(null, null);

        let tab = this.state._files;
        let tabExtensions = this.state._filesExtensions;

        tab.splice(index, 1);
        this.setState({_files: tab});

        tabExtensions.splice(index, 1);
        this[_subFilesSize](item.size);
        this.setState({_filesExtensions: tabExtensions});
    };

    clearFiles() {
        this.setState({_filesExtensions: [], _files: []});
        if (this.props.filesOnChange)
            this.props.filesOnChange(null, null);
    }

    render() {

        const {fileExtensionsFetching, meta, multiple} = this.props;
        if (fileExtensionsFetching)
            return <div/>;

        const children = <div className="dropzone-content">
            <button className="btn btn-xs btn-default btn-type-b" onClick={this.openFileWindow}><I18nMessage
                id={multiple ? "fw.dropzone.button.title" : "fw.import.firstSection.fileUpload.header.mainText"}/>
            </button>
            <I18nMessage id={multiple ? "fw.attachment.adding.dropzone.text" : "fw.attachment.adding.dropzone.oneFile.text"}
                         className="dropzone-content-desc"/>
        </div>;

        const rejectMessage = this.state.rejectReason ?
            <ErrorMessage key={2} message={this.state.rejectReason.reason}
                          messageParams={this.state.rejectReason.params}/> : null;

        return [
            <div key={1} className="fw-dropzone-centered">
                <div className="fw-dropzone" key={0}>
                    <Dropzone ref={c => this[dropZone] = c} style={{}}
                              className={classnames("dropzone", {
                                  "rejected-dropzone": this.state.rejectedClassNameActive || (meta && meta.error)
                              })}
                              multiple={multiple}
                              children={children}
                              disableClick={true}
                              onDropRejected={(files) => {
                                  if (files[0].size < this.state.maxFileSize) {
                                      this.rejectFile(true, "fw.attachment.adding.invalidType");
                                  } else {
                                      this.rejectFile(false, "fw.attachment.adding.invalidSize", {"maxFileSize": this.state.maxFileSize});
                                  }
                              }}
                              onDropAccepted={this.acceptFile}
                              maxSize={this.state.maxSize}/>
                    <Collapse in={!_.isNil(this.state.file)}>
                        <div>
                            <div className="uploaded-files-content">
                                {
                                    this.state._files.map((item, index) =>
                                        <div key={index}>

                                            <div className="uploaded-file-position">
                                                {this.state._filesExtensions[index] >= 0 &&
                                                <span className="file-name">{item && item.name}</span>}
                                                {this.state._filesExtensions[index] >= 0 &&
                                                <span className="remove uploaded-files-content-right"
                                                      onClick={() => this.clearFile(item, index)}><Icon
                                                    name="abs_indeterminate_check_box"/>
                                                    <I18nMessage id="fw.common.delete" className="remove-title"/>
                                                </span>}
                                            </div>
                                            {this.state._filesExtensions[index] < 0 &&
                                            <span className="file-name file-name--error">{item && item.name}</span>}

                                            {_.isEqual(this.state._filesExtensions[index], ERROR_TYPE) &&
                                            <div className="file-error-message"><Icon name="abs_error"/>
                                                <I18nMessage id="fw.attachment.adding.invalidType"/>
                                            </div>}

                                            {_.isEqual(this.state._filesExtensions[index], ERROR_SIZE) &&
                                            <div className="file-error-message"><Icon name="abs_error"/>
                                                <I18nMessage id="fw.attachment.adding.invalidSize" values={{
                                                    maxFileSize: MAX_FILE_SIZE
                                                }}/>
                                            </div>}

                                            {_.isEqual(this.state._filesExtensions[index], ERROR_SIZE_ZIP) &&
                                            <div className="file-error-message"><Icon name="abs_error"/>
                                                <I18nMessage id="fw.attachment.adding.invalidSize" values={{
                                                    maxFileSize: MAX_FILE_SIZE_ZIP
                                                }}/>
                                            </div>}

                                            {_.isEqual(this.state._filesExtensions[index], ERROR_EXCEEDED_FILES_SIZE) &&
                                            <div className="file-error-message"><Icon name="abs_error"/>
                                                <I18nMessage id="fw.attachment.adding.bunchInvalidSize" values={{
                                                    maxFileSize: Config.getMaxRequestSize()
                                                }}/>
                                            </div>}

                                            {(this.state._files.length > index + 1) &&
                                            <hr className="uploaded-files-content-line"/>}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </Collapse>
                </div>
                <ExtensionsInfoComponent key={1} isMultiple={multiple}/>
            </div>,
            <div key={2}>{rejectMessage}</div>
        ]
    }

    [_isAcceptedFileExtension](acceptedFileExtensions, fileExtension) {
        return _.includes(acceptedFileExtensions, fileExtension);
    }

    [_isAppropriateFileSize](maxFileSizeMB, fileSizeBytes) {
        return _.lte(fileSizeBytes, megabytesToBytes(maxFileSizeMB));
    }

    [_isFileExceedsMaximumFilesSize](fileSizeBytes) {
        return this.countFilesSize + fileSizeBytes > megabytesToBytes(Config.getMaxRequestSize())
    }

    [_addFilesSize](fileSizeBytes) {
        this.countFilesSize += fileSizeBytes;
    }

    [_subFilesSize](fileSizeBytes) {
        this.countFilesSize -= fileSizeBytes;
    }
}

export default connect(({fileExtensionReducer}) => ({
    fileExtensionsFetching: fileExtensionReducer.isFetching,
    fileExtensions: fileExtensionReducer.fileExtensions
}), null, null, {withRef: true})(DropZoneComponent)
