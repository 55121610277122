import React from "react";
import {Tooltip} from "react-bootstrap";
import {Overlay} from "react-overlays";
import {Button, fwUrlUtils, I18nMessage, RestService} from "assecobs-faktor-web-common";
import _ from "lodash";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {PACKAGE_SAVE_INFO_TOAST, toast} from "assecobs-faktor-web-common/toasts/ToastInitializer";

const UNSENT_STATS_URL = fwUrlUtils.getApiUrl("/webapi/unsentStats");
const unsentData = Symbol();
const EMPTY_STRING = "";

class SendButtonOverlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showTooltip: false,
            overlayHeader: EMPTY_STRING
        };
        this[unsentData] = null;
    }

    componentDidUpdate = async (prevProps) => {
        const {checkUnsent} = this.props;
        if (checkUnsent && !_.isEqual(checkUnsent, prevProps.checkUnsent)) {
            await this.showTooltip();
        }
    };

    showTooltip = async () => {
        const data = await RestService.getJSON(UNSENT_STATS_URL);

        if (!data.unsentPresent) {
            window.location.href = data.proceedLink;
        } else {
            this.handleUnsentPresent(data);
        }
    };

    handleUnsentPresent = (data) => {
        const {isTooltipRedirect} = this.props;
        const {invoicesAmount, packagesAmount, proceedLink} = data;

        if (invoicesAmount > 0 || packagesAmount === 0) {
            return this.prepareTooltipData(data);
        }

        if (this.props.showTooltip !== false) {
            toast(PACKAGE_SAVE_INFO_TOAST({
                refresh: isTooltipRedirect
            }));
        }
        window.location.href = proceedLink;
    };

    prepareTooltipData = (data) => {
        const {tooltipType} = this.props;
        const {overlayHeaderForSavePackage, overlayHeader} = data;
        this[unsentData] = data;

        this.setState({
            showTooltip: true,
            overlayHeader: tooltipType === 0 ? overlayHeaderForSavePackage : overlayHeader
        });
    };

    closeTooltip = () => {
        this.setState({showTooltip: false});
    };

    goToSending = () => {
        const {selectedBaseAgreementId} = this.props;
        const baseAgreementId = selectedBaseAgreementId;
        const proceedLink = this[unsentData].proceedLink;

        window.location.href = baseAgreementId
            ? `${proceedLink}?baseAgreementAnnex0Id=${baseAgreementId}`
            : proceedLink;
    };

    continueSending = () => {
        const {invoicesAmount, packagesAmount, proceedLink} = this[unsentData];

        if (invoicesAmount <= 0 && packagesAmount >= 0) {
            return window.location.href = proceedLink;
        }

        this.props.continueSending();
        this.closeTooltip();
    }

    endSending = () => {
        const {invoiceContext, packageContext, endLink} = this.props;

        return invoiceContext
            ? this.handleInvoiceContext(endLink)
            : packageContext
                ? this.handlePackageContext(endLink)
                : undefined;
    };

    handleInvoiceContext = (endLink) => {
        window.location.href = endLink || this[unsentData].invoiceListLink;
    };

    handlePackageContext = (endLink) => {
        if (this[unsentData].packagesAmount !== 0) {
            toast(PACKAGE_SAVE_INFO_TOAST({refresh: true}));
        }
        window.location.href = endLink || this[unsentData].packageListLink;
    };

    getTooltip = () => {
        const {proceedWithSending} = this.props;
        const {overlayHeader} = this.state;

        return (<Tooltip id="tooltip-top">
            <div className="fw-text-block">{overlayHeader}</div>
            <div className="fw-button-block">
                <Button styleType="A" text={<I18nMessage id="fw.unsent.bottomPanel.end"/>} onClick={this.endSending}/>
                <Button styleType="B" text={proceedWithSending ? <I18nMessage id="fw.unsent.bottomPanel.proceed"/> :
                    <I18nMessage id="fw.unsent.bottomPanel.continue"/>}
                        onClick={proceedWithSending ? this.goToSending : this.continueSending}/>
            </div>
        </Tooltip>)
    }

    isShowTooltip = () => {
        const {showTooltip, overlayHeader} = this.state;
        return showTooltip && (overlayHeader !== EMPTY_STRING);
    }

    render() {
        const {children} = this.props;
        const showTooltip = this.isShowTooltip();

        return (
            <div ref={(c) => this._container = c}
                 onClick={(event) => {
                     event.nativeEvent.stopImmediatePropagation()
                 }}
                 className="fw-unsent-block">

                {showTooltip && <>
                    <div className="modal-backdrop in"/>
                    <Overlay show={showTooltip}
                             placement="top"
                             container={this._container}
                             target={this._selectComponent}>
                        {this.getTooltip()}
                    </Overlay>
                </>}
                <div ref={c => this._selectComponent = c}>
                    {children}
                </div>
            </div>
        )
    }
}

export default connect()(injectIntl(SendButtonOverlay));