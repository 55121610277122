import React from "react";
import {ErrorMessage} from "assecobs-faktor-web-common/forms";
import _ from "lodash";
import classnames from "classnames";

export default ({emailOnChange, input, submitEmail, errorMessage}) => (
    <div>
        <input type="email" maxLength={1000} onChange={(event) => {
            emailOnChange(event.target.value.trim());
            input.onChange(event.target.value.trim());
        }}
               className={classnames({
                   "k-invalid": errorMessage
               })}
               onKeyPress={(event) => {
                   if (_.isEqual(event.key, 'Enter')) {
                       submitEmail();
                   }
               }}/>
        {errorMessage && <ErrorMessage message={errorMessage}/>}
    </div>
)
