import {configureStore as configureCommonStore} from "@assecobs/react-utils/store"


export default async function configureStore(rootReducer) {

    const store = await configureCommonStore(rootReducer);

    return {
        ...store
    };

}
