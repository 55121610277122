import React from "react";
import {fwUrlUtils} from "assecobs-faktor-web-common";
import Button from "../../button/Button";
import {RestService} from "../../index";
import OptionPlaceholder from "./OptionPlaceholder";
import PropTypes from "prop-types";

export default class UserProfileItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {userName: ""};
    }

    componentDidMount = async () => {
        const url = fwUrlUtils.getApiUrl("/webapi/settings/user/fullName");
        const data = await RestService.getJSON(url);
        this.setState({userName: data.name});
    };

    goToUserProfile = () => {
        window.location.href = fwUrlUtils.getContextUrl("/settings/userProfile");
    };

    render() {

        return (
            <Button styleType="D"
                    icon="abs_person"
                    text={(
                        <OptionPlaceholder titleKey="fw.user">
                            {this.state.userName}
                        </OptionPlaceholder>
                    )}
                    onClick={this.goToUserProfile}
                    className="quick-settings-item fw-menu-configuration__btn"
            />
        )
    }

    static contextTypes = {
        userContext: PropTypes.any
    }

}
