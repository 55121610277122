import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {TemplateType} from "../types/TemplateType";

type SendAttachmentToastType = {
    refresh: boolean,
    maxFileSize: number
}

interface ToastDangerSendAttachmentErrorTemplateType extends TemplateType {
    templateProps: SendAttachmentToastType;
}

const ToastDangerSendAttachmentErrorTemplate = ({
                                                    templateProps,
                                                    removeToast,
                                                    id,
                                                    timeOut = 5000
                                                }: ToastDangerSendAttachmentErrorTemplateType): ReactElement => {

    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id="fw.attachment.adding.error.toast.header"/>
    const messageBody = <I18nMessage id="fw.attachment.adding.maxBunchFilesSize"
                                     values={{maxFileSize: templateProps.maxFileSize}}/>
    
    return (
        <div className={`toastContainer--danger ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastDangerSendAttachmentErrorTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastDangerSendAttachmentErrorTemplate