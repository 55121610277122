import React from "react";
import BaseAgreementSingleOptionTemplate from "./BaseAgreementSingleOptionTemplate";
import BaseAgreementSingleSelectedTemplate from "./BaseAgreementSingleSelectedTemplate";
import {SingleSelectFilterComponent} from "assecobs-react-data-table/index";
import baseAgreementFilterParams from "./baseAgreementFilterParams";
import {injectIntl} from 'react-intl';

class BaseAgreementSingleSelect extends React.Component {

    render() {

        const {baseAgreements, agreementsHandler, selectedBaseAgreementId, isFetching, intl} = this.props;

        let selectedBaseAgreement = baseAgreements.find((baseAgreement) =>
            baseAgreement.get("baseAgreementAnnex0Id") === +selectedBaseAgreementId
        );


        return (
            <SingleSelectFilterComponent
                {...baseAgreementFilterParams}
                searchPlaceholder={intl.formatMessage({id: "fw.filter.baseAgreement.searchPlaceholder"})}
                options={baseAgreements.toJS()}
                initialValue={selectedBaseAgreement ? selectedBaseAgreement.toJS() : undefined}
                loading={isFetching}
                onChange={agreementsHandler}
                clearable={false}
                optionTemplate={BaseAgreementSingleOptionTemplate}
                selectedValueTemplate={BaseAgreementSingleSelectedTemplate}
            />
        );
    }
}

export default injectIntl(BaseAgreementSingleSelect);