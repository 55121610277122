import {ReactElement} from 'react'
import ToastContentTemplate from './ToastContentTemplate'
import {useToastWrapperTemplate} from '../hooks/useToastWrapperTemplate'
import {
    ToastWrapperDefaultTemplatePropTypes
} from '@assecobs-react-components/toaster/src/types/ToastWrapperDefaultTemplatePropTypes'
// @ts-ignore
import {I18nMessage, Link} from "assecobs-faktor-web-common/index";
// @ts-ignore
import _ from "lodash";
import {TemplateType} from "../types/TemplateType";

const SEND = 1;

type PackageSaveToastType = {
    packageNumber: string,
    packageLink: string,
    typeOperation: number,
    refresh: boolean
}

interface ToastInfoForPackageSaveTemplateType extends TemplateType {
    templateProps: PackageSaveToastType;
}

const ToastInfoForPackageSaveTemplate = ({
                                             templateProps,
                                             removeToast,
                                             id,
                                             timeOut = 5000
                                         }: ToastInfoForPackageSaveTemplateType): ReactElement => {

    const {className, onAnimationEnd, onClose} = useToastWrapperTemplate({id, removeToast})
    const messageHeader = <I18nMessage id={_.isEqual(templateProps.typeOperation, SEND)
        ? "fw.import.packageSend.toast.header"
        : "fw.import.packageSave.toast.header"}/>
    const messageBody = <><I18nMessage id="fw.package.no"/>: {templateProps.packageNumber}<br/></>
    const messageFooter = <Link url={templateProps.packageLink}
                                text={<I18nMessage id="fw.import.package.toast.linkText"/>} external/>

    return (
        <div className={`toastContainer--info ${className}`} onAnimationEnd={onAnimationEnd}>
            <ToastContentTemplate onClose={onClose}
                                  content={{
                                      ...templateProps,
                                      header: messageHeader,
                                      body: messageBody,
                                      footer: messageFooter
                                  }}
                                  timeOut={timeOut}/>
        </div>
    )
}

ToastInfoForPackageSaveTemplate.propTypes = ToastWrapperDefaultTemplatePropTypes

export default ToastInfoForPackageSaveTemplate