import React from 'react'
import PropTypes from 'prop-types'
import {useTile} from './hooks/useTile'

interface TileProps {
    value: boolean,
    classNameTile?: string,
    onChangeValue: ((value: boolean) => void),
    //@ts-ignore
    headerTemplate: () => React.ReactElement | null,
    bodyTemplate: () => React.ReactElement | null,
    unreadCount: number
}

const Tile = ({
                  value = true,
                  onChangeValue = () => {
                  },
                  classNameTile = '',
                  headerTemplate = () => null,
                  bodyTemplate = () => null
              }: TileProps): React.ReactElement | null => {

    const {selectedValue} = useTile({
        value,
        onChangeValue
    })

    if (!selectedValue) {
        return null;
    }

    return (
        <div className={`fw-alerts-box ${classNameTile}`}>
            {headerTemplate()}
            {bodyTemplate()}
        </div>
    );
}

Tile.propTypes = {
    value: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    classNameTile: PropTypes.string,
    titleTemplate: PropTypes.func.isRequired,
    bodyTemplate: PropTypes.func.isRequired
}

export default Tile