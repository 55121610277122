import React from 'react';
import PropTypes from 'prop-types';

import InputSelect from "@assecobs-react-components/input-select/src/InputSelect"
import {RestService, ContainerLoader} from "assecobs-faktor-web-common/index";
import RemainingSignsLabel from "./RemainingSignsLabel"
import classnames from "classnames";

const INVALID_ERROR = "fw.form.error.required"

const InputSelectPreset = ({input, invalid, url, maxLength = null}) => {

    const [dataSource, setDataSource] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [focusedClassname, setFocusedClassname] = React.useState("")

    React.useEffect(() => {
        loadOptions()
    }, [])

    const loadOptions = async () => {
        const dataSource = (await RestService.getJSON(url)).list;
        setLoading(false)
        const preparedDS = dataSource.map(option => option.description)
        setDataSource(preparedDS)
    }

    const onChangeValue = value => {
        input.onChange(value)
    }

    const onFocus = props => {
        setFocusedClassname("is-focused")
        input.onFocus(props)
    }

    const onBlur = props => {
        setFocusedClassname("")
        input.onBlur(props)
    }

    return (
        <ContainerLoader loading={loading}>
            <InputSelect
                value={input.value}
                dataSource={dataSource}
                onChangeValue={onChangeValue}
                onFocus={onFocus}
                onBlur={onBlur}
                activeItemClassName="active"
                containerClassName={classnames("input-select", focusedClassname, {"invalid": invalid === INVALID_ERROR})}
                maxLength={maxLength}
            />
            {maxLength !== null && <RemainingSignsLabel valueLength={input.value.length} maxLength={maxLength}/>}
        </ContainerLoader>
    )
};

InputSelectPreset.propTypes = {
    input: PropTypes.object.isRequired,
    invalid: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    url: PropTypes.string.isRequired,
    maxLength: PropTypes.number
};

export default InputSelectPreset;