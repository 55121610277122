const cache = Symbol();
const javaFormatMapping = Symbol();
const translateFormat = Symbol();
const appendMappedString = Symbol();

class DateFormat {

    constructor() {
        this[cache] = {};
        this[javaFormatMapping] = {
            "d": "D", // (day in month - one or two digits)"),
            "dd": "DD", // (day in month - two digits)"),
            "D": "DDD", // (day in year - one to three digits)"),
            "DD": "", // (day in year- two or three digits)"),
            "DDD": "DDDD", // (day in year- three digits)"),
            "F": "", // (day of week in month)"),
            "y": "YYYY", // (year - every digit)"),
            "yy": "YY", // (year - two digits)"),
            "yyy": "YYYY", // (year - three digits)"),
            "yyyy": "YYYY", // (year - every digit)"),
            "Y": "", // (week year - two digits)"),
            "YY": "gg", // (week year - two digits)"),
            "YYY": "", // (week year - three digits)"),
            "YYYY": "gggg", // (week year - all digits)"),
            "a": "A", // (AM or PM)"),
            "G": "", // (era - AD or BC)"),
            "M": "M", // (month in year - two digits, 1..12)"),
            "MM": "MM", // (month in year - two digits, 01..12)"),
            "MMM": "MMM", // (month in year - short text)"),
            "MMMM": "MMMM ", // (month in year - full text)"),
            "h": "h", // (hour - one or two digits, 12 hours, 1..12)"),
            "hh": "hh", // (hour - two digits, 12 hours, 01..12)"),
            "H": "H", // (hour - one or two digits, 24 hours, 0..23)"),
            "HH": "HH", // (hour - two digits, 24 hours, 00..23)"),
            "k": "k", // (hour - one or two digits, 12 hours, 1..24)"),
            "kk": "kk", // (hour - two digits, 24 hours, 01..24)"),
            "K": "", // (hour - one or two digits, 12 hours, 0..11)"),
            "KK": "", // (hour - two digits, 12 hours, 00..11)"),
            "m": "m", // (minutes - one or two digits)"),
            "mm": "mm", // (minutes - two digits)"),
            "s": "s", // (seconds- one or two digits)"),
            "ss": "ss", // (seconds - two digits)"),
            "S": "S", // (millisecond)"),
            "SS": "SS ", // (millisecond)"),
            "SSS": "SSS ", // (millisecond)"),
            "E": "ddd", // (day name in week - short)"),
            "EE": "ddd ", // (day name in week - short)"),
            "EEE": "ddd ", // (day name in week - short)"),
            "EEEE": "dddd ", // (day name in week - full)"),
            "w": "W", // (week in year - one or two digits)"),
            "ww": "WW", // (week in year - two digits, zero-padded)"),
            "W": "", // (week in month - one or two digits)"),
            "WW": "", // (week in month - two digits, zero-padded)"),
            "z": "", // (General time zone)"),
            "zz": "", // (General time zone)"),
            "zzz": "", // (General time zone)"),
            "zzzz": "", // (General time zone)"),
            "Z": "ZZ", // (RFC 822 time zone)"),
            "X": "", // (ISO 8601 time zone -  - hours only)"),
            "XX": "ZZ", // (ISO 8601 time zone - short)"),
            "XXX": "Z", // (ISO 8601 time zone - long)"),
            "u": "E", // (day number of week - 1=Monday, 7=Sunday)"),
            "'": "[]" // (escape character),
        }
    }

    j2m(javaDateFormat) {
        if (!this[cache][javaDateFormat]) {
            let mapped = "";
            const regexp = /[^']+|('[^']*')/g;
            let part;

            while ((part = regexp.exec(javaDateFormat))) {
                part = part[0];
                if (part.match(/'.?'/)) {
                    mapped += "[" + part.substring(1, part.length - 1) + "]";
                }
                else {
                    mapped += this[translateFormat](part, this[javaFormatMapping]);
                }
            }

            this[cache][javaDateFormat] = mapped;
        }
        return this[cache][javaDateFormat];
    };

    [translateFormat](formatString, mapping) {
        let len = formatString.length,
            i = 0,
            beginIndex = -1,
            lastChar = null,
            currentChar = "",
            resultString = "";

        for (; i < len; i++) {
            currentChar = formatString.charAt(i);

            if (lastChar === null || lastChar !== currentChar) {
                // change detected
                resultString = this[appendMappedString](formatString, mapping, beginIndex, i, resultString);

                beginIndex = i;
            }

            lastChar = currentChar;
        }

        return this[appendMappedString](formatString, mapping, beginIndex, i, resultString);
    };

    [appendMappedString](formatString, mapping, beginIndex, currentIndex, resultString) {
        let tempString;

        if (beginIndex !== -1) {
            tempString = formatString.substring(beginIndex, currentIndex);
            // check if the temporary string has a known mapping
            if (mapping[tempString]) {
                tempString = mapping[tempString];
            }
            resultString = resultString.concat(tempString);
        }
        return resultString;
    };
}

export default new DateFormat();
