import React from "react";
import PropTypes from "prop-types";

export default class UserContext extends React.Component {

    getChildContext() {
        return {userContext: this.props.userContext};
    }

    render() {

        return (
            this.props.children
        );
    }

    static childContextTypes = {
        userContext: PropTypes.any
    }
}
