import MessageType from "./MessageType";

class MessageFactory {

    info = (msg) => (
        {
            type: MessageType.INFO,
            text: msg
        }
    );

    error = (msg) => (
        {
            type: MessageType.ERROR,
            text: msg
        }
    );
}

export default new MessageFactory();
